import React from 'react'

import { get, isEmpty } from 'lodash-es'

import styles from './lessonThumbnail.module.scss'
import LessonReviewThumbnail from './lt_lesson_review_icon.jpg'
import DefaultButton from '../../atoms/buttons/defaultButton/DefaultButton'
import PrimaryButton from '../../atoms/buttons/primaryButton/PrimaryButton'
import ReplayIcon from '../../atoms/icons/ReplayIcon'

type Props = {
  content?: $TSFixMe
  order?: string
  onClick?: $TSFixMeFunction
}

// @ts-expect-error TS(7030): Not all code paths return a value.
const LessonThumbnail = ({ content, onClick, order, ...rest }: Props) => {
  if (isEmpty(content)) return null

  const renderVideoThumbnail = () => {
    return (
      <DefaultButton
        unstyled
        onClick={onClick}
        className={styles['video-container']}
        role='button'
        aria-label='play video'
        {...rest}
      >
        <img src={content.meta_data.thumbnail} alt={`${content.title} video thumbnail`} />
        {order === 'next' ? (
          <>
            <div className={styles.title}>{content.title}</div>,
            <div className={styles.circle}>
              <svg
                className={styles.play}
                xmlns='http://www.w3.org/2000/svg'
                width='25%'
                height='33'
                viewBox='0 0 27 33'
              >
                <polygon fill='#008599' fillRule='evenodd' points='27 16.5 0 33 0 0' />
              </svg>
            </div>
          </>
        ) : (
          <div className={styles.circle}>
            <ReplayIcon />
          </div>
        )}
      </DefaultButton>
    )
  }

  const renderFormThumbnail = () => {
    return (
      <DefaultButton unstyled className={styles['recap-container']} role='button' aria-label='go to review' {...rest}>
        <div className={styles['image-container']}>
          <img src={LessonReviewThumbnail} alt={`${content.title} review thumbnail`} />
        </div>
        <div className={styles.content}>
          <div>
            <div className={styles.title}>Recap Questions</div>
            <div className={styles.length}>
              {Object.keys(get(content, 'meta_data.schema.properties')).length} questions
            </div>
          </div>
          <PrimaryButton onClick={onClick} isSmall>
            Start
          </PrimaryButton>
        </div>
      </DefaultButton>
    )
  }

  if (content.content_type === 'video') {
    return renderVideoThumbnail()
  } else if (content.content_type === 'form') {
    return renderFormThumbnail()
  }
}

export default LessonThumbnail
