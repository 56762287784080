import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { cloneDeep, isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import {
  Capacity,
  ProviderAdminProviderInfo,
  ProviderAvailability,
  ProviderExpeditedBookingConfig,
} from '@lyrahealth-inc/shared-app-logic'
import { colors, Link, PrimaryButton, SecondaryButton, SelectField, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import InPersonToggle from './InPersonToggle'
import styles from './providerAvailabilityModal.module.scss'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { ExpeditedBookingToggle } from '../../common/components/forms/expeditedBooking/ExpeditedBookingToggle'
import { LT_PROVIDER_MAX_CAPACITY, programConfig, programNameTitleMap } from '../../common/constants/appConstants'
import { programIconMap } from '../../common/constants/programIconMap'
import { actionAlertHandler, actionStyles } from '../../common/constants/reduxConstants'
import { logToSumoLogic } from '../../common/utils/utils'
import { getAuthConfig, getAuthExpeditedBookingConfig } from '../../data/auth/authSelectors'
import {
  updateExpeditedBookingStatus,
  updateInPersonDesireStatus,
  updateProviderCapacity,
  updateProviderGracePeriod,
  updateSelectedProviderCapacity,
} from '../../providers/data/providersDataActions'
import OutOfOfficeContainer from '../../providers/outOfOffice/OutOfOfficeContainer'
import { setToastContent } from '../data/ltToastAutoActions'

const ProgramNameContainer = styled.div`
  margin-bottom: 0;
  display: flex;

  & > div:first-child {
    margin-bottom: 8px;
    width: 100%;
  }
`

const Tabs = styled.div`
  border-bottom: 1px solid ${colors.charcoal2};
  display: flex;
`

const TabsHeader = styled.div`
  margin-top: 24px;

  h4 {
    line-height: 100%;
  }
`

const ModalNavigation = styled.div`
  display: flex;
  justify-content: end;

  div[role='button'] {
    + div[role='button'] {
      margin-left: 24px;
    }
  }
  gap: 16px;
`
const DecorativeIconContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 9px;
  left: 8px;
`

const StyledLink = styled.div<{ isSelected: boolean; extraMarginLeft?: boolean }>`
  margin-left: ${(props) => (props.extraMarginLeft ? '8px' : '')};
  position: relative;

  &:after {
    bottom: -1px;
    background-color: ${(props) => (props.isSelected ? colors.primary_teal4 : colors.semiDarkGray)};
    border-radius: 5px;
    height: ${(props) => (props.isSelected ? '4px' : '0')};
    content: '';
    position: absolute;
    width: 100%;
  }
`

const AvailabilityModalOpened = styled.div({
  marginTop: '36px',
})

const ProviderAvailabilityModal: React.FC<ProviderAvailabilityModalProps> = ({
  providerId,
  selectedProvider,
  closeModal,
  currentCapacity,
  providerAvailabilities,
  triggerMismatchModal,
  setMismatchModalCount,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  actions: { updateProviderCapacity, updateExpeditedBookingStatus, updateSelectedProviderCapacity, setToastContent },
}) => {
  const dispatch = useDispatch()
  const providerExpeditedBookingConfig: ProviderExpeditedBookingConfig | undefined =
    useSelector(getAuthExpeditedBookingConfig)
  const config = useSelector(getAuthConfig)
  const tabs = {
    AVAILABILITY: 'New client capacity',
    AWAY_MESSAGE: 'Out of office message',
  }

  const [loadingCapacity, setLoadingCapacity] = useState(false)
  const [expeditedBookingErrors, setExpeditedBookingErrors] = useState({})
  const [expeditedBookingPayload, setExpeditedBookingPayload] = useState({})
  const [showInPersonSuccess, setShowInPersonSuccess] = useState(false)
  const [selectedCapacities, setSelectedCapacities] = useState(currentCapacity)
  const [selectedTab, setSelectedTab] = useState(tabs.AVAILABILITY)
  const [showTabs, setShowTabs] = useState(!!config?.bcPlatform)

  useEffect(() => {
    if (providerExpeditedBookingConfig) {
      setExpeditedBookingPayload(providerExpeditedBookingConfig)
    }
  }, [providerExpeditedBookingConfig])
  const onUpdateAvailability = () => {
    track({
      event: 'UPDATE_CAPACITY',
      details: `New capacity: ${{ ...selectedCapacities }}`,
    })
    setLoadingCapacity(true)
    const updateCapacity = isEmpty(selectedProvider) ? updateProviderCapacity : updateSelectedProviderCapacity
    for (const [key, value] of Object.entries(expeditedBookingPayload)) {
      const valueDict = value as Dict
      if (
        valueDict &&
        valueDict?.enabled &&
        (!valueDict.hasOwnProperty('grace_period') ||
          !Number.isInteger(valueDict.grace_period) ||
          valueDict.grace_period === 0)
      ) {
        setExpeditedBookingErrors({ [key]: true })
        setLoadingCapacity(false)
        return
      }
    }

    const promises = []
    let totalCombinedCapacities = 0

    // if user submits dropdown with the option of 'Select', an empty string is passed, but we convert it to a 0
    for (const [key, value] of Object.entries(selectedCapacities)) {
      totalCombinedCapacities += selectedCapacities[key]
      if (!Number.isInteger(value)) {
        selectedCapacities[key] = 0
      }
    }

    // always update capacities even if it is unchanged, because we use timestamp of last update for DA providers
    promises.push(
      updateCapacity({
        id: providerId,
        data: { capacity: { ...selectedCapacities } },
      }),
    )

    let expeditedBookingValuesChanged = false
    // if we submit a program with disabled Expedited, we want the grace_period to be 0
    if (expeditedBookingPayload && Object.keys(expeditedBookingPayload).length > 0) {
      const cleanedPayload = cloneDeep(expeditedBookingPayload)
      for (const key of Object.keys(expeditedBookingPayload)) {
        const programValues = expeditedBookingPayload[key]
        if (!programValues.enabled && Number.isInteger(programValues.grace_period)) {
          cleanedPayload[key].grace_period = 0
        }
        if (
          (providerExpeditedBookingConfig &&
            providerExpeditedBookingConfig[key] &&
            (providerExpeditedBookingConfig[key].enabled !== programValues.enabled ||
              providerExpeditedBookingConfig[key].grace_period !== programValues.grace_period)) ||
          ((providerExpeditedBookingConfig === undefined || providerExpeditedBookingConfig === null) && cleanedPayload)
        ) {
          expeditedBookingValuesChanged = true
        }
      }

      if (expeditedBookingValuesChanged) {
        track({ event: 'UPDATING_EXPEDITED_BOOKING' })
        promises.push(
          updateExpeditedBookingStatus({ id: providerId, data: { value: cleanedPayload, value_type: 'json' } }),
        )
      }
    }

    Promise.all(promises)
      .catch((error: string) => {
        actionAlertHandler({
          actionStyle: actionStyles.ERROR,
          message: error,
          dispatch,
          action: undefined,
          expires: undefined,
        })
      })
      .finally(() => {
        closeModal()
        setToastContent({
          text: 'Capacity set!',
          id: 'Capacity-Saved-Toast-Success',
          toastType: 'success',
        })
        Array.isArray(providerAvailabilities) &&
          logToSumoLogic('providerCalendarAvailableSlots', providerId, {
            providerAvailabilitiesCount: providerAvailabilities?.length,
            action: 'Provider updated capacity',
            providerId: providerId,
          })
        if (
          triggerMismatchModal &&
          Array.isArray(providerAvailabilities) &&
          totalCombinedCapacities > providerAvailabilities.length
        ) {
          setMismatchModalCount(totalCombinedCapacities - providerAvailabilities.length)
          triggerMismatchModal()
        }
        setLoadingCapacity(false)
        setShowInPersonSuccess(false)
      })
  }

  const renderCapacityInputs = (currentCapacity: Capacity) => {
    return Object.entries(currentCapacity).map(([programName]) => {
      const ProgramIcon = programConfig[programName]?.icon && programIconMap[programConfig[programName].icon]
      const programSameDayAvailability =
        (expeditedBookingPayload && expeditedBookingPayload[programName]?.enabled) || false
      const maxCapacity = programConfig[programName]?.maxCapacity ?? LT_PROVIDER_MAX_CAPACITY
      return (
        <React.Fragment key={programName}>
          <h4>{programNameTitleMap[programName]}</h4>
          <ProgramNameContainer>
            <SelectField
              onChange={(value) => {
                setSelectedCapacities({
                  ...selectedCapacities,
                  [programName]: value,
                })
              }}
              value={selectedCapacities[programName]}
              name={`ProviderAvailabilityModal-capacity-${programName}`}
              options={[...Array(maxCapacity + 1).keys()].map((i) => {
                return {
                  label: i === 0 ? '0 - Not available for new Lyra clients' : `${i}`,
                  value: i,
                }
              })}
              // @ts-expect-error TS(2322): Type '"" | Element' is not assignable to type 'Rea... Remove this comment to see the full error message
              decorativeIcon={
                programConfig[programName]?.icon && (
                  <DecorativeIconContainer>
                    <ProgramIcon width={30} height={30} />
                  </DecorativeIconContainer>
                )
              }
            />
          </ProgramNameContainer>

          {config?.sameDayBooking && (
            <ExpeditedBookingToggle
              gracePeriod={expeditedBookingPayload && expeditedBookingPayload[programName]?.grace_period}
              onChangeToggle={(programName, status) => {
                track({ event: 'TOGGLED_EXPEDITED_BOOKING' })
                setExpeditedBookingPayload((prevState) => {
                  const updated = { ...prevState }
                  updated[programName] = { ...updated[programName], enabled: status }
                  return updated
                })
              }}
              onChangeGracePeriod={(programName, buffer) => {
                track({ event: 'SELECTED_EXPEDITED_BOOKING_GRACE_PERIOD' })
                setExpeditedBookingErrors({})
                setExpeditedBookingPayload((prevState) => {
                  const updated = { ...prevState }
                  updated[programName] = { ...updated[programName], grace_period: buffer }
                  return updated
                })
              }}
              programName={programName}
              status={programSameDayAvailability}
              displayError={expeditedBookingErrors[programName]}
            />
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <AvailabilityModalOpened data-test-id='AvailabilityModal-opened'>
      {(showTabs || !config?.bcPlatform) && <h3 style={{ margin: '0px 0 30px 0' }}>Update capacity</h3>}
      {showTabs && (
        <Tabs>
          <StyledLink isSelected={selectedTab === tabs.AVAILABILITY}>
            <Link
              color={selectedTab === tabs.AVAILABILITY ? colors.primary_teal4 : colors.semiDarkGray}
              testID={tID('ProviderAvailability-availability')}
              onPress={() => setSelectedTab(tabs.AVAILABILITY)}
              style={{
                padding: 6,
                paddingBottom: 8,
                fontSize: 12,
                textTransform: 'uppercase',
              }}
              text={tabs.AVAILABILITY}
            />
          </StyledLink>
          <StyledLink isSelected={selectedTab === tabs.AWAY_MESSAGE} extraMarginLeft>
            <Link
              color={selectedTab === tabs.AWAY_MESSAGE ? colors.primary_teal4 : colors.semiDarkGray}
              testID={tID('ProviderAvailability-outOfOffice')}
              onPress={() => {
                track({ event: 'SHOW_OOO_TAB' })
                setSelectedTab(tabs.AWAY_MESSAGE)
              }}
              style={{
                padding: 6,
                paddingBottom: 8,
                fontSize: 12,
                textTransform: 'uppercase',
              }}
              text={tabs.AWAY_MESSAGE}
            />
          </StyledLink>
        </Tabs>
      )}

      {selectedTab === tabs.AVAILABILITY ? (
        <>
          <TabsHeader>{renderCapacityInputs(currentCapacity)}</TabsHeader>
          <ModalNavigation>
            <SecondaryButton
              fullWidth={true}
              text={'Cancel'}
              onPress={() => {
                closeModal()
                setShowInPersonSuccess(false)
              }}
              testID={tID('ProviderAvailabilityModal-cancel')}
            />
            <PrimaryButton
              fullWidth={true}
              text={'Save'}
              onPress={onUpdateAvailability}
              testID={tID('ProviderAvailabilityModal-update')}
              loading={loadingCapacity}
            />
          </ModalNavigation>
          <InPersonToggle
            showInPersonSuccess={showInPersonSuccess}
            onPressAfterUpdate={() => setShowInPersonSuccess(true)}
            providerId={providerId}
            testID={tID('ProviderAvailabilityModal-in-person-button')}
            customStyles={styles}
          />
        </>
      ) : (
        <OutOfOfficeContainer closeModal={closeModal} setShowTabs={setShowTabs} />
      )}
    </AvailabilityModalOpened>
  )
}

type ProviderAvailabilityModalProps = {
  currentCapacity: Capacity
  providerId: string
  selectedProvider: ProviderAdminProviderInfo | object
  closeModal: () => void
  providerAvailabilities: ProviderAvailability[]
  setMismatchModalCount: (count: number) => void
  triggerMismatchModal?: () => void
  actions: {
    updateProviderCapacity: ({ id, data }: { id: string; data: { capacity: Capacity } }) => Promise<any>
    updateSelectedProviderCapacity: ({ id, data }: { id: string; data: { capacity: Capacity } }) => Promise<any>
    updateExpeditedBookingStatus: ({
      id,
      data,
    }: {
      id: string
      data: { value: ProviderExpeditedBookingConfig; value_type: string }
    }) => Promise<any>
    updateInPersonDesireStatus: ({ id, data }: { id: string; data: { in_person_preference: boolean } }) => Promise<any>
    setToastContent: ({ text, id, toastType }: { text: string; id: string; toastType: string }) => void
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    actions: bindActionCreators(
      {
        updateProviderCapacity: updateProviderCapacity as unknown as any,
        updateProviderGracePeriod: updateProviderGracePeriod as unknown as any,
        updateExpeditedBookingStatus: updateExpeditedBookingStatus as unknown as any,
        updateSelectedProviderCapacity: updateSelectedProviderCapacity as unknown as any,
        updateInPersonDesireStatus: updateInPersonDesireStatus as unknown as any,
        setToastContent: setToastContent as unknown as any,
      },
      dispatch,
    ),
  }
}

export default connect(null, mapDispatchToProps)(ProviderAvailabilityModal)
