import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import classNames from 'classnames'
import { isToday } from 'date-fns'
import { cloneDeep } from 'lodash-es'
import moment from 'moment-timezone'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import {
  Appointment,
  ClientListClientObject,
  ClientObject,
  getAppointmentDateTimeObject,
  getDateFromAppointment,
  isExpeditedBookingAppointment,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { Dashlet, RefreshButton } from '@lyrahealth-inc/ui-core'
import { ExpeditedBookingIcon, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './upcomingSessionsDashlet.module.scss'
import { appointmentStatuses } from '../../../common/constants/appConstants'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { getClientFullName } from '../../../common/utils/utils'
import { useGetAppointmentsQuery } from '../../clients/clientDetails/data/appointmentsApi'
import { selectLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import { getLtClientsV2 } from '../../clients/data/ltClientsAutoActions'

interface ClientV2WithNextAppointment extends ClientListClientObject {
  next_appointment?: Appointment
}

const UpcomingSessionsDashlet: React.FC<UpcomingSessionsDashletProps> = ({
  clients,
  isLoading,
  providerId,
  actions: { selectLtClient, getLtClientsV2 },
}) => {
  const { data: appointments = [] } = useGetAppointmentsQuery({ providerId: providerId })
  const navigate = useNavigate()
  const intl = useIntl()
  const { isPreferredNameEnabled } = useFlags()

  const [isRefreshingSessions, setIsRefreshingSessions] = useState(false)
  const clientsWithUpcomingAppointments: ClientV2WithNextAppointment[] = []

  const appointmentsToday = appointments.filter((appt: Appointment) => {
    return (
      isToday(getDateFromAppointment(appt)) &&
      ![appointmentStatuses.completed, appointmentStatuses.missed, appointmentStatuses.canceled].includes(
        appt.appointmentStatus,
      )
    )
  })
  Array.isArray(clients) &&
    clients?.forEach((client: ClientObject) => {
      const nextAppointmentToday = appointmentsToday?.find((appt: Appointment) => appt.userId === client.id)
      nextAppointmentToday &&
        clientsWithUpcomingAppointments.push(
          Object.assign(cloneDeep(client), {
            next_appointment: nextAppointmentToday,
          }),
        )
    })
  clientsWithUpcomingAppointments.sort((a, b) => {
    return (
      getAppointmentDateTimeObject(a.next_appointment!).valueOf() -
      getAppointmentDateTimeObject(b.next_appointment!).valueOf()
    )
  })

  const renderClientListItem = (client: ClientV2WithNextAppointment) => {
    const appointmentTime = moment
      .tz(
        `${client?.next_appointment?.startDate} ${client?.next_appointment?.startTime}`,
        client?.next_appointment?.timeZone ?? 'America/Los_Angeles',
      )
      .toDate()
    return (
      <div className={styles['dashlet-item-container']}>
        <button
          className={styles['dashlet-item-content']}
          key={client.id}
          onClick={() => {
            selectLtClient(client)
            navigate(CLIENT_HOME.route)
          }}
          data-test-id={`UpcomingSessionDashlet-list-item-${`${client.first_name} ${client.last_name}`}`}
        >
          <div className={styles.left}>
            <div
              className={classNames(styles.time, {
                [styles.crossed]: new Date() > appointmentTime,
              })}
            >
              {intl.formatDate(appointmentTime, {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
            <div className={styles.name}>{getClientFullName(client, isPreferredNameEnabled)}</div>
          </div>
          <div className={styles.right}>
            {client.next_appointment && isExpeditedBookingAppointment(client.next_appointment) && (
              <div className={styles['icon-wrapper']}>
                <ExpeditedBookingIcon />
              </div>
            )}
            <div className={styles['session-count']}>{`Session ${client?.next_appointment?.sessionNumber}`}</div>
          </div>
        </button>
      </div>
    )
  }

  return (
    <div className={styles['dashlet-container']} data-test-id='UpcomingSessionDashlet-content'>
      <Dashlet
        title={`Today's upcoming sessions${isLoading ? '' : ` (${clientsWithUpcomingAppointments.length})`}`}
        buttons={
          <RefreshButton
            size={20}
            isLoading={isRefreshingSessions}
            onClick={() => {
              setIsRefreshingSessions(true)
              Promise.all([
                getLtClientsV2({ providerId, status: 'active' }),
                getLtClientsV2({ providerId, status: 'inactive' }),
              ]).then(() => {
                setIsRefreshingSessions(false)
              })
            }}
            data-testid={tID('UpcomingSessionDashlet-refreshButton')}
          />
        }
        initiallyExpanded={true}
        isLoading={isLoading}
        maxMinimizedChildren={isLoading ? 3 : Math.max(clientsWithUpcomingAppointments.length, 1)}
      >
        {clientsWithUpcomingAppointments.length === 0 ? (
          <div className={styles['empty-dashlet']}>No upcoming sessions today</div>
        ) : (
          clientsWithUpcomingAppointments.map(renderClientListItem)
        )}
      </Dashlet>
    </div>
  )
}

type UpcomingSessionsDashletProps = {
  clients: ClientObject[]
  isLoading: boolean
  providerId: string
  actions: {
    selectLtClient: (client: ClientV2WithNextAppointment) => void
    getLtClientsV2: ({ providerId, status }: { providerId: string; status: string }) => Promise<object>
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ selectLtClient, getLtClientsV2 }, dispatch),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: Upcoming... Remove this comment to see the full error message
export default connect(null, mapDispatchToProps)(UpcomingSessionsDashlet)
