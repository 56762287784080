import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { BCX_EVENTS, COMMON_MIXPANEL_EVENTS, useFlags, utilizationQuarters } from '@lyrahealth-inc/shared-app-logic'
import { CaseloadManagement, DropdownButton, LoadingIndicator } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  useAppointmentUtilizationData,
  useHistoricUtilizationData,
  useTargetUtilizationData,
  useUtilizationQuartersData,
} from './hooks'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import AvailabilityCard from '../../common/components/availabilityCard/AvailabilityCard'
import { CapacityAlertPopUp } from '../../common/components/capacityAlertPopUp/CapacityAlertPopUp'
import { BC_CASELOAD_DETAILED_WEEKLY_VIEW } from '../../common/constants/routingConstants'
import { getDropdownItemsFromQuarters, getPreviousQuartersSorted } from '../../common/utils/utils'
import { getAuthConfig, getAuthEmploymentStatus } from '../../data/auth/authSelectors'
import { getRequestedCaseloadManagementQuarter } from '../../data/lyraTherapy/clientSelectors'
import { useAppDispatch } from '../../data/store'
import {
  CalendarAvailabilitiesAlertPopUpValues,
  useCalendarAvailabilitiesAlertPopUp,
} from '../../hooks/useCalendarAvailabilitiesAlertPopUp'
import {
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
} from '../clients/clientDetails/data/appointmentsSlice'

const CaseloadManagementContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const { showPastQuartersInLCCDashboard, showCalendarAvailabilitiesWithCapacity } = useFlags()
  const showMinifiedUtilization = useSelector(getAuthConfig)?.showMinifiedUtilization ?? false
  const navigate = useNavigate()

  const [currentQuarter, setCurrentQuarter] = useState<string | null>(null)
  const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null)

  const {
    data: historicUtilization,
    isLoading: isLoadingHistoricData,
    isFetching: isFetchingHistoricData,
  } = useHistoricUtilizationData(undefined, {
    skip: !showMinifiedUtilization,
  })
  const {
    data: appointmentUtilization,
    isLoading: isLoadingAppointmentData,
    isFetching: isFetchingAppointmentData,
  } = useAppointmentUtilizationData()
  const {
    data: targetUtilization,
    isLoading: isLoadingTargetData,
    isFetching: isFetchingTargetData,
  } = useTargetUtilizationData()
  const {
    data: utilizationQuarters,
    isLoading: isLoadingQuartersData,
    isFetching: isFetchingQuartersData,
  } = useUtilizationQuartersData()

  const isLoadingUtilizationData =
    isLoadingHistoricData || isLoadingAppointmentData || isLoadingTargetData || isLoadingQuartersData

  const isLoadingWeeklySnapshot = useMemo(
    () =>
      (isFetchingAppointmentData && !isLoadingAppointmentData) ||
      (isFetchingTargetData && !isLoadingTargetData) ||
      (isFetchingQuartersData && !isLoadingQuartersData) ||
      (isFetchingHistoricData && !isLoadingHistoricData),
    [
      isFetchingAppointmentData,
      isFetchingHistoricData,
      isFetchingQuartersData,
      isFetchingTargetData,
      isLoadingAppointmentData,
      isLoadingHistoricData,
      isLoadingQuartersData,
      isLoadingTargetData,
    ],
  )
  useEffect(() => {
    if (!showPastQuartersInLCCDashboard || !utilizationQuarters) {
      return
    }
    const sortedQuarters = getPreviousQuartersSorted({
      availableQuarters: utilizationQuarters,
      howManyQuartersToFetch: 2,
    })
    const quarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : sortedQuarters[0]
    setCurrentQuarter(sortedQuarters[0])
    setSelectedQuarter(quarterName)
  }, [showPastQuartersInLCCDashboard, utilizationQuarters, requestedQuarter])

  const onPressDetailedWeeklyView = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_QUARTERLY_DETAILS,
    })
    navigate(BC_CASELOAD_DETAILED_WEEKLY_VIEW.route)
  }, [navigate])

  const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''

  const onViewHelpArticle = () => {
    track({
      event: COMMON_MIXPANEL_EVENTS.BUTTON_PRESS,
      action: BCX_EVENTS.VIEW_CASELOAD_MANAGEMENT_ARTICLE,
    })
    window.open('https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/7265611191827-Caseload-Dashboard', '_blank')
  }

  let preselectedValue = ''
  if (requestedQuarter) {
    const requestedQuarterName = Object.keys(requestedQuarter)[0]
    const dropdownAppend = requestedQuarterName === currentQuarter ? ' Current' : ''
    preselectedValue = `${requestedQuarterName.replace('_', ' ')}${dropdownAppend}`
  }

  const dropdownClickHandler = (quarter: string) => {
    dispatch(setCurrentQuarterCMDDetailedWeeklyView(currentQuarter))
    setSelectedQuarter(quarter)
    if (utilizationQuarters) {
      dispatch(setCurrentQuarterCMD({ [quarter]: utilizationQuarters[quarter] }))
    }
  }
  const dropdownWithHandlers = (
    <DropdownButton
      dropdownItems={getDropdownItemsFromQuarters(
        getPreviousQuartersSorted({ availableQuarters: utilizationQuarters, howManyQuartersToFetch: 2 }),
        dropdownClickHandler,
      )}
      dropdownWidth={210}
      preselectedValue={preselectedValue}
    />
  )
  const {
    alertText,
    handleCloseAlertPopUpModal,
    handleLearnMore,
    handleOpenCalendar,
    hasProperRoleToSeeAlert,
    isAlertPopUpOpen,
  }: CalendarAvailabilitiesAlertPopUpValues = useCalendarAvailabilitiesAlertPopUp()

  if (isLoadingUtilizationData) {
    return <LoadingIndicator size={45} />
  }

  if (!appointmentUtilization || !historicUtilization || !targetUtilization) {
    return null
  }

  return (
    <>
      {hasProperRoleToSeeAlert && showCalendarAvailabilitiesWithCapacity && isAlertPopUpOpen && (
        <CapacityAlertPopUp
          alertText={alertText}
          handleOpenCalendar={handleOpenCalendar}
          handleLearnMore={handleLearnMore}
          onClose={handleCloseAlertPopUpModal}
        />
      )}
      <CaseloadManagement
        viewHelpArticle={onViewHelpArticle}
        availabilityCard={<AvailabilityCard isMinified />}
        appointmentUtilization={appointmentUtilization}
        historicUtilization={historicUtilization}
        targetUtilization={targetUtilization}
        employmentStatus={employmentStatus}
        handleDetailedWeeklyView={onPressDetailedWeeklyView}
        dropdownWithHandlers={dropdownWithHandlers}
        isLoadingWeeklySnapshot={isLoadingWeeklySnapshot}
        requestedQuarterName={requestedQuarterName !== currentQuarter ? requestedQuarterName : ''}
        shouldShowPastQuartersInBCXDashboards={showPastQuartersInLCCDashboard}
        utilizationQuarters={utilizationQuarters}
        selectedQuarter={selectedQuarter}
        currentQuarter={currentQuarter}
      />
    </>
  )
}

export default CaseloadManagementContainer
