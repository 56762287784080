import { useSelector } from 'react-redux'

import { skipToken } from '@reduxjs/toolkit/query'

import { getAuthUserId } from '../../../../../data/auth/authSelectors'
import { RTKQueryHook, RTKQueryResultType } from '../../../../../data/baseApi'
import { getClientDetailsData } from '../../../../../data/lyraTherapy/clientSelectors'
import { useGetAppointmentsForPatientQuery } from '../appointmentsApi'

export const useClientAppointmentsData: RTKQueryHook<typeof useGetAppointmentsForPatientQuery> = (args, opts) => {
  const userId = useSelector(getAuthUserId)
  const clientId = useSelector(getClientDetailsData)?.id
  return useGetAppointmentsForPatientQuery(
    clientId
      ? {
          providerId: userId,
          patientId: clientId,
          ...args,
        }
      : skipToken,
    opts,
  ) as RTKQueryResultType<typeof useGetAppointmentsForPatientQuery>
}
