import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import styled from 'styled-components'

import { utilizationQuarters } from '@lyrahealth-inc/shared-app-logic'
import { CaseloadManagementDetailedWeeklyView, LoadingIndicator } from '@lyrahealth-inc/ui-core-crossplatform'

import { useHistoricUtilizationChargesData, useHistoricUtilizationData, useTargetUtilizationData } from './hooks'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { employmentTypes, ROLES } from '../../common/constants/appConstants'
import { BC_PERFORMANCE } from '../../common/constants/routingConstants'
import { hasRole } from '../../common/utils/utils'
import { getAuthEmploymentStatus, getAuthRoles } from '../../data/auth/authSelectors'
import {
  getCurrentQuarterCMDDetailedWeeklyView,
  getRequestedCaseloadManagementQuarter,
} from '../../data/lyraTherapy/clientSelectors'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const CaseloadManagementDetailedWeeklyViewContainer: React.FunctionComponent = () => {
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const userRoles: string[] = useSelector(getAuthRoles)
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''
  const currentQuarter: string | undefined = useSelector(getCurrentQuarterCMDDetailedWeeklyView)
  const navigate = useNavigate()

  const onBackPressed = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.BACK,
    })
    navigate(BC_PERFORMANCE.route)
  }, [navigate])

  const { data: historicUtilization, isLoading: isLoadingHistoricData } = useHistoricUtilizationData()
  const { data: targetUtilization, isLoading: isLoadingTargetData } = useTargetUtilizationData()
  const { data: historicCharges, isLoading: isLoadingHistoricChargesData } = useHistoricUtilizationChargesData()
  const isLoadingUtilizationData = isLoadingHistoricData || isLoadingTargetData || isLoadingHistoricChargesData

  const displayPTO =
    !hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) ||
    (!!employmentStatus && [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus))

  const displayTarget = hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH])
  return (
    <Container>
      {isLoadingUtilizationData || !historicUtilization ? (
        <LoadingIndicator size={45} />
      ) : (
        <CaseloadManagementDetailedWeeklyView
          historicUtilization={historicUtilization}
          targetUtilization={targetUtilization}
          onBackPressed={onBackPressed}
          displayPTO={displayPTO}
          historicQuarter={requestedQuarterName}
          currentQuarter={currentQuarter}
          displayTarget={displayTarget}
          isTeensTherapist={hasRole(userRoles, [ROLES.LT_TEENS_THERAPIST, ROLES.LT_TEENS_THERAPIST_SUPERVISOR])}
          historicCharges={historicCharges}
        />
      )}
    </Container>
  )
}

export default CaseloadManagementDetailedWeeklyViewContainer
