import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { type ReactPlayerProps } from 'react-player'

import { OnProgressProps } from 'react-player/base'

import { useEventListener, useTrackVideoWatchedIntervals } from '@lyrahealth-inc/shared-app-logic'
import { VideoWatchedStats } from '@lyrahealth-inc/shared-app-logic/src/features/common/hooks/useTrackVideoWatchedIntervals'

import ReactPlayer from './react-player'

export interface VideoPlayerWebProps extends ReactPlayerProps {
  /**
   * If given, flushes the current recorded watched interval stats when the video ends,
   * the component unmounts, or document.visibilityState changes to hidden.
   *
   * `visibilityStateHidden` indicates that this is called from `visibilitychange` event handler,
   * and therefore `navigator.sendBeacon` should be used (instead of XHR) to send analytics requests.
   * */
  onTrackVideoWatched?: TrackWatchedVideoCallback

  /**
   * Optional identifier or URI for the video
   * Will be passed as part of `VideoWatchedStats` to `onTrackVideoWatched`
   */
  videoId?: string
  videoPlayerRef?: React.MutableRefObject<typeof ReactPlayer | null>
}

export type TrackWatchedVideoCallback = (stats: VideoWatchedStats, visibilityStateHidden: boolean) => void

export const VideoPlayer: FunctionComponent<VideoPlayerWebProps> = ({
  onDuration,
  onProgress,
  onSeek,
  onPlay,
  onPause,
  onEnded,
  onTrackVideoWatched,
  videoId,
  progressInterval = 500,
  videoPlayerRef,
  ...reactPlayerProps
}) => {
  const documentRef = useRef<Document>(document)
  const defaultVideoPlayerRef = useRef<typeof ReactPlayer | null>(null)
  const playerRef = videoPlayerRef || defaultVideoPlayerRef

  const trackVideo = useTrackVideoWatchedIntervals(videoId, progressInterval)

  // Flushes the current watched stats to `onTrackVideoWatched`, which can be used to emit an analytics event
  const trackWatchedVideoEvent = useCallback(
    (visibilityStateHidden: boolean = false) => {
      if (!onTrackVideoWatched) {
        return
      }
      const stats = trackVideo.getStats()
      stats.percentViewed && stats.percentViewed > 0 && onTrackVideoWatched(stats, visibilityStateHidden)
    },
    [onTrackVideoWatched, trackVideo],
  )

  useEffect(() => {
    // When the VideoPlayer is unmounted, flush watched stats
    return () => {
      trackVideo.pause(undefined, 'player_close')
      trackWatchedVideoEvent()
    }
  }, [trackVideo, trackWatchedVideoEvent])

  // If the user navigates away or backgrounds the tab, flush watched stats and pause tracking
  useEventListener(
    'visibilitychange',
    () => {
      if (document.visibilityState === 'hidden') {
        trackVideo.pause(playerRef.current?.getCurrentTime(), 'app_background')
        trackWatchedVideoEvent(true)
      } else if (document.visibilityState === 'visible') {
        trackVideo.resume(playerRef.current?.getCurrentTime(), 'app_foreground')
      }
    },
    documentRef,
  )

  return (
    <ReactPlayer
      ref={playerRef}
      {...reactPlayerProps}
      onDuration={(duration: number) => {
        onTrackVideoWatched && trackVideo.setDuration(duration)
        onDuration?.(duration)
      }}
      onProgress={(data: OnProgressProps) => {
        onTrackVideoWatched && trackVideo.progressed(data.playedSeconds)
        onProgress?.(data)
      }}
      onSeek={(seconds: number) => {
        const currentTime = playerRef.current?.getCurrentTime()
        onTrackVideoWatched && trackVideo.seek(currentTime ?? seconds)
        onSeek?.(seconds)
      }}
      onEnded={() => {
        onTrackVideoWatched && trackVideo.ended()
        // When the video has ended, flush watched stats
        trackWatchedVideoEvent()
        onEnded?.()
      }}
      onPause={() => {
        onTrackVideoWatched && trackVideo.pause(playerRef.current?.getCurrentTime())
        onPause?.()
      }}
      onPlay={() => {
        onTrackVideoWatched && trackVideo.resume(playerRef.current?.getCurrentTime())
        onPlay?.()
      }}
      progressInterval={progressInterval}
    />
  )
}
