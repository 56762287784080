import React from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { cloneDeep } from 'lodash-es'
import moment from 'moment-timezone'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { css, useTheme } from 'styled-components/native'

import { CalendarCardAppointment, ClientObject, FieldSchema } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  FormBody,
  FormBodyCustomStyles,
  FormButtonParams,
  InlineInfoBanner,
  Link,
  PrimaryButton,
  SecondaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import sessionCancelationMetadata from './sessionCancelationMetadata'
import { actionAlertHandler, actionStyles } from '../../common/constants/reduxConstants'
import { getAuthUser, getAuthUserId } from '../../data/auth/authSelectors'
import { getClientEpisodeProgramConfig } from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'
import {
  useCancelAppointmentMutation,
  useUpdateAppointmentMutation,
} from '../clients/clientDetails/data/appointmentsApi'

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(!theme.breakpoints.isMinWidthTablet && { padding: `${theme.spacing['24px']} ${theme.spacing['48px']}` }),
  maxWidth: '576px',
}))

const Subheader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const AppointmentInfo = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const AdjacentButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'relative',
  right: 0,
  flexDirection: 'row',
  paddingBottom: theme.spacing['16px'],
  marginTop: theme.spacing['8px'],
}))

const SubmitButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['16px'],
}))

type CancellationFormValues = {
  changeReason?: string
  changeNotice?: string
  changeNoticeDetail?: string
  providerInitiated?: string
  providerInitiatedDetails?: string
  technicalIssuesCancelDetails?: string
  technicalIssuesCancelSelection?: string
}

const SessionCancellation: React.FC<SessionCancellationProps> = ({
  appointment,
  clientDetails,
  closeFunc,
  programConfig,
  actions: { dispatch },
}) => {
  const { colors } = useTheme()
  const userId = useSelector(getAuthUserId)
  const appointmentStart = moment.tz(
    new Date(`${appointment?.startDate} ${appointment?.startTime}`),
    `${appointment?.timeZone}`,
  )
  const appointmentEnd = appointmentStart.clone().add(appointment?.appointmentDuration, 'minutes')
  const appointmentDate = `${appointmentStart.format('ddd MMM D YYYY  h:mm')}-${appointmentEnd.format('h:mm A')}`
  const inLateCancelWindow = appointmentStart.diff(moment(), 'hours') <= 24
  const intl = useIntl()
  const [cancelAppointment] = useCancelAppointmentMutation()
  const [updateAppointment] = useUpdateAppointmentMutation()
  const cancelClicked = async (values: CancellationFormValues) => {
    const {
      changeReason,
      changeNotice,
      changeNoticeDetail,
      providerInitiated,
      providerInitiatedDetails,
      technicalIssuesCancelDetails,
      technicalIssuesCancelSelection,
    } = values
    const appointmentChangeReason = (
      changeReason !== 'clientCancel'
        ? changeReason
        : changeNotice === 'clientInitiated'
        ? changeNotice
        : changeNoticeDetail
    ) as string | undefined
    let appointmentCancelled
    if (
      appointment &&
      (technicalIssuesCancelDetails || technicalIssuesCancelSelection || providerInitiated || providerInitiatedDetails)
    ) {
      const appointmentToUpdate = cloneDeep(appointment)
      appointmentToUpdate.appointmentStatus = 'canceled'
      appointmentToUpdate.changeReason = appointmentChangeReason
      appointmentToUpdate.appointmentAttributes = {
        technicalIssuesCancelDetails,
        technicalIssuesCancelSelection,
        providerInitiatedCancelSelection: providerInitiated,
        providerInitiatedCancelDetails: providerInitiatedDetails,
      }
      delete appointmentToUpdate?.subtitle
      appointmentCancelled = updateAppointment({
        data: appointmentToUpdate,
        providerId: userId,
      })
    } else {
      appointmentCancelled = cancelAppointment({
        providerId: userId,
        appointmentId: appointment!.appointmentId,
        changeReason: appointmentChangeReason,
      })
    }
    closeFunc()
    actionAlertHandler({
      actionStyle: actionStyles.SUCCESS,
      message: `Session ${appointment?.sessionNumber} has been cancelled`,
      expires: true,
      dispatch,
      autoDismissTimer: 4000,
    })
    return appointmentCancelled.unwrap()
  }
  const metadata = sessionCancelationMetadata(clientDetails.first_name, appointmentDate, inLateCancelWindow)

  return (
    <Container>
      <Subheader
        size={SubheadSize.LARGE}
        text={`Cancel Session ${appointment?.sessionNumber} with ${clientDetails.first_name}?`}
        color={colors.textPrimary}
      />
      <AppointmentInfo
        testID={tID('SessionCancellation-appointmentDate')}
        color={colors.textInactive}
        text={appointmentDate}
      />
      <InlineInfoBanner
        text={
          <>
            You are about to cancel session {appointment?.sessionNumber} for {clientDetails.first_name}. This action has
            billing implications for clients and customers{' '}
            <Link
              text={'Learn more about program session closure policy.'}
              onPress={() => window.open(programConfig?.sessionClosureGuide, '_blank')}
              testID={tID(`SessionCancellation-sessionClosurePolicyLink`)}
              size={BodyTextSize.DEFAULT}
            />
          </>
        }
      />
      <FormBody
        intl={intl}
        name={'cancelSession'}
        saveForm={({ values, formSubmit }: { values: CancellationFormValues; formSubmit: boolean }) => {
          if (formSubmit) {
            cancelClicked(values)
          }
        }}
        schema={metadata.schema as FieldSchema}
        uiSchema={metadata.uiSchema}
        formButton={({ handleSubmit, pristine, loading, errors }: FormButtonParams) => {
          const numberOfErrors = typeof errors === 'object' ? Object.keys(errors).length : 0
          return (
            <AdjacentButtonContainer>
              <SubmitButton
                testID={tID('SessionCancellation-Cancel')}
                text='Cancel Session'
                disabled={pristine || numberOfErrors > 0}
                loading={loading}
                onPress={() => {
                  handleSubmit()
                }}
              />
              <SecondaryButton text='Keep Session' onPress={() => closeFunc()} />
            </AdjacentButtonContainer>
          )
        }}
        scrollContainerCustomStyles={{
          formBodyPageContainer: css`
            padding-top: 0px;
          `,
          scrollContainerContentCustomStyles: {
            padding: 0,
          },
        }}
        formBodyCustomStyles={
          {
            submitButtonContainer: {
              borderTopWidth: 0,
              borderWidth: '0 !important',
              borderColor: 'white',
              boxShadow: 'none',
              paddingLeft: 0,
            },
          } as unknown as FormBodyCustomStyles
        }
      >
        <AppointmentInfo
          size={BodyTextSize.SMALL}
          color={colors.textInactive}
          testID={tID('SessionCancellation-clientNotificationText')}
          text={`${clientDetails.first_name} will receive a notification that this appointment has been canceled.`}
        />
      </FormBody>
    </Container>
  )
}

type SessionCancellationProps = ConnectedProps<typeof connector> & {
  appointment?: CalendarCardAppointment
  closeFunc: () => void
  clientDetails: ClientObject
}

const mapStateToProps = (state: RootState) => {
  return {
    programConfig: getClientEpisodeProgramConfig(state),
    user: getAuthUser(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: {
    ...bindActionCreators({}, dispatch),
    dispatch,
  },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(SessionCancellation)
