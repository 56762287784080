import {
  AudioChangeAction,
  AudioOption,
  AudioQosData,
  DialoutState,
  SharePrivilege,
  ShareStatus,
  Stream,
  VideoCapturingState,
  VideoPlayer,
  VideoQosData,
  VideoQuality,
} from '@zoom/videosdk'

import { EmptyPromise, IVideoClient } from './utils'

const MockAudioQoSData: AudioQosData = {
  sample_rate: 0,
  rtt: 0,
  jitter: 0,
  avg_loss: 0,
  max_loss: 0,
  bandwidth: 0,
  bitrate: 0,
}

const MockVideoQosData: VideoQosData = {
  sample_rate: 0,
  rtt: 0,
  jitter: 0,
  avg_loss: 0,
  max_loss: 0,
  width: 0,
  height: 0,
  fps: 0,
  bandwidth: 0,
  bitrate: 0,
}

type StreamType = typeof Stream

class BaseMediaStream implements Partial<StreamType> {
  inviteByPhone() {
    return EmptyPromise
  }

  cancelInviteByPhone() {
    return EmptyPromise
  }

  hangup() {
    return EmptyPromise
  }

  getCurrentSessionCallinInfo() {
    return {
      meetingId: 'meeting-id',
      tollNumbers: [],
    }
  }

  muteShareAudio() {
    return EmptyPromise
  }

  unmuteShareAudio() {
    return EmptyPromise
  }

  switchCamera() {
    return EmptyPromise
  }

  switchMicrophone() {
    return EmptyPromise
  }

  switchShareView() {
    return EmptyPromise
  }

  switchSpeaker() {
    return EmptyPromise
  }

  subscribeAudioStatisticData() {
    return EmptyPromise
  }

  subscribeShareStatisticData() {
    return EmptyPromise
  }

  subscribeVideoStatisticData() {
    return EmptyPromise
  }

  unmuteAllUserAudioLocally() {
    return EmptyPromise
  }

  unmuteUserAudioLocally() {
    return EmptyPromise
  }

  unsubscribeAudioStatisticData() {
    return EmptyPromise
  }

  unsubscribeShareStatisticData() {
    return EmptyPromise
  }

  unsubscribeVideoStatisticData() {
    return EmptyPromise
  }

  muteAllUserAudioLocally() {
    return EmptyPromise
  }

  muteUserAudioLocally() {
    return EmptyPromise
  }

  adjustRenderedVideoPosition() {
    return EmptyPromise
  }

  adjustUserAudioVolumeLocally() {
    return EmptyPromise
  }

  enableBackgroundNoiseSuppression() {
    return EmptyPromise
  }

  enableHardwareAcceleration() {
    return Promise.resolve(true)
  }

  enableOptimizeForSharedVideo() {
    return EmptyPromise
  }

  enableOriginalSound() {
    return EmptyPromise
  }

  enableSyncButtonsOnHeadset() {
    return EmptyPromise
  }

  startRemoteControl() {
    return EmptyPromise
  }

  startShareView() {
    return EmptyPromise
  }

  setSharePrivilege() {
    return EmptyPromise
  }

  shareToSubsession() {
    return EmptyPromise
  }

  stopPreviewVideoMask() {
    return EmptyPromise
  }

  stopPreviewVirtualBackground() {
    return EmptyPromise
  }

  stopRemoteControl() {
    return EmptyPromise
  }

  stopShareScreen() {
    return EmptyPromise
  }

  stopShareToSubsession() {
    return EmptyPromise
  }

  stopShareView() {
    return EmptyPromise
  }

  switchSharingSecondaryCamera() {
    return EmptyPromise
  }

  isBrowserSupportPTZ() {
    return false
  }

  isCameraTaken() {
    return false
  }

  isCaptureForbidden() {
    return false
  }

  isCapturingVideo() {
    return false
  }

  isControllingUserRemotely() {
    return false
  }

  isOptimizeForSharedVideoEnabled() {
    return false
  }

  isOthersShareAudioMutedLocally() {
    return false
  }

  isRemoteControlEnabled() {
    return false
  }

  isRemotelyControlApproved() {
    return false
  }

  isRenderSelfViewWithVideoElement() {
    return false
  }

  isShareLocked() {
    return false
  }

  isSupportHDVideo() {
    return false
  }

  isSupportMicrophoneAndShareAudioSimultaneously() {
    return false
  }

  isSupportMultipleVideos() {
    return false
  }

  isSupportOptimizedForSharedVideo() {
    return false
  }

  isSupportPhoneFeature() {
    return false
  }

  isSupportVirtualBackground() {
    return false
  }

  isTargetShareSupportRemoteControl() {
    return false
  }

  isUserAudioMutedLocally() {
    return false
  }

  getInviteByPhoneStatus() {
    return DialoutState.NotAvailable
  }

  getActiveCamera() {
    return 'active-camera'
  }

  getActiveMicrophone() {
    return 'active-microphone'
  }

  getActiveShareUserId() {
    return 0
  }

  getActiveSpeaker() {
    return 'active-speaker'
  }

  getActiveVideoId() {
    return 0
  }

  getAudioStatisticData() {
    return { encode: MockAudioQoSData, decode: MockAudioQoSData }
  }

  getMicList() {
    return []
  }

  getSpeakerList() {
    return []
  }

  getSupportCountryInfo() {
    return []
  }

  getShareAudioStatus() {
    return {
      isShareAudioEnabled: false,
      isShareAudioMuted: false,
      isSharingAudio: false,
    }
  }

  getUserVolumeLocally() {
    return 0
  }

  updateVideoCanvasDimension() {
    return EmptyPromise
  }

  clearVideoCanvas() {
    return EmptyPromise
  }

  mirrorVideo() {
    return EmptyPromise
  }

  previewVirtualBackground() {
    return EmptyPromise
  }

  updateVirtualBackgroundImage() {
    return EmptyPromise
  }

  previewVideoMask() {
    return EmptyPromise
  }

  updateVideoMask() {
    return EmptyPromise
  }

  getCameraList() {
    return []
  }

  getVideoMaxQuality() {
    return VideoQuality.Video_720P
  }

  getReceivedVideoDimension() {
    return {
      height: 1080,
      width: 1920,
    }
  }

  getVirtualbackgroundStatus() {
    return {
      isVBConfigured: false,
      isVBPreloadReady: false,
      imageSrc: undefined,
    }
  }

  getVideoStatisticData() {
    return {
      encode: MockVideoQosData,
      decode: MockVideoQosData,
    }
  }

  getNetworkQuality() {
    return {
      uplink: 3,
      downlink: 3,
    }
  }

  getCapturedVideoResolution() {
    return {
      height: 1080,
      width: 1920,
    }
  }

  getVideoMaskStatus() {
    return {
      imageUrl: null,
      cropped: false,
      rootWidth: undefined,
      rootHeight: undefined,
      clip: undefined,
    }
  }

  pauseShareScreen() {
    return EmptyPromise
  }

  resumeShareScreen() {
    return EmptyPromise
  }

  lockShare() {
    return EmptyPromise
  }

  updateSharingCanvasDimension() {
    return EmptyPromise
  }

  updateSharedVideoQuality() {
    return EmptyPromise
  }

  getSharePrivilege() {
    return SharePrivilege.Locked
  }

  getShareStatus() {
    return ShareStatus.Paused
  }

  getShareUserList() {
    return []
  }

  getShareStatisticData() {
    return {
      encode: MockVideoQosData,
      decode: MockVideoQosData,
    }
  }

  requestFarEndCameraControl() {
    return EmptyPromise
  }

  approveFarEndCameraControl() {
    return EmptyPromise
  }

  declineFarEndCameraControl() {
    return EmptyPromise
  }

  giveUpFarEndCameraControl() {
    return EmptyPromise
  }

  controlCamera() {
    return EmptyPromise
  }

  controlFarEndCamera() {
    return EmptyPromise
  }

  getFarEndCameraPTZCapability() {
    return {
      pan: false,
      tilt: false,
      zoom: false,
    }
  }

  getCameraPTZCapability() {
    return {
      pan: false,
      tilt: false,
      zoom: false,
    }
  }

  requestRemoteControl() {
    return EmptyPromise
  }

  approveRemoteControl() {
    return EmptyPromise
  }

  declineRemoteControl() {
    return EmptyPromise
  }

  giveUpRemoteControl() {
    return EmptyPromise
  }

  grabRemotoControl() {
    return EmptyPromise
  }

  launchRemoteControlApp() {
    return EmptyPromise
  }

  getRemoteControlAppDownloadUrl() {
    return ''
  }

  getRemotelyControllingUser() {
    return null
  }

  callCRCDevice() {
    return EmptyPromise
  }

  cancelCallCRCDevice() {
    return EmptyPromise
  }

  startSecondaryAudio() {
    return EmptyPromise
  }

  stopSecondaryAudio() {
    return EmptyPromise
  }

  muteAllAudio() {
    return EmptyPromise
  }

  unmuteAllAudio() {
    return EmptyPromise
  }

  isVideoMirrored() {
    return false
  }

  muteAudioUponStartAudio() {
    return EmptyPromise
  }

  spotlightVideo() {
    return EmptyPromise
  }

  removeSpotlightedVideo() {
    return EmptyPromise
  }

  removeAllSpotlightedVideos() {
    return EmptyPromise
  }

  grabRemoteControl() {
    return EmptyPromise
  }

  getAudioMediaPlaybackController() {
    return null
  }

  getSpotlightedUserList() {
    return []
  }

  getVideoMediaPlaybackController() {
    return null
  }

  renderVideo() {
    return EmptyPromise
  }

  stopRenderVideo() {
    return EmptyPromise
  }
}

class ZoomError extends Error {
  type: string

  reason: string

  constructor(type: string, reason: string) {
    super('Zoom Error')
    this.type = type
    this.reason = reason
  }
}
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))
export class MockMediaStream extends BaseMediaStream implements StreamType {
  private videoEl?: HTMLVideoElement

  private videoClient: IVideoClient

  private isAudioConnected: boolean = false

  private isMuted: boolean = false

  private videoElements = new Map<number, VideoPlayer>()

  constructor(videoClient: IVideoClient) {
    super()
    this.videoClient = videoClient
  }

  isRenderSelfViewWithVideoElement() {
    return true
  }

  isStartShareScreenWithVideoElement() {
    return true
  }

  async startVideo() {
    if (this.videoClient.shouldThrowPermissionsErrors()) {
      throw new ZoomError('VIDEO_USER_FORBIDDEN_CAPTURE', '')
    }
    await sleep(500)

    this.videoClient.emit('video-capturing-change', { state: VideoCapturingState.Started })
    return EmptyPromise
  }

  async attachVideo(userId: number) {
    const participant = this.videoClient.getUser(userId)
    const canvas = document.createElement('canvas') as HTMLCanvasElement
    const ctx = canvas.getContext('2d')!
    ctx.fillStyle = '#444693'
    const parentWidth = 146
    const parentHeight = 82
    ctx.fillRect(0, 0, parentWidth, parentHeight)
    ctx.font = `${Math.floor(parentWidth / 10)}px Moderat-Regular`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#FFFFFF'
    ctx.fillText(`${participant?.userIdentity ?? participant?.userId ?? 'Self'}`, parentWidth / 2, parentHeight / 2)

    const element = document.createElement('video-player') as VideoPlayer
    element['node-id'] = 'blah'
    element.appendChild(canvas)
    this.videoElements.set(userId, element)
    return element
  }

  async detachVideo(userId: number) {
    const videoPlayer = this.videoElements.get(userId)
    this.videoElements.delete(userId)
    return videoPlayer ?? (document.createElement('video-player') as VideoPlayer)
  }

  async stopVideo() {
    if (this.videoEl && this.videoEl.srcObject instanceof MediaStream) {
      this.videoEl.srcObject.getTracks().forEach((track) => track.stop())
      this.videoEl.srcObject = null
    }
    this.videoClient.emit('video-capturing-change', { state: VideoCapturingState.Stopped })

    return EmptyPromise
  }

  startAudio(options?: AudioOption) {
    if (this.videoClient.shouldThrowPermissionsErrors()) {
      throw new ZoomError('INSUFFICIENT_PRIVILEGES', 'USER_FORBIDDEN_MICROPHONE')
    }
    this.isAudioConnected = true
    this.isMuted = options?.mute ?? true
    this.videoClient.emit('current-audio-change', { action: AudioChangeAction.Join, type: 'computer' })
    return EmptyPromise
  }

  stopAudio() {
    this.isAudioConnected = false
    this.videoClient.emit('current-audio-change', { action: AudioChangeAction.Leave, type: 'computer' })
    return EmptyPromise
  }

  muteAudio() {
    if (!this.isAudioConnected) {
      return EmptyPromise
    }

    this.isMuted = true
    this.videoClient.emit('current-audio-change', { action: AudioChangeAction.Muted, type: 'computer' })
    return EmptyPromise
  }

  unmuteAudio() {
    if (!this.isAudioConnected) {
      return EmptyPromise
    }

    this.isMuted = false
    this.videoClient.emit('current-audio-change', { action: AudioChangeAction.Unmuted, type: 'computer' })
    return EmptyPromise
  }

  async startShareScreen() {
    return EmptyPromise
  }

  public isAudioMuted() {
    return this.isMuted
  }

  public getIsAudioConnected() {
    return this.isAudioConnected
  }
}
