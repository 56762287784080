import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import styled from 'styled-components'

import { LoadingIndicator, PlatformEngagement } from '@lyrahealth-inc/ui-core-crossplatform'

import { useGetPlatformEngagementMetricsQuery } from './data/platformEngagementApi'
import usePEQuarters from './hooks/usePEQuarters'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS } from '../../../common/constants/routingConstants'
import { getAuthUserId } from '../../../data/auth/authSelectors'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const PlatformEngagementContainer: React.FunctionComponent = () => {
  const userId = useSelector(getAuthUserId)
  const navigate = useNavigate()

  const peQuarters = usePEQuarters()

  const onLearnMoreClicked = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.LEARN_MORE,
    })
    window.open(
      'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/10189970197651-Platform-Engagement-Metric',
      '_blank',
    )
  }, [])

  const onViewQuarterlyDetailsClicked = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.VIEW_QUARTERLY_DETAILS,
    })
    navigate(BC_PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS.route)
  }, [navigate])

  const { data: currentYearMetrics, isLoading } = useGetPlatformEngagementMetricsQuery({
    providerId: userId,
    data: peQuarters,
  })
  const currentYearMetricsYTD = currentYearMetrics?.ytd
  return (
    <>
      {isLoading && !currentYearMetrics && <LoadingIndicator />}
      <Container>
        {currentYearMetricsYTD && (
          <PlatformEngagement
            assignmentRate={currentYearMetricsYTD.assignment_rate}
            feedbackRate={currentYearMetricsYTD.feedback_rate}
            onLearnMoreClicked={onLearnMoreClicked}
            onViewQuarterlyDetailsClicked={onViewQuarterlyDetailsClicked}
          />
        )}
      </Container>
    </>
  )
}

export default PlatformEngagementContainer
