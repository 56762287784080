/**
 * This class is a partial mock of `mixpanel-browser` library. This is for testing only.
 */

type Callback = (response: Response) => void
export class MixpanelMock {
  token: string

  superProps: any = {}

  constructor(token: string) {
    this.token = token
  }

  identify(id: string): void {
    console.log('identify called with: ', id)
  }

  init(id: string, options?: { loaded?: () => void; api_host: string }): void {
    console.log('Mixpanel init with id: ', id, ' and options: ', options)
    if (options?.loaded) {
      options?.loaded()
    }
  }

  register(props: unknown): void {
    console.log('register')
    this.superProps = props
  }

  track(name: string, props?: unknown): void {
    console.log('track called with: ', name, props)
  }

  alias(id: string): void {
    console.log('Mixpanel alias called. Id: ', id)
  }

  track_links(target: string, event: string, props?: unknown): void {
    console.log('track links called with: target - ', target, ', event - ', event, ', and props - ', props)
  }

  opt_in_tracking(): void {
    console.log('Mixpanel opt_in_tracking called')
  }

  opt_out_tracking(): void {
    console.log('Mixpanel opt_out_tracking called')
  }

  people = {
    set: (prop: string, to: any, callback?: Callback | undefined) => {
      console.log('people.set called', prop, to, callback)
    },
  }

  people_set = (prop: string, to: any, callback?: Callback | undefined) => {
    console.log('people_set called', prop, to, callback)
  }
}
