import { combineReducers } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form/immutable'

import alerts from './alertReducer'
import auth from './authReducer'
import { baseApi } from './baseApi'
import healthPlan from './healthPlanReducer'
import calendar from '../calendar/data/calendarReducer'
import clients from '../clients/data/clientsReducer'
import downloadCSV from '../common/components/downloadCSV/data/downloadReducer'
import { LOGOUT } from '../common/constants/reduxConstants'
import lyraTherapy from '../lyraTherapy/data/lyraTherapyReducer'
import myInfo from '../myInfo/data/myInfoReducer'
import paymentHistory from '../paymentHistory/data/paymentHistoryReducer'
import payments from '../payments/data/paymentsReducer'
import practices from '../practices/data/practicesReducer'
import providers from '../providers/data/providersReducer'
import reconciler from '../reconciler/data/reconcilerReducer'
import requestPayment from '../requestPayment/data/requestPaymentReducer'
import scheduling from '../scheduling/data/schedulingReducer'

const appReducer = combineReducers({
  form: formReducer,
  auth,
  practices,
  payments,
  downloadCSV,
  providers,
  myInfo,
  clients,
  alerts,
  requestPayment,
  paymentHistory,
  reconciler,
  lyraTherapy,
  healthPlan,
  scheduling,
  calendar,
  [baseApi.reducerPath]: baseApi.reducer,
})

const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}
export default rootReducer
