import React, { FunctionComponent, useMemo, useState } from 'react'
import { Field } from 'react-final-form'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router'

import { cloneDeep, get, isBoolean, isEmpty, isNil, merge, omit } from 'lodash-es'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components/native'

import {
  Appointment,
  CustomerInfo,
  extendMetadata,
  FieldSchema,
  FormMetadata,
  getProgramNameFromId,
  isFieldDisplayed,
  ProgramNames,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  EligibilityErrorBanner,
  FormBody,
  FormBodyCustomStyles,
  FormButtonParams,
  InlineErrorBanner,
  InlineInfoBanner,
  Link,
  LoadingIndicator,
  PrimaryButton,
  SecondaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { useUpdateAppointmentMutation } from './data/appointmentsApi'
import { useClientAppointmentsData } from './data/hooks/useClientAppointmentsData'
import { endOfSessionMetaData } from './endOfSessionMetaData'
import attestationFormMetadata from '../../../common/components/healthPlanInfo/attestationFormMetadata'
import getApproachingSpecialityCareMetadata from '../../../common/components/healthPlanInfo/getApproachingSpecialityCareMetadata'
import getPreAuthSpecialityCareMetadata from '../../../common/components/healthPlanInfo/getPreAuthSpecialityCareMetadata'
import ICD10sField from '../../../common/components/healthPlanInfo/ICD10sField'
import ICD10sFormMetadata from '../../../common/components/healthPlanInfo/ICD10sFormMetadata'
import {
  appointmentStatuses,
  attendanceValues,
  coachingSessionTypesList,
  diagnoses,
  episodeTerminationStatuses,
  getConditions,
  healthPlanStates,
  ROLES,
  specialityCareAttestationStatuses,
} from '../../../common/constants/appConstants'
import { actionAlertHandler, actionStyles } from '../../../common/constants/reduxConstants'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import {
  getBCCharge,
  getItemsErrors,
  getLTSessionTypeAndRate,
  hasRole,
  isOneArrayItemTrue,
  logToSumoLogic,
  prepopulateAttestation,
} from '../../../common/utils/utils'
import { getAuthUser, getAuthUserId } from '../../../data/auth/authSelectors'
import { getHealthPlanICD10s } from '../../../data/healthPlan/healthPlanSelectors'
import { getHPEligibility, getICD10s } from '../../../data/healthPlanAutoActions'
import {
  getClientAssignmentsData,
  getClientCurrentSessionCount,
  getClientDetailsData,
  getClientEpisodeProgramConfig,
  getClientEpisodesData,
  getClientNotesData,
  getClientSelectedEpisode,
  getClientUnclosedPastAppointments,
  getSelectedEpisodeCurriculum,
} from '../../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData } from '../../../data/lyraTherapy/contentSelectors'
import { getPaymentsDataAllData } from '../../../data/payments/paymentsSelectors'
import {
  getRequestPaymentClientCustomers,
  getRequestPaymentRates,
} from '../../../data/requestPayment/requestPaymentSelectors'
import { RootState } from '../../../data/store'
import useFetchClientEligibility from '../../../hooks/useFetchClientEligibility'
import { getPayments } from '../../../payments/dashboard/data/paymentsDashboardActions'
import { checkEligibility, submitPaymentRequest } from '../../../requestPayment/data/requestPaymentActions'
import { setAssignment, updateAssignment } from '../../assignments/data/assignmentsAutoActions'
import { updateEpisode } from '../../episodes/data/episodesAutoActions'
import { getLtClient } from '../clientDetails/data/ltClientDetailsAutoActions'

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  maxWidth: theme.breakpoints.isMinWidthLaptop ? '750px' : theme.breakpoints.isMinWidthTablet ? '550px' : '100%',
  margin: `${theme.spacing['24px']} auto`,
  padding: `0 ${theme.spacing['16px']}`,
}))

const Subheader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const NotificationBannerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  marginBottom: theme.spacing['24px'],
}))

const CloseSessionWarningText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const AdjacentButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'relative',
  right: 0,
  flexDirection: 'row',
  paddingBottom: theme.spacing['16px'],
  marginTop: theme.spacing['8px'],
}))

const EndOfSession: FunctionComponent<EndOfSessionProps> = ({
  icd10Codes,
  clientDetails,
  selectedEpisode,
  dispatch,
  curriculum,
  contents,
  assignments,
  notes,
  sessionsRates,
  customers = [],
  episodes,
  programConfig,
  pastAppointmentsToClose,
  pastPayments,
  currentSessionCount,
  actions: {
    getHPEligibility,
    getICD10s,
    submitPaymentRequest,
    updateEpisode,
    setAssignment,
    updateAssignment,
    getLtClient,
    getPayments,
    checkEligibility,
  },
}: any) => {
  const user = useSelector(getAuthUser)
  const userId = useSelector(getAuthUserId)
  const [updateAppointment] = useUpdateAppointmentMutation()
  const { appointment } = useOutletContext<{ appointment: Appointment }>()
  const navigate = useNavigate()
  const [isFormProcessing, setIsFormProcessing] = useState(false)
  const intl = useIntl()
  const { showEnhancedLCxDiagnosis, isProgramLevelSessionLimitEnabled } = useFlags()
  const enhancedLCxDiagnosisEnabled = programConfig?.enhancedLCxDiagnosisEnabled && showEnhancedLCxDiagnosis
  const shouldShowGuardianConsent =
    selectedEpisode?.program_name === ProgramNames.TeensTherapy && currentSessionCount === 1

  const conditionsList = useMemo(() => getConditions({ includeDivorce: false }), [])
  const { data: allAppointments } = useClientAppointmentsData()
  const previousSessionTransfer = useMemo(() => {
    const pastEpisodeAppointments = allAppointments?.filter((appt: any) => appt.episodeId === selectedEpisode.id) ?? []
    const episodeTransfers = pastPayments?.filter((pastPayment: any) =>
      pastEpisodeAppointments.map((appointment: any) => appointment.appointmentId).includes(pastPayment.appointment_id),
    )
    const sortedEpisodeTransfers = episodeTransfers?.sort(
      (a: any, b: any) =>
        (pastEpisodeAppointments.find((appointment) => appointment.appointmentId === b.appointment_id)?.sessionNumber ??
          0) -
        (pastEpisodeAppointments.find((appointment) => appointment.appointmentId === a.appointment_id)?.sessionNumber ??
          0),
    )
    return sortedEpisodeTransfers ? sortedEpisodeTransfers[0] : null
  }, [allAppointments, pastPayments, selectedEpisode.id])

  const {
    employer,
    gender,
    last_name: lastName,
    first_name: firstName,
    full_name: fullName,
    date_of_birth: dateOfBirth,
    sessions_used: sessionsUsed,
    sessions_limit: sessionsLimit,
    sessions_remaining: sessionsRemaining,
    health_plan_status: healthPlanStatus,
    id: clientId,
    lyra_code: lyraCode,
    open_bhb_session_limit: openBhbSessionLimit,
    country,
  } = clientDetails
  const sessionType = getLTSessionTypeAndRate(
    selectedEpisode.program_name,
    appointment.sessionNumber,
    sessionsRates,
  ).sessionType
  const healthPlanEligibilityPayload = {
    ...(lyraCode && { lyra_code: lyraCode }),
    visit_date: appointment.startDate,
    provider_lyra_id: user?.id,
    session_type: sessionType,
    company: employer,
    gender,
    date_of_birth: dateOfBirth,
    last_name: lastName,
    first_name: firstName,
    ...(clientId && { patient_lyra_id: clientId }),
  }
  // @ts-expect-error TS(2548): Type 'unknown[] | undefined' is not an array type ... Remove this comment to see the full error message
  const [isLoadingHPEligibility, [eligibilityObj = {}]] = useFetcher(
    [[getHPEligibility, healthPlanEligibilityPayload], [getICD10s]],
    [],
  )

  const [isLoadingPayments] = useFetcher(
    [[getPayments, { page: 0, limit: 20, providerId: user?.id, patientId: clientId }]],
    [user?.id, clientId],
  )

  const clientCustomer = customers?.find(
    (customer: CustomerInfo) => customer?.value?.toLowerCase() === clientDetails?.employer?.toLowerCase(),
  )

  const eligibilityError = useFetchClientEligibility({
    shouldFetchEligibility: country === 'US' && clientCustomer?.eap_eligibility_check_enabled === 'true',
    clientDetails,
    checkEligibility,
    programName: selectedEpisode?.program_name,
  })

  const isLoading = isLoadingHPEligibility || isLoadingPayments || isEmpty(icd10Codes)

  const {
    health_plan_extension_eligible: healthPlanExtensionEligible,
    speciality_care_attestation_status: specialtyCareAttestationStatus,
    next_session_uses_health_plan: nextSessionUsesHealthPlan,
  } = eligibilityObj

  const terminationStatuses = () => {
    return episodes.length > 1 && appointment.sessionNumber === 1
      ? episodeTerminationStatuses
      : episodeTerminationStatuses.filter((status) => status.value !== 'booster')
  }

  const getPreviousSessionICD10 = (icd10Type: any) => {
    if (isEmpty(icd10Codes)) return []
    const lastTransfer = previousSessionTransfer
    const lastICD10s = lastTransfer && icd10Type in lastTransfer ? lastTransfer[icd10Type]?.split(',') : null
    const validLastICD10s = lastICD10s?.filter((lastICD10: any) =>
      icd10Codes?.all.map((icd10Code: any) => icd10Code.code).includes(lastICD10),
    )
    return validLastICD10s
      ? validLastICD10s.map((lastICD10: any) => icd10Codes?.all.find((icd10Code: any) => icd10Code.code === lastICD10))
      : []
  }

  const getPreviousSessionDiagnosis = () => {
    const lastTransfer = previousSessionTransfer
    const lastDiagnoses = lastTransfer && 'diagnosis' in lastTransfer ? lastTransfer.diagnosis?.split(',') : null
    return lastDiagnoses
      ?.map(
        (lastDiagnosis: string) =>
          diagnoses?.find((possibleDiagnosis) => lastDiagnosis === possibleDiagnosis.value)?.label,
      )
      .filter((diagnosisLabel: string) => diagnosisLabel)
  }

  const getPreviousSessionConditions = (conditionType: string) => {
    const lastTransfer = previousSessionTransfer
    const lastDiagnoses = lastTransfer?.condition_info?.[conditionType]?.split(',')
    return lastDiagnoses
      ?.map(
        (lastDiagnosis: string) =>
          conditionsList?.find((possibleDiagnosis) => lastDiagnosis === possibleDiagnosis.value)?.label,
      )
      .filter((diagnosisLabel: string) => diagnosisLabel)
  }

  const showCreditedSessionTransition = [
    ProgramNames.SingleSessionCoaching,
    ProgramNames.Coaching,
    ProgramNames.MedicationManagement,
    ProgramNames.ClinicalLeaveEvaluation,
  ].includes(getProgramNameFromId(appointment.programId) as ProgramNames)

  let metadata = endOfSessionMetaData({
    clientDetails,
    conditionsList,
    terminationStatuses: terminationStatuses(),
    isLastSessionToClose:
      pastAppointmentsToClose.filter((appt: any) => {
        return appt.sessionNumber > appointment.sessionNumber && appt.episodeId === selectedEpisode?.id
      }).length === 0,
    ...(enhancedLCxDiagnosisEnabled
      ? {
          primary_condition: getPreviousSessionConditions('primary_condition'),
          other_primary_description: previousSessionTransfer?.condition_info?.other_primary_description,
          secondary_condition: getPreviousSessionConditions('secondary_condition'),
          other_secondary_description: previousSessionTransfer?.condition_info?.other_secondary_description,
          primary_condition_severity: previousSessionTransfer?.condition_info?.primary_condition_severity,
          underassessment_verification: previousSessionTransfer?.condition_info?.underassessment_verification,
        }
      : { diagnosis: getPreviousSessionDiagnosis() }),
    ...(nextSessionUsesHealthPlan && {
      icd10_primary_diagnosis: getPreviousSessionICD10('icd10_primary_diagnosis'),
    }),
    ...(nextSessionUsesHealthPlan && {
      icd10_secondary_diagnosis: getPreviousSessionICD10('icd10_secondary_diagnosis'),
    }),
    showCreditedSessionTransition,
    shouldShowGuardianConsent,
    enhancedLCxDiagnosisEnabled,
  }) as FormMetadata

  // Coaches should not be asked to provide ICD10 and verify copay consent for coaching health plan sessions.
  // Default values will be used when the health plan claim is generated.
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  if (healthPlanExtensionEligible && !coachingSessionTypesList.includes(sessionType)) {
    metadata = extendMetadata(metadata, ICD10sFormMetadata.meta_data as FormMetadata)
  }
  const { APPROACHING_ATTESTATION, ATTESTATION_PRIOR_AUTHORIZATION, ATTESTATION_NEEDED } =
    specialityCareAttestationStatuses
  const { label: companyLabel } = customers.find(({ value }: any) => value === employer) || {}
  switch (specialtyCareAttestationStatus) {
    case APPROACHING_ATTESTATION:
      metadata = extendMetadata(
        metadata,
        getApproachingSpecialityCareMetadata(companyLabel, fullName).meta_data as FormMetadata,
      )
      break
    case ATTESTATION_NEEDED:
      metadata = extendMetadata(metadata, attestationFormMetadata.meta_data as FormMetadata)
      metadata.initialValues = merge(
        metadata.initialValues,
        prepopulateAttestation(pastPayments?.filter((pastPayment: any) => pastPayment.is_health_plan)),
      )
      break
    case ATTESTATION_PRIOR_AUTHORIZATION:
      metadata = extendMetadata(
        metadata,
        getPreAuthSpecialityCareMetadata(companyLabel, firstName, fullName).meta_data as FormMetadata,
      )
      break
    default:
      break
  }

  const assignCurrentSessionActivities = async () => {
    const newSessionCount = appointment.sessionNumber + 1
    const curriculumObject = curriculum.find((obj: any) => obj.session === newSessionCount) || {}
    if (isEmpty(curriculumObject.contents)) return

    const promises: any = []
    curriculumObject.contents.forEach(async (activity: any) => {
      if (activity.group === 'exercise' && activity.id) {
        return
      } else {
        const content = contents.find((content: any) => content.name === activity.name) || {}

        if (isEmpty(content)) return
        promises.push(
          setAssignment({
            provider_id: user?.id,
            patient_id: clientId,
            content_id: content.id,
            content_meta_data: activity.content_meta_data ?? content.meta_data,
            instructions: activity.instructions ?? content.instructions,
            session_period: newSessionCount,
          }),
        )
      }
    })
    return Promise.all(promises)
  }

  /**
   * This will handle expiring or completing assignments in the current session when the provider closes the session.
   * Lessons, guides, and assessments are set to completed when they are completed by the client. Exercises are only set from new to completed when the session closes.
   * However, it is possible for exercises to have a completed status when a client submits an entry when it is missed.
   *
   * @return {Promise} A list of API endpoints that will be called to set the status of an assignment to either missed or completed
   *
   */
  const expireAndCompletePreviousAssignments = async () => {
    // Handles lessons, guides, and assessments
    const assignmentsToMarkAsMissed = assignments.filter((assignment: any) => {
      return (
        assignment.session_period <= appointment.sessionNumber &&
        ['new', 'in_progress'].includes(assignment.status) &&
        get(assignment, 'content.stage') !== 'initial' &&
        get(assignment, 'content.group') !== 'exercise'
      )
    })
    // Exercises don't contain the same functionality of being marked as completed or missed until the session is closed. Thus, we'll want to handle those cases here
    // ICE-4396: Mark exercises as completed only if they have a valid submission else mark as missed
    const exercisesToMarkAsCompleted = assignments.filter((assignment: any) => {
      return (
        get(assignment, 'content.group') === 'exercise' &&
        assignment.session_period <= appointment.sessionNumber &&
        ['in_progress'].includes(assignment.status) &&
        !isEmpty(assignment.assignment_responses) &&
        assignment.assignment_responses.some((response: any) => response.status === 'completed')
      )
    })
    const exercisesToMarkAsMissed = assignments.filter((assignment: any) => {
      return (
        get(assignment, 'content.group') === 'exercise' &&
        assignment.session_period <= appointment.sessionNumber &&
        (['new'].includes(assignment.status) ||
          (['in_progress'].includes(assignment.status) &&
            !assignment.assignment_responses.some((response: any) => response.status === 'completed')))
      )
    })
    const promises: any = []
    assignmentsToMarkAsMissed
      .concat(exercisesToMarkAsMissed)
      .forEach((assignment: any) => promises.push(updateAssignment({ ...assignment, status: 'missed' })))

    exercisesToMarkAsCompleted.forEach((exercise: any) =>
      promises.push(updateAssignment({ ...exercise, status: 'completed' })),
    )

    return Promise.all(promises)
  }

  // @ts-expect-error TS(7030): Not all code paths return a value.
  const submitForm = async (values) => {
    const {
      attendance,
      changeNotice,
      episode_state: episodeState,
      termination_comment: terminationComment,
      technicalIssuesCancelDetails,
      technicalIssuesCancelSelection,
      diagnosis,
      primary_condition,
      providerInitiated,
      providerInitiatedDetails,
      secondary_condition,
      guardianConsent,
    } = values
    const changeReason = values.changeReason

    const { missed, canceled, completed } = appointmentStatuses
    const isAppointmentMissed = attendanceValues.NO_SHOW === attendance
    const appointmentToUpdate = cloneDeep(appointment)
    const formValues = cloneDeep(omit(values, ['diagnosis', 'primary_condition', 'secondary_condition']))
    // for No-show, the appointment status is missed, for all other reasons of session did not happen, it should be canceled.
    appointmentToUpdate.appointmentStatus = (
      isAppointmentMissed && changeReason === 'clientInitiatedLate'
        ? missed
        : isAppointmentMissed
        ? canceled
        : completed
    ) as any
    // if a change reason is provided and client initiated the cancel (gave 24 hour notice), set the changeReason to the changeNotice value
    appointmentToUpdate.changeReason = changeReason !== 'clientCancel' ? changeReason : changeNotice

    // for clientIneligible, we append changeReason of 'clientIneligible'
    if (attendance === attendanceValues.INELIGIBLE) {
      appointmentToUpdate.changeReason = 'clientIneligible'
    }

    if (technicalIssuesCancelDetails || technicalIssuesCancelSelection) {
      appointmentToUpdate.appointmentAttributes = { technicalIssuesCancelDetails, technicalIssuesCancelSelection }
    }

    if (changeReason === 'providerInitiated') {
      appointmentToUpdate.appointmentAttributes = {
        providerInitiatedCancelSelection: providerInitiated,
        providerInitiatedCancelDetails: providerInitiatedDetails,
      }
    }

    if (enhancedLCxDiagnosisEnabled) {
      formValues.primary_condition = primary_condition?.map((selectedDiagnosis: string) => {
        const condition = conditionsList.find((possibleDiagnosis) => possibleDiagnosis.label === selectedDiagnosis)
        return condition ? condition.value : ''
      })
      formValues.secondary_condition = secondary_condition?.map((selectedDiagnosis: string) => {
        const condition = conditionsList.find((possibleDiagnosis) => possibleDiagnosis.label === selectedDiagnosis)
        return condition ? condition.value : ''
      })
      formValues.diagnosis = []
    } else {
      values.diagnosis = diagnosis?.map((selectedDiagnosis: string) =>
        diagnoses.find((possibleDiagnosis) => possibleDiagnosis.label === selectedDiagnosis),
      )
    }
    try {
      // no charge should be sent for no_show backend will take care of charging for cancellations
      const shouldCharge = attendance !== attendanceValues.NO_SHOW && attendance !== attendanceValues.INELIGIBLE
      let charge = {}
      setIsFormProcessing(true)
      if (shouldCharge) {
        charge = await submitPaymentRequest(
          getBCCharge(
            enhancedLCxDiagnosisEnabled ? formValues : values,
            user,
            clientDetails,
            appointmentToUpdate,
            selectedEpisode,
            sessionsRates,
          ),
          true,
          isProgramLevelSessionLimitEnabled,
        )
        logToSumoLogic('closeSession', user?.id, {
          step: '1. Charge sent',
          clientId,
          returnedCharge: (charge as any).data,
        })
      }

      const updatedAppointment = await updateAppointment({
        providerId: userId,
        data: appointmentToUpdate,
      }).unwrap()

      logToSumoLogic('closeSession', user?.id, { step: '2. Appointment updated', clientId, updatedAppointment })
      if (shouldShowGuardianConsent) {
        await updateEpisode({
          ...selectedEpisode,
          guardian_consent: guardianConsent,
        })
      }
      if (values.is_last_session) {
        await updateEpisode({
          ...selectedEpisode,
          state: episodeState,
          end_date: moment().utc().format(),
          end_episode_note: terminationComment,
        })
      } else if (!isAppointmentMissed && programConfig.autoAssignOnSessionClose) {
        await expireAndCompletePreviousAssignments()
        logToSumoLogic('closeSession', user?.id, {
          step: '3. Previous activities completed',
          clientId,
        })
        await assignCurrentSessionActivities()
        logToSumoLogic('closeSession', user?.id, {
          step: '4. New activites assigned',
          clientId,
        })
      }
      await getLtClient({ clientId, providerId: user?.id })
      navigate(CLIENT_HOME.route)
      actionAlertHandler({
        actionStyle: actionStyles.SUCCESS,
        message: `Session ${appointment.sessionNumber} has been closed`,
        expires: false,
        dispatch,
        autoDismissTimer: 4000,
      })
      return charge
    } catch (error) {
      actionAlertHandler({
        actionStyle: actionStyles.ERROR,
        message: error,
        dispatch,
      })
    } finally {
      setIsFormProcessing(false)
    }
  }

  const isLastSessionHandler = ({ input: { value } }: any) => {
    if (!isBoolean(value)) {
      return null
    }
    let notificationBanner
    const currentEpisodeUpcomingAppointments =
      allAppointments?.filter((appt: any) => appt.episodeId === selectedEpisode.id && !appt.pastAppointment) ?? []
    if (value) {
      notificationBanner = <InlineErrorBanner text='The episode with your client will be closed' />
    } else {
      if (programConfig.autoAssignOnSessionClose) {
        let notificationText = 'The next activities will be shared with your client'
        if (hasRole(user?.roles, [ROLES.LT_PRESCRIBER, ROLES.LT_PRESCRIBER_SUPERVISOR])) {
          const noteForSession = notes.find(
            (note: any) => get(note, 'appointment_id') === get(appointment, 'appointmentId'),
          )
          if (!noteForSession || get(noteForSession, 'status') !== 'completed') {
            notificationText +=
              ', including assessments. Assessments sent are determined by fields in notes. Please complete notes before closing a session.'
          }
        }
        notificationBanner = <InlineInfoBanner text={notificationText} />
      }
    }
    return (
      <NotificationBannerContainer>
        {notificationBanner}
        {value && currentEpisodeUpcomingAppointments.length > 0 && (
          <CloseSessionWarningText
            style={{ marginTop: '15px' }}
            testID={tID('EndOfSession-closeUpcomingSessionBanner')}
            text='If you have upcoming sessions when you close the episode, cancel them to avoid seeing unfinished notes.'
          />
        )}
      </NotificationBannerContainer>
    )
  }

  const renderHealthPlanLimitInfo = () => {
    const currentYear = new Date().getFullYear().toString()
    const programCoverageBreakdown = customers?.find(
      (customer: any) => customer.value.toLowerCase() === clientDetails.employer.toLowerCase(),
    )?.program_coverage_breakdown
    const currentProgramCoverageBreakdown =
      programCoverageBreakdown &&
      currentYear in programCoverageBreakdown &&
      programConfig.programCoverageName in programCoverageBreakdown[currentYear] &&
      programCoverageBreakdown[currentYear][programConfig.programCoverageName]

    let healthPlanLimitInfo = `The client has completed ${sessionsUsed} of ${sessionsLimit} free sessions. `
    if (currentProgramCoverageBreakdown?.bhbSupported) {
      switch (healthPlanStatus) {
        case healthPlanStates.NOT_READY:
          healthPlanLimitInfo += `The client may be eligible to use their medical plan and credit card for co-pays beyond ${sessionsLimit} sessions. Ask the client to check their email or Lyra Profile to add their medical plan and payment information.`
          break
        case healthPlanStates.READY:
          healthPlanLimitInfo += `Sessions beyond ${sessionsLimit} will be submitted to the client’s medical plan and Lyra will collect a co-pay from the client. `
          break
      }
    }
    if (currentProgramCoverageBreakdown?.specialtyBhbSupported && healthPlanStatus === healthPlanStates.READY) {
      healthPlanLimitInfo += `You will be asked to provide ICD-10 diagnoses after session ${sessionsLimit}, as required by the medical plan.`
    }
    return <BodyText testID={tID('EndOfSession-HealthPlanLimitInfo')} text={healthPlanLimitInfo} />
  }

  const customValidationFunction = (fields: any, values: any) => {
    let errors = {}
    // @ts-expect-error TS(2339): Property 'items' does not exist on type 'unknown'.
    Object.entries(fields).forEach(([, { items, condition }]) => {
      // @ts-expect-error TS(2345): Argument of type '{ condition: any; formValues: an... Remove this comment to see the full error message
      const isDisplayed = isFieldDisplayed({ condition, formValues: values, fields })
      if (items) {
        const fields = items.reduce(
          // @ts-expect-error TS(7006): Parameter 'obj' implicitly has an 'any' type.
          (obj, item) => ({
            [item.name]: item,
            ...obj,
          }),
          {},
        )
        const isOneSelected = isOneArrayItemTrue(fields, values)
        if (!isOneSelected && isDisplayed) {
          errors = merge(errors, getItemsErrors(fields))
        }
      }
    })
    return errors
  }

  return isLoading ? (
    <LoadingIndicator size={45} />
  ) : (
    <Container>
      {isFormProcessing ? (
        <>
          <Subheader
            size={SubheadSize.LARGE}
            text={`Closing Session with ${clientDetails.first_name}`}
            color={colors.ui_oatmeal6}
          />
          <BodyText text='Please do not navigate away from or close this page' />
          <LoadingIndicator size={45} />
        </>
      ) : (
        <>
          <Subheader
            size={SubheadSize.LARGE}
            text={`Close Session ${appointment.sessionNumber} with ${clientDetails.first_name}`}
            color={colors.ui_oatmeal6}
          />
          {eligibilityError && <EligibilityErrorBanner />}
          <InlineInfoBanner
            text={
              <>
                You are about to close session {appointment.sessionNumber} for {clientDetails.first_name}. This action
                has billing implications for clients and customers.{' '}
                <Link
                  text={'Learn more about program session closure policy.'}
                  onPress={() => window.open(programConfig.sessionClosureGuide, '_blank')}
                  testID={tID(`EndOfSession-sessionClosurePolicyLink`)}
                  size={BodyTextSize.DEFAULT}
                />
              </>
            }
          />
          {metadata && (
            <FormBody
              intl={intl}
              schema={metadata.schema as FieldSchema}
              uiSchema={metadata.uiSchema}
              initialValues={metadata.initialValues}
              customFields={{ icd10s: (props: any) => <ICD10sField {...props} availableCodes={icd10Codes} /> }}
              customValidation={customValidationFunction}
              formButton={({ handleSubmit, pristine, loading }: FormButtonParams) => {
                return (
                  <AdjacentButtonContainer>
                    <PrimaryButton
                      disabled={pristine && !loading}
                      testID={tID('EndOfSession-closeSessionButton')}
                      onPress={handleSubmit}
                      text='Close Session'
                      style={{ marginRight: '16px' }}
                    />
                    <SecondaryButton
                      testID={tID('EndOfSession-keepSessionButton')}
                      onPress={() => navigate(CLIENT_HOME.route)}
                      text='Keep Session'
                    />
                  </AdjacentButtonContainer>
                )
              }}
              saveForm={({ values }) => {
                submitForm(values)
              }}
              name='endOfSession'
              scrollContainerCustomStyles={{
                formBodyPageContainer: css`
                  padding-top: 0px;
                  max-width: 100%;
                  background-color: transparent;
                `,
                scrollContainerContentCustomStyles: {
                  paddingHorizontal: 16,
                },
              }}
              formBodyCustomStyles={
                {
                  submitButtonContainer: {
                    borderTopWidth: 0,
                    padding: 0,
                    width: '100%',
                    boxShadow: 'none',
                    background: 'transparent',
                  },
                  submitButtonWrapper: { maxWidth: 'none', marginLeft: '40px', marginBottom: '20px' },
                } as unknown as FormBodyCustomStyles
              }
              formContext={{ useMultiSelectTypeAhead: true, saveSingleSelectFieldsAsArray: false }}
            >
              {sessionsRemaining <= 5 &&
                sessionsRemaining > 0 &&
                isNil(openBhbSessionLimit) &&
                renderHealthPlanLimitInfo()}
              <Field name='is_last_session' subscription={{ value: true }} render={isLastSessionHandler} />
            </FormBody>
          )}
        </>
      )}
    </Container>
  )
}

type EndOfSessionProps = ConnectedProps<typeof connector>

const mapStateToProps = (state: RootState) => {
  return {
    sessionsRates: getRequestPaymentRates(state),
    icd10Codes: getHealthPlanICD10s(state),
    user: getAuthUser(state),
    clientDetails: getClientDetailsData(state) || {},
    selectedEpisode: getClientSelectedEpisode(state),
    curriculum: getSelectedEpisodeCurriculum(state),
    assignments: getClientAssignmentsData(state),
    contents: getLyraTherapyContentsData(state),
    notes: getClientNotesData(state),
    customers: getRequestPaymentClientCustomers(state),
    episodes: getClientEpisodesData(state),
    programConfig: getClientEpisodeProgramConfig(state),
    pastAppointmentsToClose: getClientUnclosedPastAppointments(state),
    pastPayments: getPaymentsDataAllData(state),
    currentSessionCount: getClientCurrentSessionCount(state) || 0,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,

  actions: bindActionCreators(
    {
      getHPEligibility,
      getICD10s,
      submitPaymentRequest,
      updateEpisode,
      setAssignment,
      updateAssignment,
      getLtClient,
      getPayments,
      checkEligibility,
    },
    dispatch,
  ),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(EndOfSession)
