import React, { useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { Appointment } from '@lyrahealth-inc/shared-app-logic'
import { BootstrapContainer, CardFullWidth, NavButton, PrimaryButton, SessionsList } from '@lyrahealth-inc/ui-core'

import styles from './sessionSelectionConfirmation.module.scss'
import { APPOINTMENT_OPERATIONS } from '../../common/constants/appConstants'
import {
  CLIENTS_NEW_SESSION_DETAILS,
  CLIENTS_RESCHEDULE_SESSION_DETAILS,
  CLIENTS_SESSIONS,
} from '../../common/constants/routingConstants'
import { getAuthUserId } from '../../data/auth/authSelectors'
import {
  getClientAppointmentDetails,
  getClientDetailsData,
  getClientSelectedEpisode,
} from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'
import {
  useCreateAppointmentMutation,
  useCreateRecurringAppointmentMutation,
  useUpdateAppointmentMutation,
} from '../clients/clientDetails/data/appointmentsApi'
import { setToastContent } from '../data/ltToastAutoActions'

function SessionSelectionConfirmation({ appointment, selectedEpisode, operation, actions: { setToastContent } }: any) {
  const providerID: string | undefined = useSelector(getAuthUserId)
  const [isSchedulingAppointment, setIsSchedulingAppointment] = useState(false)
  const [createAppointment] = useCreateAppointmentMutation()
  const [createRepeatingAppointment] = useCreateRecurringAppointmentMutation()
  const [updateAppointment] = useUpdateAppointmentMutation()
  const navigate = useNavigate()

  let headerCTA, submitText, goBack: any
  let onSubmit: () => Promise<Appointment | void>
  switch (operation) {
    case APPOINTMENT_OPERATIONS.NEW:
      headerCTA = 'Create new'
      submitText = 'Create session'
      goBack = () => navigate(CLIENTS_NEW_SESSION_DETAILS.route)
      const sessionCount = appointment?.repeatFor || 0
      const appointmentCreationFunction = sessionCount > 1 ? createRepeatingAppointment : createAppointment
      onSubmit = () => {
        return appointmentCreationFunction({
          providerId: providerID,
          data: {
            ...appointment,
            appointment,
            episodeId: selectedEpisode?.id,
            providerPatientId: selectedEpisode?.provider_patient_id,
            intervalDays: appointment?.sessionFrequency,
            sessionCount,
            programId: selectedEpisode?.program_id,
          },
        }).unwrap()
      }
      break
    case APPOINTMENT_OPERATIONS.RESCHEDULE:
      headerCTA = 'Reschedule'
      submitText = 'Reschedule'
      goBack = () => navigate(CLIENTS_RESCHEDULE_SESSION_DETAILS.route)
      onSubmit = () => {
        return updateAppointment({
          providerId: providerID,
          data: {
            ...appointment,
            programId: selectedEpisode.program_id,
          },
        }).unwrap()
      }
      break
  }

  const submitAppointment = () => {
    setIsSchedulingAppointment(true)
    onSubmit()
      .then(() => {
        navigate(CLIENTS_SESSIONS.route)
        setToastContent({
          text: `Done! Successfully ${
            operation === APPOINTMENT_OPERATIONS.NEW ? 'scheduled' : 'rescheduled'
          } the appointment`,
          id: 'SessionSelectionConfirmation-Toast-Success',
          toastType: 'success',
        })
      })
      .catch(() => {
        setIsSchedulingAppointment(false)
      })
  }

  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'>
      <div>
        <NavButton text='Back' styleType='back' onClick={goBack} />
        <h2>{`${headerCTA} session`}</h2>
        <CardFullWidth styleName='details-card' roundCorners>
          <div styleName='container'>
            {/* @ts-expect-error TS(2739): Type '{ userRole: string; appointments: any[]; }' ... Remove this comment to see the full error message */}
            <SessionsList userRole='provider' appointments={[appointment]} />
            <PrimaryButton
              type='submit'
              data-test-id='SessionSelectionConfirmation-submit-button'
              onClick={submitAppointment}
              isLoading={isSchedulingAppointment}
            >
              {submitText}
            </PrimaryButton>
          </div>
        </CardFullWidth>
      </div>
    </BootstrapContainer>
  )
}

SessionSelectionConfirmation.propTypes = {
  operation: PropTypes.oneOf([APPOINTMENT_OPERATIONS.NEW, APPOINTMENT_OPERATIONS.RESCHEDULE]),
  selectedEpisode: PropTypes.object,
}

const mapStateToProps = (state: RootState) => {
  return {
    appointment: getClientAppointmentDetails(state),
    selectedEpisode: getClientSelectedEpisode(state),
    clientDetails: getClientDetailsData(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    ...bindActionCreators(
      {
        setToastContent,
      },
      dispatch,
    ),
    dispatch,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(SessionSelectionConfirmation, styles))
