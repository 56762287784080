import React, { FunctionComponent, ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { getVideoStatusesLabel, VideoLesson, VideoStatuses } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Headline, Size as HeadLineSize } from '../../atoms/headline/Headline'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { AccessibilityRolesNative } from '../../constants'
import { SubheadSize as SizeType } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { LessonThumbnail } from '../lessonThumbnail/LessonThumbnail'

export interface VideoItemProps extends VideoLesson {
  thumbnail: string
  videoTitle: string
  timeFormatted?: string
  completionStatus: VideoStatuses
  onPress: (id: string, videoPropertyName?: string) => void
  videoId: string
  withCTA?: boolean
  videoItem?: number
  ctaTitle?: string | ReactNode
  accessibilityLabel?: string
  videoPropertyName: string
  videoProgress: number
  withProgressBar?: boolean
  videoTitleSize?: SizeType
  disabled?: boolean
}

const CallToActionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  position: 'static',
  paddingTop: theme.spacing['16px'],
}))

const VideoItemContainer = styled(PressableOpacity)<{
  hoverIn?: boolean
  theme: ThemeType
}>(
  ({
    hoverIn,
    disabled,
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
      colors,
    },
  }) => ({
    width: '100%',
    backgroundColor: hoverIn ? colors.backgroundFocus : colors.cardBackgroundPrimary,
    border: `1px solid ${disabled ? colors.cardOutlineInactive : colors.cardOutlinePrimary}`,
    flexDirection: 'column',
    borderRadius: spacing['16px'],
    padding: spacing['24px'],
    ...(!isMinWidthTablet && { padding: spacing['16px'] }),
  }),
)

const VideoInfoContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
    },
  }) => ({
    top: '-4px',
    marginLeft: isMinWidthTablet ? spacing['24px'] : spacing['16px'],
    flex: 1,
  }),
)

const Title = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  paddingBottom: theme.spacing['4px'],
}))

const SubTitle = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  paddingBottom: theme.spacing['8px'],
}))

const TopContainer = styled.View({
  flexDirection: 'row',
})

const TimeHeadline = styled(Headline)<{ theme: ThemeType }>(({ theme }) => ({
  paddingLeft: theme.spacing['4px'],
}))
/**
 * Represents a single video on the lesson overview page
 */
export const VideoItem: FunctionComponent<VideoItemProps> = ({
  thumbnail,
  videoTitle,
  timeFormatted,
  completionStatus,
  onPress,
  videoId,
  withCTA,
  videoItem,
  ctaTitle,
  accessibilityLabel,
  videoPropertyName,
  videoProgress,
  withProgressBar = false,
  videoTitleSize = SubheadSize.XSMALL,
  disabled,
}) => {
  const [hoverIn, setHoverIn] = useState(false)
  const { formatMessage } = useIntl()
  const {
    breakpoints: { isMinWidthTablet },
    colors,
  } = useTheme()

  return (
    <VideoItemContainer
      testID={tID('VideoItem')}
      onHoverIn={() => setHoverIn(true)}
      onHoverOut={() => setHoverIn(false)}
      hoverIn={hoverIn}
      onPress={() => onPress(videoId, videoPropertyName)}
      accessible
      accessibilityRole='button'
      accessibilityLabel={`PART ${videoItem}, ${timeFormatted}, ${videoTitle}, ${getVideoStatusesLabel(
        completionStatus,
      )}`}
      disabled={disabled}
    >
      <TopContainer importantForAccessibility='no-hide-descendants'>
        <LessonThumbnail
          id={videoId}
          thumbnailMetadata={thumbnail}
          width={isMinWidthTablet ? 170 : 116}
          height={isMinWidthTablet ? 98 : 64}
          showPlayButton={completionStatus !== 'completed'}
          showRefreshButton={completionStatus === 'completed'}
          withProgressBar={withProgressBar}
          videoProgress={completionStatus === 'completed' ? 1 : videoProgress}
          disabled={disabled}
          progressBarHeight={4}
          videoTitle={videoTitle}
        />
        <VideoInfoContainer>
          <SubTitle>
            {videoItem && (
              <Headline
                text={formatMessage(
                  {
                    defaultMessage: 'PART {videoItem}',
                    description: 'Indicates the chronological order of the video item',
                  },
                  {
                    videoItem,
                  },
                )}
                size={HeadLineSize.EYEBROW}
                color={disabled ? colors.textInactive : colors.textSecondary}
              />
            )}
            {!!timeFormatted && (
              <TimeHeadline
                text={formatMessage(
                  {
                    defaultMessage: '· {timeFormatted}',
                    description: 'Shows the local time prepended with a dot',
                  },
                  {
                    timeFormatted,
                  },
                )}
                size={HeadLineSize.EYEBROW}
                color={disabled ? colors.textInactive : colors.textSecondary}
              />
            )}
          </SubTitle>
          <Title>
            <Subhead
              text={videoTitle}
              size={videoTitleSize}
              color={disabled ? colors.textInactive : colors.textPrimary}
            />
          </Title>
          <BodyText
            text={getVideoStatusesLabel(completionStatus)}
            size={BodyTextSize.SMALL}
            color={disabled ? colors.textInactive : colors.textSecondary}
          />
        </VideoInfoContainer>
      </TopContainer>
      {withCTA && (
        <CallToActionContainer>
          <PrimaryButton
            text={ctaTitle}
            onPress={() => onPress(videoId, videoPropertyName)}
            accessibilityLabel={accessibilityLabel}
            fullWidth
            role={AccessibilityRolesNative.NONE}
          />
        </CallToActionContainer>
      )}
    </VideoItemContainer>
  )
}
