import { useSelector } from 'react-redux'

import { useTargetDate } from './useTargetDate'
import { employmentTypes, ROLES } from '../../../common/constants/appConstants'
import { hasRole } from '../../../common/utils/utils'
import { getAuthConfig, getAuthEmploymentStatus, getAuthRoles, getAuthUserId } from '../../../data/auth/authSelectors'
import { RTKQueryHook, RTKQueryResultType } from '../../../data/baseApi'
import { useGetTargetUtilizationQuery } from '../../clients/clientDetails/data/utilizationApi'

export const useTargetUtilizationData: RTKQueryHook<typeof useGetTargetUtilizationQuery> = (args, opts) => {
  const userId = useSelector(getAuthUserId)
  const config = useSelector(getAuthConfig)
  const showMinifiedUtilization = config?.showMinifiedUtilization ?? false
  const userRoles = useSelector(getAuthRoles)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const { targetDate, shouldRequestCurrentQuarter } = useTargetDate()

  const newCaseloadDashboardVersion =
    employmentStatus &&
    hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) &&
    [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus)

  return useGetTargetUtilizationQuery(
    {
      providerId: userId,
      date: targetDate,
      includeFinalWeek: !shouldRequestCurrentQuarter,
      ...args,
    },
    { skip: showMinifiedUtilization && !newCaseloadDashboardVersion, ...opts },
  ) as RTKQueryResultType<typeof useGetTargetUtilizationQuery>
}
