import React, { FunctionComponent, MutableRefObject, useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { AssignmentStatuses, Contentmetadata, useWindowSize } from '@lyrahealth-inc/shared-app-logic'

import { PrimaryButton, ThemedStatusBar } from '../../atoms'
import { LoadingIndicator } from '../../atoms/icons/LoadingIndicator'
import { IS_WEB } from '../../constants'
import { ActivityHeader } from '../../organisms/activityHeader/ActivityHeader'
import { FormBody, FormButtonParams } from '../../organisms/formBody/FormBody'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface LessonReviewSubmittedProps {
  content: Contentmetadata
  title?: string
  name?: string
  status?: string
  response?: Dict
  onClosePress: () => void
  saveForm: (values: Dict) => void
  date?: string | null
  a11yFocusedElementOnLoad?: MutableRefObject<any>
}

const LessonReviewSubmittedContainer = styled(Flex1View)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
}))

const LoadingContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  backgroundColor: colors.backgroundSecondary,
}))

const CTAContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
    },
  }) => ({
    marginTop: spacing['16px'],
    marginBottom: isMinWidthTablet ? spacing['16px'] : spacing['32px'],
  }),
)

/**
 * A wrapper for the lesson review form after it has been submitted (read only)
 */
export const LessonReviewSubmitted: FunctionComponent<LessonReviewSubmittedProps> = ({
  content,
  title = '',
  status,
  response,
  onClosePress,
  name,
  date,
  a11yFocusedElementOnLoad,
  saveForm,
}) => {
  const [isAtTopOfPage, setIsAtTopOfPage] = useState(true)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const [, windowHeight] = useWindowSize()
  const { schema, uiSchema } = content ?? {}
  const intl = useIntl()
  const completed = status === AssignmentStatuses.completed
  const { colors } = useTheme()
  const backgroundColor = colors.backgroundSecondary

  const formButton = ({ handleSubmit }: FormButtonParams) => {
    return (
      <CTAContainer>
        <PrimaryButton
          text={<FormattedMessage defaultMessage='Done' description='Button to dismiss lesson review' />}
          onPress={() => handleSubmit()}
          testID={tID('LessonReviewSubmitted-submit')}
          accessibilityLabel={intl.formatMessage({
            defaultMessage: 'Done',
            description: 'button text done',
          })}
          fullWidth
        />
      </CTAContainer>
    )
  }

  const calculateScrollFieldHeight = useCallback(() => {
    return windowHeight - (headerHeight + footerHeight)
  }, [footerHeight, headerHeight, windowHeight])

  return (
    <LessonReviewSubmittedContainer testID={tID('LessonReviewSubmitted')} backgroundColor={backgroundColor}>
      <ActivityHeader
        title={title}
        date={date}
        status={status}
        onClosePress={onClosePress}
        setHeaderHeight={IS_WEB ? setHeaderHeight : noop}
        a11yFocusedElementOnLoad={a11yFocusedElementOnLoad}
        isAtTopOfPage={isAtTopOfPage}
      />
      <Flex1View testID={tID('form-container')}>
        {schema && response ? (
          <FormBody
            intl={intl}
            schema={schema}
            uiSchema={uiSchema}
            initialValues={response}
            name={name}
            readOnly
            showEditButtons={false}
            saveForm={saveForm}
            formButton={!completed ? formButton : undefined}
            showAnswers
            setFooterHeight={setFooterHeight}
            // Only calculate the scroll field height on web, doing this on mobile will cause slow performance
            scrollListHeight={IS_WEB ? calculateScrollFieldHeight() : undefined}
            hasNewLookAndFeel
            backgroundColor={backgroundColor}
            setIsAtTopOfPage={setIsAtTopOfPage}
          />
        ) : (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        )}
      </Flex1View>
      <ThemedStatusBar defaultStyle='dark' />
    </LessonReviewSubmittedContainer>
  )
}
