/* eslint-disable formatjs/no-literal-string-in-jsx */
import React from 'react'
import { ReactPlayerProps } from 'react-player'

import styled from 'styled-components/native'

import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { RowView } from '../../templates/content/CommonViews'
import { tID } from '../../utils/utils'

const VideoContainer = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'lavender',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingTop: '50px',
})

const ButtonsContainer = styled.View({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '10px',
})

export default class ReactPlayerMock {
  public render(): any {
    return ReactPlayerClassMock
  }
}

class ReactPlayerClassMock extends React.Component<
  ReactPlayerProps,
  {
    progress: number
    duration: number
  }
> {
  public constructor(props: ReactPlayerProps) {
    super(props)
    if (props.ref) {
      props.ref.current = this
    }
    this.state = {
      progress: 0,
      duration: 100,
    }
    if (props.url && (props.url as string).includes('vimeo')) {
      fetch('https://vimeo.com/api/oembed.json?')
    }
  }

  seekTo = (fraction: number) => {
    this.progress({ played: fraction })
  }

  getDuration = () => {
    return this.state.duration
  }

  getCurrentTime = () => {
    return this.state.progress * this.state.duration
  }

  progress = ({ played }: { played: number }) => {
    // Only `played` is used
    if (this.props.onProgress) this.props.onProgress({ played, playedSeconds: 0, loaded: 0, loadedSeconds: 0 })
    this.setState({ progress: played })
  }

  ended = () => {
    if (this.props.onEnded) this.props.onEnded()
    this.progress({ played: 1 })
  }

  start = () => {
    if (this.props.onStart) this.props.onStart()
  }

  play = () => {
    if (this.props.onPlay) this.props.onPlay()
  }

  seek = (seconds: number) => {
    if (this.props.onSeek) this.props.onSeek(seconds)
  }

  duration = (totalDuration: number) => {
    if (this.props.onDuration) this.props.onDuration(totalDuration)
  }

  watchHalf = () => {
    this.progress({ played: 0.5 })
    if (this.props.onSeek) this.props.onSeek(0.5)
  }

  render(): any {
    return (
      <VideoContainer testID={tID('VideoPlayer')}>
        <BodyText text='Mocked Web Video Player (react-player)' />
        <RowView>
          <BodyText text={`Progress: `} />
          <BodyText testID={tID('VideoPlayer-currentProgress')} text={`${this.state.progress}`} />
        </RowView>

        <ButtonsContainer>
          <PrimaryButton
            testID={tID('VideoPlayer-init')}
            text={'init'}
            onPress={() => {
              this.start()
              this.duration(this.state.duration)
            }}
            size={ButtonSize.SMALL}
          />

          <PrimaryButton
            testID={tID('VideoPlayer-onEnded')}
            text={'onEnded'}
            onPress={this.ended}
            size={ButtonSize.SMALL}
          />

          <PrimaryButton
            testID={tID('VideoPlayer-onPlay')}
            text={'onPlay'}
            onPress={this.play}
            size={ButtonSize.SMALL}
          />

          <PrimaryButton
            testID={tID('VideoPlayer-watchHalf')}
            text={'Watch half'}
            onPress={this.watchHalf}
            size={ButtonSize.SMALL}
          />
        </ButtonsContainer>
      </VideoContainer>
    )
  }
}
