import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useBlocker, useNavigate } from 'react-router'

import { bindActionCreators } from 'redux'

import { BookingMode, ProviderCalendarEvent, useFlags, useInterval } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  ConfirmationModal,
  LoadingIndicator,
  ProviderBookableCalendarSetup,
  ProviderCalendarLoadingModal,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import '../settings/providerBookableCalendar.scss'

import {
  getCalendarAvailabilitySlots,
  getCalendars,
  patchCalendarProvider,
  postCalendarEventsTemplate,
  putCalendarEventsTemplateCancel,
  showCalendarLiveModal,
} from './data/calendarActions'
import {
  getCalendarAvailabilitySlots as getCalendarAvailabilitySlotsSelector,
  getCalendarConfiguration,
} from './data/calendarSelectors'
import { getBookableMaxSlots, getBookableTarget, getSlotsToUpdate, mapEventToBookableEvent } from './utils'
import { actions as mixpanelActions, mixpanelEvents } from '../../../mixpanel/mixpanelConstants'
import { track } from '../../../mixpanel/mixpanelTracking'
import { LC_CALENDAR_CURRENT, SETTINGS } from '../common/constants/routingConstants'
import { getAuthConfig, getAuthEmploymentStatus, getAuthUserId } from '../data/auth/authSelectors'
import { setToastContent } from '../lyraTherapy/data/ltToastAutoActions'

const CHECK_RECURRING_EVENTS_INTERVAL_MS = 5000
const MAX_CHECK_RECURRING_EVENTS_ATTEMPTS = 2

const CalendarSetup: FunctionComponent<CalendarSetupProps> = ({
  isEdit = false,
  actions: {
    getCalendars,
    postCalendarEventsTemplate,
    getCalendarAvailabilitySlots,
    setToastContent,
    putCalendarEventsTemplateCancel,
    patchCalendarProvider,
    showCalendarLiveModal,
  },
}) => {
  const { isInProductCalendarEnabled } = useFlags()
  const config = useSelector(getAuthConfig)
  const navigate = useNavigate()
  const calendarConfiguration = useSelector(getCalendarConfiguration)
  const userId = useSelector(getAuthUserId)
  const availabilitySlots = useSelector(getCalendarAvailabilitySlotsSelector)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const [isSaving, setIsSaving] = useState(false)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  // const [currentSlotsAmount, setCurrentSlotsAmount] = useState<number | null>(null)
  const [showMaxModal, setShowMaxModal] = useState(false)
  // const [showMinModal, setShowMinModal] = useState(false)
  const [dirty, setIsDirty] = useState(false)
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)
  const [shouldPollForRecurringEventsAdded, setShouldPollForRecurringEventsAdded] = useState(false)
  const [eventsToSave, setEventsToSave] = useState<{ startTime: string; endTime: string }[] | null>(null)
  const checkRecurringEventsAddedAttempts = useRef(0)
  const canNavigateBack = useRef(false)

  const initialEvents = useMemo(() => {
    return (
      availabilitySlots?.map((slot) => ({
        startTime: slot.start_datetimetz,
        endTime: slot.end_datetimetz,
        id: slot.external_id,
      })) ?? []
    )
  }, [availabilitySlots])
  useFetcher(
    [
      [getCalendars, { providerId: userId }, !!userId],
      [getCalendarAvailabilitySlots, { providerId: userId }, !!userId],
    ],
    [userId],
  )

  const target = getBookableTarget(employmentStatus)
  const maxSlots = getBookableMaxSlots(employmentStatus)

  const beforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (dirty) {
        event.preventDefault()
        event.returnValue = true
      }
    },
    [dirty],
  )

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [beforeUnload])

  const navigationBlocker = useBlocker(({ currentLocation, nextLocation }): boolean => {
    if (!canNavigateBack.current && dirty && currentLocation.pathname !== nextLocation.pathname) {
      setShowUnsavedChangesModal(true)
      track({
        event: isEdit
          ? mixpanelEvents.CALENDAR_TEMPLATE_CANCEL_MODAL_SHOWN
          : mixpanelEvents.CALENDAR_SETUP_CANCEL_MODAL_SHOWN,
      })
      return true
    } else {
      return false
    }
  })

  const navigateBack = useCallback(
    (isSuccess: boolean = false) => {
      canNavigateBack.current = true
      if (isEdit) {
        navigate(SETTINGS.route)
      } else {
        if (isSuccess) {
          showCalendarLiveModal()
        }
        navigate(LC_CALENDAR_CURRENT.route)
      }
    },
    [isEdit, navigate, showCalendarLiveModal],
  )

  const handleError = useCallback(() => {
    if (isEdit) {
      setToastContent({
        text: 'Failed to update calendar',
        toastType: 'error',
        id: 'CalendarSetup-failure',
      })
    } else {
      setToastContent({
        text: 'Failed to create calendar',
        toastType: 'error',
        id: 'CalendarSetup-failure',
      })
    }

    navigateBack()
  }, [isEdit, navigateBack, setToastContent])

  const handleSuccess = useCallback(() => {
    if (isEdit) {
      setToastContent({
        text: 'Updated. Clients will now be able to book these times',
        toastType: 'success',
        id: 'CalendarSetup-success',
      })
    }
    navigateBack(true)
  }, [isEdit, navigateBack, setToastContent])

  const updateBookableTemplate = useCallback(
    (events: { startTime: string; endTime: string }[], calendarConfigurationId: string) => {
      if (!userId) {
        return
      }
      setIsSaving(true)

      const { slotsToAdd, slotsToRemove } = getSlotsToUpdate(events, initialEvents, timeZone)
      const eventsToCreate = slotsToAdd.map((event) => mapEventToBookableEvent(event, timeZone))
      const eventIdsToRemove = slotsToRemove.map((event) => event.id)
      const promises: Promise<any>[] = []
      if (!isEdit) {
        promises.push(
          patchCalendarProvider({
            providerId: userId,
            body: {
              booking_mode: BookingMode.BOOKABLE,
            },
          }),
        )
      }

      if (eventsToCreate.length > 0) {
        promises.push(
          postCalendarEventsTemplate({
            provider_calendar_configuration_id: calendarConfigurationId,
            events: eventsToCreate,
          }),
        )
      }

      if (eventIdsToRemove.length > 0) {
        promises.push(
          putCalendarEventsTemplateCancel({
            provider_calendar_configuration_id: calendarConfigurationId,
            event_ids: eventIdsToRemove,
          }),
        )
      }

      Promise.all(promises)
        .then(() => {
          setIsSaving(false)
          handleSuccess()
        })
        .catch((error) => {
          if (!isEdit && error.response?.status === 504) {
            setShouldPollForRecurringEventsAdded(true)
          } else {
            setIsSaving(false)
            handleError()
          }
        })
    },
    [
      initialEvents,
      timeZone,
      patchCalendarProvider,
      userId,
      postCalendarEventsTemplate,
      putCalendarEventsTemplateCancel,
      handleSuccess,
      isEdit,
      handleError,
    ],
  )

  const onSave = useCallback(
    (events: { startTime: string; endTime: string }[]) => {
      if (!calendarConfiguration) {
        return
      }
      // setCurrentSlotsAmount(events.length)

      // if (events.length < target) {
      //   track({
      //     event: mixpanelEvents.BUTTON_PRESS,
      //     action: isEdit ? mixpanelActions.CALENDAR_TEMPLATE_SAVE_BELOW : mixpanelActions.CALENDAR_SETUP_SAVE_BELOW,
      //   })
      //   setShowMinModal(true)
      //   return
      // }

      if (events.length > maxSlots && !config?.hidePerformanceMetrics) {
        track({
          event: mixpanelEvents.BUTTON_PRESS,
          action: isEdit ? mixpanelActions.CALENDAR_TEMPLATE_SAVE_ABOVE : mixpanelActions.CALENDAR_SETUP_SAVE_ABOVE,
        })
        setShowMaxModal(true)
        return
      }

      track({
        event: mixpanelEvents.BUTTON_PRESS,
        action: isEdit ? mixpanelActions.CALENDAR_TEMPLATE_SAVE_SUCCESS : mixpanelActions.CALENDAR_SETUP_SAVE_SUCCESS,
      })
      setEventsToSave(events)
    },
    [calendarConfiguration, config, isEdit, maxSlots],
  )

  const onSaveConfirm = useCallback(() => {
    if (eventsToSave && calendarConfiguration?.id) {
      updateBookableTemplate(eventsToSave, calendarConfiguration.id)
    }

    setEventsToSave(null)
  }, [calendarConfiguration, eventsToSave, updateBookableTemplate])

  const onCalendarChanged = useCallback(
    (events: { startTime: string; endTime: string }[]) => {
      const { slotsToAdd, slotsToRemove } = getSlotsToUpdate(events, initialEvents, timeZone)
      setIsDirty(slotsToAdd.length > 0 || slotsToRemove.length > 0)
    },
    [initialEvents, timeZone],
  )

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const checkRecurringEventsAdded = useCallback(async () => {
    if (checkRecurringEventsAddedAttempts.current >= MAX_CHECK_RECURRING_EVENTS_ATTEMPTS || !userId) {
      handleError()
      return
    }
    checkRecurringEventsAddedAttempts.current++
    const { data: slots } = await getCalendarAvailabilitySlots({ providerId: userId })
    if (slots.length > 0) {
      handleSuccess()
    }
  }, [getCalendarAvailabilitySlots, handleError, handleSuccess, userId])
  useInterval(checkRecurringEventsAdded, shouldPollForRecurringEventsAdded ? CHECK_RECURRING_EVENTS_INTERVAL_MS : null)
  if (!isInProductCalendarEnabled) {
    return null
  }

  if (initialEvents == null) {
    return <LoadingIndicator />
  }

  // const amountToOpen = target - (currentSlotsAmount ?? 0)
  return (
    <>
      <div className='lc-bookable-calendar'>
        <ProviderBookableCalendarSetup
          onCancel={onCancel}
          onSave={onSave}
          timeZone={timeZone}
          isSaving={isSaving}
          initialEvents={initialEvents}
          isEditing={isEdit}
          bookableTarget={target}
          bookableMax={maxSlots}
          onChange={onCalendarChanged}
          hidePerformanceMetrics={config?.hidePerformanceMetrics}
        />
      </div>
      <ConfirmationModal
        visible={showUnsavedChangesModal}
        onRequestClose={() => {
          navigationBlocker.reset?.()
          setShowUnsavedChangesModal(false)
          track({
            event: mixpanelEvents.BUTTON_PRESS,
            action: isEdit
              ? mixpanelActions.CALENDAR_TEMPLATE_CANCEL_MODAL_CONTINUE_EDITING
              : mixpanelActions.CALENDAR_SETUP_CANCEL_MODAL_CONTINUE_EDITING,
          })
        }}
        onConfirmationButtonPress={() => {
          navigationBlocker.reset?.()
          setShowUnsavedChangesModal(false)
          track({
            event: mixpanelEvents.BUTTON_PRESS,
            action: isEdit
              ? mixpanelActions.CALENDAR_TEMPLATE_CANCEL_MODAL_CONTINUE_EDITING
              : mixpanelActions.CALENDAR_SETUP_CANCEL_MODAL_CONTINUE_EDITING,
          })
        }}
        onCancelPressed={() => {
          track({
            event: mixpanelEvents.BUTTON_PRESS,
            action: isEdit
              ? mixpanelActions.CALENDAR_TEMPLATE_CANCEL_MODAL_DISCARD_CHANGES
              : mixpanelActions.CALENDAR_SETUP_CANCEL_MODAL_DISCARD_CHANGES,
          })
          if (navigationBlocker.state === 'blocked') {
            navigationBlocker.proceed()
          } else {
            navigate(-1)
          }
        }}
        modalTitle={
          <FormattedMessage
            defaultMessage='You have unsaved changes'
            description='Title of modal where provider has unsaved changes'
          />
        }
        cancelButtonText={
          <FormattedMessage defaultMessage='Discard changes' description='Discard changes and navigate back' />
        }
        confirmationButtonText={
          <FormattedMessage
            defaultMessage='Continue editing'
            description='Stay on current page and continue editing template'
          />
        }
        modalContents={
          <BodyText
            text={
              isEdit ? (
                <FormattedMessage
                  defaultMessage='Any changes you’ve made to your calendar spots template will not be saved'
                  description='Body of modal where provider has unsaved changes'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Changes will not be saved and your Lyra calendar will not be set up. You can return later and restart the setup.'
                  description='Body of modal where provider has unsaved changes'
                />
              )
            }
          />
        }
      />
      <ConfirmationModal
        visible={eventsToSave != null}
        onRequestClose={() => setEventsToSave(null)}
        onConfirmationButtonPress={onSaveConfirm}
        modalTitle={
          <FormattedMessage
            defaultMessage='Save your calendar spots template?'
            description='Title of modal where provider is attempting to save availability'
          />
        }
        cancelButtonText={<FormattedMessage defaultMessage='Go back' description='Cancel button' />}
        confirmationButtonText={<FormattedMessage defaultMessage='Save' description='Button to save availability' />}
        modalContents={
          <BodyText
            text={
              isEdit ? (
                <FormattedMessage
                  defaultMessage='Once saved, clients will be able to book during these times, starting with the current week.'
                  description='Body of modal where provider is attempting to save availability'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Once saved, clients will be able to book during these times. You will also be able to adjust individual weekly availability as needed.'
                  description='Body of modal where provider is attempting to save availability'
                />
              )
            }
          />
        }
      />
      {/* <ConfirmationModal
        visible={showMinModal}
        onRequestClose={() => setShowMinModal(false)}
        onConfirmationButtonPress={() => setShowMinModal(false)}
        modalTitle={
          <FormattedMessage
            defaultMessage='Add {amountToOpen, plural,
              one {# more bookable spot}
              other {# more bookable spots}}'
            description='Title of modal where provider needs to open more slots'
            values={{
              amountToOpen,
            }}
          />
        }
        showCancel={false}
        confirmationButtonText={<FormattedMessage defaultMessage='Go back' description='Go back to modify template' />}
        modalContents={
          <BodyText
            text={
              <FormattedMessage
                defaultMessage='You need between {target}-{maxSlots} bookable spots per standard working week for this template to be saved.'
                description='Body of modal where provider needs to open more slots'
                values={{
                  target,
                  maxSlots,
                }}
              />
            }
          />
        }
      /> */}
      <ConfirmationModal
        visible={showMaxModal}
        onRequestClose={() => setShowMaxModal(false)}
        onConfirmationButtonPress={() => setShowMaxModal(false)}
        modalTitle={
          <FormattedMessage
            defaultMessage='Remove excess calendar spots'
            description='Title of modal where provider has too many slots'
          />
        }
        showCancel={false}
        confirmationButtonText={<FormattedMessage defaultMessage='Go back' description='Go back to modify template' />}
        modalContents={
          <BodyText
            text={
              <FormattedMessage
                defaultMessage='You need between {target}-{maxSlots} calendar spots per standard working week for this template to be saved.'
                description='Body of modal where provider has too many slots'
                values={{
                  target,
                  maxSlots,
                }}
              />
            }
          />
        }
      />
      <ProviderCalendarLoadingModal isVisible={isSaving} />
    </>
  )
}

type CalendarSetupProps = {
  isEdit?: boolean
  actions: {
    getCalendars: (params: Parameters<typeof getCalendars>[0]) => Promise<any>
    postCalendarEventsTemplate: (params: Parameters<typeof postCalendarEventsTemplate>[0]) => Promise<any>
    getCalendarAvailabilitySlots: (
      params: Parameters<typeof getCalendarAvailabilitySlots>[0],
    ) => Promise<{ data: ProviderCalendarEvent[] }>
    setToastContent: (params: Parameters<typeof setToastContent>[0]) => Promise<any>
    putCalendarEventsTemplateCancel: (params: Parameters<typeof putCalendarEventsTemplateCancel>[0]) => Promise<any>
    patchCalendarProvider: (params: Parameters<typeof patchCalendarProvider>[0]) => Promise<any>
    showCalendarLiveModal: () => Promise<any>
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getCalendars: getCalendars as any,
      postCalendarEventsTemplate: postCalendarEventsTemplate as any,
      getCalendarAvailabilitySlots: getCalendarAvailabilitySlots as any,
      setToastContent: setToastContent as any,
      putCalendarEventsTemplateCancel: putCalendarEventsTemplateCancel as any,
      patchCalendarProvider: patchCalendarProvider as any,
      showCalendarLiveModal: showCalendarLiveModal as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(CalendarSetup)
