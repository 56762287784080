import { useSelector } from 'react-redux'

import { useTargetDate } from './useTargetDate'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import { RTKQueryHook, RTKQueryResultType } from '../../../data/baseApi'
import { useGetHistoricUtilizationQuery } from '../../clients/clientDetails/data/utilizationApi'

export const useHistoricUtilizationData: RTKQueryHook<typeof useGetHistoricUtilizationQuery> = (args, opts) => {
  const userId = useSelector(getAuthUserId)
  const { targetDate } = useTargetDate()

  return useGetHistoricUtilizationQuery(
    {
      providerId: userId,
      date: targetDate,
      timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'America/Los_Angeles',
      ...args,
    },
    opts,
  ) as RTKQueryResultType<typeof useGetHistoricUtilizationQuery>
}
