import { useSelector } from 'react-redux'

import { useTargetDate } from './useTargetDate'
import { getAuthUserId } from '../../../data/auth/authSelectors'
import { RTKQueryHook, RTKQueryResultType } from '../../../data/baseApi'
import { useGetHistoricUtilizationChargesQuery } from '../../clients/clientDetails/data/utilizationApi'

export const useHistoricUtilizationChargesData: RTKQueryHook<typeof useGetHistoricUtilizationChargesQuery> = (
  args,
  opts,
) => {
  const userId = useSelector(getAuthUserId)
  const { targetDate } = useTargetDate()
  return useGetHistoricUtilizationChargesQuery(
    {
      providerId: userId,
      date: targetDate,
      sessionType: 'lyra_therapy_teens_parent_session',
      ...args,
    },
    opts,
  ) as RTKQueryResultType<typeof useGetHistoricUtilizationChargesQuery>
}
