/**
 * Returns maximum [width, height] while maintaining the video's aspect ratio within the available screen space.
 */
export function calculateVideoSize(
  screenWidth: number,
  screenHeight: number,
  minWidth = 426,
  aspectRatio = 0.5625, // 9 / 16
): [number, number] {
  if (aspectRatio > 1) {
    // vertical video
    const [height, width] = calculateVideoSize(screenHeight, screenWidth, minWidth * aspectRatio, 1 / aspectRatio)
    return [width, height]
  }

  // horizontal video

  let width: number
  let height: number
  screenWidth = Math.max(minWidth, screenWidth)
  if (screenWidth < screenHeight) {
    // portrait
    // height is bounded by the screen height or video height
    height = Math.min(screenHeight, screenWidth * aspectRatio)
    width = Math.max(minWidth, height / aspectRatio)
  } else {
    // landscape
    // width is bounded by the screen width or video width
    width = Math.max(minWidth, Math.min(screenWidth, screenHeight / aspectRatio))
    height = width * aspectRatio
  }

  return [width, height]
}
