import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { VideoLesson } from '@lyrahealth-inc/shared-app-logic'

import { VideoItem } from '../../molecules/videoItem/VideoItem'
import { SubheadSize } from '../../styles/typeStyles'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export type VideoListProps = {
  videos: VideoLesson[]
  onVideoPressed: (id: string, videoPropertyName?: string) => void
  ctaTitle?: string | React.ReactNode
  showCTAOnVideoItem?: number
  withProgressBar?: boolean
  videoTitleSize?: SubheadSize
  accessibilityLabel?: string
  withCTA?: boolean
  isPressable?: boolean
}

const VideoListContainer = styled(Flex1View)({
  alignItems: 'center',
})
const VideoItemContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  width: '100%',
  padding: `0 0 ${spacing['12px']}`,
}))

/**
 * Displays a list of videos
 */
export const VideoList: FunctionComponent<VideoListProps> = ({
  videos,
  onVideoPressed,
  ctaTitle,
  showCTAOnVideoItem,
  withProgressBar = false,
  videoTitleSize,
  accessibilityLabel,
  withCTA,
  isPressable = false,
}) => {
  return (
    <VideoListContainer testID={tID('VideoList')}>
      {videos.map((video, index) => {
        return (
          <VideoItemContainer key={`${video.videoPropertyName}-${video.assignmentId}-${index}`}>
            <VideoItem
              {...video}
              onPress={onVideoPressed}
              ctaTitle={ctaTitle}
              withCTA={withCTA ? showCTAOnVideoItem === index : false}
              videoItem={index + 1}
              withProgressBar={withProgressBar}
              videoTitleSize={videoTitleSize}
              accessibilityLabel={accessibilityLabel}
              disabled={!isPressable && (showCTAOnVideoItem || 0) < index}
            ></VideoItem>
          </VideoItemContainer>
        )
      })}
    </VideoListContainer>
  )
}
