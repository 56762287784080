import React, { FunctionComponent } from 'react'
import { ImageBackground } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { GradientOverlay } from '../../atoms/gradientOverlay/GradientOverlay'
import { RefreshIcon } from '../../atoms/icons/RefreshIcon'
import { ThumbnailPlayIcon } from '../../atoms/icons/ThumbnailPlayIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { ProgressBar } from '../../molecules/progressBar/ProgressBar'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export type LessonThumbnailProps = {
  id: string
  thumbnailMetadata?: string
  thumbnailIcon?: React.ReactElement
  showPlayButton?: boolean
  showRefreshButton?: boolean
  onPress?: (id: string) => void
  gradientColors?: ArrayMinTwo<string>
  width?: string | number
  height?: string | number
  borderRadius?: number
  borderColor?: string
  disabled?: boolean
  withProgressBar?: boolean
  videoProgress?: number
  showGradient?: boolean
  progressBarHeight?: number
  accessibilityLabel?: string
  gradientProps?: object
  videoTitle: string
  fullScreen?: boolean
  thumbnailID?: string
}

const RootLessonThumbContainer = styled.View<{
  theme: ThemeType
  width: number | string
  borderRadius: number
  fullScreen?: boolean
}>(({ theme: { breakpoints }, width, borderRadius, fullScreen }) => ({
  width,
  borderRadius,
  position: 'relative',
  ...(fullScreen && { height: breakpoints.isMinWidthDesktop ? '768px' : '100%' }),
}))

const LessonThumbnailContainer = styled.View<{
  width: number | string
  minHeight: number | string
  theme: ThemeType
  disabled: boolean
  fullScreen?: boolean
}>(({ theme: { breakpoints, spacing }, width, disabled, minHeight, fullScreen }) => ({
  position: 'relative',
  width,
  minHeight,
  padding: fullScreen ? 0 : breakpoints.isMinWidthTablet ? spacing['24px'] : spacing['16px'],
  opacity: disabled ? 0.65 : 1,
  ...(fullScreen && { height: breakpoints.isMinWidthDesktop ? '768px' : '100%' }),
}))

const LessonButton = styled(PressableOpacity)<{ borderRadius: number; borderColor?: string }>(
  ({ borderRadius = 0, borderColor }) => ({
    borderRadius,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    ...(borderColor && { border: `1px solid ${borderColor}` }),
  }),
)

const ThumbnailContainer = styled(ImageBackground)({ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 })

const ProgressBarContainer = styled(ProgressBar)({
  marginTop: '8px',
  width: '100%',
})

const ThumbnailIconContainer = styled.View<{ theme: ThemeType; disabled: boolean }>(
  ({
    disabled,
    theme: {
      breakpoints: { isMinWidthTablet },
      colors,
    },
  }) => ({
    zIndex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    top: '25%',
    background: disabled ? colors.backgroundSecondary : colors.backgroundSection,
    borderRadius: '50px',
    height: isMinWidthTablet ? '48px' : '32px',
    width: isMinWidthTablet ? '48px' : '32px',
  }),
)

const IconDisplayContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.colors.components.lessonThumbnail.iconDisplay.background,
  alignItems: 'center',
}))

/**
 * thumbnail representing a video lesson
 */
export const LessonThumbnail: FunctionComponent<LessonThumbnailProps> = ({
  id,
  thumbnailMetadata,
  thumbnailIcon,
  showPlayButton = false,
  showRefreshButton = false,
  onPress,
  children,
  width = '100%',
  height = 218,
  gradientColors,
  borderRadius = 8,
  borderColor,
  disabled = false,
  withProgressBar = false,
  showGradient = false,
  videoProgress,
  progressBarHeight,
  accessibilityLabel,
  gradientProps,
  videoTitle,
  fullScreen,
  thumbnailID,
}) => {
  const {
    colors,
    breakpoints: { isMinWidthTablet },
  } = useTheme()
  const { disabledIcon, progressBar } = colors.components.lessonThumbnail

  return (
    <RootLessonThumbContainer
      width={width}
      borderRadius={borderRadius}
      fullScreen={fullScreen}
      testID={tID('Lesson-Thumbnail')}
    >
      <LessonThumbnailContainer
        accessibilityElementsHidden
        importantForAccessibility='no-hide-descendants'
        width={width}
        minHeight={height}
        disabled={disabled}
        fullScreen={fullScreen}
      >
        {thumbnailIcon ? (
          <IconDisplayContainer>{thumbnailIcon}</IconDisplayContainer>
        ) : (
          <>
            {thumbnailMetadata && (
              <ThumbnailContainer
                nativeID={thumbnailID ?? `${videoTitle?.replace(/ /g, '-')}-lesson-thumbnail-picture`}
                resizeMode='cover'
                imageStyle={{ borderRadius }}
                source={{
                  uri: thumbnailMetadata?.startsWith('https://') ? thumbnailMetadata : '',
                }}
              >
                {showPlayButton && (
                  <ThumbnailIconContainer testID={tID('PlayIcon')} disabled={disabled}>
                    <ThumbnailPlayIcon
                      width={isMinWidthTablet ? 42 : 32}
                      height={isMinWidthTablet ? 33 : 26}
                      fillColor={disabled ? disabledIcon.fill : colors.iconActive}
                    />
                  </ThumbnailIconContainer>
                )}
                {showRefreshButton && (
                  <ThumbnailIconContainer testID={tID('RefreshIcon')} disabled={disabled}>
                    <RefreshIcon
                      size={isMinWidthTablet ? 18 : 12}
                      fillColor={disabled ? disabledIcon.fill : colors.iconActive}
                    />
                  </ThumbnailIconContainer>
                )}
                {showGradient && (
                  <GradientOverlay
                    gradientProps={gradientProps}
                    gradientColors={gradientColors}
                    borderRadius={borderRadius}
                  />
                )}
              </ThumbnailContainer>
            )}
          </>
        )}
        {children}
      </LessonThumbnailContainer>
      {withProgressBar && (
        <ProgressBarContainer
          progress={videoProgress!}
          shouldAnimate={false}
          backgroundColor={colors.backgroundSection}
          color={progressBar.fill}
          height={progressBarHeight}
        />
      )}
      {!!onPress && !disabled && (
        <LessonButton
          onPress={onPress ? () => onPress(id) : () => {}}
          pressedBorderColor={colors.borderHover}
          borderRadius={borderRadius}
          borderColor={borderColor}
          disabled={disabled}
          accessible
          accessibilityLabel={accessibilityLabel}
        />
      )}
    </RootLessonThumbContainer>
  )
}
