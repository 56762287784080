import React from 'react'
import { useSelector } from 'react-redux'

import { isEmpty } from 'lodash-es'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'

import CaseloadManagementContainer from './CaseloadManagementContainer'
import ProviderPerformance from './ProviderPerformance'
import { employmentTypes, ROLES } from '../../common/constants/appConstants'
import { hasRole } from '../../common/utils/utils'
import { getAuthEmploymentStatus, getAuthRoles, getAuthSupervisor, getAuthUser } from '../../data/auth/authSelectors'

export const ProviderCaseload: React.FC = () => {
  const userRoles = useSelector(getAuthRoles)
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const supervisor = useSelector(getAuthSupervisor)
  const authUser = useSelector(getAuthUser)
  const authUserCountry = authUser?.country
  const isUSProvider = authUserCountry && authUserCountry === 'US'

  const { showNewCaseloadDashboardProviders } = useFlags()

  const newCaseloadDashboardVersion =
    hasRole(userRoles, [ROLES.LT_COACH, ROLES.LT_SS_COACH]) &&
    employmentStatus &&
    [employmentTypes.FULL_TIME, employmentTypes.PART_TIME].includes(employmentStatus) &&
    isUSProvider
  const isBCCSupervisor =
    supervisor &&
    !isEmpty(supervisor.roles) &&
    hasRole(supervisor.roles, [ROLES.LT_COACH_SUPERVISOR, ROLES.LT_SS_COACH_SUPERVISOR])
  if (newCaseloadDashboardVersion && showNewCaseloadDashboardProviders && !isBCCSupervisor) {
    return <CaseloadManagementContainer />
  }

  return <ProviderPerformance />
}

export default ProviderCaseload
