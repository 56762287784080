import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { differenceInCalendarWeeks, isFriday, parseISO, subMilliseconds } from 'date-fns'

import {
  CalendarAlert,
  CalendarAlertArgs,
  CalendarAlertType,
  getTimezoneOffset,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'

import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { getTotalCapacity } from '../../common/utils/utils'
import { getAuthEmploymentStatus, getAuthUserCapacityValue } from '../../data/auth/authSelectors'
import { getBookableTarget, getWorkingHours } from '../utils'

const useCalendarAlerts = (
  updateCapacity: (value: number) => Promise<void>,
  setToastContent: (params: ToastContent) => void,
  openCapacityModal: () => void,
  onDeleteOverlappingEvents: (ids: string[]) => Promise<void>,
) => {
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const { showInProductCalendarAlerts } = useFlags()
  const capacities = useSelector(getAuthUserCapacityValue)
  const totalCapacity = useMemo(() => getTotalCapacity(capacities), [capacities])
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const [isUpdatingCapacity, setIsUpdatingCapacity] = useState(false)
  const [isDeletingSlots, setIsDeletingSlots] = useState(false)
  const { formatMessage } = useIntl()

  const workingHours = getWorkingHours(employmentStatus)
  const targetHours = getBookableTarget(employmentStatus)
  return useCallback<(args: CalendarAlertArgs) => CalendarAlert[]>(
    ({ timeOffHours, clientHours, startDate, scheduledSessions, overlappingSlots }) => {
      if (!showInProductCalendarAlerts) {
        return []
      }
      const expectedHours = Math.ceil(((workingHours - timeOffHours) / workingHours) * targetHours)
      const alerts: CalendarAlert[] = []
      if (clientHours < expectedHours) {
        const slotsNeeded = expectedHours - clientHours
        alerts.push({
          ariaLabel: formatMessage(
            {
              defaultMessage:
                'Add {slotsNeeded} calendar spots. This week you are expected to have {expectedHours, plural, one {{expectedHours, number} calendar spot.} other {{expectedHours, number} calendar spots.}}',
              description:
                'Instruction to tell provider to add the necessary number of calendar spots based on expected hours.',
            },
            {
              slotsNeeded,
              expectedHours,
            },
          ),
          type: CalendarAlertType.BOOKABLE,
          badge: (
            <FormattedMessage
              defaultMessage='Add {slotsNeeded}'
              description='Slots needed to meet target hours'
              values={{ slotsNeeded }}
            />
          ),
          title: (
            <FormattedMessage
              defaultMessage='Add {slotsNeeded} calendar spots'
              description='Slots needed to meet target hours'
              values={{ slotsNeeded }}
            />
          ),
          body: (
            <FormattedMessage
              defaultMessage='This week you are expected to have {expectedHours, plural,
                    one {{expectedHours, number} calendar spot.}
                    other {{expectedHours, number} calendar spots.}}'
              description='Desciption of alert for not meeting target hours'
              values={{ expectedHours }}
            />
          ),
          trackingEvent: mixpanelEvents.ALERT_SHOWN_UNDER_BOOKABLE,
        })
      }

      if (clientHours > expectedHours && timeOffHours > 0) {
        const slotsNeeded = clientHours - expectedHours
        if (overlappingSlots.length > 0) {
          const slotsToDelete = overlappingSlots.length
          alerts.push({
            ariaLabel: formatMessage(
              {
                defaultMessage:
                  'Delete up to {slotsNeeded} calendar spots. Due to your time off hours entered this week, your calendar spots expectation is reduced to {expectedHours}.',
                description:
                  'Instruction for the provider to delete a certain number of calendar spots because of how many hours of time off.',
              },
              {
                slotsNeeded,
                expectedHours,
              },
            ),
            type: CalendarAlertType.BOOKABLE,
            badge: (
              <FormattedMessage
                defaultMessage='Time off: delete {slotsNeeded}'
                description='Number of slots that need to be removed'
                values={{ slotsNeeded }}
              />
            ),
            title: (
              <FormattedMessage
                defaultMessage='Delete {slotsToDelete, plural, 
                one {{slotsToDelete, number} available spot}
                other {{slotsToDelete, number} available spots}}
                that {slotsToDelete, select, 
                1 {conflicts}
                other {conflict}} with your time off this week?'
                description='Number of slots that need to be removed'
                values={{ slotsToDelete }}
              />
            ),
            body: (
              <FormattedMessage
                defaultMessage='Bookable expectation reduced to {expectedHours} this week due to time off.'
                description='Desciption of alert for not meeting target hours'
                values={{ expectedHours: Math.max(expectedHours, 0) }}
              />
            ),
            trackingEvent: mixpanelEvents.ALERT_SHOWN_OVER_BOOKABLE_TIME_OFF,
            actions: {
              primary: {
                label: (
                  <FormattedMessage
                    defaultMessage='Delete all'
                    description='Button to delete any events that overlap with OOO events'
                  />
                ),
                testID: 'ProviderCalendarAlert-delete-all-button',
                onClick: () => {
                  track({
                    event: mixpanelEvents.BUTTON_PRESS,
                    action: mixpanelActions.CALENDAR_MANAGEMENT_ALERT_DELETE_ALL,
                  })
                  setIsDeletingSlots(true)
                  onDeleteOverlappingEvents(overlappingSlots)
                    .then(() => {
                      setToastContent({
                        text: formatMessage(
                          {
                            defaultMessage: `{slotsToDelete, plural, 
                            one {{slotsToDelete, number} available spot}
                            other {{slotsToDelete, number} available spots}} deleted this week`,
                            description: 'Toast message when slots are deleted',
                          },
                          { slotsToDelete },
                        ),
                        toastType: 'success',
                        id: 'CurrentCalendar-delete-success',
                      })
                    })
                    .finally(() => {
                      setIsDeletingSlots(false)
                    })
                },
                isLoading: isDeletingSlots,
              },
            },
          })
        } else {
          alerts.push({
            ariaLabel: formatMessage(
              {
                defaultMessage:
                  'Delete up to {slotsNeeded} calendar spots. Due to your time off hours entered this week, your calendar spots expectation is reduced to {expectedHours}.',
                description:
                  'Instruction for the provider to delete a certain number of calendar spots because of how many hours of time off.',
              },
              {
                slotsNeeded,
                expectedHours,
              },
            ),
            type: CalendarAlertType.BOOKABLE,
            badge: (
              <FormattedMessage
                defaultMessage='Time off: delete {slotsNeeded}'
                description='Number of slots that need to be removed'
                values={{ slotsNeeded }}
              />
            ),
            title: (
              <FormattedMessage
                defaultMessage='Delete up to {slotsNeeded} calendar spots'
                description='Number of slots that need to be removed'
                values={{ slotsNeeded }}
              />
            ),
            body: (
              <FormattedMessage
                defaultMessage='Bookable expectation reduced to {expectedHours} this week due to time off.'
                description='Desciption of alert for not meeting target hours'
                values={{ expectedHours: Math.max(expectedHours, 0) }}
              />
            ),
            trackingEvent: mixpanelEvents.ALERT_SHOWN_OVER_BOOKABLE_TIME_OFF,
          })
        }
      }

      const tzOffset = getTimezoneOffset(timeZone, startDate)
      const startDateLocal = subMilliseconds(parseISO(startDate), tzOffset)
      if (
        isFriday(new Date()) &&
        differenceInCalendarWeeks(startDateLocal, new Date()) === 1 &&
        scheduledSessions + totalCapacity < expectedHours
      ) {
        const unfilledSessions = expectedHours - (scheduledSessions + totalCapacity)
        alerts.push({
          ariaLabel: formatMessage(
            {
              defaultMessage:
                'Add {unfilledSessions, plural, one {{unfilledSessions, number} client} other {{unfilledSessions, number} clients}}. To meet your bookable expectation, either schedule more followup sessions or set your new client capacity to {newCapacity}',
              description:
                'Instruction to tell the Provider to add certain number of sessions to meet bookable expectations',
            },
            {
              unfilledSessions,
              newCapacity: totalCapacity + unfilledSessions,
            },
          ),
          type: CalendarAlertType.SCHEDULED,
          badge: (
            <FormattedMessage
              defaultMessage='Add {unfilledSessions, plural,
                    one {{unfilledSessions, number} client}
                    other {{unfilledSessions, number} clients}}'
              description='Badge label for unfilled sesssions alert'
              values={{ unfilledSessions }}
            />
          ),
          title: (
            <FormattedMessage
              defaultMessage='Add {unfilledSessions, plural,
                  one {{unfilledSessions, number} client}
                  other {{unfilledSessions, number} clients}}'
              description='Title for unfilled sessions alert'
              values={{ unfilledSessions }}
            />
          ),
          body: (
            <FormattedMessage
              defaultMessage='To meet your bookable expectation, either schedule more followup sessions or set your new client capacity to {newCapacity}'
              description='Body for unfilled sessions alert'
              values={{ newCapacity: totalCapacity + unfilledSessions }}
            />
          ),
          actions: {
            primary: {
              label: (
                <FormattedMessage
                  defaultMessage='Add for me'
                  description='Button to auto add capacity to allow open slots to be filled'
                />
              ),
              testID: 'ProviderCalendarAlert-add-for-me-button',
              onClick: () => {
                track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CAPACITY_ALERT_ADD_FOR_ME })
                setIsUpdatingCapacity(true)
                updateCapacity(unfilledSessions)
                  .then(() => {
                    setToastContent({
                      text: 'Capacity updated',
                      id: 'Capacity-Saved-Toast-Success',
                      toastType: 'success',
                    })
                  })
                  .finally(() => {
                    setIsUpdatingCapacity(false)
                  })
              },
              isLoading: isUpdatingCapacity,
            },
            secondary: {
              testID: 'ProviderCalendarAlert-open-capacity-button',
              label: (
                <FormattedMessage defaultMessage='Open capacity' description='Button label to open capacity modal' />
              ),
              onClick: () => {
                track({ event: mixpanelEvents.BUTTON_PRESS, action: mixpanelActions.CAPACITY_ALERT_OPEN_CAPACITY })
                openCapacityModal()
              },
            },
          },
          trackingEvent: mixpanelEvents.ALERT_SHOWN_CAPACITY,
          closeTrackingAction: mixpanelActions.CAPACITY_ALERT_X,
        })
      }

      return alerts
    },
    [
      formatMessage,
      isDeletingSlots,
      isUpdatingCapacity,
      onDeleteOverlappingEvents,
      openCapacityModal,
      setToastContent,
      showInProductCalendarAlerts,
      targetHours,
      timeZone,
      totalCapacity,
      updateCapacity,
      workingHours,
    ],
  )
}

export default useCalendarAlerts
