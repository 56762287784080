import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormattedDateTimeRange, FormattedMessage, useIntl } from 'react-intl'

import {
  DateSelectArg,
  DateSpanApi,
  DatesSetArg,
  DayHeaderContentArg,
  EventApi,
  EventChangeArg,
  EventContentArg,
  EventInput,
  EventRemoveArg,
  EventSourceInput,
  SlotLabelContentArg,
} from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridWeek from '@fullcalendar/timegrid'
import { addHours, addMinutes, differenceInMinutes, parseISO, subMilliseconds, subMinutes } from 'date-fns'
import { format } from 'date-fns-tz'
import { partition, sortBy } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'
import { v4 as uuidv4 } from 'uuid'

import {
  BookableEventExtendedProps,
  CalendarAlert,
  CalendarAlertArgs,
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PROPERTIES,
  EventExtendedProps,
  eventsOverlap,
  getAdjustedEvent,
  getTimeZoneName,
  getTimezoneOffset,
  isBookableEvent,
  isOOOEvent,
  isSessionOrSlot,
  LyraEventExtendedPropsNoTZ,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, LoadingIndicator, ScheduleIcon, Subhead } from '../../atoms'
import { AppContext } from '../../context'
import { ProviderCalendarAlerts, ProviderCalendarEvent, ProviderCalendarHeader } from '../../molecules'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal'

export type ProviderCalendarProps = {
  timeZone?: string
  onSettingsPressed?: () => void
  loading?: boolean
  getEvents?: (params: { startDate: string; endDate: string }) => Promise<EventInput[]>
  onClientProfilePressed?: (clientId: string) => void
  onEventMoved?: (event: { externalId: string; startTime: string; endTime: string }, revert: () => void) => void
  onEventsAdded?: (
    events: { startTime: string; endTime: string }[],
    revert: () => void,
    attachEventID: (events: { id: string; start: { dateTime: string; timeZone: string } }[]) => void,
  ) => void
  onEventDeleted?: (event: { externalId: string }, revert: () => void) => void
  onOpenInGoogleCalendar?: (externalId: string, calendarConfigurationId: string) => void
  onOpenVideoConferencing?: (externalId: string, calendarConfigurationId: string) => void
  onOpenZendeskPressed?: () => void
  getAlerts?: (args: CalendarAlertArgs) => CalendarAlert[]
  hidePerformanceMetrics?: boolean
}

const Container = styled.View<{ lowOpacity: boolean }>(({ lowOpacity }) => ({
  opacity: lowOpacity ? 0.4 : 1,
  overflow: 'scroll',
  height: '100%',
}))

const DayContentContainer = styled.View<{ isToday: boolean }>(({ theme, isToday }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing['4px'],
  padding: theme.spacing['12px'],
  borderBottomWidth: isToday ? '4px' : 0,
  borderBottomColor: theme.colors.borderFocus,
  width: '100%',
}))

const LoadingView = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  zIndex: 2,
  justifyContent: 'center',
})

const AddRow = styled.View(({ theme }) => ({
  gap: theme.spacing['8px'],
  flexDirection: 'row',
  alignItems: 'center',
}))

const START_TIME_ALLOWED = 7
const END_TIME_ALLOWED = 21

export type ProviderCalendarRef = {
  updateAppointments: (appointments: { [key: number]: LyraEventExtendedPropsNoTZ }) => void
  refresh: () => void
}

export const ProviderCalendar = forwardRef<ProviderCalendarRef, ProviderCalendarProps>(
  (
    {
      onSettingsPressed,
      timeZone = 'America/Los_Angeles',
      loading = false,
      getEvents,
      onClientProfilePressed,
      onEventMoved,
      onEventsAdded,
      onEventDeleted,
      onOpenInGoogleCalendar,
      onOpenVideoConferencing,
      onOpenZendeskPressed,
      getAlerts,
      hidePerformanceMetrics,
    },
    ref,
  ) => {
    const intl = useIntl()
    const theme = useTheme() as ThemeType
    const calendarRef = useRef<FullCalendar>(null)
    const startingRange = useRef<{ startStr: string; endStr: string } | null>(null)
    const [scheduledSessions, setScheduledSessions] = useState<number | null>(null)
    const [clientHours, setClientHours] = useState<number | null>(null)
    const [rangeToAdd, setRangeToAdd] = useState<{
      start: string
      end: string
      startLocal: string
      endLocal: string
    } | null>(null)
    const [dateRange, setDateRange] = useState<{ start: Date; end: Date } | null>(null)
    const [timeOffHours, setTimeOffHours] = useState<number | null>(null)
    const [alertsToShow, setAlertsToShow] = useState<CalendarAlert[]>([])
    const [startDate, setStartDate] = useState<string | null>(null)
    const [overlappingSlots, setOverlappingSlots] = useState<string[] | null>(null)
    const { trackEvent } = useContext(AppContext)

    const onDatesSet = useCallback((dates: DatesSetArg) => {
      setDateRange(dates)
    }, [])

    const dayContentRenderer = useCallback(
      ({ date, isToday }: DayHeaderContentArg) => {
        const newDate = addMinutes(date, date.getTimezoneOffset())
        return (
          <DayContentContainer isToday={isToday}>
            <Subhead
              size={SubheadSize.XSMALL}
              text={format(newDate, 'eee d', { timeZone })}
              color={isToday ? theme.colors.textActive : undefined}
            />
          </DayContentContainer>
        )
      },
      [theme.colors.textActive, timeZone],
    )

    const slotLabelContent = useCallback(
      ({ text }: SlotLabelContentArg) => {
        return <BodyText color={theme.colors.textSecondary} size={BodyTextSize.SMALL} text={text} />
      },
      [theme.colors.textSecondary],
    )

    const eventContent = useCallback(
      (args: EventContentArg) => {
        return (
          <ProviderCalendarEvent
            args={args}
            startingRange={startingRange}
            timeZone={timeZone}
            onClientProfilePressed={onClientProfilePressed}
            onOpenInGoogleCalendar={onOpenInGoogleCalendar}
            onOpenVideoConferencing={onOpenVideoConferencing}
          />
        )
      },
      [onClientProfilePressed, onOpenInGoogleCalendar, onOpenVideoConferencing, timeZone],
    )

    useEffect(() => {
      if (!calendarRef.current) {
        return
      }
      const calendar = calendarRef.current.getApi()
      setDateRange({ start: calendar.view.currentStart, end: calendar.view.currentEnd })
      calendar.on('datesSet', onDatesSet)

      return () => {
        calendar.off('datesSet', onDatesSet)
      }
    }, [onDatesSet])

    const rangeText = useMemo(() => {
      if (!dateRange) {
        return ''
      }

      const startDate = addMinutes(dateRange.start, dateRange.start.getTimezoneOffset())
      const endDate = subMinutes(addMinutes(dateRange.end, dateRange.end.getTimezoneOffset()), 1)
      const startMonth = intl.formatDate(startDate, { month: 'short', timeZone })
      const endMonth = intl.formatDate(endDate, { month: 'short', timeZone })
      const startYear = intl.formatDate(startDate, { year: 'numeric', timeZone })
      const endYear = intl.formatDate(endDate, { year: 'numeric', timeZone })
      if (startMonth === endMonth && startYear === endYear) {
        return intl.formatDate(endDate, { month: 'long', year: 'numeric', timeZone })
      }
      const startDateFormatted = intl.formatDate(startDate, {
        month: 'short',
        year: startYear === endYear ? undefined : 'numeric',
        timeZone,
      })

      const endDateFormatted = intl.formatDate(endDate, { month: 'short', year: 'numeric', timeZone })
      return `${startDateFormatted} - ${endDateFormatted}`
    }, [dateRange, intl, timeZone])

    const onSelectAllow = useCallback((args: DateSpanApi) => {
      const startTime = parseISO(args.startStr)
      const endTime = parseISO(args.endStr)
      if (startTime < new Date()) {
        return false
      }
      if (startTime.getDate() !== endTime.getDate()) {
        return false
      }
      if (startTime.getHours() < START_TIME_ALLOWED || endTime.getHours() > END_TIME_ALLOWED) {
        return false
      }

      const adjustedEvent = getAdjustedEvent(args, startingRange.current)

      return !calendarRef.current
        ?.getApi()
        .getEvents()
        .filter((event) => isSessionOrSlot(event.extendedProps as EventExtendedProps))
        .some((event) =>
          eventsOverlap(
            {
              start: parseISO(event.startStr + 'Z'),
              end: parseISO(event.endStr + 'Z'),
            },
            {
              start: parseISO(adjustedEvent.start),
              end: parseISO(adjustedEvent.end),
            },
          ),
        )
    }, [])
    useEffect(() => {
      document.getElementsByClassName('fc-timegrid-now-indicator-line').item(0)?.scrollIntoView({ block: 'center' })
    }, [])

    const eventSources = useMemo<EventSourceInput[]>(
      () => [
        {
          events: (info, _success, _failure) => {
            if (!getEvents) {
              return []
            }
            return getEvents({ startDate: info.start.toISOString(), endDate: info.end.toISOString() })
          },
        },
      ],
      [getEvents],
    )

    const onSelect = useCallback(
      (event: DateSelectArg) => {
        if (!event.start || !event.end || !calendarRef.current) {
          return
        }
        const adjustedEvent = getAdjustedEvent(event, startingRange.current)
        const tzOffset = getTimezoneOffset(timeZone, event.startStr)
        setRangeToAdd({
          start: adjustedEvent.start,
          end: adjustedEvent.end,
          startLocal: subMilliseconds(parseISO(adjustedEvent.start), tzOffset).toISOString(),
          endLocal: subMilliseconds(parseISO(adjustedEvent.end), tzOffset).toISOString(),
        })
        calendarRef.current.getApi().unselect()
      },
      [timeZone],
    )

    const resetConflicts = useCallback(() => {
      if (!calendarRef.current) {
        return
      }
      const allEvents = calendarRef.current.getApi().getEvents()
      const hasConflictMap = new Map<string, boolean>()
      const [sessionsAndSlots, otherEvents] = partition(allEvents, (event) =>
        isSessionOrSlot(event.extendedProps as EventExtendedProps),
      )
      sessionsAndSlots.forEach((sessionOrSlot) => {
        otherEvents.forEach((event) => {
          const hasConflict = eventsOverlap(
            {
              start: parseISO(sessionOrSlot.startStr),
              end: parseISO(sessionOrSlot.endStr),
            },
            {
              start: parseISO(event.startStr),
              end: parseISO(event.endStr),
            },
          )

          if (hasConflict) {
            hasConflictMap.set(sessionOrSlot.id, true)
            hasConflictMap.set(event.id, !isOOOEvent(event.title))
          }
        })
      })

      allEvents.forEach((event) => {
        const newValue = hasConflictMap.get(event.id) ?? false
        if (event.extendedProps.hasConflict !== newValue) {
          event.setExtendedProp('hasConflict', newValue)
        }
      })
    }, [])

    const onEventChange = useCallback(
      ({ event, revert, oldEvent }: EventChangeArg) => {
        if (event.startStr === oldEvent.startStr && event.endStr === oldEvent.endStr) {
          return
        }
        const tzOffset = getTimezoneOffset(timeZone, event.startStr)
        const extendedProps = event.extendedProps as EventExtendedProps
        if (extendedProps.lyraEventType !== 'bookable' && extendedProps.lyraEventType !== 'bookable_recurring') {
          return
        }
        resetConflicts()
        onEventMoved?.(
          {
            externalId: extendedProps.externalId,
            startTime: subMilliseconds(parseISO(event.startStr + 'Z'), tzOffset).toISOString(),
            endTime: subMilliseconds(parseISO(event.endStr + 'Z'), tzOffset).toISOString(),
          },
          revert,
        )
      },
      [onEventMoved, resetConflicts, timeZone],
    )

    const onEventRemove = useCallback(
      ({ event, revert }: EventRemoveArg) => {
        resetConflicts()
        onEventDeleted?.(
          {
            externalId: event.extendedProps.externalId,
          },
          revert,
        )
      },
      [onEventDeleted, resetConflicts],
    )

    const eventAllow = useCallback((span: DateSpanApi) => {
      const startTime = parseISO(span.startStr)
      if (startTime.getHours() < START_TIME_ALLOWED) {
        return false
      }

      if (
        startTime.getHours() > END_TIME_ALLOWED ||
        (startTime.getHours() === END_TIME_ALLOWED && startTime.getMinutes() > 0)
      ) {
        return false
      }

      if (startTime < new Date()) {
        return false
      }
      return true
    }, [])

    const onAddConfirmation = useCallback(() => {
      if (!rangeToAdd || !calendarRef.current) {
        return
      }
      let startTime = parseISO(rangeToAdd.start)
      const endTime = parseISO(rangeToAdd.end)
      const differenceHours = differenceInMinutes(endTime, startTime) / 60
      const numEvents = Math.ceil(differenceHours)
      const events: (EventApi | null)[] = []
      for (let i = 0; i < numEvents; i++) {
        events.push(
          calendarRef.current.getApi().addEvent(
            {
              id: uuidv4(),
              title: 'Available',
              start: startTime.toISOString(),
              end: addHours(startTime, 1).toISOString(),
              extendedProps: {
                lyraEventType: 'bookable',
              },
            },
            true,
          ),
        )
        startTime = addHours(startTime, 1)
      }

      const nonNullEvents = events.filter((evt) => evt != null) as EventApi[]
      const tzOffset = getTimezoneOffset(timeZone, rangeToAdd.start)

      resetConflicts()
      onEventsAdded?.(
        nonNullEvents.map((event) => ({
          startTime: subMilliseconds(parseISO(event.startStr + 'Z'), tzOffset).toISOString(),
          endTime: subMilliseconds(parseISO(event.endStr + 'Z'), tzOffset).toISOString(),
        })),
        () => {
          nonNullEvents.forEach((event) => {
            event.remove()
          })
        },
        (googleEvents) => {
          const sortedGoogleEvents = sortBy(googleEvents, (event) => parseISO(event.start.dateTime))
          nonNullEvents.forEach((event, i) => {
            event.setExtendedProp('externalId', sortedGoogleEvents[i].id)
          })
        },
      )
      setRangeToAdd(null)
    }, [onEventsAdded, rangeToAdd, resetConflicts, timeZone])

    const onEventsSet = useCallback((events: EventApi[]) => {
      setStartDate(calendarRef.current?.getApi().view.currentStart.toISOString() ?? null)
      const sessions = events.filter((event) => (event.extendedProps as EventExtendedProps).lyraEventType === 'session')
      const slots = events.filter((event) => isBookableEvent(event.extendedProps as EventExtendedProps))
      const newClientHours = sessions.length + slots.length
      setClientHours(newClientHours)

      const currentTime = new Date()
      const futureSessions = sessions.filter(
        (session) =>
          parseISO(session.startStr.endsWith('Z') ? session.startStr.slice(0, -1) : session.startStr) > currentTime,
      )
      setScheduledSessions(futureSessions.length)

      const oooEvents = events.filter((event) => {
        const extendedProps = event.extendedProps as EventExtendedProps
        return extendedProps.lyraEventType === 'generic' && extendedProps.isOOOEvent
      })

      const timeOffMap = new Map<string, number>()
      oooEvents.forEach((event) => {
        const start = event.startStr
        const end = event.endStr
        const duration = differenceInMinutes(parseISO(end), parseISO(start)) / 60

        const dateStr = start.split('T')[0]
        timeOffMap.set(dateStr, (timeOffMap.get(dateStr) ?? 0) + duration)
      })

      const timeOffHours = Array.from(timeOffMap).reduce((acc, [_, hours]) => {
        return acc + Math.min(hours, 8)
      }, 0)
      setTimeOffHours(timeOffHours)

      const overlappingSlots = slots
        .filter((slot) =>
          oooEvents.some((oooEvent) =>
            eventsOverlap(
              {
                start: parseISO(oooEvent.startStr),
                end: parseISO(oooEvent.endStr),
              },
              {
                start: parseISO(slot.startStr),
                end: parseISO(slot.endStr),
              },
            ),
          ),
        )
        .map((slot) => (slot.extendedProps as BookableEventExtendedProps).externalId)
      setOverlappingSlots(overlappingSlots)
    }, [])

    const transformEvents = useCallback((eventInputs: EventInput[]) => {
      const [sessionsAndSlots, otherEvents] = partition(eventInputs, (eventInput) =>
        isSessionOrSlot(eventInput.extendedProps as EventExtendedProps),
      )

      sessionsAndSlots.forEach((sessionOrSlot) => {
        otherEvents.forEach((event) => {
          const hasConflict = eventsOverlap(
            {
              start: parseISO(sessionOrSlot.start as string),
              end: parseISO(sessionOrSlot.end as string),
            },
            {
              start: parseISO(event.start as string),
              end: parseISO(event.end as string),
            },
          )
          if (hasConflict) {
            sessionOrSlot.extendedProps = {
              ...sessionOrSlot.extendedProps,
              hasConflict: true,
            }
            event.extendedProps = {
              ...event.extendedProps,
              hasConflict: !isOOOEvent(event.title),
            }
          }
        })
      })

      return eventInputs
    }, [])

    useImperativeHandle(
      ref,
      () => ({
        updateAppointments: (newExtendedProps: { [key: number]: LyraEventExtendedPropsNoTZ }) =>
          // Full calendar library uses flushSync when calling setExtendedProp which will warn when about calling while already rendering
          // Fix found here: https://ah-mahir.medium.com/understanding-reacts-flushsync-warning-and-how-to-handle-it-with-settimeout-9442f6dd4ed9
          setTimeout(
            () =>
              calendarRef.current
                ?.getApi()
                .getEvents()
                .forEach((event) => {
                  const extendedProps = event.extendedProps as EventExtendedProps
                  if (extendedProps.lyraEventType === 'session' || extendedProps.lyraEventType === 'generic') {
                    const appointmentId = extendedProps.lyraAppointmentId
                    if (appointmentId != null && appointmentId in newExtendedProps) {
                      Object.entries(newExtendedProps[appointmentId]).forEach(([key, value]) => {
                        if (event.extendedProps[key] !== value) {
                          event.setExtendedProp(key, value)
                        }
                      })
                    }
                  }
                }),
            0,
          ),
        refresh: () => calendarRef.current?.getApi().refetchEvents(),
      }),
      [],
    )

    const alerts = useMemo(() => {
      if (
        !getAlerts ||
        timeOffHours == null ||
        clientHours == null ||
        !startDate ||
        scheduledSessions == null ||
        overlappingSlots == null
      ) {
        return []
      }
      return getAlerts({ timeOffHours, clientHours, startDate, scheduledSessions, overlappingSlots })
    }, [clientHours, getAlerts, overlappingSlots, scheduledSessions, startDate, timeOffHours])

    const onAlertPressed = useCallback(
      (newAlertToShow: CalendarAlert) => {
        trackEvent?.(newAlertToShow.trackingEvent)
        setAlertsToShow((prev) => [...prev.filter((alert) => alert.type !== newAlertToShow.type), newAlertToShow])
      },
      [trackEvent],
    )

    const onAlertClose = useCallback(
      (alert: CalendarAlert) => {
        if (alert.closeTrackingAction) {
          trackEvent?.(COMMON_MIXPANEL_EVENTS.BUTTON_PRESS, {
            [COMMON_MIXPANEL_PROPERTIES.ACTION]: alert.closeTrackingAction,
          })
        }
        setAlertsToShow((prev) => prev.filter((alertToShow) => alertToShow.type !== alert.type))
      },
      [trackEvent],
    )

    useEffect(() => {
      setAlertsToShow([])
    }, [startDate])

    useEffect(() => {
      setAlertsToShow((prev) => {
        const newAlertsToShow: CalendarAlert[] = []
        alerts.forEach((alert) => {
          if (prev.some((alertToShow) => alertToShow.type === alert.type)) {
            newAlertsToShow.push(alert)
          }
        })

        return newAlertsToShow
      })
    }, [alerts])

    return (
      <>
        <Container lowOpacity={loading}>
          <ProviderCalendarHeader
            rangeText={rangeText}
            onNextPressed={() => calendarRef.current?.getApi().next()}
            onPrevPressed={() => calendarRef.current?.getApi().prev()}
            onTodayPressed={() => calendarRef.current?.getApi().today()}
            onSettingsPressed={() => onSettingsPressed?.()}
            onOpenZendeskPressed={() => onOpenZendeskPressed?.()}
            scheduledSessions={scheduledSessions}
            clientHours={clientHours}
            alerts={alerts}
            onAlertPressed={onAlertPressed}
            hidePerformanceMetrics={hidePerformanceMetrics}
          />
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[timeGridWeek, interactionPlugin]}
            initialView='timeGridWeek'
            eventSources={eventSources}
            allDaySlot={false}
            dayHeaderContent={dayContentRenderer}
            nowIndicator
            slotLabelContent={slotLabelContent}
            eventColor='transparent'
            selectable
            selectMirror
            select={onSelect}
            selectAllow={onSelectAllow}
            eventChange={onEventChange}
            eventRemove={onEventRemove}
            eventsSet={onEventsSet}
            eventSourceSuccess={transformEvents}
            eventStartEditable
            eventAllow={eventAllow}
            eventOverlap={(stillEvent) => !isSessionOrSlot(stillEvent.extendedProps as EventExtendedProps)}
            slotEventOverlap={false}
            expandRows={true}
            contentHeight={2000}
            stickyHeaderDates={true}
            eventContent={eventContent}
            timeZone={timeZone}
            viewDidMount={({ el }) => {
              const axisEl = el.querySelector('.fc-timegrid-axis-frame')
              if (axisEl) {
                axisEl.innerHTML = getTimeZoneName({ timeZone, timeZoneName: 'short' }) ?? ''
              }
            }}
          />
          <ProviderCalendarAlerts alerts={alertsToShow} onClose={onAlertClose} />
        </Container>
        {loading && (
          <LoadingView>
            <LoadingIndicator topPadding={0} />
          </LoadingView>
        )}
        <ConfirmationModal
          visible={!!rangeToAdd}
          onRequestClose={() => setRangeToAdd(null)}
          onConfirmationButtonPress={onAddConfirmation}
          modalTitle={
            <FormattedMessage
              defaultMessage='Add availability this week?'
              description='Title of modal to add one-time availability'
            />
          }
          cancelButtonText={<FormattedMessage defaultMessage='Cancel' description='Cancel button' />}
          confirmationButtonText={<FormattedMessage defaultMessage='Add' description='Add availability button' />}
          modalContents={
            <AddRow>
              <ScheduleIcon fillColor={theme.colors.iconDefault} size={24} />
              {rangeToAdd && (
                <BodyText
                  text={
                    <FormattedDateTimeRange
                      from={parseISO(rangeToAdd.startLocal)}
                      to={parseISO(rangeToAdd.endLocal)}
                      weekday='long'
                      month='long'
                      day='numeric'
                      hour='numeric'
                      minute='numeric'
                      timeZoneName='short'
                      timeZone={timeZone}
                    />
                  }
                  size={BodyTextSize.DEFAULT}
                />
              )}
            </AddRow>
          }
        />
      </>
    )
  },
)
