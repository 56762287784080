import { useMemo } from 'react'

import { getYearMonthDayLocaleByDate } from '../../../common/utils/utils'
import { RTKQueryHook, RTKQueryResultType } from '../../../data/baseApi'
import { useGetUtilizationQuartersQuery } from '../../clients/clientDetails/data/utilizationApi'

export const useUtilizationQuartersData: RTKQueryHook<typeof useGetUtilizationQuartersQuery> = (args, opts) => {
  const todayDate = useMemo(() => new Date(), [])

  return useGetUtilizationQuartersQuery(
    {
      date: getYearMonthDayLocaleByDate(todayDate),
      ...args,
    },
    opts,
  ) as RTKQueryResultType<typeof useGetUtilizationQuartersQuery>
}
