import { default as realPlayer } from 'react-player'

import ReactPlayerMock from './reactPlayerMock'

declare global {
  interface Window {
    Cypress: any
    reactPlayerMock: ReactPlayerMock
  }
}

const reactPlayerMock = new ReactPlayerMock()

if (window.Cypress) {
  window.reactPlayerMock = reactPlayerMock
}

const ReactPlayer = window.Cypress ? reactPlayerMock.render() : realPlayer

export default ReactPlayer
