import React, { useEffect, useRef } from 'react'

import { useZoom } from '../../hooks/useZoom'

export const ZoomSelfViewInner: React.FC = ({}) => {
  const { setSelfElement, videoOff, sessionStarted } = useZoom()
  const containerRef = useRef<HTMLElement>(null)
  useEffect(() => {
    setSelfElement(containerRef.current)
    return () => setSelfElement(null)
  }, [setSelfElement])

  return (
    <video-player-container
      style={{
        width: '100%',
        height: '100%',
        display: videoOff || !sessionStarted ? 'none' : 'block',
      }}
      ref={containerRef}
    />
  )
}
