import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { format } from 'date-fns'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { ClientListClientObject } from '@lyrahealth-inc/shared-app-logic'
import { PrimaryButton, SecondaryButton, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './awayMessage.module.scss'
import { OOOStates } from './OutOfOfficeContainer'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { getAuthUserId } from '../../data/auth/authSelectors'
import { getActiveV2Clients } from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'
import { saveOOOMessage } from '../../lyraTherapy/messages/data/messagesAutoActions'

export const ConfirmationPrompt: React.FC<ConfirmationPromptProps> = ({
  clientsActive,
  isScheduled,
  message,
  onContinue,
  scheduledDate,
  scheduledTime,
  actions: { saveOOOMessage },
}) => {
  const userId = useSelector(getAuthUserId)
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <div>
      <h2>{`You are sending this message to ${clientsActive?.length} active clients.`}</h2>
      <p className={styles['prompt-text']}>
        You won&apos;t be able to edit or retract this message once scheduled or sent. Make sure your message does not
        contain any PHI and is appropriate for all active clients.
      </p>

      <div className={styles.buttons}>
        <SecondaryButton
          fullWidth={true}
          text={'Send'}
          onPress={() => {
            if (!userId) {
              return
            }
            setIsSubmitting(true)
            saveOOOMessage({
              message_body: message,
              message_type: 'out_of_office',
              provider_lyra_id: userId,
              time_zone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
              ...(isScheduled && {
                delivery_datetime: format(
                  new Date(`${scheduledDate} ${scheduledTime}` ?? undefined),
                  'yyyy-MM-dd HH:mm:ss',
                ),
              }),
            }).then(() => {
              setIsSubmitting(false)
              track({ event: 'SEND_OOO_MESSAGE_SELECTED' })
              onContinue(OOOStates.DISPLAYING_CONFIRMATION)
            })
          }}
          loading={isSubmitting}
          testID={tID('ConfirmationPrompt-confirm')}
        />
        <PrimaryButton
          fullWidth={true}
          text={'Keep editing'}
          onPress={() => {
            track({ event: 'GO_BACK_TO_EDIT_OOO_MESSAGE_SELECTED' })
            onContinue(OOOStates.SETTING_MESSAGE)
          }}
          testID={tID('ConfirmationPrompt-go-back')}
          disabled={isSubmitting}
        />
      </div>
    </div>
  )
}

type ConfirmationPromptProps = {
  clientsActive: ClientListClientObject[]
  message: string
  isScheduled: boolean
  scheduledDate: string | null
  scheduledTime: string | null
  onContinue: (newOperation: string) => void
  actions: {
    saveOOOMessage: ({
      message_body,
      message_type,
      provider_lyra_id,
      delivery_datetime,
      time_zone,
    }: {
      message_body: string
      message_type: string
      provider_lyra_id: string
      delivery_datetime?: string | null
      time_zone: string
    }) => Promise<any>
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    clientsActive: getActiveV2Clients(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      saveOOOMessage,
    },
    dispatch,
  ),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: Confirma... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPrompt)
