import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import queryString from 'query-string'

import { LoadingIndicator } from '@lyrahealth-inc/ui-core'

import { LOGIN, OUTCOMES_CONSENT } from '../common/constants/routingConstants'
import {
  getAuthHydrated,
  getAuthIsLoggedIn,
  getAuthIsLTSupervisor,
  getAuthUserIsRegistered,
  getAuthUserOutcomesAgreed,
  getAuthUserOutcomesEnabled,
  getLastAuthorizedRoute,
} from '../data/auth/authSelectors'
import { useLoginMutation } from '../data/authApi'

const OktaCallback: React.FunctionComponent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [login] = useLoginMutation()
  const outcomesAgreed = useSelector(getAuthUserOutcomesAgreed)
  const outcomesEnabled = useSelector(getAuthUserOutcomesEnabled)
  const showOutcomesConsent = !!outcomesEnabled && !outcomesAgreed
  const isLoggedIn = useSelector(getAuthIsLoggedIn)
  const hydrated = useSelector(getAuthHydrated)
  const isLtSupervisor = useSelector(getAuthIsLTSupervisor)
  const lastAuthorizedRoute = useSelector(getLastAuthorizedRoute)
  const isRegistered = useSelector(getAuthUserIsRegistered)

  useEffect(() => {
    login({ queryString: location.search, navigate })
  }, [location.search, login, navigate])

  if (!hydrated) return null

  if (!isLoggedIn) {
    return (
      <div style={{ textAlign: 'center', paddingTop: '60px' }}>
        <LoadingIndicator size={45} />
      </div>
    )
  }

  if (isRegistered) {
    if (showOutcomesConsent && !isLtSupervisor) {
      return <Navigate to={OUTCOMES_CONSENT.route} state={{ withoutNav: true }} />
    }
    if (lastAuthorizedRoute && lastAuthorizedRoute !== LOGIN.route) {
      return <Navigate to={lastAuthorizedRoute} />
    } else if (queryString.parse(location.search).next) {
      // @ts-expect-error TS(2322): Type 'string | string[] | null' is not assignable ... Remove this comment to see the full error message
      window.location.href = queryString.parse(router.location.search).next
      return null
    }
  }
  // Redirect the user to their default page
  return <Navigate to='/' />
}

export default OktaCallback
