import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isPast } from 'date-fns'
import { forIn, groupBy, mergeWith } from 'lodash-es'
import moment from 'moment'

import { Appointment, getDate, utilizationQuarters, VideoSession } from '@lyrahealth-inc/shared-app-logic'

import { GET_VIDEO_SESSIONS } from '../../../../common/constants/reduxConstants'

type AppointmentsState = {
  currentQuarterCMDDetailedWeeklyView?: string
  currentSessionCount?: number
  nextAppointment?: Appointment
  unclosedPastAppointments: Appointment[]
  videoSessions?: VideoSession[]
  currentCaseloadManagementDashboardQuarter?: utilizationQuarters
  appointmentDetails?: Appointment
}

const initialState: AppointmentsState = {
  unclosedPastAppointments: [],
}

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    getLTAppointmentsForPatient: (state, action: PayloadAction<Appointment[]>) => {
      const appointments = action.payload || []
      const nextAppt = appointments.find(
        (appt) => moment(`${appt.startDate} ${appt.startTime}`) > moment() && appt.appointmentStatus !== 'canceled',
      )
      const pastAppointmentsToClose = appointments.filter((appt) => {
        return (
          isPast(
            getDate({
              date: appt.startDate,
              time: appt.startTime,
              timeZone: appt.timeZone,
            }),
          ) && !['canceled', 'missed', 'completed'].includes(appt.appointmentStatus)
        )
      })

      state.nextAppointment = nextAppt
      state.unclosedPastAppointments = pastAppointmentsToClose
    },
    setCurrentQuarterCMD: (state, action: PayloadAction<utilizationQuarters>) => {
      state.currentCaseloadManagementDashboardQuarter = action.payload
    },
    setCurrentQuarterCMDDetailedWeeklyView: (state, action: PayloadAction<string | null>) => {
      state.currentQuarterCMDDetailedWeeklyView = action.payload ?? undefined
    },
    setCurrentSessionCount: (state, action: PayloadAction<{ episodeId: string; appointments: Appointment[] }>) => {
      const appointments = action.payload.appointments
      let currentSessionCount = 0
      const appointmentsByEpisode = groupBy(appointments, 'episodeId')
      forIn(appointmentsByEpisode[action.payload.episodeId], (appt: Appointment) => {
        if (appt.appointmentStatus === 'completed') {
          currentSessionCount++
        }
      })
      state.currentSessionCount = currentSessionCount + 1
    },
    setAppointment: (state, action: PayloadAction<Appointment>) => {
      state.appointmentDetails = mergeWith({}, action.payload, state.appointmentDetails, (a, b) => a || b)
    },
    clearSelectedAppointment: (state) => {
      state.appointmentDetails = undefined
    },
    clearLTAppointments: () => {
      return initialState
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AppointmentsState>) => {
    builder.addCase(GET_VIDEO_SESSIONS, (state, action: any) => {
      state.videoSessions = action.data
      return state
    })
  },
})

export const {
  getLTAppointmentsForPatient,
  setCurrentSessionCount,
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
  setAppointment,
  clearSelectedAppointment,
  clearLTAppointments,
} = appointmentsSlice.actions
export default appointmentsSlice.reducer
