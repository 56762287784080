import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { CalendarAlert } from '@lyrahealth-inc/shared-app-logic'

import { AlertPopUp } from '../alertPopUp/AlertPopUp'

export type ProviderCalendarAlertsProps = {
  alerts: CalendarAlert[]
  onClose: (alert: CalendarAlert) => void
}

const Container = styled.View(({ theme }) => ({
  flexDirection: 'column',
  position: 'fixed',
  left: theme.spacing['48px'],
  bottom: theme.spacing['48px'],
  zIndex: 3,
  gap: theme.spacing['16px'],
}))

export const ProviderCalendarAlerts: FunctionComponent<ProviderCalendarAlertsProps> = ({ alerts, onClose }) => {
  return (
    <Container>
      {alerts.map((alert) => (
        <AlertPopUp
          ariaLabel={alert.ariaLabel}
          key={alert.type}
          headerText={alert.title}
          text={alert.body}
          onClose={() => onClose(alert)}
          secondaryButton={
            alert.actions?.primary && {
              onPress: alert.actions.primary.onClick,
              text: alert.actions.primary.label,
              testID: alert.actions.primary.testID,
              loading: alert.actions.primary.isLoading,
            }
          }
          tertiaryButton={
            alert.actions?.secondary && {
              onPress: alert.actions.secondary.onClick,
              text: alert.actions.secondary.label,
              testID: alert.actions.secondary.testID,
              loading: alert.actions.secondary.isLoading,
            }
          }
        />
      ))}
    </Container>
  )
}
