import { useSelector } from 'react-redux'

import { useTargetDate } from './useTargetDate'
import { ROLES } from '../../../common/constants/appConstants'
import { hasRole } from '../../../common/utils/utils'
import { getAuthRoles, getAuthUserId } from '../../../data/auth/authSelectors'
import { RTKQueryHook, RTKQueryResultType } from '../../../data/baseApi'
import { useGetUtilizationChargesQuery } from '../../clients/clientDetails/data/utilizationApi'

export const useUtilizationChargesData: RTKQueryHook<typeof useGetUtilizationChargesQuery> = (args, opts) => {
  const userId = useSelector(getAuthUserId)
  const userRoles = useSelector(getAuthRoles)

  const { targetDate } = useTargetDate()

  return useGetUtilizationChargesQuery(
    {
      providerId: userId,
      date: targetDate,
      sessionType: 'lyra_therapy_teens_parent_session',
      ...args,
    },
    {
      skip: !hasRole(userRoles, [ROLES.LT_TEENS_THERAPIST, ROLES.LT_TEENS_THERAPIST_SUPERVISOR]),
      ...opts,
    },
  ) as RTKQueryResultType<typeof useGetUtilizationChargesQuery>
}
