import React, { forwardRef, ReactElement, ReactNode } from 'react'
import { FormatDateOptions, FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import { isPast, isToday } from 'date-fns'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  Appointment,
  CalendarCardAppointment,
  getDateFromAppointment,
  getProgramNameFromId,
  isWithin24Hours,
  ProgramNames,
  TREATMENT_OPTIONS_FROM_FRONT_END,
  TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND,
} from '@lyrahealth-inc/shared-app-logic'

import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { FieldBadge } from '../../atoms/formElements/FieldBadge'
import { Headline } from '../../atoms/headline/Headline'
import { AddToCalendarIcon, SessionListPlayIcon } from '../../atoms/icons'
import { Avatar, avatarAltTextGeneric, AvatarDetails } from '../../atoms/icons/Avatar'
import { ManageSessionLink } from '../../atoms/link/ManageSessionLink'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead } from '../../atoms/subhead/Subhead'
import { TruncatedText } from '../../atoms/truncate/TruncatedText'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks'
import { HeadlineSize, SubheadSize, TextType } from '../../styles/typeStyles'
import { ThemeType, tID } from '../../utils'

export type CalendarCardProps = {
  id: number
  onPress?: () => void
  avatarDetails?: AvatarDetails
  title: string
  session: Appointment
  subtitle?: string | ReactElement | ReactNode
  icon?: ReactElement<View>
  isMissed?: boolean
  addToCalendarLink?: (appointmentId: { appointmentId: number }) => void
  handleReschedulePress?: (appointment: Appointment) => void
  handleCancelPress?: (appointment: Appointment) => void
  providerIcon?: ReactElement
  viewSessionRecording?: (sessionID: string) => void
  showManageSessionButton?: boolean // show "Manage session" button in native mobile, default to true
  disallowSessionUpdate?: boolean // do not allow cancel or reschedule a session by user
  testId?: string
  providerID?: string
  childFirstName?: string
}

const CardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingVertical: theme.spacing['8px'],
  padding: theme.spacing['16px'],
  marginBottom: theme.spacing['8px'],
  alignItems: 'center',
  minHeight: '80px',
  backgroundColor: theme.colors.backgroundPrimary,
  borderWidth: '1px',
  borderColor: theme.colors.borderDefault,
  borderRadius: theme.breakpoints.isMinWidthLaptop ? theme.spacing['16px'] : theme.spacing['12px'],
}))

const CardMainContents = styled(View)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

const AvatarContainer = styled.View({
  position: 'relative',
})

const SessionDateTimeContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '14px',
  display: 'flex',
  marginTop: '4px',
})

const TitleTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: 15,
  flexShrink: 1,
  paddingRight: theme.breakpoints.isMinWidthLaptop ? 16 : 8,
}))

const Icon = styled.View({
  position: 'absolute',
  right: '-4px',
  bottom: '-4px',
})

const DateBlockContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginLeft: 'auto',
  height: theme.breakpoints.isMinWidthLaptop ? '56px' : '48px',
  width: theme.breakpoints.isMinWidthLaptop ? '56px' : '48px',
  backgroundColor: theme.colors.backgroundSection,
  borderRadius: '5px',
  overflow: 'visible',
  color: theme.colors.textSecondary,
}))

const AppointmentActionsContainer = styled(View)<{ theme: ThemeType; providerIconNullish: boolean }>(
  ({ theme, providerIconNullish }) => {
    return {
      color: theme.colors.textActive,
      display: !providerIconNullish || theme.breakpoints.isMinWidthLaptop ? 'flex' : 'none',
      flexDirection: 'row',
      width: '100%',
    }
  },
)

const ManageSessionButtonContainer = styled(PrimaryButton)(({ theme }) => ({
  display: !theme.breakpoints.isMinWidthLaptop ? 'flex' : 'none',
  width: '100%',
  marginTop: theme.spacing['16px'],
}))

const HeadlineContainer = styled(Headline)({
  ...(IS_WEB && {
    lineHeight: '100%',
  }),
})

const SubHeadContainer = styled(Subhead)({
  ...(IS_WEB && {
    lineHeight: '100%',
  }),
})

const ManageSessionLinkContainer = styled(View)<{ theme: ThemeType; first?: boolean }>(({ theme, first }) => {
  return {
    height: theme.spacing['24px'],
    display: 'flex',
    justifyContent: 'center',
    marginLeft: first ? `-${theme.spacing['8px']}` : '0px',
    paddingLeft: first ? '0px' : theme.spacing['8px'],
    paddingRight: theme.spacing['8px'],
    borderLeftWidth: first ? '0' : '1px',
    borderLeftColor: first ? '0' : theme.colors.borderDefault,
    marginTop: theme.spacing['16px'],
  }
})

const RecordingUnavailableContainer = styled(View)<{ theme: ThemeType; first?: boolean }>(({ theme, first }) => {
  return {
    height: theme.spacing['24px'],
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: first ? 0 : theme.spacing['8px'],
    paddingRight: theme.spacing['8px'],
    borderLeftWidth: '0',
    marginTop: theme.spacing['16px'],
    color: theme.colors.textSecondary,
  }
})

const ProviderIconContainer = styled.View({
  alignSelf: 'flex-start',
})

const TodayContainer = styled.View({
  fontSize: '14px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
})
/**
 * Represents a single session as a card which contains
 * an avatar, title name, session info, and calendar icon
 */
let CalendarCard = forwardRef<unknown, CalendarCardProps>(
  (
    {
      id,
      onPress,
      avatarDetails,
      title,
      subtitle,
      icon,
      isMissed,
      handleReschedulePress,
      handleCancelPress,
      providerIcon,
      viewSessionRecording,
      addToCalendarLink,
      session,
      showManageSessionButton = true,
      testId = '',
      providerID,
      childFirstName,
      disallowSessionUpdate,
    },
    _,
  ) => {
    const { isMinWidthLaptop } = useMediaQuerySize()
    const { formatMessage } = useIntl()
    const { colors } = useTheme()
    const { appointmentTodayText } = colors.components.calendarCard
    const onCardPress = onPress || noop
    const intl = useIntl()
    const dateTime = getDateFromAppointment(session)
    const isAppointmentToday = isToday(dateTime)
    const isInPast = isPast(dateTime)
    const day = intl.formatTime(dateTime, {
      month: 'long',
      day: 'numeric',
      timeZone: session?.timeZone ?? '',
      timeZoneName: 'short',
    })

    const dateFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }

    const timeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    }
    const todayFormatted = (
      <TodayContainer>
        <FormattedMessage defaultMessage='TODAY' description='current day' />
      </TodayContainer>
    )

    const getTranslatedProgramName = (programName?: string) => {
      switch (programName) {
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.COACHING:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.LIVE_MESSAGING_COACHING:
        case ProgramNames.Coaching:
          return (
            <FormattedMessage
              defaultMessage='Coaching {spacer}'
              description='Label showing the program name Coaching'
              values={{ spacer: '• ' }}
            />
          )
        case TREATMENT_OPTIONS_FROM_FRONT_END.ONSITE_THERAPY:
        case TREATMENT_OPTIONS_FROM_FRONT_END.BLENDED_CARE_THERAPY:
        case TREATMENT_OPTIONS_FROM_FRONT_END.INDIVIDUAL_THERAPY_CHILD_ICAS:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.INDIVIDUAL_THERAPY:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.INDIVIDUAL_THERAPY_ICAS_PHONE:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.FAMILY_THERAPY:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.COUPLES_THERAPY:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.FAMILY_THERAPY_CHILD:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.INDIVIDUAL_THERAPY_CHILD:
        case ProgramNames.ICASTherapy:
        case ProgramNames.DIRECT_ACCESS_THERAPY:
        case ProgramNames.BlendedCareTherapy:
          return (
            <FormattedMessage
              defaultMessage='Therapy {spacer}'
              description='Label showing the program name of Therapy'
              values={{ spacer: '• ' }}
            />
          )

        case TREATMENT_OPTIONS_FROM_FRONT_END.CONTROLLED_MEDICATION:
        case TREATMENT_OPTIONS_FROM_FRONT_END.MEDICATION_CONSULTATION:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.BLENDED_CARE_MEDS:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.DIRECT_ACCESS_MEDS:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.DIRECT_ACCESS_MEDS_CHILD:
        case ProgramNames.MedicationManagement:
          return (
            <FormattedMessage
              defaultMessage='Medication Management {spacer}'
              description='Label showing the program name Medication Management'
              values={{ spacer: '• ' }}
            />
          )
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.SELF_CARE_APPS:
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.GUIDED_SELF_CARE:
        case ProgramNames.SingleSessionCoaching:
          return (
            <FormattedMessage
              defaultMessage='Guided self-care {spacer}'
              description='Label showing the program name Guided self-care'
              values={{ spacer: '• ' }}
            />
          )
        case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.ALCOHOL_USE_DISORDER:
        case ProgramNames.AlcoholUseDisorderTherapy:
          return (
            <FormattedMessage
              defaultMessage='Renew {spacer}'
              description='Label showing the program name Renew'
              values={{ spacer: '• ' }}
            />
          )
        case ProgramNames.ClinicalLeaveEvaluation:
          return (
            <FormattedMessage
              defaultMessage='Clinical Leave Evaluation {spacer}'
              description='Label showing the program name Clinical Leave Evaluation'
              values={{ spacer: '• ' }}
            />
          )
        default:
          return ''
      }
    }
    const programName = getProgramNameFromId(session?.programId) ?? session?.treatmentType
    const sessionNumber = session?.sessionNumber === 0 ? 1 : session?.sessionNumber
    const translatedProgramName = getTranslatedProgramName(programName)
    const programNameSubtitle = (
      <>
        {translatedProgramName}
        <FormattedMessage
          defaultMessage='Session {sessionNumber}'
          description='Label showing the session number'
          values={{ sessionNumber }}
        />
      </>
    )
    const formattedDate = intl.formatDate(dateTime, dateFormatOptions as FormatDateOptions)
    const formattedTime = intl.formatDate(dateTime, timeFormatOptions as FormatDateOptions)
    const isCancelled = session?.appointmentStatus === 'canceled'
    const isTooLateToReschedule =
      !isInPast && session && session.appointmentStatus !== 'canceled' && isWithin24Hours(session)
    if (!session) {
      return null
    }

    const childBadgeText = childFirstName
      ? formatMessage(
          {
            defaultMessage: 'for {childFirstName}',
            description: 'Text to indicate the child the session is for',
          },
          { childFirstName },
        )
      : null

    return (
      <CardContainer
        testID={tID(`CalendarCard${testId ? '-' + testId : ''}`)}
        key={id}
        accessibilityLabel={`${title}${subtitle ? `, ${subtitle}` : ''}, on ${day}`}
      >
        <CardMainContents testID={tID('CalendarCard-information-block')}>
          {avatarDetails && (
            <AvatarContainer>
              <Avatar
                details={avatarDetails}
                accessibilityLabel={formatMessage(avatarAltTextGeneric, { name: avatarDetails.displayName })}
                size={isMinWidthLaptop ? 60 : 48}
                providerID={providerID}
              />

              <Icon testID={tID(`CalendarCard-modality-icon-${session?.meetingFormat}`)}>{icon}</Icon>
            </AvatarContainer>
          )}
          {providerIcon && <ProviderIconContainer>{providerIcon}</ProviderIconContainer>}
          <TitleTextContainer testID={tID('CalendarCard-title')} importantForAccessibility='no-hide-descendants'>
            <TruncatedText
              fontColor={colors.textPrimary}
              text={title}
              textType={TextType.BODY}
              textSize={BodyTextSize.DEFAULT}
              maxNumberOfLines={1}
              noMargin
              textDecorator={
                childBadgeText ? (
                  <FieldBadge
                    testID={tID('CalendarCard-childBadge')}
                    disableHeadline
                    text={childBadgeText}
                    badgeTheme='Periwinkle'
                  />
                ) : undefined
              }
            />
            <BodyText size={BodyTextSize.SMALL} color={colors.textSecondary} testID={tID('CalendarCard-subtitle')}>
              {isCancelled ? (
                !providerIcon ? (
                  <>
                    <BodyText
                      color={colors.textSecondary}
                      text={translatedProgramName}
                      size={BodyTextSize.SMALL}
                      testID={tID('CalendarCard-programName')}
                    />
                    <BodyText
                      text={
                        <FormattedMessage
                          defaultMessage={`Canceled`}
                          description='Canceled or anulled or no longer valid'
                        />
                      }
                      color={colors.textWarning}
                      size={BodyTextSize.SMALL}
                      testID={tID('CalendarCard-cancelled')}
                    />
                  </>
                ) : (
                  <BodyText text={subtitle} size={BodyTextSize.SMALL} color={colors.textSecondary} />
                )
              ) : subtitle ? (
                <BodyText text={subtitle} size={BodyTextSize.SMALL} color={colors.textSecondary} />
              ) : (
                programNameSubtitle
              )}
            </BodyText>
            <SessionDateTimeContainer>
              <BodyText size={BodyTextSize.SMALL} testID={tID('CalendarCard-appointment-time')}>
                <FormattedMessage
                  defaultMessage='{date} {spacer} {time}{endSpacer}'
                  description='Date and time of the appointment'
                  values={{
                    date: formattedDate,
                    spacer: '•',
                    time: formattedTime,
                    endSpacer: isAppointmentToday ? ' • ' : '',
                  }}
                />
                {isAppointmentToday && (
                  <BodyText
                    text={todayFormatted}
                    color={appointmentTodayText.text}
                    size={BodyTextSize.CAPTION}
                    testID={tID('CalendarCard-Today')}
                  />
                )}
              </BodyText>
            </SessionDateTimeContainer>
          </TitleTextContainer>
          <DateBlockContainer testID={tID('CalendarCard-date-block')} importantForAccessibility='no-hide-descendants'>
            <HeadlineContainer
              size={HeadlineSize.EYEBROW}
              text={intl
                .formatDate(dateTime, {
                  month: 'short',
                })
                .toUpperCase()}
            />
            <SubHeadContainer
              size={SubheadSize.MEDIUM}
              text={intl.formatDate(dateTime, {
                day: 'numeric',
              })}
            />
          </DateBlockContainer>
        </CardMainContents>
        {!isInPast &&
          session?.appointmentStatus !== 'canceled' &&
          !providerIcon &&
          showManageSessionButton &&
          !disallowSessionUpdate && (
            <ManageSessionButtonContainer
              fullWidth={true}
              onPress={onCardPress}
              size={ButtonSize.SMALL}
              testID={tID('CalendarCard-manage-button')}
              text={
                <FormattedMessage defaultMessage='Manage session' description='Label of button that submits the form' />
              }
            />
          )}
        {!isMissed && (
          <AppointmentActionsContainer
            providerIconNullish={!providerIcon}
            testID={tID('CalendarCard-actions-container')}
          >
            {viewSessionRecording && session && session.videoRecording && (
              <ManageSessionLinkContainer first={true} testID={tID('CalendarCard-watch-recording')}>
                <ManageSessionLink
                  icon={<SessionListPlayIcon />}
                  linkText={
                    <BodyText
                      color={colors.linkDefault}
                      size={BodyTextSize.DEFAULT}
                      text={
                        <FormattedMessage
                          defaultMessage='Watch recording'
                          description='Watch the recorded appointment'
                        />
                      }
                    />
                  }
                  onPress={() => {
                    viewSessionRecording(session.videoRecording as string)
                  }}
                />
              </ManageSessionLinkContainer>
            )}
            {isInPast && session && !session.videoRecording && providerIcon && (
              <RecordingUnavailableContainer first={true} testID={tID('CalendarCard-watch-recording-not-available')}>
                <BodyText
                  color={colors.textActive}
                  size={BodyTextSize.DEFAULT}
                  text={
                    <FormattedMessage
                      defaultMessage='Recording unavailable'
                      description='Recorded meeting is not available to watch'
                    />
                  }
                />
              </RecordingUnavailableContainer>
            )}
            {addToCalendarLink && session?.appointmentStatus !== 'canceled' && (
              <ManageSessionLinkContainer first={true} testID={tID('CalendarCard-add-to-calendar')}>
                <ManageSessionLink
                  icon={<AddToCalendarIcon />}
                  linkText={
                    <BodyText
                      color={colors.linkDefault}
                      size={BodyTextSize.DEFAULT}
                      text={
                        <FormattedMessage defaultMessage='Add to calendar' description='Add appointment to calendar' />
                      }
                    />
                  }
                  onPress={() => {
                    addToCalendarLink({ appointmentId: id })
                  }}
                />
              </ManageSessionLinkContainer>
            )}
            {!isTooLateToReschedule &&
              handleReschedulePress &&
              session?.appointmentStatus !== 'canceled' &&
              !disallowSessionUpdate && (
                <ManageSessionLinkContainer first={!addToCalendarLink} testID={tID('CalendarCard-reschedule')}>
                  <ManageSessionLink
                    linkText={
                      <BodyText
                        color={colors.linkDefault}
                        size={BodyTextSize.DEFAULT}
                        text={
                          <FormattedMessage
                            defaultMessage='Reschedule'
                            description='Change the schedule of the appointment'
                          />
                        }
                      />
                    }
                    onPress={() => handleReschedulePress(session)}
                  />
                </ManageSessionLinkContainer>
              )}
            {handleCancelPress && session?.appointmentStatus !== 'canceled' && !disallowSessionUpdate && (
              <ManageSessionLinkContainer
                first={isTooLateToReschedule && handleReschedulePress !== undefined && providerIcon !== undefined}
                testID={tID('CalendarCard-cancel')}
              >
                <ManageSessionLink
                  linkText={
                    <BodyText
                      color={colors.textActive}
                      size={BodyTextSize.DEFAULT}
                      text={<FormattedMessage defaultMessage='Cancel session' description='Cancel the appointment' />}
                    />
                  }
                  onPress={() => {
                    handleCancelPress(session as CalendarCardAppointment)
                  }}
                />
              </ManageSessionLinkContainer>
            )}
          </AppointmentActionsContainer>
        )}
      </CardContainer>
    )
  },
)

CalendarCard = React.memo(CalendarCard)

export { CalendarCard }
