import { createSelector } from 'reselect'

import { RootState } from '../store'
export const getLyraTherapyState = (state: RootState) => state?.lyraTherapy

export const getClients = createSelector(getLyraTherapyState, (lyraTherapyState) => lyraTherapyState?.clients)

export const getClientDetails = createSelector(getClients, (clients) => clients?.clientDetails)

export const getClientDetailsData = createSelector(getClients, (clients) => clients?.clientDetails?.data)

export const getClientAppointments = createSelector(getClientDetails, (clientDetails) => clientDetails?.appointments)

export const getEpisodes = createSelector(getClientDetails, (clientDetails) => clientDetails?.episodes)

export const getClientNotes = createSelector(getClientDetails, (clientDetails) => clientDetails?.notes)

export const getClientAppointmentDetails = createSelector(
  getClientAppointments,
  (clientAppointments) => clientAppointments?.appointmentDetails,
)

export const getClientNextAppointment = createSelector(
  getClientAppointments,
  (clientAppointments) => clientAppointments?.nextAppointment,
)

export const getRequestedCaseloadManagementQuarter = createSelector(
  getClientAppointments,
  (clientAppointments) => clientAppointments?.currentCaseloadManagementDashboardQuarter,
)

export const getCurrentQuarterCMDDetailedWeeklyView = createSelector(
  getClientAppointments,
  (clientAppointments) => clientAppointments?.currentQuarterCMDDetailedWeeklyView,
)

export const getClientCurrentSessionCount = createSelector(
  getClientAppointments,
  (clientAppointments) => clientAppointments?.currentSessionCount,
)

export const getClientUnclosedPastAppointments = createSelector(
  getClientAppointments,
  (clientAppointments) => clientAppointments?.unclosedPastAppointments,
)

export const getClientVideoSessionAppointments = createSelector(
  getClientAppointments,
  (clientAppointments) => clientAppointments?.videoSessions,
)

export const getClientAssignments = createSelector(getClientDetails, (clientDetails) => clientDetails?.assignments)

export const getClientAssignmentsData = createSelector(
  getClientAssignments,
  (clientAssignments) => clientAssignments?.data,
)

export const getClientAssignmentDetails = createSelector(
  getClientAssignments,
  (clientAssignments) => clientAssignments?.assignmentDetails,
)

export const getClientAssignmentResponse = createSelector(
  getClientAssignments,
  (clientAssignments) => clientAssignments?.responseDetails,
)

export const getClientLiveMessaging = createSelector(
  getClientDetails,
  (clientDetails) => clientDetails?.data?.live_messaging,
)

export const getClientDataId = createSelector(getClientDetailsData, (clientData) => clientData?.id)

export const getClientDataFullName = createSelector(getClientDetailsData, (clientData) => clientData?.full_name)

export const getClientDataPreferredFirstName = createSelector(
  getClientDetailsData,
  (clientData) => clientData?.preferred_first_name,
)

export const getClientDataPreferredLastName = createSelector(
  getClientDetailsData,
  (clientData) => clientData?.preferred_last_name,
)

export const getClientDataCountry = createSelector(getClientDetailsData, (clientData) => clientData?.country)

export const getClientEpisodes = createSelector(getClientDetails, (clientDetails) => clientDetails?.episodes)

export const getClientEpisodesData = createSelector(getClientEpisodes, (clientEpisodes) => clientEpisodes?.data)

export const getClientEpisodeProgramConfig = createSelector(
  getClientEpisodes,
  (clientEpisodes) => clientEpisodes?.programConfig,
)

export const getClientEpisodeProgramConfigId = createSelector(
  getClientEpisodeProgramConfig,
  (programConfig) => programConfig?.programId,
)

export const getClientSelectedEpisodeIndex = createSelector(
  getClientEpisodes,
  (clientEpisodes) => clientEpisodes?.selectedEpisodeIdx,
)

export const getClientSelectedEpisode = createSelector(
  getClientEpisodes,
  getClientSelectedEpisodeIndex,
  (clientEpisodes, selectedEpisodeIndex) =>
    selectedEpisodeIndex != null ? clientEpisodes?.data[selectedEpisodeIndex] : undefined,
)

export const getClientSelectedEpisodeId = createSelector(
  getClientEpisodes,
  getClientSelectedEpisodeIndex,
  (clientEpisodes, selectedEpisodeIndex) =>
    selectedEpisodeIndex != null ? clientEpisodes?.data[selectedEpisodeIndex]?.id : undefined,
)

export const getSelectedEpisodeProgram = createSelector(
  getClientSelectedEpisode,
  (clientSelectedEpisode) => clientSelectedEpisode?.program_name,
)

export const getSelectedEpisodeCurriculum = createSelector(
  getClientSelectedEpisode,
  (clientSelectedEpisode) => clientSelectedEpisode?.curriculum,
)

export const getClientClosedEpisodes = createSelector(
  getClientEpisodes,
  (clientEpisodes) => clientEpisodes?.closedEpisodes,
)

export const getClientsData = createSelector(getClients, (clients) => clients?.data)

export const getActiveV2Clients = createSelector(getClientsData, (clientsData) => clientsData?.activeV2)

export const getInactiveV2Clients = createSelector(getClientsData, (clientsData) => clientsData?.inactiveV2)

export const getInitialActiveClientsFetched = createSelector(
  getClientsData,
  (clientsData) => clientsData?.initialActiveClientsFetched,
)

export const getInitialInactiveClientsFetched = createSelector(
  getClientsData,
  (clientsData) => clientsData?.initialInactiveClientsFetched,
)

export const getClientsDataCounts = createSelector(getClientsData, (clientsData) => clientsData?.counts)

export const getClientsDataFiltered = createSelector(getClientsData, (clientsData) => clientsData?.filtered)

export const getToastContent = createSelector(getLyraTherapyState, (lyraTherapyState) => lyraTherapyState.toast)

export const getClientNotesData = createSelector(getClientNotes, (clientNotes) => clientNotes?.data)

export const getClientNotesAllNotes = createSelector(getClientNotes, (clientNotes) => clientNotes?.allNotes)

export const getClientNotesAppointmentSummaries = createSelector(
  getClientNotes,
  (clientNotes) => clientNotes?.appointmentSummaries,
)

export const getClientNotesSummaryFeedback = createSelector(getClientNotes, (clientNotes) => clientNotes?.notesFeedback)

export const getClientDetailsMedications = createSelector(
  getClientDetails,
  (clientDetails) => clientDetails?.medications,
)

export const getClientDetailsMedicationsPrescriptions = createSelector(
  getClientDetailsMedications,
  (clientDetailsMedications) => clientDetailsMedications?.prescriptions,
)
