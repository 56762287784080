import { useMemo } from 'react'

import moment from 'moment'

import { PEMetric, PEMetrics } from '../data/platformEngagementApi'

type PEMetricsTransformed = {
  ytd: PEMetric
  quarters: { [key: string]: PEMetric }
  isEmpty: boolean
}
const useTransformPEMetrics = (peMetrics: PEMetrics | undefined): PEMetricsTransformed | undefined => {
  return useMemo(() => {
    if (!peMetrics) {
      return undefined
    }

    const { ytd, ...quarters } = peMetrics
    const newQuarters = {}
    for (const [name, metric] of Object.entries(quarters)) {
      const date = moment(name)
      const newKey = `Q${date.quarter()} ${date.year()}`
      newQuarters[newKey] = metric
    }

    return {
      ytd,
      quarters: newQuarters,
      isEmpty: ytd.assignment_rate === null && ytd.feedback_rate === null && ytd.engagement_rate === null,
    }
  }, [peMetrics])
}

export default useTransformPEMetrics
