import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { get, isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { Assignment } from '@lyrahealth-inc/shared-app-logic'
import {
  BootstrapContainer,
  LoadingIndicator,
  MedicationsList,
  MedicineIcon,
  PrimaryButton,
} from '@lyrahealth-inc/ui-core'
import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import { getDSPatientUrl, getPatientPrescriptions } from './data/medicationsAutoActions'
import styles from './medications.module.scss'
import { programConfig, ROLES } from '../../common/constants/appConstants'
import { hasRole } from '../../common/utils/utils'
import { getAuthConfig, getAuthRoles } from '../../data/auth/authSelectors'
import {
  getClientAssignmentsData,
  getClientDataId,
  getClientDetailsMedicationsPrescriptions,
} from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'

export const Medications: React.FC<MedicationsProps> = ({
  clientId,
  prescriptions,
  clientIntake,
  actions: { getDSPatientUrl, getPatientPrescriptions },
}) => {
  const userRoles: string[] = useSelector(getAuthRoles)
  const config = useSelector(getAuthConfig)
  const [loadingDSUrl, setLoadingDSUrl] = useState(false)
  const [loadingPrescriptions] = useFetcher([getPatientPrescriptions, { clientId }, !!clientIntake], [clientId])

  if (!config?.medicationEnabled) {
    return null
  }

  const fetchDSUrl = async () => {
    try {
      setLoadingDSUrl(true)
      const doseSpotLink = await getDSPatientUrl({ clientId })
      window.open(get(doseSpotLink, 'sso_url'), '_blank')
    } finally {
      setLoadingDSUrl(false)
    }
  }

  return (
    <BootstrapContainer col='col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2'>
      {(isEmpty(prescriptions) && loadingPrescriptions) || loadingDSUrl ? (
        <div className={styles['loading-container']}>
          <LoadingIndicator size={45} />
        </div>
      ) : (
        <>
          {clientIntake ? (
            <>
              <div className={styles['prescribe-container']}>
                <h2>Lyra Medications</h2>
                {(!hasRole(userRoles, [ROLES.LT_CLINICAL_LEAVE_EVALUATOR]) ||
                  hasRole(userRoles, [ROLES.LT_PRESCRIBER])) && (
                  <PrimaryButton data-test-id='Medications-AddMedication' onClick={fetchDSUrl}>
                    Add Medication
                  </PrimaryButton>
                )}
              </div>
              <MedicationsList medications={prescriptions} onMedicationViewItemClick={fetchDSUrl} />
            </>
          ) : (
            <div className={styles['no-data-container']} data-test-id='Medications-noData'>
              <div className={styles['text-container']}>
                <h4 className={styles.title}>Intake form incomplete</h4>
                <p className={styles['missing-intake']} data-test-id='Medications-missing-intake'>
                  Your client hasn’t completed their client intake yet and therefore has not given consent. Encourage
                  your client to complete this, as you will not be able to prescribe medication until they do so.
                </p>
              </div>
              <MedicineIcon width='100' />
            </div>
          )}
        </>
      )}
    </BootstrapContainer>
  )
}

type MedicationsProps = {
  clientId: string
  clientIntake: Assignment
  prescriptions: string[]
  actions: {
    getDSPatientUrl: ({ clientId }: { clientId: string }) => void
    getPatientPrescriptions: () => void
  }
}

const mapStateToProps = (state: RootState) => ({
  clientId: getClientDataId(state),
  // ICE-3160: Filtering out prescriptions where status is of requested
  prescriptions: getClientDetailsMedicationsPrescriptions(state).filter(
    (prescriptionItem) => prescriptionItem.Status !== 8,
  ),
  clientIntake: getClientAssignmentsData(state).find(
    (assignment) =>
      [
        programConfig.MedicationManagement.clientIntake,
        programConfig.MedicationManagement.oldClientIntake,
        programConfig.ClinicalLeaveEvaluation.clientIntake,
      ].includes(assignment.content.name) && assignment.status === 'completed',
  ),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): any => ({
  actions: bindActionCreators({ getDSPatientUrl, getPatientPrescriptions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Medications)
