import React from 'react'
import { connect, useSelector } from 'react-redux'

import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { ClientListClientObject } from '@lyrahealth-inc/shared-app-logic'
import { BootstrapContainer, LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './bcDashboard.module.scss'
import ActivitiesDashlet from './dashlets/ActivitiesDashlet'
import MessagesDashlet from './dashlets/MessagesDashlet'
import SelfHarmDashlet from './dashlets/SelfHarmDashlet'
import UnclosedSessionsDashlet from './dashlets/UnclosedSessionsDashlet'
import UnfinishedNotesDashlet from './dashlets/UnfinishedNotesDashlet'
import UpcomingSessionsDashlet from './dashlets/UpcomingSessionsDashlet'
import useTwilioMessaging from '../../common/components/hooks/useTwilioMessaging'
import { TwilioConversations } from '../../common/twilio-conversations'
import { getAuthConfig, getAuthUserId } from '../../data/auth/authSelectors'
import {
  getActiveV2Clients,
  getInactiveV2Clients,
  getInitialActiveClientsFetched,
  getInitialInactiveClientsFetched,
} from '../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../data/store'
import { getLtClientsV2 } from '../clients/data/ltClientsAutoActions'
import {
  getAccessToken,
  getMessageConversations,
  incrementUnreadMessageCount,
  setConversationsClient,
} from '../messages/data/messagesAutoActions'
import { getConversationsClient } from '../messages/data/messagesSelectors'

export const BCDashboard: React.FC<BCDashboardProps> = ({
  activeClients,
  inactiveClients,
  initialActiveClientsFetched,
  initialInactiveClientsFetched,
  conversationsClient,
  actions: {
    getLtClientsV2,
    getMessageConversations,
    getAccessToken,
    incrementUnreadMessageCount,
    setConversationsClient,
  },
}) => {
  const userId = useSelector(getAuthUserId)
  const showOverdueMessageFlag = useSelector(getAuthConfig)?.showOverdueMessageFlag ?? false
  useTwilioMessaging({
    userId: userId,
    conversationsClient,
    actions: { getAccessToken, incrementUnreadMessageCount, setConversationsClient },
  })

  useFetcher(
    [
      [
        getLtClientsV2,
        { providerId: userId, status: 'active' },
        initialActiveClientsFetched && Array.isArray(activeClients) && activeClients?.length > 0,
      ],
    ],
    [userId],
  )
  useFetcher(
    [
      [
        getLtClientsV2,
        { providerId: userId, status: 'inactive' },
        initialInactiveClientsFetched && inactiveClients?.length > 0,
      ],
    ],
    [userId],
  )

  const [isLoadingConversations] = useFetcher(
    [
      getMessageConversations,
      { provider_lyra_id: userId, ...(showOverdueMessageFlag && { derived_properties: 'overdue' }) },
    ],
    [userId],
  )

  let clients = Array.isArray(activeClients) ? activeClients : undefined
  clients = Array.isArray(inactiveClients) ? activeClients.concat(inactiveClients) : activeClients

  const activeClientsFetched = initialActiveClientsFetched || (!isEmpty(clients) && activeClients?.length > 1)

  return (
    <BootstrapContainer col='col-md-12' style={{ marginBottom: '50px', width: '85%' }}>
      <div
        className={classNames(styles['inactive-loading-container'], {
          [styles.hidden]: initialActiveClientsFetched && initialInactiveClientsFetched && !isLoadingConversations,
        })}
      >
        {/* @ts-expect-error TS(2322): Type '{ size: number; style: { marginRight: string... Remove this comment to see the full error message */}
        <LoadingIndicator size={25} style={{ marginRight: '10px' }} />
        Loading latest data
      </div>
      <div className='row'>
        <div className={classNames('col-md-4', styles.dashlet)}>
          {/* @ts-expect-error TS(2739): Type '{ clients: ClientListClientObject[]; provide... Remove this comment to see the full error message */}
          <MessagesDashlet clients={clients} providerId={userId} activeClientsFetched={activeClientsFetched} />
          {/* @ts-expect-error TS(2322): Type 'ClientListClientObject[]' is not assignable ... Remove this comment to see the full error message */}
          <ActivitiesDashlet clients={clients} providerId={userId} activeClientsFetched={activeClientsFetched} />
        </div>
        <div className={classNames('col-md-4', styles.dashlet)}>
          {/* @ts-expect-error TS(2322): Type 'ClientListClientObject[]' is not assignable ... Remove this comment to see the full error message */}
          <UnfinishedNotesDashlet clients={clients} providerId={userId} activeClientsFetched={activeClientsFetched} />
          {/* For the Unclosed Session dashlet, we want to display active clients only */}
          {/* @ts-expect-error TS(2322): Type 'ClientListClientObject[]' is not assignable ... Remove this comment to see the full error message */}
          <UnclosedSessionsDashlet clients={activeClients} providerId={userId} isLoading={!activeClientsFetched} />
        </div>
        <div className={classNames('col-md-4', styles.dashlet)}>
          {/* @ts-expect-error TS(2322): Type 'ClientListClientObject[]' is not assignable ... Remove this comment to see the full error message */}
          <UpcomingSessionsDashlet clients={clients} providerId={userId} isLoading={!activeClientsFetched} />
          {/* For the Self Harm dashlet, we want to display active clients only */}
          {/* @ts-expect-error TS(2322): Type 'ClientListClientObject[]' is not assignable ... Remove this comment to see the full error message */}
          <SelfHarmDashlet clients={clients} providerId={userId} isLoading={!activeClientsFetched} />
        </div>
      </div>
    </BootstrapContainer>
  )
}

type BCDashboardProps = {
  actions: {
    getLtClientsV2: ({
      providerId,
      status,
      cancelToken,
    }: {
      providerId: string
      status: boolean
      cancelToken: string
    }) => { action: string; request: { method: string; url: string; params: object; cancelToken: any } }
    getMessageConversations: ({
      provider_lyra_id,
      derived_properties,
    }: {
      provider_lyra_id: string
      derived_properties: string
    }) => void
    getAccessToken: () => void
    incrementUnreadMessageCount: () => void
    setConversationsClient: () => void
  }
  activeClients: ClientListClientObject[]
  inactiveClients: ClientListClientObject[]
  initialActiveClientsFetched: boolean
  initialInactiveClientsFetched: boolean
  conversationsClient: typeof TwilioConversations | undefined
}

const mapStateToProps = (state: RootState) => ({
  activeClients: getActiveV2Clients(state),
  inactiveClients: getInactiveV2Clients(state),
  initialActiveClientsFetched: getInitialActiveClientsFetched(state),
  initialInactiveClientsFetched: getInitialInactiveClientsFetched(state),
  conversationsClient: getConversationsClient(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getLtClientsV2,
      getMessageConversations,
      getAccessToken,
      incrementUnreadMessageCount,
      setConversationsClient,
    },
    dispatch,
  ),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: BCDashbo... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(BCDashboard)
