import * as mixpanelTracker from '../../../mixpanel/mixpanelTracking'
import {
  CLEAR_LT_CLIENTS,
  CLEAR_MESSAGES,
  REFRESH_API_TOKEN,
  REGISTER_SUCCESSFUL,
  REPLACE_USER,
  RESET_VIDEO_SESSION_INFO,
  SAVE_LAST_AUTHORIZED_ROUTE,
} from '../common/constants/reduxConstants'

export const saveLastAuthorizedRoute = (location: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SAVE_LAST_AUTHORIZED_ROUTE,
      payload: `${location.pathname}${location.search}`,
    })
  }
}

export const registerSuccessful = () => {
  mixpanelTracker.track({ event: 'REGISTER' })
  return {
    type: REGISTER_SUCCESSFUL,
  }
}

export const refreshApiToken = (token: any) => {
  return {
    type: REFRESH_API_TOKEN,
    payload: token,
  }
}

export const supervisorSelectProvider = (user: any) => (dispatch: any) => {
  dispatch({ type: REPLACE_USER, user })
  // Clear Actions
  dispatch({ type: CLEAR_LT_CLIENTS })
  dispatch({ type: CLEAR_MESSAGES })
  dispatch({ type: RESET_VIDEO_SESSION_INFO })
}
