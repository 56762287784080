import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import styled from 'styled-components/native'

import { getStartOfNextQuarterByWeeks, useFlags, utilizationQuarters } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  colors,
  DashboardUpdateInfo,
  DropdownButton,
  ExternalLinkIcon,
  InlineInfoBanner,
  LoadingIndicator,
  LoadingSkeleton,
  Subhead,
  SubheadSize,
  TextButton,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import CapacityHeader from './CapacityHeader'
import DetailedWeeklyViewContainer from './DetailedWeeklyViewContainer'
import {
  useAppointmentUtilizationData,
  useHistoricUtilizationData,
  useTargetUtilizationData,
  useUtilizationChargesData,
  useUtilizationQuartersData,
} from './hooks'
import QuarterlyProgressChart from './QuarterlyProgressChart'
import QuarterlySnapshot from './QuarterlySnapshot'
import WeeklySnapshot from './WeeklySnapshot'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import AvailabilityCard from '../../common/components/availabilityCard/AvailabilityCard'
import { CapacityAlertPopUp } from '../../common/components/capacityAlertPopUp/CapacityAlertPopUp'
import { BCT_ROLES } from '../../common/constants/appConstants'
import { getDropdownItemsFromQuarters, getPreviousQuartersSorted, hasRole } from '../../common/utils/utils'
import { getAuthConfig, getAuthEmploymentStatus, getAuthRoles, getAuthUserId } from '../../data/auth/authSelectors'
import { getRequestedCaseloadManagementQuarter } from '../../data/lyraTherapy/clientSelectors'
import { useAppDispatch } from '../../data/store'
import {
  CalendarAvailabilitiesAlertPopUpValues,
  useCalendarAvailabilitiesAlertPopUp,
} from '../../hooks/useCalendarAvailabilitiesAlertPopUp'
import { getProviderCapacity } from '../../providers/data/providersDataActions'
import {
  setCurrentQuarterCMD,
  setCurrentQuarterCMDDetailedWeeklyView,
} from '../clients/clientDetails/data/appointmentsSlice'

const Heading = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['32px'],
  paddingBottom: theme.spacing['32px'],
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 1,
}))

const AvailabilityAndCapacityContainer = styled.View({
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: '98px',
  padding: '24px',
  shadowColor: '#000',
  shadowOffset: {
    width: '0px',
    height: '2px',
  },
  shadowOpacity: '0.1',
  shadowRadius: '10px',
  width: '100%',
})

const HeadingTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['24px'],
}))

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: `${theme.spacing['16px']} ${theme.spacing['32px']}`,
}))

const SnapshotSection = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  flexDirection: theme.breakpoints.isMinWidthDesktop ? 'row' : 'column',
  justifyContent: 'space-between',
}))

const DataBoardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  backgroundColor: colors.white,
  padding: `${theme.spacing['32px']} ${theme.spacing['32px']}`,
  borderRadius: '16px',
  border: `1px solid ${colors.ui_oatmeal3}`,
}))

const WeeklySnapshotContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthDesktop && { width: '50%', paddingRight: theme.spacing['12px'] }),
}))

const QuarterlySnapshotContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthDesktop && { width: '50%', paddingLeft: theme.spacing['12px'] }),
}))

const DropdownContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['16px'],
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
}))

const DropwdownLabel = styled.View({
  justifyContent: 'center',
  marginRight: '8px',
})

const FlexRowContainer = styled.View({
  flexDirection: 'row',
})

const CaseloadHeadingLearnmoreContainer = styled.View({
  flexDirection: 'row',
  gap: '8px',
})

export const ProviderPerformance: React.FC<ConnectedProps<typeof connector>> = ({ getProviderCapacity }) => {
  const dispatch = useAppDispatch()
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const config = useSelector(getAuthConfig)
  const showMinifiedUtilization = config?.showMinifiedUtilization ?? false
  const userId = useSelector(getAuthUserId)
  const userRoles = useSelector(getAuthRoles)
  const { formatMessage } = useIntl()
  const [selectedQuarter, setSelectedQuarter] = useState<string | null>(null)
  const isLCT = hasRole(userRoles, BCT_ROLES)
  const {
    shouldShowPastQuartersInBCXDashboards,
    showCalendarAvailabilitiesWithCapacity,
    showDetailedWeeklyViewForCMD,
  } = useFlags()

  const { data: historicUtilization, isLoading: isLoadingHistoricData } = useHistoricUtilizationData(undefined, {
    skip: !showMinifiedUtilization,
  })
  const {
    data: appointmentUtilization,
    isLoading: isLoadingAppointmentData,
    isFetching: isFetchingAppointmentData,
  } = useAppointmentUtilizationData()
  const {
    data: targetUtilization,
    isLoading: isLoadingTargetData,
    isFetching: isFetchingTargetData,
  } = useTargetUtilizationData()
  const {
    data: utilizationQuarters,
    isLoading: isLoadingQuartersData,
    isFetching: isFetchingQuartersData,
  } = useUtilizationQuartersData()
  const {
    data: utilizationCharges,
    isLoading: isLoadingChargesData,
    isFetching: isFetchingChargesData,
  } = useUtilizationChargesData()
  const isLoadingUtilizationData =
    isLoadingHistoricData ||
    isLoadingAppointmentData ||
    isLoadingTargetData ||
    isLoadingQuartersData ||
    isLoadingChargesData

  const isLoadingWeeklySnapshot = useMemo(
    () =>
      (isFetchingAppointmentData && !isLoadingAppointmentData) ||
      (isFetchingTargetData && !isLoadingTargetData) ||
      (isFetchingQuartersData && !isLoadingQuartersData) ||
      (isFetchingChargesData && !isLoadingChargesData),
    [
      isFetchingAppointmentData,
      isFetchingChargesData,
      isFetchingQuartersData,
      isFetchingTargetData,
      isLoadingAppointmentData,
      isLoadingChargesData,
      isLoadingQuartersData,
      isLoadingTargetData,
    ],
  )
  const quarterPaidTimeOff =
    historicUtilization &&
    historicUtilization.reduce(
      (totalPTO, week) =>
        week.providerPaidTimeOffHours ? totalPTO + parseInt(week.providerPaidTimeOffHours) : totalPTO,
      0,
    )

  const [loadingCapacity] = useFetcher([[getProviderCapacity, { id: userId }]], [userId])

  const loadingUtilization = isLoadingUtilizationData || loadingCapacity

  useEffect(() => {
    if (!shouldShowPastQuartersInBCXDashboards || !utilizationQuarters) {
      return
    }
    const sortedQuarters = getPreviousQuartersSorted({
      availableQuarters: utilizationQuarters,
      howManyQuartersToFetch: 2,
    })
    const quarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : sortedQuarters[0]
    setSelectedQuarter(quarterName)
    dispatch(setCurrentQuarterCMDDetailedWeeklyView(sortedQuarters[0]))
  }, [requestedQuarter, shouldShowPastQuartersInBCXDashboards, utilizationQuarters, dispatch])

  const currentQuarter = useMemo(() => {
    if (!shouldShowPastQuartersInBCXDashboards || !utilizationQuarters) {
      return null
    }
    const sortedQuarters = getPreviousQuartersSorted({
      availableQuarters: utilizationQuarters,
      howManyQuartersToFetch: 2,
    })
    return sortedQuarters[0]
  }, [shouldShowPastQuartersInBCXDashboards, utilizationQuarters])

  const {
    alertText,
    handleCloseAlertPopUpModal,
    handleLearnMore,
    handleOpenCalendar,
    hasProperRoleToSeeAlert,
    isAlertPopUpOpen,
  }: CalendarAvailabilitiesAlertPopUpValues = useCalendarAvailabilitiesAlertPopUp()

  const dropdownClickHandler = (quarter: string) => {
    setSelectedQuarter(quarter)
    if (utilizationQuarters) {
      dispatch(
        setCurrentQuarterCMD({
          [quarter]: utilizationQuarters[quarter],
        }),
      )
    }
  }

  const onLearnMoreClicked = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.LEARN_MORE,
    })
    window.open(
      showDetailedWeeklyViewForCMD && isLCT
        ? 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/360047217173-Caseload-Best-Practices'
        : 'https://bct-lyrahealth.zendesk.com/hc/en-us/articles/360042313293-Caseload-Measurement-Based-Care-MBC-Compensation-Criteria',
      '_blank',
    )
  }, [isLCT, showDetailedWeeklyViewForCMD])

  if (loadingUtilization) {
    return <LoadingIndicator size={45} />
  }
  if (!utilizationQuarters || !appointmentUtilization) {
    return null
  }
  let preselectedValue = ''
  if (requestedQuarter) {
    const requestedQuarterName = Object.keys(requestedQuarter)[0]
    const dropdownAppend = requestedQuarterName === currentQuarter ? ' Current' : ''
    preselectedValue = `${requestedQuarterName.replace('_', ' ')}${dropdownAppend}`
  }
  if (showDetailedWeeklyViewForCMD && isLCT) {
    return (
      <>
        <AvailabilityAndCapacityContainer testID={tID('ProviderPerformance-availabilityContainer')}>
          <CaseloadHeadingLearnmoreContainer>
            <Subhead
              style={{ display: 'flex', alignItems: 'center' }}
              size={SubheadSize.LARGE}
              text={
                <FormattedMessage
                  defaultMessage='Caseload'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              }
            />

            <TextButton
              testID={tID('Caseload-learnMoreButton')}
              style={{ alignSelf: 'center' }}
              onPress={onLearnMoreClicked}
              text='Learn more'
              rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.teal4} />}
            />
          </CaseloadHeadingLearnmoreContainer>
          <AvailabilityCard isMinified hideCalendarAvailability />
        </AvailabilityAndCapacityContainer>
        <DetailedWeeklyViewContainer />
      </>
    )
  } else if (showCalendarAvailabilitiesWithCapacity) {
    return (
      <>
        {hasProperRoleToSeeAlert && isAlertPopUpOpen && (
          <CapacityAlertPopUp
            alertText={alertText}
            handleOpenCalendar={handleOpenCalendar}
            handleLearnMore={handleLearnMore}
            onClose={handleCloseAlertPopUpModal}
          />
        )}
        <AvailabilityAndCapacityContainer testID={tID('ProviderPerformance-availabilityContainer')}>
          <Subhead
            style={{ display: 'flex', alignItems: 'center' }}
            size={SubheadSize.LARGE}
            text={
              shouldShowPastQuartersInBCXDashboards ? (
                <FormattedMessage
                  defaultMessage='Caseload'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Caseload Management Dashboard'
                  description='This is a heading that tells the Provider this is about the amount of case work they are taking on for this period of time'
                />
              )
            }
          />
          <AvailabilityCard isMinified />
        </AvailabilityAndCapacityContainer>
        <Container>
          <Heading testID={tID('Caseload-heading')}>
            <HeadingTextContainer testID={tID('Caseload-title')}>
              <DashboardUpdateInfo
                resetDateTime={getStartOfNextQuarterByWeeks(utilizationQuarters)}
                quarterAndYear={selectedQuarter !== currentQuarter ? selectedQuarter : ''}
              />
            </HeadingTextContainer>
            <FlexRowContainer>
              {shouldShowPastQuartersInBCXDashboards && (
                <DropdownContainer>
                  <DropwdownLabel>
                    <BodyText
                      size={BodyTextSize.LARGE}
                      text={formatMessage({
                        defaultMessage: 'View quarter',
                        description: 'a label to tell the user to select a quarter of data to look at',
                      })}
                      color={colors.ui_oatmeal6}
                    />
                  </DropwdownLabel>
                  <DropdownButton
                    dropdownItems={getDropdownItemsFromQuarters(
                      getPreviousQuartersSorted({ availableQuarters: utilizationQuarters, howManyQuartersToFetch: 2 }),
                      dropdownClickHandler,
                    )}
                    dropdownWidth={185}
                    preselectedValue={preselectedValue}
                  />
                </DropdownContainer>
              )}
              <TextButton
                testID={tID('Caseload-learnMoreButton')}
                style={{ alignSelf: 'center' }}
                onPress={onLearnMoreClicked}
                text='Learn more'
                rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.teal4} />}
              />
            </FlexRowContainer>
          </Heading>
          {currentQuarter === selectedQuarter ? (
            <DataBoardContainer>
              <CapacityHeader
                targetUtilization={targetUtilization}
                appointmentUtilization={appointmentUtilization}
                showMinified={showMinifiedUtilization}
              />
            </DataBoardContainer>
          ) : isLoadingWeeklySnapshot ? (
            <LoadingSkeleton width='100%' height={56} />
          ) : currentQuarter !== selectedQuarter ? (
            <InlineInfoBanner
              testId={'ProviderPerformance-inlineInfoBanner'}
              marginBottom='0px'
              text={`You are viewing ${preselectedValue} data. If manual adjustments were made after the quarter they will not be reflected here.`}
            />
          ) : (
            <></>
          )}
          <SnapshotSection>
            <WeeklySnapshotContainer testID={tID('ProviderPerformance-weeklySnapshotContainer')}>
              <DataBoardContainer>
                {isLoadingWeeklySnapshot ? (
                  <LoadingSkeleton width='100%' height={252} />
                ) : (
                  <WeeklySnapshot
                    appointmentUtilization={appointmentUtilization}
                    utilizationCharges={utilizationCharges}
                    isHistorical={currentQuarter !== selectedQuarter}
                  />
                )}
              </DataBoardContainer>
            </WeeklySnapshotContainer>
            <QuarterlySnapshotContainer>
              <DataBoardContainer>
                {isLoadingWeeklySnapshot ? (
                  <LoadingSkeleton width='100%' height={252} />
                ) : (
                  <QuarterlySnapshot
                    targetUtilization={
                      targetUtilization
                        ? quarterPaidTimeOff && quarterPaidTimeOff > 0
                          ? { ...targetUtilization, quarterPaidTimeOff }
                          : targetUtilization
                        : undefined
                    }
                    appointmentUtilization={appointmentUtilization}
                    utilizationCharges={utilizationCharges}
                    employmentStatus={employmentStatus}
                    showMinified={showMinifiedUtilization}
                    userRoles={userRoles}
                    hideWeeklySchedules={currentQuarter !== selectedQuarter}
                  />
                )}
              </DataBoardContainer>
            </QuarterlySnapshotContainer>
          </SnapshotSection>

          {!showMinifiedUtilization && (
            <DataBoardContainer>
              {isLoadingWeeklySnapshot ? (
                <LoadingSkeleton width='100%' height={252} />
              ) : (
                <QuarterlyProgressChart
                  targetUtilization={targetUtilization}
                  appointmentUtilization={appointmentUtilization}
                  utilizationCharges={utilizationCharges}
                />
              )}
            </DataBoardContainer>
          )}
        </Container>
      </>
    )
  }
  return (
    <Container>
      <Heading testID={tID('Caseload-heading')}>
        <HeadingTextContainer testID={tID('Caseload-title')}>
          <Subhead
            size={SubheadSize.LARGE}
            text={formatMessage({
              defaultMessage: 'Caseload',
              description: 'Header for Caseload Tab in Caseload',
            })}
            color={colors.ui_oatmeal6}
          />
          <DashboardUpdateInfo
            resetDateTime={getStartOfNextQuarterByWeeks(utilizationQuarters)}
            quarterAndYear={selectedQuarter !== currentQuarter ? selectedQuarter : ''}
          />
        </HeadingTextContainer>
        <FlexRowContainer>
          {shouldShowPastQuartersInBCXDashboards && (
            <DropdownContainer>
              <DropwdownLabel>
                <BodyText
                  size={BodyTextSize.LARGE}
                  text={formatMessage({
                    defaultMessage: 'View quarter',
                    description: 'a label to tell the user to select a quarter of data to look at',
                  })}
                  color={colors.ui_oatmeal6}
                />
              </DropwdownLabel>
              <DropdownButton
                dropdownItems={getDropdownItemsFromQuarters(
                  getPreviousQuartersSorted({ availableQuarters: utilizationQuarters, howManyQuartersToFetch: 2 }),
                  dropdownClickHandler,
                )}
                dropdownWidth={185}
                preselectedValue={preselectedValue}
              />
            </DropdownContainer>
          )}

          <TextButton
            testID={tID('Caseload-learnMoreButton')}
            style={{ alignSelf: 'center' }}
            onPress={onLearnMoreClicked}
            text='Learn more'
            rightIcon={<ExternalLinkIcon size={24} strokeColor={colors.teal4} />}
          />
        </FlexRowContainer>
      </Heading>
      {currentQuarter === selectedQuarter ? (
        <DataBoardContainer>
          <CapacityHeader
            targetUtilization={targetUtilization}
            appointmentUtilization={appointmentUtilization}
            showMinified={showMinifiedUtilization}
          />
        </DataBoardContainer>
      ) : isLoadingWeeklySnapshot ? (
        <LoadingSkeleton width='100%' height={56} />
      ) : (
        <InlineInfoBanner
          testId={'ProviderPerformance-inlineInfoBanner'}
          marginBottom='0px'
          text={`You are viewing ${preselectedValue} data. If manual adjustments were made after the quarter they will not be reflected here.`}
        />
      )}
      <SnapshotSection>
        <WeeklySnapshotContainer testID={tID('ProviderPerformance-weeklySnapshotContainer')}>
          <DataBoardContainer>
            {isLoadingWeeklySnapshot ? (
              <LoadingSkeleton width='100%' height={252} />
            ) : (
              <WeeklySnapshot
                appointmentUtilization={appointmentUtilization}
                utilizationCharges={utilizationCharges}
                isHistorical={currentQuarter !== selectedQuarter}
              />
            )}
          </DataBoardContainer>
        </WeeklySnapshotContainer>
        <QuarterlySnapshotContainer>
          <DataBoardContainer>
            {isLoadingWeeklySnapshot ? (
              <LoadingSkeleton width='100%' height={252} />
            ) : (
              <QuarterlySnapshot
                targetUtilization={
                  targetUtilization
                    ? quarterPaidTimeOff && quarterPaidTimeOff > 0
                      ? { ...targetUtilization, quarterPaidTimeOff }
                      : targetUtilization
                    : undefined
                }
                appointmentUtilization={appointmentUtilization}
                utilizationCharges={utilizationCharges}
                employmentStatus={employmentStatus}
                showMinified={showMinifiedUtilization}
                userRoles={userRoles}
                hideWeeklySchedules={currentQuarter !== selectedQuarter}
              />
            )}
          </DataBoardContainer>
        </QuarterlySnapshotContainer>
      </SnapshotSection>

      {!showMinifiedUtilization && (
        <DataBoardContainer>
          {isLoadingWeeklySnapshot ? (
            <LoadingSkeleton width='100%' height={252} />
          ) : (
            <QuarterlyProgressChart
              targetUtilization={targetUtilization}
              appointmentUtilization={appointmentUtilization}
              utilizationCharges={utilizationCharges}
            />
          )}
        </DataBoardContainer>
      )}
    </Container>
  )
}

const connector = connect(null, {
  getProviderCapacity,
})
export default connector(ProviderPerformance)
