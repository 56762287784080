import React from 'react'

import { Scores } from '@lyrahealth-inc/shared-app-logic'
import { LyraPopover, TextButton, TextButtonAlign } from '@lyrahealth-inc/ui-core'

import * as assessmentPopovers from './AssessmentPopovers'
import styles from './clientDetails.module.scss'

type LeapsResultsEntryProps = {
  describeScore: (assessmentName: string, score: number) => string
  onViewAnswersClick: (assessmentName: string) => void
  scores: Scores
}

export const LeapsResultsEntry: React.FC<LeapsResultsEntryProps> = ({ scores, onViewAnswersClick, describeScore }) => {
  if (scores.LEAPS === undefined || scores.LEAPS === null) return null

  return (
    <div className={styles.row} data-test-id={`ClientOutcomes-results-leaps}`}>
      <div className={styles['leaps-results-entry']}>
        <h3>
          LEAPS{' '}
          <TextButton
            data-test-id={`ClientOutcomes-viewAnswers-leaps`}
            onClick={() => {
              onViewAnswersClick('LEAPS')
            }}
            customClass={styles['view-answers-button']}
            align={TextButtonAlign.LEFT}
            text='view answers'
          />
        </h3>
        {scores['LEAPS-NW-IMP'] !== undefined && scores['LEAPS-NW-IMP'] !== null && (
          <p>
            {`Percent Non-work Impairment `}
            <LyraPopover
              content={assessmentPopovers.leapsNwImp}
              iconId='leaps-nw-imp-popover'
              customClass={styles['leaps-popover']}
            />
          </p>
        )}
        {scores['LEAPS-PRD-SS'] !== undefined && scores['LEAPS-PRD-SS'] !== null && (
          <p>
            {`Productivity Subscale `}
            <LyraPopover
              content={assessmentPopovers.leapsPrdSS}
              iconId='leaps-prd-ss-popover'
              customClass={styles['leaps-popover']}
            />
          </p>
        )}
      </div>
      <div>
        <h3 data-test-id={`client-leaps-score`}>{scores.LEAPS}</h3>
      </div>
      <div>
        <h3 data-test-id={`client-leaps-score-description`}>{describeScore('LEAPS', scores.LEAPS)}</h3>
        {scores['LEAPS-NW-IMP'] && <p>{`${scores['LEAPS-NW-IMP']}%`}</p>}
        {scores['LEAPS-PRD-SS'] && <p>{scores['LEAPS-PRD-SS']}</p>}
      </div>
    </div>
  )
}
