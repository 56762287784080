import React, { FunctionComponent, useEffect, useState } from 'react'
import { GestureResponderEvent, Pressable, View } from 'react-native'

import { get } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  Assignment,
  AssignmentResponse,
  Contentmetadata,
  InfoPopoverPlacement,
  InfoPopoverTriggerAction,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { MenuDotsIcon } from '../../atoms/icons/MenuDotsIcon'
import { ExerciseIcon } from '../../atoms/illustrations/ExerciseIcon'
import { InfoPopover } from '../../atoms/infoPopover/InfoPopover'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { StyledMarkdown } from '../../atoms/styledMarkdown/StyledMarkdown'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { ProviderUIMetadataComponent } from '../../formMetadata/providerUIMetaDataComponent/ProviderUIMetadataComponent'
import { type CommentType } from '../../molecules/exerciseProviderComment/ExerciseProviderComment'
import { type Column, ExerciseResponseTable } from '../../molecules/exerciseResponseTable/ExerciseResponseTable'
import { ReportList } from '../../molecules/reportList/ReportList'
import { ItemAction } from '../../organisms/assignmentsList/AssignmentsList'
import { ExerciseResponseNavigator } from '../../organisms/exerciseResponseNavigator/ExerciseResponseNavigator'
import { RowView } from '../../templates'
import { ThemeType, tID } from '../../utils'

export type ExerciseResponsePageProps = {
  content: Assignment
  data: AssignmentResponse[]
  fetchComment: ({ messageId }: { messageId: string }) => Promise<CommentType>
  itemActions: ItemAction[]
  receiverName: string
  responseIdFromNavigation: string | undefined
  sessionCount: number
  submitComment: (inputValue: string, response: AssignmentResponse) => Promise<AssignmentResponse>
  supervisorMode: boolean
  track: ({
    event,
    action,
    details,
    page,
    properties,
  }: {
    event: string
    action?: string
    details?: string | { type: string; title: string }
    page?: string
    properties?: Dict
  }) => void
  updateResponse: (data: Partial<AssignmentResponse>) => void
}

type ExerciseReport = {
  report: {
    customColumns: Column[]
    fixedColumns: Column[]
  }
}

const ExerciseResponsePageContainer = styled.View({
  flexShrink: 1,
})

const TitleContainer = styled(RowView)({
  alignItems: 'center',
})

const ExerciseTitle = styled(Subhead)({
  marginLeft: '10px',
})

const RightActionOption = styled(PressableOpacity)({
  padding: '10px',
  width: '100%',
})

const RightActionOptionText = styled(BodyText)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  paddingLeft: spacing['16px'],
}))

const TriggerContainer = styled(Pressable)<{ theme: ThemeType }>(({ theme }) => {
  return {
    borderRadius: '50%',
    padding: theme.spacing['12px'],
    display: 'flex',
    position: 'absolute',
    right: '-12px',
  }
})

const InstructionsContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['20px'],
  marginBottom: spacing['20px'],
}))

export const ExerciseResponsePage: FunctionComponent<ExerciseResponsePageProps> = ({
  content,
  data,
  fetchComment,
  itemActions,
  receiverName,
  responseIdFromNavigation,
  sessionCount,
  submitComment,
  supervisorMode,
  track,
  updateResponse,
}) => {
  const { colors } = useTheme()
  const componentColors = colors.components.assignmentsListItem
  const { rightActionHover, rightActionPressed } = componentColors

  const metaData: Contentmetadata = content.content_meta_data

  const name = content.name || get(content, 'content.name') || ''
  const title = content.title || get(content, 'content.title')
  const instructions = content.instructions || get(content, 'content.instructions') || ''

  const [responseIdx, setResponseIdx] = useState<number>(0)
  const [showNavigator, setShowNavigator] = useState<boolean>(false)

  // A reponseId can be added to the navigation state if the Provider clicks on a chat message that
  // contains their comment about an Exercise. This check will open the modal at the response from
  // the message.
  useEffect(() => {
    if (responseIdFromNavigation) {
      const responseIdx = data.findIndex((response) => response.id === responseIdFromNavigation)
      if (responseIdx === -1) {
        return
      } else {
        setResponseIdx(responseIdx)
        setShowNavigator(true)
      }
    }
  }, [data, responseIdFromNavigation])

  const isTypeCheckIn = (name: string): boolean => {
    return name.toLowerCase().includes('checkin')
  }

  const uiMetaData = (name: string): ExerciseReport => {
    return isTypeCheckIn(name) ? ProviderUIMetadataComponent.checkIn : ProviderUIMetadataComponent.baseSleepDiary
  }

  const getReportComponent = (name: string) => {
    if (isTypeCheckIn(name) || name === 'baseSleepDiary') {
      return (
        <ExerciseResponseTable
          data={data}
          name={name}
          metaData={metaData}
          rowClickHandler={rowClickHandler}
          UIMetadata={uiMetaData(name)}
        />
      )
    }
    return <ReportList data={data} rowClickHandler={rowClickHandler} metaData={metaData} />
  }

  const rowClickHandler = (responseData: AssignmentResponse) => {
    const responseIdx = data.findIndex((response) => response.id === responseData.id)
    if (responseIdx === -1) {
      return
    } else {
      setResponseIdx(responseIdx)
      setShowNavigator(true)
    }
  }

  return (
    <ExerciseResponsePageContainer testID={tID('ExerciseResponsePage')}>
      <TitleContainer>
        <ExerciseIcon fillColor={colors.iconDefault} size={24} />
        <ExerciseTitle size={SubheadSize.MEDIUM} text={title} />

        <InfoPopover
          content={
            <View>
              {itemActions.map((action: ItemAction) => (
                <RightActionOption
                  testID={tID(`ExerciseResponsePage-dropdownAction-${action.name}`)}
                  key={action.name}
                  onPress={(event: GestureResponderEvent) => {
                    action.selectHandler(event, action.name, content, sessionCount)
                  }}
                  hoveredBackgroundColor={rightActionHover.background}
                  pressedBackgroundColor={rightActionPressed.background}
                  focusable={false}
                >
                  <RightActionOptionText
                    testID={tID(`menuItemText-${action.name}`)}
                    text={action.text}
                    size={BodyTextSize.DEFAULT}
                    color={colors.textSecondary}
                    textAlign={'left'}
                  />
                </RightActionOption>
              ))}
            </View>
          }
          offset={0}
          placement={InfoPopoverPlacement.BOTTOM_RIGHT}
          shouldDisplayCloseButton={false}
          trigger={
            <TriggerContainer testID={tID(`ExerciseResponsePage-dropdownTrigger`)}>
              <MenuDotsIcon fillColor={colors.iconDefault} />
            </TriggerContainer>
          }
          popoverTriggerAction={InfoPopoverTriggerAction.PRESS}
          closeIconColor={colors.iconDefault}
          popoverStyle={{
            container: {
              borderRadius: '12px',
              width: '180px',
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          allowContentUnderCloseButton
          clickToClose={false}
        />
      </TitleContainer>

      <InstructionsContainer>
        <StyledMarkdown content={instructions} />
      </InstructionsContainer>

      {getReportComponent(name)}

      {showNavigator && (
        <ExerciseResponseNavigator
          clientName={receiverName}
          content={content}
          fetchComment={fetchComment}
          initialResponseIndex={responseIdx}
          onRequestClose={() => setShowNavigator(false)}
          submitHandler={submitComment}
          supervisorMode={supervisorMode}
          track={track}
          updateResponse={updateResponse}
        />
      )}
    </ExerciseResponsePageContainer>
  )
}
