import { useEffect, useRef } from 'react'

import { useProviderCapacityAvailability } from '../../../hooks/useProviderCapacityAvailability'
import { logToSumoLogic } from '../../utils/utils'

type Params = {
  providerId?: string
}

export const useAvailabilitiesLogging = ({ providerId }: Params) => {
  const { data: providerAvailability } = useProviderCapacityAvailability()
  const providerAvailabilities = providerAvailability?.provider_availability?.[0]?.availability
  // useRef is not necessary in the depency array for the useEffect
  const loggedSumo = useRef(false)

  useEffect(() => {
    if (!loggedSumo.current && providerAvailabilities) {
      logToSumoLogic('providerCalendarAvailableSlots', providerId, {
        action: 'Provider loaded client list',
        providerAvailabilitiesCount: providerAvailabilities.length,
        providerId: providerId,
      })
      loggedSumo.current = true
    }
  }, [providerAvailabilities, providerId])
}
