import {
  ChatClient,
  ChatMessage,
  ChatPrivilege,
  CommandChannel,
  ConnectionState,
  LiveStreamClient,
  LiveStreamStatus,
  LiveTranscriptionClient,
  LoggerClient,
  Participant,
  RecordingClient,
  RecordingStatus,
  SubsessionClient,
  SubsessionStatus,
  SubsessionUserStatus,
  VideoClient,
} from '@zoom/videosdk'

import { MockMediaStream } from './MockMediaStream'
import { EmptyPromise, IVideoClient } from './utils'
import { ZoomCommand } from '../types'

const MockParticipant: Participant = {
  userId: 0,
  displayName: '',
  audio: '',
  isHost: false,
  isManager: false,
  bVideoOn: false,
  sharerOn: false,
  sharerPause: false,
  isAllowIndividualRecording: false,
  isVideoConnect: false,
}

const createMockParticipant: (partial: Partial<Participant>) => Participant = (partial) => ({
  ...MockParticipant,
  ...partial,
})

const MockChatMessage: ChatMessage = {
  sender: {
    name: '',
    userId: 0,
    avatar: undefined,
  },
  receiver: {
    name: '',
    userId: 0,
  },
  timestamp: 0,
  message: '',
}

const MockChatClient: typeof ChatClient = {
  send: () => Promise.resolve(MockChatMessage),
  sendToAll: () => Promise.resolve(MockChatMessage),
  setPrivilege: () => EmptyPromise,
  getPrivilege: () => ChatPrivilege.All,
  getHistory: () => [MockChatMessage],
  getReceivers: () => [],
  sendFile: () => Promise.resolve(() => {}),
  downloadFile: () => Promise.resolve(() => {}),
  isFileTransferEnabled: () => false,
  getFileTransferSetting: () => ({
    typeLimit: '',
    sizeLimit: 0,
  }),
}

const MockCommandClient: typeof CommandChannel = {
  send: () =>
    Promise.resolve({
      senderId: 0,
      senderName: '',
      receiverId: 0,
      text: '',
      timestamp: 0,
      msgid: '',
    }),
}

const MockRecordingClient: typeof RecordingClient = {
  startCloudRecording: () => EmptyPromise,
  stopCloudRecording: () => EmptyPromise,
  pauseCloudRecording: () => EmptyPromise,
  resumeCloudRecording: () => EmptyPromise,
  getCloudRecordingStatus: () => RecordingStatus.Accept,
  canStartRecording: () => false,
  acceptIndividualRecording: () => EmptyPromise,
  declineIndividualRecording: () => EmptyPromise,
}

const MockSubsessionClient: typeof SubsessionClient = {
  createSubsessions: () => Promise.resolve([]),
  openSubsessions: () => EmptyPromise,
  joinSubsession: () => EmptyPromise,
  leaveSubsession: () => EmptyPromise,
  askForHelp: () => EmptyPromise,
  postponeHelping: () => EmptyPromise,
  broadcast: () => EmptyPromise,
  assignUserToSubsession: () => EmptyPromise,
  moveUserToSubsession: () => EmptyPromise,
  closeAllSubsessions: () => EmptyPromise,
  getUnassignedUserList: () => [],
  getSubsessionList: () => [],
  getUserStatus: () => SubsessionUserStatus.MainSession,
  getSubsessionStatus: () => SubsessionStatus.NotStarted,
  getCurrentSubsession: () => ({
    userStatus: SubsessionUserStatus.MainSession,
    subsessionName: '',
    subsessionId: '',
  }),
  getSubsessionOptions: () => ({
    isAutoJoinSubsession: false,
    isBackToMainSessionEnabled: false,
    isTimerEnabled: false,
    timerDuration: 0,
    isTimerAutoEnabled: false,
    waitSeconds: 0,
    isAutoMoveBackToMainSession: false,
    isSubsessionSelectionEnabled: false,
  }),
  moveBackToMainSession: () => EmptyPromise,
  startBroadcastVoice: () => EmptyPromise,
  stopBroadcastVoice: () => EmptyPromise,
}

const MockLiveTranscriptionClient: typeof LiveTranscriptionClient = {
  startLiveTranscription: () => EmptyPromise,
  setSpeakingLanguage: () => EmptyPromise,
  setTranslationLanguage: () => EmptyPromise,
  disableCaptions: () => EmptyPromise,
  getLiveTranscriptionStatus: () => ({
    isLiveTranscriptionEnabled: false,
    isLiveTranslationEnabled: false,
    isManualCaptionerEnabled: false,
    transcriptionLanguage: '',
    translationLanguage: [],
    isHostDisableCaptions: false,
  }),
  getCurrentTranscriptionLanguage: () => null,
  getCurrentTranslationLanguage: () => null,
  getLatestTranscription: () => '',
  getLatestTranslation: () => '',
  getFullTranscriptionHistory: () => [],
}

const MockLoggerClient: typeof LoggerClient = {
  reportToGlobalTracing: () => Promise.resolve(),
  reportRating: () => Promise.resolve(),
}

const MockLiveStreamClient: typeof LiveStreamClient = {
  startLiveStream: () => EmptyPromise,
  stopLiveStream: () => EmptyPromise,
  isLiveStreamEnabled: () => false,
  getLiveStreamStatus: () => LiveStreamStatus.Connecting,
}
type VideoClientType = typeof VideoClient

class BaseVideoClient implements Partial<VideoClientType> {
  init() {
    return EmptyPromise
  }

  join() {
    return EmptyPromise
  }

  leave() {
    return EmptyPromise
  }

  changeName() {
    return EmptyPromise
  }

  removeUser() {
    return EmptyPromise
  }

  makeHost() {
    return EmptyPromise
  }

  makeManager() {
    return EmptyPromise
  }

  revokeManager() {
    return EmptyPromise
  }

  reclaimHost() {
    return EmptyPromise
  }

  getChatClient() {
    return MockChatClient
  }

  getCommandClient() {
    return MockCommandClient
  }

  getRecordingClient() {
    return MockRecordingClient
  }

  getSubsessionClient() {
    return MockSubsessionClient
  }

  getLiveTranscriptionClient() {
    return MockLiveTranscriptionClient
  }

  getLoggerClient() {
    return MockLoggerClient
  }

  getLiveStreamClient() {
    return MockLiveStreamClient
  }

  getSessionInfo = () => ({
    topic: '',
    password: '',
    userName: '',
    userId: 0,
    isInMeeting: false,
    sessionId: '',
  })

  isHost() {
    return true
  }

  getSessionHost() {
    return MockParticipant
  }

  isManager() {
    return true
  }

  isOriginalHost() {
    return true
  }
}
type EventCallback = (...args: any[]) => void
interface EventListeners {
  [event: string]: EventCallback[]
}

class MockVideoClient extends BaseVideoClient implements VideoClientType, IVideoClient {
  private listeners: EventListeners = {}

  private remoteParticipants: Map<number, Participant> = new Map()

  private mediaStream: MockMediaStream = new MockMediaStream(this)

  inSession = false

  throwPermissionsErrors = false

  getMediaStream() {
    return this.mediaStream
  }

  join() {
    this.inSession = true
    return new Promise<''>((resolve) => {
      setTimeout(() => {
        this.emit('connection-change', {
          state: ConnectionState.Connected,
        })
        resolve('')
      }, 1000)
    })
  }

  leave() {
    this.inSession = false
    this.emit('connection-change', {
      state: ConnectionState.Closed,
    })
    return EmptyPromise
  }

  on(event: string, callback: EventCallback) {
    if (!this.listeners[event]) {
      this.listeners[event] = []
    }
    this.listeners[event].push(callback)
  }

  off(event: string, callback: EventCallback) {
    if (!this.listeners[event]) return
    this.listeners[event] = this.listeners[event].filter((listener) => listener !== callback)
  }

  getCurrentUserInfo() {
    return createMockParticipant({
      userId: -1,
      userIdentity: 'self',
      muted: this.mediaStream.isAudioMuted(),
      audio: this.mediaStream.getIsAudioConnected() ? 'computer' : '',
    })
  }

  getAllUser() {
    return Array.from(this.remoteParticipants.values())
  }

  public getUser(userId: number): Participant | undefined {
    return this.remoteParticipants.get(userId)
  }

  public emit(event: string, ...args: any[]) {
    if (!this.listeners[event]) return
    this.listeners[event].forEach((listener) => listener(...args))
  }

  public shouldThrowPermissionsErrors(): boolean {
    return this.throwPermissionsErrors
  }

  public setThrowPermissionsErrors(throwPermissionsErrors: boolean) {
    this.throwPermissionsErrors = throwPermissionsErrors
  }

  public addParticipant(partialParticipant: Partial<Participant>) {
    const participant = createMockParticipant(partialParticipant)
    this.remoteParticipants.set(participant.userId, createMockParticipant(partialParticipant))

    this.emit('user-added', {})
  }

  public updateParticipant(partialParticipant: Pick<Participant, 'userId'> & Partial<Participant>) {
    const currentParticipant = this.remoteParticipants.get(partialParticipant.userId)
    if (!currentParticipant) {
      return
    }
    this.remoteParticipants.set(currentParticipant.userId, { ...currentParticipant, ...partialParticipant })
    this.emit('user-updated', {})
  }

  public removeParticipant(userId: number) {
    this.remoteParticipants.delete(userId)
    this.emit('user-removed', {})
  }

  public startSession(): void {
    this.emit('command-channel-message', {
      text: ZoomCommand.START_SESSION,
    })
  }

  public endSession(): void {
    this.emit('connection-change', {
      state: ConnectionState.Closed,
      reason: 'ended by host',
    })
  }

  public emitNetworkQualityEvent(level: number): void {
    this.emit('network-quality-change', {
      userId: this.getCurrentUserInfo().userId,
      type: 'uplink',
      level,
    })
  }
}

const videoClient = new MockVideoClient()

declare global {
  interface Window {
    zoomClientMock: MockVideoClient
  }
}
if (window.Cypress) {
  window.zoomClientMock = videoClient
}

export default videoClient
