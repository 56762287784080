import { Camera, CameraView } from 'expo-camera'

import { Stream } from './ZoomVideo'
import { Strength } from '../../atoms/connectionIndicator/ConnectionIndicator'
import { ToastContent } from '../../ui-models'

export type Camera = {
  deviceId: string
  deviceName: string
}

export type JoinSessionParams = {
  token: string
  userName: string
  sessionName: string
  sessionPassword?: string
}

export type Participant = {
  userId: string | number
  isVideoOn: boolean
  isMuted: boolean
  joinTime: Date
  userIdentity: string | undefined
}

export type ZoomConfig = {
  canScreenShare: boolean
  showMessenger: boolean
  canStartSession: boolean
  showRemoteParticipantStatus: boolean
  defaultDisplayMode: DisplayMode
  canChangeDisplayMode: boolean
  shouldEndSessionOnLeave: boolean
  shouldJoinOnOpen: boolean
  shouldNotifyOnParticipantReady: boolean
  shouldStopSessionOnParticipantLeave: boolean
}
export type ToastParams = ToastContent
export type AddToastFunction = (params: ToastParams) => void

export enum VideoCallEffect {
  NONE = 'none',
  BLUR = 'blur',
  OFFICE = 'office',
  HILL_LANDSCAPE = 'hill_landscape',
}

export type DeviceSettings = {
  videoInput?: string
  audioInput?: string
  audioOutput?: string
  videoEffect?: VideoCallEffect
}

export enum RemoteParticipantStatus {
  WAITING = 'waiting',
  READY = 'ready',
  LEFT = 'left',
}

export enum ZoomCommand {
  START_SESSION = 'start_session',
  END_SESSION = 'end_session',
}

export enum DisplayMode {
  BANNER = 'banner',
  FULL_SCREEN = 'full_screen',
}

export enum AudioType {
  None = 'ZoomVideoSDKAudioType_None',
  VOIP = 'ZoomVideoSDKAudioType_VOIP',
  Telephony = 'ZoomVideoSDKAudioType_Telephony',
  Unknown = 'ZoomVideoSDKAudioType_Unknow',
}

export type LeaveSessionParams = {
  end?: boolean
  rejoining?: boolean
}

export enum ZoomNetworkQuality {
  NONE = 'none',
  BAD = 'bad',
  NORMAL = 'normal',
  GOOD = 'good',
}

export const NETWORK_QUALITY_MAP: { [key: number]: ZoomNetworkQuality } = {
  0: ZoomNetworkQuality.BAD,
  1: ZoomNetworkQuality.BAD,
  2: ZoomNetworkQuality.NORMAL,
  3: ZoomNetworkQuality.GOOD,
  4: ZoomNetworkQuality.GOOD,
  5: ZoomNetworkQuality.GOOD,
}

export const QUALITY_STRENGTH_MAP: { [key in ZoomNetworkQuality]: Strength | null } = {
  [ZoomNetworkQuality.NONE]: null,
  [ZoomNetworkQuality.BAD]: Strength.LOW,
  [ZoomNetworkQuality.NORMAL]: Strength.MEDIUM,
  [ZoomNetworkQuality.GOOD]: null,
}

export type ZoomPlatformSpecific = {
  muted: boolean
  toggleMute: () => void
  videoOff: boolean
  toggleVideo: () => void
  cameraList: Camera[]
  userId: string | number | undefined
  joinSession: (params: JoinSessionParams) => Promise<any>
  leaveSession: (params?: LeaveSessionParams) => void
  remoteParticipants: Participant[]
  inSession: boolean
  setSelfElement: (containerEl: HTMLElement | null) => void
  setParticipantElement: (containerEl: HTMLElement | null) => void
  setPreviewVideoElement: (videoEl: HTMLVideoElement | null) => void
  setPreviewCanvasElement: (canvasEl: HTMLCanvasElement | null) => void
  isPreviewVBMode: boolean
  sharingUserId: string | number | null
  startScreenshare: () => void
  stopScreenshare: () => void
  setScreenshareElement: (videoEl: HTMLVideoElement | HTMLCanvasElement | null) => void
  setVideoEffect: (effect: VideoCallEffect) => void
  videoEffect: VideoCallEffect
  startSession: () => void
  sessionStarted: boolean
  setSessionStarted: (sessionStarted: boolean) => void
  startAudio: () => void
  updateSettings: (settings: DeviceSettings) => void
  settings: DeviceSettings
  setParticipantScreenshareElement: (canvasEl: HTMLCanvasElement | null) => void
  stream: typeof Stream | null
  setCameraRef: (cameraRef: CameraView | null) => void
  hasCameraAccess?: boolean
  joinTime: Date | null
  userIdentity: string | undefined
  supportsVirtualBackground: boolean
  showPermissionsModal: boolean
  closePermissionsModal: () => void
}

export type ZoomPlatformSpecificParams = {
  sessionEndedCallback?: () => void
  sessionClosedCallback?: () => void
  addToast?: AddToastFunction
  attached: boolean
  setNetworkQuality: (quality: ZoomNetworkQuality) => void
}

export type ZoomPlatformSpecificFunction = (params: ZoomPlatformSpecificParams) => ZoomPlatformSpecific

export type Zoom = ZoomPlatformSpecific & {
  config: ZoomConfig
  isScreensharing: boolean
  remoteParticipantStatus: RemoteParticipantStatus
  remoteParticipant: Participant | null
  displayMode: DisplayMode
  setDisplayMode: (displayMode: DisplayMode) => void
  setSessionEndedCallback: (callback?: () => void) => void
  setSessionClosedCallback: (callback?: () => void) => void
  remoteParticipantScreenSharing: boolean
  setAddToast: (callback?: AddToastFunction) => void
  setAttached: (attached: boolean) => void
  networkQuality: ZoomNetworkQuality
}
