import { useDispatch } from 'react-redux'

import { AnyAction, applyMiddleware, compose, createStore, Dispatch } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import { createLogger } from 'redux-logger'
import { autoRehydrate, persistStore } from 'redux-persist'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { encryptTransform } from 'redux-persist-transform-encrypt'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import promise from 'redux-promise-middleware'

import autoThunkMiddleware from '@lyrahealth-inc/auto-thunk'

import { baseApi } from './baseApi'
import reducers from './reducers'
import { track } from '../../../mixpanel/mixpanelTracking'
import { actionAlertHandler, actionStyles } from '../common/constants/reduxConstants'
import { axiosAuthInstance } from '../common/http/axiosInstance'
import { logToSumoLogic } from '../common/utils/utils'

const encryptor = encryptTransform({
  secretKey: 'lhpp-enc',
})

const autoThunk = autoThunkMiddleware({
  httpClient: axiosAuthInstance,
  errorHandler: (error: any, dispatch: any, action: any) =>
    actionAlertHandler({ actionStyle: actionStyles.ERROR, message: error, action, dispatch }),
  track,
  log: logToSumoLogic,
})

const initialState = {}

const isProduction = process.env.NODE_ENV === 'production'
const middlewares = [promise(), autoThunk, baseApi.middleware]

if (!isProduction) {
  const logger = createLogger({
    collapsed: true,
  })
  middlewares.push(logger)
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export type RootState = ReturnType<typeof reducers>
const store = createStore(
  enableBatching<RootState>(reducers),
  initialState,
  composeEnhancers(applyMiddleware(...middlewares), autoRehydrate()),
)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => Dispatch<AnyAction> = () => useDispatch<AppDispatch>()

persistStore(store, {
  whitelist: ['auth', 'healthPlan'],
  transforms: [encryptor],
})

// Export the store to Cypress to give access to it in the tests.
if (window.Cypress) {
  ;(window as any).store = store
}

export default store
