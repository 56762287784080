import { NavigateFunction } from 'react-router'

import Cookies from 'universal-cookie'

import { ProviderUser } from '@lyrahealth-inc/shared-app-logic'

import { AxiosInstance, baseApi } from './baseApi'
import * as mixpanelTracker from '../../../mixpanel/mixpanelTracking'
import { oktaBaseUrl } from '../common/constants/appConstants'
import {
  actionAlertHandler,
  actionStyles,
  LOGIN,
  LOGOUT,
  SAVE_LAST_AUTHORIZED_ROUTE,
} from '../common/constants/reduxConstants'
import { LOGIN as LOGIN_ROUTE } from '../common/constants/routingConstants'

type LoginArgs = {
  queryString: string
  navigate: NavigateFunction
}

type LoginResponse = {
  access_token?: string
  user?: ProviderUser
}

type LogoutArgs = {
  clearLastPage?: boolean
}

const cookies = new Cookies()

const extendedAPI = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginArgs>({
      query: ({ queryString }) => ({
        method: 'get',
        url: `v1/okta/callback${queryString}`,
        withCredentials: true,
        axiosInstanceType: AxiosInstance.GENERIC,
        showAlert: false,
      }),
      async onQueryStarted({ navigate }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data.access_token && data.user) {
            mixpanelTracker.setUser(data.user)
            mixpanelTracker.track({ event: 'LOGIN' })

            dispatch({
              type: LOGIN,
              payload: data,
            })
          } else {
            actionAlertHandler({
              actionStyle: actionStyles.ERROR,
              message: 'There was an error in retrieving an access token. Please try again.',
              dispatch,
            })
          }
        } catch (error) {
          navigate(LOGIN_ROUTE.route)
          actionAlertHandler({
            actionStyle: actionStyles.ERROR,
            message: error.error,
            dispatch,
          })
        }
      },
    }),
    logout: builder.mutation<void, LogoutArgs>({
      query: () => ({
        method: 'delete',
        url: `${oktaBaseUrl}/api/v1/sessions/me`,
        withCredentials: true,
        axiosInstanceType: AxiosInstance.GENERIC,
        showAlert: false,
      }),
      onQueryStarted: ({ clearLastPage = false }, { dispatch, queryFulfilled }) => {
        queryFulfilled.finally(() => {
          cookies.remove('session', { path: '/' })
          sessionStorage.clear()
          if (clearLastPage) {
            dispatch({
              type: SAVE_LAST_AUTHORIZED_ROUTE,
              payload: '',
            })
          }
          mixpanelTracker.track({ event: 'LOGOUT' })
          dispatch({
            type: LOGOUT,
          })
        })
      },
    }),
  }),
})

export const { useLoginMutation, useLogoutMutation } = extendedAPI
export const logoutMutation = extendedAPI.endpoints.logout
