import React, { FunctionComponent } from 'react'

import { LinearGradient } from 'expo-linear-gradient'
import styled, { useTheme } from 'styled-components/native'

type GradientOverlayProps = {
  borderRadius?: number
  gradientColors?: ArrayMinTwo<string>
  gradientLocations?: ArrayMinTwo<number>
  gradientProps?: object
  isDarkModeHeaderOverlay?: boolean
}

const GradientContainer = styled(LinearGradient)<{ borderRadius: number }>(({ borderRadius }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  flexGrow: 1,
  borderRadius,
}))

export const GradientOverlay: FunctionComponent<GradientOverlayProps> = ({
  borderRadius = 0,
  gradientColors,
  gradientLocations,
  gradientProps,
  isDarkModeHeaderOverlay,
}) => {
  const { colors } = useTheme()
  const getDefaultGradientProps = () => {
    if (isDarkModeHeaderOverlay) {
      const firstGradientStartingLocation = 0
      const secondGradientStartingLocation = 1
      return {
        colors: gradientColors
          ? gradientColors
          : ([`${colors.backgroundHeaderPrimary}FF`, `${colors.backgroundHeaderPrimary}FA`] as ArrayMinTwo<string>),
        // locations can't have a negative value
        locations: gradientLocations
          ? gradientLocations
          : ([firstGradientStartingLocation, secondGradientStartingLocation] as ArrayMinTwo<number>),
        ...gradientProps,
      }
    }

    const firstGradientStartingLocation = 0
    const secondGradientStartingLocation = 0.598
    const thirdGradientStartingLocation = 0.6964
    return {
      colors: gradientColors
        ? gradientColors
        : (['rgba(27,26,24,0.52)', 'rgba(0,0,0,0.598)', 'rgba(0,0,0,0.65)'] as ArrayMinTwo<string>),
      // locations can't have a negative value
      locations: gradientLocations
        ? gradientLocations
        : ([
            firstGradientStartingLocation,
            secondGradientStartingLocation,
            thirdGradientStartingLocation,
          ] as ArrayMinTwo<number>),
      ...gradientProps,
    }
  }

  return <GradientContainer borderRadius={borderRadius} {...getDefaultGradientProps()} />
}
