import React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import { useFlags, utilizationQuarters } from '@lyrahealth-inc/shared-app-logic'
import { DetailedWeeklyView, LoadingIndicator } from '@lyrahealth-inc/ui-core-crossplatform'

import { useHistoricUtilizationData, useTargetUtilizationData } from './hooks'
import { getBookableTarget, getWorkingHours } from '../../calendar/utils'
import { BCT_ROLES, employmentTypes } from '../../common/constants/appConstants'
import { hasRole } from '../../common/utils/utils'
import { getAuthEmploymentStatus, getAuthRoles } from '../../data/auth/authSelectors'
import {
  getCurrentQuarterCMDDetailedWeeklyView,
  getRequestedCaseloadManagementQuarter,
} from '../../data/lyraTherapy/clientSelectors'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const DetailedWeeklyViewContainer: React.FunctionComponent = () => {
  const employmentStatus = useSelector(getAuthEmploymentStatus)
  const userRoles = useSelector(getAuthRoles)
  const requestedQuarter: utilizationQuarters | undefined = useSelector(getRequestedCaseloadManagementQuarter)
  const currentQuarter: string | undefined = useSelector(getCurrentQuarterCMDDetailedWeeklyView)
  const { showDetailedWeeklyViewForCMD } = useFlags()
  const isLCT = hasRole(userRoles, BCT_ROLES)

  const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''

  const { data: historicUtilization, isLoading: isLoadingHistoricData } = useHistoricUtilizationData({
    bookableView: isLCT && showDetailedWeeklyViewForCMD,
  })
  const { data: targetUtilization, isLoading: isLoadingTargetData } = useTargetUtilizationData()
  const isLoadingUtilizationData = isLoadingHistoricData || isLoadingTargetData

  // need a one-off exception to not show the week of 12/29/2024 - 01/04/2025
  //  Clean up when Q1 2025 will no longer show up in Detailed Weekly View
  //  https://lyrahealth.atlassian.net/browse/LCDELIVERY-5803
  const updatedHistoricUtilization = historicUtilization?.filter(
    (utilizationWeek) => utilizationWeek.startDate !== '12/29/2024',
  )
  const employmentTypeToExpectedWorkingHours = {
    [employmentTypes.FULL_TIME]: getWorkingHours(employmentTypes.FULL_TIME),
    [employmentTypes.PART_TIME]: getWorkingHours(employmentTypes.PART_TIME),
    [employmentTypes.FULL_TIME_REDUCED]: getWorkingHours(employmentTypes.FULL_TIME_REDUCED),
    [employmentTypes.REDUCED_FULL_TIME]: getWorkingHours(employmentTypes.REDUCED_FULL_TIME),
    [employmentTypes.CLINICAL_QUALITY_CONSULTANTS]: getWorkingHours(employmentTypes.CLINICAL_QUALITY_CONSULTANTS),
    [employmentTypes.CLINICAL_QUALITY_COORDINATOR]: getWorkingHours(employmentTypes.CLINICAL_QUALITY_COORDINATOR),
    [employmentTypes.FELLOW_SUPERVISOR]: getWorkingHours(employmentTypes.FELLOW_SUPERVISOR),
  }
  const employmentTypeToWeeklyExpectedBookable = {
    [employmentTypes.FULL_TIME]: getBookableTarget(employmentTypes.FULL_TIME),
    [employmentTypes.PART_TIME]: getBookableTarget(employmentTypes.PART_TIME),
    [employmentTypes.FULL_TIME_REDUCED]: getBookableTarget(employmentTypes.REDUCED_FULL_TIME),
    [employmentTypes.REDUCED_FULL_TIME]: getBookableTarget(employmentTypes.REDUCED_FULL_TIME),
    [employmentTypes.CLINICAL_QUALITY_CONSULTANTS]: getBookableTarget(employmentTypes.CLINICAL_QUALITY_COORDINATOR),
    [employmentTypes.CLINICAL_QUALITY_COORDINATOR]: getBookableTarget(employmentTypes.CLINICAL_QUALITY_COORDINATOR),
    [employmentTypes.FELLOW_SUPERVISOR]: getBookableTarget(employmentTypes.FELLOW_SUPERVISOR),
  }
  return (
    <Container>
      {isLoadingUtilizationData || !updatedHistoricUtilization ? (
        <LoadingIndicator size={45} />
      ) : (
        <DetailedWeeklyView
          historicUtilization={updatedHistoricUtilization}
          targetUtilization={targetUtilization}
          historicQuarter={requestedQuarterName}
          currentQuarter={currentQuarter}
          employmentStatus={employmentStatus}
          employmentTypeToExpectedWorkingHours={employmentTypeToExpectedWorkingHours}
          employmentTypeToWeeklyExpectedBookable={employmentTypeToWeeklyExpectedBookable}
        />
      )}
    </Container>
  )
}

export default DetailedWeeklyViewContainer
