import { useSelector } from 'react-redux'

import { DAILY_END_TIME, DAILY_START_TIME, SLICE_DURATION_USER_MINUTES } from '@lyrahealth-inc/shared-app-logic'
import { useVisibilityChange } from '@lyrahealth-inc/ui-core-crossplatform'

import { BCC_ROLES } from '../common/constants/appConstants'
import { hasRole } from '../common/utils/utils'
import { getAuthConfig, getAuthProgramTaxonomies, getAuthRoles, getAuthUserId } from '../data/auth/authSelectors'
import { RTKQueryHook, RTKQueryResultType } from '../data/baseApi'
import { useGetProviderAvailabilityQuery } from '../lyraTherapy/clients/clientDetails/data/appointmentsApi'

export const useProviderCapacityAvailability: RTKQueryHook<typeof useGetProviderAvailabilityQuery> = (args, opts) => {
  const userId = useSelector(getAuthUserId)
  const authUserProgramTaxonomies = useSelector(getAuthProgramTaxonomies)
  const roles = useSelector(getAuthRoles)
  const config = useSelector(getAuthConfig)
  const hasProperRolesToSeeCalendarAvailabilities = hasRole(roles, BCC_ROLES)
  const [windowVisible] = useVisibilityChange()
  const shouldSeeNewAvailabilityCard = config?.bcPlatform ?? false
  return useGetProviderAvailabilityQuery(
    {
      providerId: userId,
      daysToFetch: 14,
      params: {
        slice_duration_minutes: SLICE_DURATION_USER_MINUTES,
        daily_start_time: DAILY_START_TIME,
        daily_end_time: DAILY_END_TIME,
        clientele: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.clientele) || '',
        treatment: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.treatment) || '',
        partner: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.partner) || '',
        offering: (authUserProgramTaxonomies?.length && authUserProgramTaxonomies?.[0]?.offering) || '',
        appointment_class: 'initial',
        lead_time_hours: 0,
      },
      ...args,
    },
    {
      skip: !windowVisible || !hasProperRolesToSeeCalendarAvailabilities || !shouldSeeNewAvailabilityCard,
      ...opts,
    },
  ) as RTKQueryResultType<typeof useGetProviderAvailabilityQuery>
}
