import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { startOfYear, subYears } from 'date-fns'
import styled from 'styled-components'

import { LoadingIndicator, PlatformEngagementQuarterlyDetails } from '@lyrahealth-inc/ui-core-crossplatform'

import { useGetPlatformEngagementMetricsQuery } from './data/platformEngagementApi'
import usePEQuarters from './hooks/usePEQuarters'
import useTransformPEMetrics from './hooks/useTransformPEMetrics'
import { actions as mixpanelActions, mixpanelEvents } from '../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { BC_PLATFORM_ENGAGEMENT } from '../../../common/constants/routingConstants'
import { getAuthUserId } from '../../../data/auth/authSelectors'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const PlatformEngagementQuarterlyDetailsContainer: React.FunctionComponent = () => {
  const userId = useSelector(getAuthUserId)
  const navigate = useNavigate()
  const onBackPressed = useCallback(() => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.BACK,
    })
    navigate(BC_PLATFORM_ENGAGEMENT.route)
  }, [navigate])
  const onCurrentYearPressed = useCallback((isExpanded) => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.CURRENT_YEAR,
      details: isExpanded ? 'Open' : 'Close',
    })
  }, [])

  const peQuartersCurrent = usePEQuarters()
  const peQuartersPrevious = usePEQuarters({ previousYear: true })

  const { data: currentYearMetrics, isLoading: isLoadingCurr } = useGetPlatformEngagementMetricsQuery({
    providerId: userId,
    data: peQuartersCurrent,
  })
  const { data: previousYearMetrics, isLoading: isLoadingPrev } = useGetPlatformEngagementMetricsQuery({
    providerId: userId,
    data: peQuartersPrevious,
  })
  const currentMetrics = useTransformPEMetrics(currentYearMetrics)
  const prevMetrics = useTransformPEMetrics(previousYearMetrics)

  const onPreviousYearPressed = useCallback((isExpanded) => {
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: mixpanelActions.PREVIOUS_YEAR,
      details: isExpanded ? 'Open' : 'Close',
    })
  }, [])

  const loadingMetrics = isLoadingCurr || isLoadingPrev
  const fetchedMetrics = currentMetrics && prevMetrics
  return (
    <Container>
      {loadingMetrics && !fetchedMetrics ? (
        <LoadingIndicator topPadding={24} />
      ) : (
        <PlatformEngagementQuarterlyDetails
          currentYear={startOfYear(new Date()).getUTCFullYear()}
          previousYear={subYears(startOfYear(new Date()), 1).getUTCFullYear()}
          currentYearMetrics={currentMetrics}
          previousYearMetrics={prevMetrics}
          onBackPressed={onBackPressed}
          onCurrentYearPressed={onCurrentYearPressed}
          onPreviousYearPressed={onPreviousYearPressed}
        />
      )}
    </Container>
  )
}

export default PlatformEngagementQuarterlyDetailsContainer
