import axios from 'axios'

import { CALENDAR_SERVICE_BASE_URL } from '@lyrahealth-inc/shared-app-logic'

import { refreshApiToken } from '../../data/authActions'
import * as store from '../../data/store'
import { actionAlertHandler, actionStyles } from '../constants/reduxConstants'

// Auth Instance ------
// set up auth instance, that sends token and listens for 401
const authInstance = axios.create({
  baseURL: '/api',
})
const CancelToken = axios.CancelToken

authInstance.interceptors.request.use(function (config) {
  const token = store.default.getState().auth?.apiToken
  if (token && config.headers) {
    config.headers.Authorization = 'JWT ' + token
  }
  if (!config.cancelToken) {
    // allow for preattached cancel tokens, so specific call targeting is possible
    config.cancelToken = new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      ;(authInstance as any).cancelLastRequest = c
    })
  }
  return config
})

authInstance.interceptors.response.use(
  function (response) {
    if (response.headers['fresh-token']) {
      store.default.dispatch(refreshApiToken(response.headers['fresh-token']))
    }

    return response
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      const { logoutMutation } = require('../../data/authApi')
      store.default.dispatch(logoutMutation.initiate({}))
    }

    return Promise.reject(error)
  },
)

export const axiosAuthInstance = authInstance

// Generic Instance --------
// axios instance without any frills
export const axiosGenericInstance = axios.create({ baseURL: '/api' })

// Calendar service INSTANCE --------------------------------

const calendarServiceInstance = axios.create({
  baseURL: CALENDAR_SERVICE_BASE_URL,
})

calendarServiceInstance.interceptors.request.use(function (config) {
  const token = store.default.getState().auth?.apiToken
  if (token && config.headers) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
})

calendarServiceInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // @ts-expect-error TS(2345): Argument of type '(dispatch: any) => Promise<unkno... Remove this comment to see the full error message
      store.default.dispatch(logoutMutation.initiate({}))
    }

    if (error.response && error.response.status === 404) {
      return Promise.reject(error)
    }

    actionAlertHandler({
      actionStyle: actionStyles.ERROR,
      message: error,
      dispatch: store.default.dispatch,
      expires: true,
      action: undefined,
    })

    return Promise.reject(error)
  },
)

export const axiosCalendarServicInstance = calendarServiceInstance
