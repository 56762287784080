import React, { Dispatch, useEffect, useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ComponentPropsGetterRC } from 'react-table'

import { isEmpty } from 'lodash-es'
import { AnyAction, bindActionCreators } from 'redux'

import { ProviderAdminProviderInfo } from '@lyrahealth-inc/shared-app-logic'
import {
  BootstrapContainer,
  CheckIcon,
  ContentLayout,
  DataTable,
  DownloadIcon,
  ExclamationIcon,
  InputField,
  LoadingIndicator,
  PrimaryButton,
  SearchIcon,
} from '@lyrahealth-inc/ui-core'

import styles from './providersDashboard.module.scss'
import { ROLES } from '../../common/constants/appConstants'
import { GET_PROVIDERS_LITE } from '../../common/constants/reduxConstants'
import { PROVIDERS_DETAILS_INFO } from '../../common/constants/routingConstants'
import { hasErrorForAction, hasRole } from '../../common/utils/utils'
import { getAlertsState } from '../../data/alertsSelectors'
import { Alert } from '../../data/alertTypes'
import { getAuthRoles } from '../../data/auth/authSelectors'
import { RootState } from '../../data/store'
import * as providersDataActions from '../data/providersDataActions'
import { getProvidersIsDownloadingCSV, getProvidersList } from '../data/providerSelectors'
import * as providerDetailsActions from '../individualProvider/data/providerDetailsActions'

let tableColumns: Array<{
  Header: string
  accessor: string
  Cell?: any
  minWidth?: number | undefined
  maxWidth?: number | undefined
}> = [
  {
    Header: 'Name',
    accessor: 'full_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    minWidth: 300,
  },
]

const ProvidersDashboard: React.FC<ProviderDashboardProps> = ({
  providersList,
  isDownloadingCSV,
  actions: { getProviders, providerSelected, downloadProviders },
}) => {
  const roles: string[] = useSelector(getAuthRoles)
  const alerts: Alert[] = useSelector(getAlertsState)
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getProviders()
  }, [getProviders])

  const rowClickHandler = (state: RootState, rowInfo: any): void => {
    providerSelected(rowInfo.original)
    navigate(PROVIDERS_DETAILS_INFO.route, { state: { provider_id: rowInfo.original.lyra_id } })
  }

  if (!providersList) {
    if (hasErrorForAction(alerts, GET_PROVIDERS_LITE)) {
      return <div /> // custom workflow or display could render here
    } else {
      return (
        <ContentLayout>
          <BootstrapContainer col='col-md-12'>
            <h2 styleName='title'>Providers</h2>
            <div styleName='card loading-container'>
              <LoadingIndicator size={45} />
            </div>
          </BootstrapContainer>
        </ContentLayout>
      )
    }
  }

  const filteredData = !search
    ? providersList
    : providersList.filter((row: any) => {
        return (
          row.full_name.toLowerCase().includes(search.toLowerCase()) ||
          row.email.toLowerCase().includes(search.toLowerCase())
        )
      })

  if (tableColumns.length === 2 && hasRole(roles, ROLES.PROVIDERS_ADMIN)) {
    tableColumns = tableColumns.concat([
      {
        Header: 'Rates Set',
        accessor: 'rates',
        maxWidth: 130,
        Cell: (row: any) => (
          <span>
            {!isEmpty(row.value) ? (
              <CheckIcon fillColor={styles.x_success} isFilled width={15} />
            ) : (
              <ExclamationIcon isFilled width={15} />
            )}
          </span>
        ),
      },
      {
        Header: 'Calendar Status',
        accessor: 'calendar_state',
      },
    ])
  }

  return (
    <ContentLayout>
      <BootstrapContainer col='col-md-12'>
        <h2 styleName='title'>Providers</h2>
        <div styleName='filter-add-container'>
          <InputField
            input={{
              name: 'external_filter',
              value: search,
              onChange: (e: any) => setSearch(e.target.value),
              onFocus: (e: any) => e && setSearch(e.target.value),
              onBlur: (e: any) => e && setSearch(e.target.value),
            }}
            meta={{ touched: false, error: '', submitError: '', warning: '' }}
            className={styles['filter-input']}
            type='text'
            insetIcon={SearchIcon}
            placeholder='Search by name or email'
            readonly={false}
            disabled={false}
            autocomplete={''}
            label={''}
            maxLength={999}
          />
          {hasRole(roles, ROLES.PAYMENTS_ADMIN) ? (
            <PrimaryButton
              onClick={downloadProviders}
              id='test-providersdashboard-downloadcsv'
              styleType='line'
              className={styles['csv-download']}
              isLoading={isDownloadingCSV}
            >
              <DownloadIcon fillColor='currentColor' />
              CSV
            </PrimaryButton>
          ) : (
            []
          )}
        </div>

        <DataTable
          pagination
          pageSize={20}
          destinationSelector='lyra_id'
          rowClickHandler={rowClickHandler as unknown as ComponentPropsGetterRC}
          className={styles.card}
          // @ts-expect-error TS(2740): Type 'Iterable<number, ProviderAdminProviderInfo>'... Remove this comment to see the full error message
          data={filteredData}
          columns={tableColumns}
        />
      </BootstrapContainer>
    </ContentLayout>
  )
}

type ProviderDashboardProps = {
  actions: {
    downloadProviders: () => void
    getProviders: () => void
    providerSelected: (rowInfo: any) => void
  }
  providersList: ProviderAdminProviderInfo[]
  isDownloadingCSV: boolean
}

const mapStateToProps = (state: RootState): any => {
  return {
    providersList: getProvidersList(state),
    isDownloadingCSV: getProvidersIsDownloadingCSV(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    // @ts-expect-error TS(2769): No overload matches this call.
    actions: bindActionCreators({ ...providerDetailsActions, ...providersDataActions }, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CSSModules(ProvidersDashboard, styles, { allowMultiple: true }))
