import React, { ReactElement, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { isFuture, isPast } from 'date-fns'
import { cloneDeep, get, isEmpty, noop } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import {
  APPOINTMENT_STATUS,
  CalendarCardAppointment,
  ClientObject,
  Episode,
  getDateFromAppointment,
} from '@lyrahealth-inc/shared-app-logic'
import { PeopleIcon, VideoPlayer } from '@lyrahealth-inc/ui-core'
import {
  colors,
  LoadingIndicator,
  Modal,
  PlusSignIcon,
  PrimaryButton,
  SessionCompletedCheck,
  SessionList,
  SessionListCancelIcon,
  SessionListLiveMessageIcon,
  SessionListPhoneIcon,
  tID,
  useFetcher,
  useMediaQuerySize,
  VideoIconWithEllipse,
} from '@lyrahealth-inc/ui-core-crossplatform'

import SessionCancellation from './SessionCancellation'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { cloudfrontOriginUrl, episodeStates } from '../../common/constants/appConstants'
import { CLIENTS_NEW_SESSION, CLIENTS_RESCHEDULE_SESSION } from '../../common/constants/routingConstants'
import { getAuthUserId } from '../../data/auth/authSelectors'
import {
  getClientDetailsData,
  getClientSelectedEpisode,
  getClientVideoSessionAppointments,
} from '../../data/lyraTherapy/clientSelectors'
import { RootState, useAppDispatch } from '../../data/store'
import { clearSelectedAppointment, setAppointment } from '../clients/clientDetails/data/appointmentsSlice'
import { useClientAppointmentsData } from '../clients/clientDetails/data/hooks/useClientAppointmentsData'
import { getVideoSessionRecording, getVideoSessions } from '../data/ltVideoAutoActions'

type Recording = {
  url: string
}

const ScheduleSessionContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const EmptyState = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 80,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 16,
  borderColor: colors.ui_oatmeal3,
  backgroundColor: colors.white,
  fontSize: '16px',
})

const LoadingContainer = styled.div({
  textAlign: 'center',
  margin: '30px 0',
})

const SessionPageWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const SessionContainer = styled.div(
  ({ isMinWidthTablet, isMinWidthLaptop }: { isMinWidthTablet: boolean; isMinWidthLaptop: boolean }) => ({
    width: isMinWidthTablet ? '544px' : '100%',
    marginTop: isMinWidthLaptop ? '32px' : '24px',
  }),
)

/**
 * Displays a list of sessions between the authenticated provider and a given client.
 * (A session is an object consisting of an appointment and some amount of associated curriculum
 * for the client to complete. Session numbering is relative to chronological order of appointments.)
 * Allows the provider to create new appointments as well as both reschedule and cancel
 * existing appointments.
 */
const Sessions: React.FC<SessionsProps> = ({
  clientDetails,
  selectedEpisode,
  actions: { getVideoSessions, getVideoSessionRecording },
}) => {
  const dispatch = useAppDispatch()
  const id = useSelector(getAuthUserId)
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
  const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false)
  const [isLoading, , hasFetched] = useFetcher([[getVideoSessions, { provider_id: id, patient_id: clientDetails?.id }]])
  const videoRef = useRef(null)
  const navigate = useNavigate()
  const videoSessions = useSelector(getClientVideoSessionAppointments)
  const { data: allAppointments, isFetching: isRefreshingSessions } = useClientAppointmentsData()
  const appointments = useMemo(() => {
    if (
      videoSessions == null ||
      videoSessions.length === 0 ||
      allAppointments == null ||
      allAppointments.length === 0
    ) {
      return (allAppointments ?? []) as CalendarCardAppointment[]
    }
    return allAppointments.map<CalendarCardAppointment>((appt) => {
      const videoSession = videoSessions.find(
        (session) => parseInt(session.appointment_id, 10) === parseInt(appt.appointmentId as any, 10),
      )
      if (!videoSession || videoSession.recording_status.indexOf('saved') === -1) return appt
      const apptCopy: CalendarCardAppointment = cloneDeep(appt)
      apptCopy.videoRecording = videoSession.id
      return apptCopy
    })
  }, [allAppointments, videoSessions])

  const goToCreateNewSession = () => {
    track({ event: 'BUTTON_PRESS', action: 'SCHEDULE_NEW_SESSION' })
    dispatch(clearSelectedAppointment())
    navigate(CLIENTS_NEW_SESSION.route)
  }

  /**
   * Handler for when a provider selects an appointment to be cancelled. This triggers a
   * cancellation modal and does not cover the actual appointment cancellation.
   */
  const selectAppointmentToCancel = (appointment: CalendarCardAppointment) => {
    track({ event: 'BUTTON_PRESS', action: 'CANCEL_SESSION' })
    dispatch(setAppointment(appointment))
    setModalContent(
      <SessionCancellation
        clientDetails={clientDetails}
        closeFunc={() => {
          setModalContent(null)
        }}
        appointment={appointment as unknown as CalendarCardAppointment}
      />,
    )
  }

  /**
   * Handler for when a provider selects an appointment to be rescheduled.
   */
  const rescheduleAppointment = (appointment: CalendarCardAppointment) => {
    track({ event: 'BUTTON_PRESS', action: 'RESCHEDULE_SESSION' })
    dispatch(setAppointment(appointment))
    navigate(CLIENTS_RESCHEDULE_SESSION.route)
  }

  const getProviderIcon = (meetingFormat = '', isInPast = false) => {
    let icon
    if (isInPast) {
      return meetingFormat === 'canceled' || meetingFormat === 'cancelled' ? (
        <SessionListCancelIcon />
      ) : (
        <SessionCompletedCheck size={40} />
      )
    }
    switch (meetingFormat) {
      case 'video':
        icon = <VideoIconWithEllipse size={40} backgroundColor={colors.ui_oatmeal2} fillColor={colors.ui_oatmeal5} />
        break
      case 'inPerson':
        icon = <PeopleIcon width={40} />
        break
      case 'live_messaging':
        icon = <SessionListLiveMessageIcon size={40} />
        break
      case 'canceled':
      case 'cancelled':
        icon = <SessionListCancelIcon />
        break
      default:
        icon = <SessionListPhoneIcon size={40} />
    }
    return icon
  }

  const viewSessionRecording = (sessionID: string) => {
    track({ event: 'BUTTON_PRESS', action: 'WATCH_RECORDING' })
    getVideoSessionRecording({ id: sessionID }).then((recording: Recording) => {
      if (recording.url) {
        setIsPlayingVideo(true)
        setModalContent(
          <VideoPlayer
            url={recording?.url}
            reference={videoRef}
            onError={(e: Error) => console.log('onError', e)}
            height='100%'
            width='100%'
            style={{ position: 'absolute', left: 0, top: 0, paddingTop: '40px' }}
            controls={true}
            config={{
              file: {
                hlsOptions: {
                  xhrSetup: (xhr: XMLHttpRequest) => {
                    xhr.withCredentials = true // send cookies
                    xhr.setRequestHeader('Access-Control-Allow-Headers', 'Content-Type, Accept, X-Requested-With')
                    xhr.setRequestHeader('Access-Control-Allow-Origin', cloudfrontOriginUrl)
                    xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true')
                  },
                },
              },
            }}
            playing
          />,
        )
      }
    })
  }
  const getMeetingFormatAndDuration = (format: string, duration: number): ReactElement => {
    if (format) {
      const lowered = format.toLowerCase()
      if (lowered.indexOf('person') !== -1) {
        return (
          <FormattedMessage
            defaultMessage='In person{spacer}{duration}{minutesAbbreviation}'
            description='Format of the session will be in person instead of on video or phone'
            values={{ spacer: ' • ', duration: duration, minutesAbbreviation: 'min' }}
          />
        )
      } else if (lowered.indexOf('phone') !== -1) {
        return (
          <FormattedMessage
            defaultMessage='Phone{spacer}{duration}{minutesAbbreviation}'
            description='Format of the session will be via telephone or phone'
            values={{ spacer: ' • ', duration: duration, minutesAbbreviation: 'min' }}
          />
        )
      } else if (lowered.indexOf('video') !== -1) {
        return (
          <FormattedMessage
            defaultMessage='Video{spacer}{duration}{minutesAbbreviation}'
            description='Format of the session will be a video call'
            values={{ spacer: ' • ', duration: duration, minutesAbbreviation: 'min' }}
          />
        )
      } else if (lowered.indexOf('message') !== -1 || lowered.indexOf('messaging') !== -1) {
        return (
          <FormattedMessage
            defaultMessage='Live messaging{spacer}{duration}{minutesAbbreviation}'
            description='Format of the session will be via live typed messages'
            values={{ spacer: ' • ', duration: duration, minutesAbbreviation: 'min' }}
          />
        )
      }
    }
    return <></>
  }
  const getTitle = (appointment: CalendarCardAppointment): string => {
    switch (appointment.appointmentStatus) {
      case APPOINTMENT_STATUS.MISSED:
        return 'Missed Session'
      case APPOINTMENT_STATUS.CANCELED:
        return 'Canceled Session'
      default:
        return `Session ${appointment?.sessionNumber}`
    }
  }
  const pastAppointments = appointments
    .filter((appointment: CalendarCardAppointment) => {
      const sessionStart = getDateFromAppointment(appointment)
      return isPast(sessionStart) || appointment.appointmentStatus === 'canceled'
    })
    .map((appt) => {
      const addedSubtitle = {
        ...appt,
        subtitle: getMeetingFormatAndDuration(appt?.meetingFormat, appt?.appointmentDuration),
      }
      return addedSubtitle
    })
  const currentAppointments = appointments
    .filter((appointment: CalendarCardAppointment) => {
      const sessionStart = getDateFromAppointment(appointment)
      return isFuture(sessionStart) && appointment.appointmentStatus !== 'canceled'
    })
    .map((appt) => {
      const addedSubtitle = {
        ...appt,
        subtitle: getMeetingFormatAndDuration(appt?.meetingFormat, appt?.appointmentDuration),
      }
      return addedSubtitle
    })

  const { isMinWidthLaptop, isMinWidthTablet } = useMediaQuerySize()

  return (
    <SessionPageWrapper>
      {(isEmpty(appointments) && (isLoading || !hasFetched)) || isRefreshingSessions ? (
        <LoadingContainer>
          <LoadingIndicator size={45} />
        </LoadingContainer>
      ) : (
        <SessionContainer isMinWidthLaptop={isMinWidthLaptop} isMinWidthTablet={isMinWidthTablet}>
          <>
            {get(selectedEpisode, 'state') === episodeStates.IN_PROGRESS && (
              <ScheduleSessionContainer>
                <PrimaryButton
                  text='Schedule session'
                  onPress={goToCreateNewSession}
                  testID={tID('Sessions-new-session')}
                  leftIcon={<PlusSignIcon size={16} />}
                ></PrimaryButton>
              </ScheduleSessionContainer>
            )}
            <SessionList
              title={
                <FormattedMessage
                  defaultMessage='Upcoming sessions'
                  description='Title for sessions coming up in the future'
                />
              }
              sessions={currentAppointments as unknown as CalendarCardAppointment[]}
              handleReschedulePress={rescheduleAppointment}
              handleCancelPress={selectAppointmentToCancel}
              getProviderIcon={getProviderIcon}
              testId={tID('Upcoming')}
              emptyListMessage={
                <EmptyState>
                  <FormattedMessage
                    defaultMessage='No upcoming sessions'
                    description='Message displayed for when no future sessions are scheduled'
                  />
                </EmptyState>
              }
              getCardTitle={getTitle}
              onPressContactCareNavigator={noop}
            />
            {pastAppointments.length > 0 && (
              <SessionList
                title={
                  <FormattedMessage
                    defaultMessage='Past sessions'
                    description='Title for sessions that happened in the past'
                  />
                }
                sessions={pastAppointments as unknown as CalendarCardAppointment[]}
                getProviderIcon={getProviderIcon}
                viewSessionRecording={viewSessionRecording}
                testId={tID('Past')}
                getCardTitle={getTitle}
                onPressContactCareNavigator={noop}
              />
            )}
          </>

          <Modal
            visible={!!modalContent}
            modalContents={modalContent ?? <div />}
            scrollableModalWidth={isPlayingVideo ? '900px' : undefined}
            scrollableModalHeight={isPlayingVideo ? '530px' : 'auto'}
            onCloseEnd={() => {
              setModalContent(null)
              setIsPlayingVideo(false)
            }}
            onRequestClose={() => {
              setModalContent(null)
              setIsPlayingVideo(false)
            }}
            scrollable
            isHeaderTransparent
            disableBottomSheet
          />
        </SessionContainer>
      )}
    </SessionPageWrapper>
  )
}

type SessionsProps = {
  clientDetails: ClientObject
  actions: {
    getVideoSessions: (provider_id: string, patient_id: string) => Promise<object>
    getVideoSessionRecording: ({ id }: { id: string }) => Promise<object>
  }
  selectedEpisode: Episode
}

const mapStateToProps = (state: RootState) => {
  const clientDetails = getClientDetailsData(state)
  const selectedEpisode = getClientSelectedEpisode(state)

  return {
    clientDetails,
    selectedEpisode,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getVideoSessions,
      getVideoSessionRecording,
    },
    dispatch,
  ),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: Sessions... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(Sessions)
