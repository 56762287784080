import React, { FunctionComponent, MutableRefObject } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { useMediaQuerySize } from '../../../src/hooks/useMediaQuerySize'
import { BackButtonSmall } from '../../atoms/backButtonSmall/BackButtonSmall'
import { LessonIcon } from '../../atoms/illustrations/LessonIcon'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { LessonThumbnail } from '../lessonThumbnail/LessonThumbnail'

export type LessonHeaderProps = {
  id: string
  lessonName: string
  thumbnail?: string
  onBackPress: () => void
  topInset?: number
  a11yFocusedElementOnLoad?: MutableRefObject<any>
}

const LessonHeaderContainer = styled.View<{ theme: ThemeType; topInset: number; height: number }>(
  ({ theme, topInset, height }) => ({
    minHeight: `${height + topInset}px`,
    marginTop: `${-topInset}px`,
    flexShrink: 1,
    backgroundColor: theme.colors.backgroundDarkOverlay,
  }),
)

const ContentContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  position: 'absolute',
  padding: `0px ${spacing['16px']} ${spacing['24px']}`,
  justifyContent: 'flex-end',
  bottom: '0px',
  width: '100%',
  alignItems: 'center',
}))

const SubTitleContainer = styled.View({
  flexDirection: 'row',
  width: '100%',
  maxWidth: '466px',
})

const SubheadContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  width: '100%',
  maxWidth: '466px',
  paddingTop: spacing['4px'],
}))

const BackButtonContainer = styled.View<{ isMinWidthTablet?: boolean; topInset: number; theme: ThemeType }>(
  ({ isMinWidthTablet, topInset, theme: { spacing } }) => ({
    position: 'absolute',
    top: `${topInset * 2}px`,
    left: 0,
    zIndex: 1,
    margin: topInset ? `0px ${spacing['24px']}` : isMinWidthTablet ? spacing['40px'] : spacing['24px'],
  }),
)

const LessonIconContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginRight: spacing['8px'],
}))

const HeadlineComp = styled(Subhead)({
  display: 'flex',
  alignItems: 'center',
})

export const LessonHeader: FunctionComponent<LessonHeaderProps> = ({
  id,
  lessonName,
  thumbnail,
  onBackPress,
  topInset = 0,
  a11yFocusedElementOnLoad,
}) => {
  const { isMinWidthTablet } = useMediaQuerySize()
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const headerHeight = 236

  return (
    <LessonHeaderContainer topInset={topInset} height={headerHeight}>
      <LessonThumbnail
        id={id}
        thumbnailMetadata={thumbnail ?? ''}
        onPress={() => {}}
        showGradient
        borderRadius={0}
        height={headerHeight + topInset}
        videoTitle={lessonName}
        disabled
      />
      <BackButtonContainer isMinWidthTablet={isMinWidthTablet} topInset={topInset}>
        <BackButtonSmall
          testID={tID('LessonHeader-back')}
          onPress={onBackPress}
          accessibilityLabel={formatMessage({
            defaultMessage: 'Back',
            description: 'Back button to go to the previous page',
          })}
          color={colors.iconDarkBackground}
        />
      </BackButtonContainer>
      <ContentContainer accessible ref={a11yFocusedElementOnLoad}>
        <SubTitleContainer>
          <LessonIconContainer>
            <LessonIcon fillColor={colors.iconDarkBackground} />
          </LessonIconContainer>
          <HeadlineComp
            text={formatMessage({
              defaultMessage: 'Watch lesson',
              description: 'Overview title of the page',
            })}
            size={SubheadSize.XSMALL}
            color={colors.textDarkBackground}
          />
        </SubTitleContainer>
        <SubheadContainer>
          {isMinWidthTablet ? (
            <Subhead text={lessonName} size={SubheadSize.LARGE} color={colors.textDarkBackground} />
          ) : (
            <Subhead text={lessonName} size={SubheadSize.MEDIUM} color={colors.textDarkBackground} />
          )}
        </SubheadContainer>
      </ContentContainer>
    </LessonHeaderContainer>
  )
}
