import React, { useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { ClientListClientObject, Episode, useFlags } from '@lyrahealth-inc/shared-app-logic'

import styles from './clientListContainer.module.scss'
import { CapacityAlertPopUp } from '../../../common/components/capacityAlertPopUp/CapacityAlertPopUp'
import { useAvailabilitiesLogging } from '../../../common/components/hooks/useAvailabilitiesLogging'
import { SUPERVISOR_ROLES } from '../../../common/constants/appConstants'
import { hasRole } from '../../../common/utils/utils'
import { getAuthRoles, getAuthUserCapacityValue, getAuthUserId } from '../../../data/auth/authSelectors'
import { getClientEpisodesData } from '../../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../../data/store'
import {
  CalendarAvailabilitiesAlertPopUpValues,
  useCalendarAvailabilitiesAlertPopUp,
} from '../../../hooks/useCalendarAvailabilitiesAlertPopUp'
import { getClientId, getLtClient } from '../clientDetails/data/ltClientDetailsAutoActions'
import ClientList from '../clientList/ClientList'
import ClientListHeader from '../clientList/ClientListHeader'
import ClientListOptions from '../clientList/ClientListOptions'
import ClientPreviewPane from '../clientList/ClientPreviewPane'

const ClientListContainer: React.FC<ConnectedProps<typeof connector>> = ({
  episodes,
  actions: { getClientId, getLtClient },
}) => {
  const userRoles: string[] = useSelector(getAuthRoles)
  const capacities = useSelector(getAuthUserCapacityValue) ?? {}
  const userId = useSelector(getAuthUserId)
  const [searchParams, setSearchParams] = useSearchParams()
  const { showCalendarAvailabilitiesWithCapacity } = useFlags()
  const navigate = useNavigate()
  const [selectedClient, setSelectedClient] = useState<ClientListClientObject | null>(null)
  const [selectedSortBy, setSelectedSortBy] = useState<string>('next_appointment')
  const [showActiveClients, setShowActiveClients] = useState<boolean>(true)
  const [programFilter, setProgramFilter] = useState<string | null>(null)
  const [showUnscheduledOnly, setShowUnscheduledOnly] = useState<boolean>(false)
  const [selectedEpisodeProgressOption, setSelectedEpisodeProgressOption] = useState<string>('All')
  const onSelectClient = useCallback(
    (client: ClientListClientObject | null) => {
      setSelectedClient(client)
    },
    [setSelectedClient],
  )

  const relId = searchParams.get('relId')

  useAvailabilitiesLogging({ providerId: userId })
  useEffect(() => {
    async function handleRelID() {
      if (relId && episodes.length === 0) {
        const res: any = await getClientId({ relationshipId: relId })
        if (!res) {
          return
        }
        const client: any = await getLtClient({ clientId: res.patient_id, providerId: userId })
        if (!client) {
          return
        }
        onSelectClient({ id: client.id, first_name: client.first_name, last_name: client.last_name })
      }

      const episodeRelId = episodes.map((episode) => episode.provider_patient_id).find(Boolean)
      if (selectedClient && episodeRelId && relId !== episodeRelId) {
        setSearchParams({ relId: episodeRelId })
      }
    }

    handleRelID()
  }, [episodes, getClientId, getLtClient, navigate, onSelectClient, relId, selectedClient, setSearchParams, userId])

  const {
    alertText,
    handleCloseAlertPopUpModal,
    handleLearnMore,
    handleOpenCalendar,
    hasProperRoleToSeeAlert,
    isAlertPopUpOpen,
  }: CalendarAvailabilitiesAlertPopUpValues = useCalendarAvailabilitiesAlertPopUp()

  if (hasRole(userRoles, SUPERVISOR_ROLES)) {
    return <div className={styles.supervisor}>Please select a provider from the dropdown above.</div>
  }

  return (
    <>
      {hasProperRoleToSeeAlert && showCalendarAvailabilitiesWithCapacity && isAlertPopUpOpen && (
        <CapacityAlertPopUp
          alertText={alertText}
          handleOpenCalendar={handleOpenCalendar}
          handleLearnMore={handleLearnMore}
          onClose={handleCloseAlertPopUpModal}
        />
      )}
      <ClientListHeader />
      <div className={styles['client-section']}>
        <ClientListOptions
          sortOptionSelected={(option: string) => setSelectedSortBy(option)}
          showActiveClients={(option: boolean) => setShowActiveClients(option)}
          capacities={capacities}
          setProgramFilter={(program: string) => setProgramFilter(program === 'All' ? null : program)}
          setShowUnscheduledOnly={(option: boolean) => setShowUnscheduledOnly(option)}
          setEpisodeProgressFilter={(episodeProgress: string) => setSelectedEpisodeProgressOption(episodeProgress)}
        />
        {/* @ts-expect-error TS(2739) */}
        <ClientList
          selectedClient={selectedClient}
          onSelectClient={onSelectClient}
          sortProperty={selectedSortBy}
          showActiveClients={showActiveClients}
          programFilter={programFilter}
          showUnscheduledOnly={showUnscheduledOnly}
          selectedEpisodeProgressOption={selectedEpisodeProgressOption}
        />
        {/* @ts-expect-error TS(2739): Type '{ selectedClient: ClientListClientObject | n... Remove this comment to see the full error message */}
        <ClientPreviewPane selectedClient={selectedClient} />
      </div>
    </>
  )
}
type StateProps = {
  episodes: Episode[]
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    episodes: getClientEpisodesData(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getClientId,
      getLtClient,
    },
    dispatch,
  ),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(ClientListContainer)
