import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { ContentLayout } from '@lyrahealth-inc/ui-core'

import { getAuthRoles } from '../data/auth/authSelectors'
import { RootState } from '../data/store'
// pages

type ReconcilerRoutesProps = {
  roles?: any // TODO: PropTypes.instanceOf(List)
}

class ReconcilerRoutes extends Component<ReconcilerRoutesProps> {
  render() {
    return (
      <div>
        <ContentLayout>
          <div style={{ marginTop: '50px' }}>
            <Outlet />
          </div>
        </ContentLayout>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    roles: getAuthRoles(state),
  }
}

export default connect(mapStateToProps, null)(ReconcilerRoutes)
