import React, { FunctionComponent, ReactElement, ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { ActivityBadge } from '../../atoms/activityBadge/ActivityBadge'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Headline, Size as HeadLineSize } from '../../atoms/headline/Headline'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { AccessibilityRolesNative } from '../../constants'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export type ActivityItemProps = {
  id: string
  title?: string | ReactNode
  subtitle: string | ReactNode
  onPress: (id: string) => void
  showTodo?: boolean
  icon?: ReactElement
  disabled?: boolean
  borderColor?: string
  headline?: string | ReactNode
  withHeadline?: boolean
  time?: number
  ctaTitle?: string | ReactNode
  accessibilityLabel?: string | ReactNode
  withCTA?: boolean
}

const ActivityItemContainer = styled(PressableOpacity)<{ borderColor?: string; disabled?: boolean; hoverIn: boolean }>(
  ({ borderColor, hoverIn, theme: { colors } }) => ({
    borderRadius: '16px',
    border: `1px solid ${borderColor}`,
    flexDirection: 'row',
    backgroundColor: hoverIn ? colors.backgroundFocus : colors.cardBackgroundPrimary,
  }),
)

const IconContainer = styled.View<{ theme: ThemeType; disabled?: boolean }>(
  ({
    disabled,
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
    },
  }) => ({
    paddingRight: isMinWidthTablet ? spacing['24px'] : spacing['16px'],
    opacity: disabled ? 0.65 : 1,
  }),
)

const ActivityItemDescription = styled.View({
  width: '100%',
})

const SubTitleContainer = styled.View<{
  withHeadline: boolean
  theme: ThemeType
}>(({ withHeadline, theme }) => ({
  flexDirection: 'row',
  marginTop: withHeadline ? theme.spacing['4px'] : '0px',
}))

const TodoContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  position: 'absolute',
  flexDirection: 'row',
  top: spacing['8px'],
  right: spacing['8px'],
}))

const HeadlineContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: theme.spacing['8px'],
}))

const CallToActionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  position: 'static',
  paddingTop: theme.spacing['16px'],
}))

const ActivityItemContent = styled.View<{
  icon?: ReactElement
  withHeadline: boolean
  theme: ThemeType
}>(
  ({
    icon,
    withHeadline,
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
    },
  }) => {
    const other = { width: '80%', flex: 1 }
    let padding = { padding: `16px ${icon ? '11px' : '16px'}` }

    if (withHeadline) {
      padding = { padding: `${isMinWidthTablet ? spacing['24px'] : spacing['16px']}` }
    }

    return { ...other, ...padding }
  },
)

const ActivityTopContent = styled.View({
  flexDirection: 'row',
})

const TimeHeadline = styled(Headline)<{ theme: ThemeType }>(({ theme }) => ({
  paddingLeft: theme.spacing['4px'],
}))
/**
 * A pressable rounded view used to show an activity
 * Used for assessments and exercises
 */
export const ActivityItem: FunctionComponent<ActivityItemProps> = ({
  id,
  title = '',
  onPress,
  showTodo,
  icon,
  disabled,
  borderColor,
  subtitle,
  headline = '',
  withHeadline = false,
  time,
  ctaTitle,
  accessibilityLabel,
  withCTA = false,
}) => {
  const [hoverIn, setHoverIn] = useState(false)
  const { colors } = useTheme() as ThemeType
  const { formatMessage } = useIntl()

  return (
    <ActivityItemContainer
      onPress={() => onPress(id)}
      onHoverIn={() => setHoverIn(true)}
      onHoverOut={() => setHoverIn(false)}
      hoverIn={hoverIn}
      pressedBackgroundColor={colors.backgroundFocus}
      disabled={disabled}
      borderColor={disabled ? colors.cardOutlineInactive : borderColor || colors.cardOutlinePrimary}
      testID={tID('ActivityItem')}
      accessibilityState={{ disabled }}
    >
      <ActivityItemContent icon={icon} withHeadline={withHeadline} importantForAccessibility='no-hide-descendants'>
        <ActivityTopContent>
          {icon && <IconContainer disabled={disabled}>{icon}</IconContainer>}
          <ActivityItemDescription>
            {withHeadline && (
              <HeadlineContainer>
                <Headline
                  text={headline}
                  size={HeadLineSize.EYEBROW}
                  color={disabled ? colors.textInactive : colors.textSecondary}
                />
                {!!time && (
                  <TimeHeadline
                    text={formatMessage(
                      {
                        defaultMessage: '· {time} min',
                        description: 'Indicates the average time it takes to finish the activity',
                      },
                      {
                        time,
                      },
                    )}
                    size={HeadLineSize.EYEBROW}
                    color={disabled ? colors.textInactive : colors.textSecondary}
                  />
                )}
              </HeadlineContainer>
            )}
            <Subhead
              size={withHeadline ? SubheadSize.SMALL : SubheadSize.XSMALL}
              numberOfLines={2}
              text={title}
              color={disabled ? colors.textInactive : undefined}
            />
            <SubTitleContainer withHeadline={withHeadline}>
              <BodyText
                text={subtitle}
                size={withHeadline ? BodyTextSize.SMALL : BodyTextSize.CAPTION}
                color={disabled ? colors.textInactive : colors.textSecondary}
                testID={tID(`ActivityItem-${title}-status`)}
              />
            </SubTitleContainer>
          </ActivityItemDescription>
          {showTodo && (
            <TodoContainer>
              <ActivityBadge
                text={formatMessage({
                  defaultMessage: 'To do',
                  description:
                    'Tag that lets user know that this is an activity that the user needs to work on or take action on',
                })}
              />
            </TodoContainer>
          )}
        </ActivityTopContent>
        {ctaTitle !== undefined && withCTA && (
          <CallToActionContainer>
            <PrimaryButton
              text={ctaTitle}
              onPress={() => onPress(id)}
              accessibilityLabel={accessibilityLabel}
              role={AccessibilityRolesNative.NONE}
              fullWidth
            />
          </CallToActionContainer>
        )}
      </ActivityItemContent>
    </ActivityItemContainer>
  )
}
