import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'

import { bindActionCreators } from 'redux'

import {
  Appointment,
  appointmentIsSupported,
  getDateFromAppointment,
  MEETING_FORMATS,
  useApptFormattedTime,
  useApptToShow,
  VideoSession,
} from '@lyrahealth-inc/shared-app-logic'
import { ProviderJoinSessionBanner } from '@lyrahealth-inc/ui-core-crossplatform'

import { getAuthUserId } from '../../../data/auth/authSelectors'
import { RootState } from '../../../data/store'
import { useGetAppointmentsQuery } from '../../../lyraTherapy/clients/clientDetails/data/appointmentsApi'
import { getToken } from '../../../lyraTherapy/data/ltVideoAutoActions'
import { getLTVideoSessionOpen } from '../../../lyraTherapy/data/ltVideoSelectors'
import { logToSumoLogic } from '../../utils/utils'

type TokenRequest = { appointment_id: number; patient_id: string; provider_id: string; session_number: number }
type JoinSessionBannerProps = ConnectedProps<typeof connector> & {
  onTwilioSessionJoin: (appointment: Appointment) => void
  onZoomSessionJoin: (appointment: Appointment) => void
}

const JoinSessionBanner: FunctionComponent<JoinSessionBannerProps> = ({
  videoSessionOpen,
  onTwilioSessionJoin,
  onZoomSessionJoin,
  actions: { getToken },
}) => {
  const userId = useSelector(getAuthUserId)
  const { data: appointments } = useGetAppointmentsQuery({ providerId: userId })
  const supportedAppointments = useMemo(
    () =>
      appointments?.filter((appt) => appointmentIsSupported(appt) && appt.meetingFormat === MEETING_FORMATS.VIDEO) ??
      [],
    [appointments],
  )
  const [isFetchingToken, setIsFetchingToken] = useState(false)

  const { apptToShow = null } = useApptToShow({ appts: supportedAppointments, reminderWindow: 10 })
  const sessionNumber = apptToShow?.sessionNumber ?? -1
  const { startsIn } = useApptFormattedTime(apptToShow)
  const onPress = useCallback(() => {
    if (!userId || !apptToShow?.userInfo) {
      return
    }

    logToSumoLogic('getVideoSessionToken', userId, {
      stack: new Error().stack,
    })
    setIsFetchingToken(true)
    ;(getToken as unknown as (data: TokenRequest) => Promise<VideoSession>)({
      appointment_id: apptToShow.appointmentId,
      patient_id: apptToShow.userInfo.lyraId,
      provider_id: userId,
      session_number: sessionNumber,
    })
      .then((session) => {
        if (session.video_provider === 'twilio') {
          onTwilioSessionJoin(apptToShow)
        } else {
          onZoomSessionJoin(apptToShow)
        }
      })
      .finally(() => {
        setIsFetchingToken(false)
      })
  }, [apptToShow, userId, getToken, sessionNumber, onTwilioSessionJoin, onZoomSessionJoin])

  if (videoSessionOpen || !apptToShow?.userInfo) {
    return null
  }
  return (
    <ProviderJoinSessionBanner
      sessionStartDate={getDateFromAppointment(apptToShow)}
      sessionDuration={apptToShow.appointmentDuration}
      sessionStartsIn={startsIn}
      onPress={onPress}
      isFetchingToken={isFetchingToken}
    />
  )
}

type StateProps = {
  videoSessionOpen: boolean
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    videoSessionOpen: getLTVideoSessionOpen(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      {
        getToken,
      },
      dispatch,
    ),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(JoinSessionBanner)
