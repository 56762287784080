import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useLocation, useNavigate, useOutletContext } from 'react-router'

import { FormApi } from 'final-form'
import { get, isEmpty } from 'lodash-es'
import moment from 'moment-timezone'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/native'

import {
  AIDraftStatus,
  AppointmentSummary,
  Episode,
  getAIEnabledFields,
  getAppointmentDateTimeObject,
  getProgramNameFromId,
  Note,
  NoteStatusStates,
  noteSummaryStatusText,
  NoteTypes,
  Program,
  ProgramNames,
  SchemaProperties,
  STATES,
  useFlags,
  useIsMounted,
  useThrottledCallback,
} from '@lyrahealth-inc/shared-app-logic'
import { contentSchemaUtils } from '@lyrahealth-inc/ui-core'
import {
  BodyText,
  ChevronIcon,
  ChevronIconDirection,
  colors,
  ExpandingSidePanel,
  FormButtonParams,
  NoteContainer,
  NotesSidebar,
  PrimaryButton,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import ClientNotesPanel from './ClientNotesPanel'
import { getAppointmentSummaries, submitNote, updateNote } from './data/clientNotesAutoActions'
import { actions, mixpanelEvents, sessionNoteTypes } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { programConfig, ROLES, SUPERVISOR_ROLES } from '../../common/constants/appConstants'
import { CLIENT_NOTES } from '../../common/constants/routingConstants'
import { hasRole, logToSumoLogic } from '../../common/utils/utils'
import { getAuthRoles, getAuthSupervisor, getAuthUserFullName, getAuthUserId } from '../../data/auth/authSelectors'
import { getHealthPlanICD10s } from '../../data/healthPlan/healthPlanSelectors'
import {
  getClientDataId,
  getClientDetailsData,
  getClientNotesAppointmentSummaries,
} from '../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData, getLyraTherapyContentsPrograms } from '../../data/lyraTherapy/contentSelectors'
import { useClientAppointmentsData } from '../clients/clientDetails/data/hooks/useClientAppointmentsData'

const Container = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  height: '100%',
})

const MainSectionContainer = styled.View<{ isPanelOpen: boolean }>(({ isPanelOpen }) => ({
  marginRight: isPanelOpen ? '500px' : 0,
  transition: '1s ease-in-out',
}))

const NoteInfoContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  margin: 'auto',
  marginTop: theme.spacing['32px'],
  paddingTop: theme.spacing['16px'],
}))

const SidebarContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing['32px'],
  margin: `0 ${theme.spacing['16px']}`,
}))

const GoBackContainer = styled.Pressable({
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '90px',
})

const NoteLayoutContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '690px',
  height: '100%',
  margin: 0,
  marginBottom: theme.spacing['32px'],
  paddingLeft: theme.spacing['32px'],
  paddingTop: 0,
}))

const AINotesPanelContainer = styled.View({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
})

const ClientNoteDetails: FunctionComponent<ClientNoteDetailsProps> = ({
  actions: { getAppointmentSummaries, submitNote, updateNote },
}: any) => {
  const { enableAINotesFeatures, showAINotesFeatures, isGPSv1Enabled, showAINotesForBCCAndGSC } = useFlags()

  const noteRef = useRef()
  const feedbackFormRef = useRef<FormApi<Dict>>()

  const userId = useSelector(getAuthUserId)
  const userName = useSelector(getAuthUserFullName)
  const roles = useSelector(getAuthRoles)
  const supervisor = useSelector(getAuthSupervisor)
  const clientId = useSelector(getClientDataId)
  const clientDetails = useSelector(getClientDetailsData)
  const { data: appointments = [] } = useClientAppointmentsData()
  const icd10s = useSelector(getHealthPlanICD10s)
  const contents = useSelector(getLyraTherapyContentsData)
  const contentsPrograms = useSelector(getLyraTherapyContentsPrograms)
  const appointmentSummaries: AppointmentSummary[] | undefined = useSelector(getClientNotesAppointmentSummaries)
  const { episode } = useOutletContext<{ episode: Episode }>()
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const panelContainerRef = useRef<any>(null)
  const noteDetailsContainerRef = useRef<any>(null)

  const togglePanelStickiness = useCallback(() => {
    const notesPanel = panelContainerRef?.current
    const noteDetailsContainer = noteDetailsContainerRef?.current
    if (notesPanel) {
      if (noteDetailsContainer && noteDetailsContainer.getBoundingClientRect().top <= 0) {
        if (notesPanel) {
          notesPanel.style.position = 'fixed'
        }
      } else {
        notesPanel.style.position = 'absolute'
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', togglePanelStickiness)

    return () => {
      window.removeEventListener('scroll', togglePanelStickiness)
    }
  }, [togglePanelStickiness])

  const [note, setNote] = useState<Note & { sessionDate: string; sessionNumber: string }>(
    get(location.state, 'note') || {},
  )

  const [, , hasFetchedSummaries] = useFetcher(
    [
      getAppointmentSummaries,
      {
        providerId: userId,
        patientId: clientId,
        episodeId: episode?.id,
        statusOnly: false,
        appointmentId: note?.appointment_id,
      },
      !!enableAINotesFeatures,
    ],
    [userId, clientId, episode?.id],
  )

  let noteMetadata = note.content_meta_data
  const noteType = note.note_type
  const providerType = Object.values(ROLES).find((role) => roles?.includes(role))
  const mappedIcd10s =
    (icd10s?.all || []).map((obj: any) => ({
      label: `${obj.code} ${obj.desc}`,
      value: obj.value,
    })) || []
  const worksheets = contents
    .filter(({ group }: any) => group === 'exercise')
    .map(({ title }: any) => title)
    .sort()
  const lessons = contents
    .filter(({ group }: any) => group === 'lesson')
    .map(({ title }: any) => title)
    .sort()
  const infosheets = contents
    .filter(({ group }: any) => group === 'infosheet')
    .map(({ title }: any) => title)
    .sort()

  const notesThatNeedDeclaration = ['bctInitialNote', 'bctSessionNote']
  const isSupervisor = !!supervisor && !isEmpty(supervisor.roles) && hasRole(supervisor.roles, SUPERVISOR_ROLES)
  const isFellowSupervisor =
    supervisor &&
    !isEmpty(supervisor.roles) &&
    (hasRole(supervisor.roles, ROLES.LT_SUPERVISOR) || hasRole(supervisor.roles, ROLES.LT_FELLOW_SUPERVISOR))
  const isFellow = hasRole(roles, ROLES.LT_FELLOW_THERAPIST)
  const [isNoteBeingManuallySubmitted, setIsNoteBeingManuallySubmitted] = useState(false)
  const [isNoteBeingAutoSaved, setIsNoteBeingAutoSaved] = useState(false)
  const [autoSaveFailed, setAutoSaveFailed] = useState(false)
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const isMounted = useIsMounted()

  const asyncSaveForm = async ({ values }: { values: object }): Promise<void> => {
    setIsNoteBeingAutoSaved(true)
    await submitForm(values, 'draft')
  }

  const [saveForm] = useThrottledCallback(({ values, formSubmit }: { values: object; formSubmit: boolean }): void => {
    if (formSubmit) {
      setIsNoteBeingManuallySubmitted(true)
    }
    if (isNoteBeingAutoSaved) {
      return
    }
    !formSubmit && setIsNoteBeingAutoSaved(true)
    isMounted() && submitForm(values, formSubmit ? 'completed' : 'draft')
  }, 5000)

  const submitForm = async (values: any, status: any) => {
    // For slower chromebook machines, sometimes the autosave will trigger before the note values are initialized
    if (isEmpty(values)) {
      setIsNoteBeingAutoSaved(false)
      return
    }

    const body = {
      ...values,
      approverName: isFellow && status === 'completed' ? `${supervisor?.first_name} ${supervisor?.last_name}` : null,
      approverLyraId: isFellow && status === 'completed' ? supervisor?.id : null,
      approverRole: isFellow && status === 'completed' ? supervisor?.roles : null,
      authorName: isFellow ? userName : isSupervisor ? `${supervisor.first_name} ${supervisor.last_name}` : userName,
      authorLyraId: isFellow ? userId : isSupervisor ? supervisor.id : userId,
      authorRoles: isFellow ? roles : isSupervisor ? supervisor.roles : roles,
      providerType: isSupervisor ? supervisor.role : providerType,
    }

    let res = {}
    const data = {
      content_meta_data_id: noteMetadata.id,
      content_meta_data: noteMetadata,
      patient_id: clientId,
      provider_id: userId,
      episode_id: episode.id,
      appointment_id: note.appointment_id,
      note_type: note.note_type,
      version_id: note.version_id,
      body: body,
      status,
    }
    if (note.id) {
      logToSumoLogic('noteDraftSubmission', userId, {
        message: `Submitted note draft with body: ${JSON.stringify(body)}`,
      })
      try {
        setAutoSaveFailed(false)
        res = await updateNote({ note_id: note.id, ...data })
      } catch (error) {
        logToSumoLogic('noteSubmissionError', userId, error)
        setAutoSaveFailed(true)
      }
    } else {
      res = await submitNote({ ...data })
      setIsNoteBeingManuallySubmitted(false)
    }
    if (status === 'draft') {
      // when we save a draft, we filter out the Sign heading and Supervisor declaration because otherwise,
      //  the saved version can be edited by the Fellow, who can then check the Declaration checkbox
      if (isFellowSupervisor) {
        data.content_meta_data = contentSchemaUtils.removeMetadataField(data.content_meta_data, 'sign')
        data.content_meta_data = contentSchemaUtils.removeMetadataField(
          data.content_meta_data,
          'declareInfoAccurateAndComplete',
        )
      }
      setIsNoteBeingAutoSaved(false)
      setNote({ ...note, ...res })
    } else {
      navigate(CLIENT_NOTES.route)
    }
    return res
  }

  const noteDeclaration = contents.find((n: any) => n.name === 'noteDeclaration')
  const noteDeclarationSupervisor = contents.find((n: any) => n.name === 'noteDeclarationSupervisor')
  const fellowQuestions = contents.find((n: any) => n.name === 'fellowQuestions')

  const noteHasDeclarationCheckbox =
    // @ts-expect-error TS(2339): Property 'declareInfoAccurateAndComplete' does not exist on type... Remove this comment to see the full error message
    note?.content_meta_data?.meta_data?.schema?.properties?.declareInfoAccurateAndComplete

  // @ts-expect-error TS(2339): Property 'fellowDisclosure' does not exist on type... Remove this comment to see the full error message
  const noteHasFellowDisclosure = note?.content_meta_data?.meta_data?.schema?.properties?.fellowDisclosure

  if (isFellowSupervisor && !noteHasDeclarationCheckbox && note.status !== 'completed' && noteDeclarationSupervisor) {
    note.content_meta_data = contentSchemaUtils.extendMetadata(note.content_meta_data, noteDeclarationSupervisor)
    noteMetadata = note.content_meta_data
  } else if (
    note?.content_meta_data?.name &&
    notesThatNeedDeclaration.includes(note.content_meta_data.name) &&
    !isFellow &&
    !noteHasDeclarationCheckbox &&
    note.status !== 'completed' &&
    noteDeclaration
  ) {
    note.content_meta_data = contentSchemaUtils.extendMetadata(note.content_meta_data, noteDeclaration)
    noteMetadata = note.content_meta_data
  }

  if (
    isFellow &&
    !noteHasFellowDisclosure &&
    note?.content_meta_data?.name === 'bctInitialNote' &&
    note.status !== 'completed' &&
    fellowQuestions
  ) {
    // supply an optional flag to extendMetadata to set the order of the added metadata to be above (defaults to below) the original
    note.content_meta_data = contentSchemaUtils.extendMetadata(note.content_meta_data, fellowQuestions, true)
  }

  const appointment = appointments.find(({ appointmentId }: any) => appointmentId === note.appointment_id)
  const isNoteForFutureAppointment = appointment && moment(getAppointmentDateTimeObject(appointment)).isAfter(moment())
  const programName = getProgramNameFromId(episode?.program_id) ?? ''
  const noteAppointmentSummary: AppointmentSummary | undefined = useMemo(() => {
    return showAINotesFeatures
      ? programConfig[programName]?.aiNotesEnabled &&
        (programName !== ProgramNames.Coaching || (programName === ProgramNames.Coaching && showAINotesForBCCAndGSC)) &&
        (programName !== ProgramNames.SingleSessionCoaching ||
          (programName === ProgramNames.SingleSessionCoaching && showAINotesForBCCAndGSC))
        ? appointmentSummaries?.find(
            (summary: AppointmentSummary) => summary.appointment_id === note.appointment_id?.toString(),
          )
        : undefined
      : undefined
  }, [appointmentSummaries, note.appointment_id, programName, showAINotesFeatures, showAINotesForBCCAndGSC])

  const firstAIEnabledField = useMemo(() => {
    const schemaProperties = noteMetadata?.meta_data?.schema?.properties as unknown as SchemaProperties
    if (!schemaProperties) {
      return
    }
    return getAIEnabledFields(noteMetadata, schemaProperties, note.body)[0]
  }, [noteMetadata, note.body])

  const contentProgram: Program | undefined = contentsPrograms.find((program) => program.name === programName)

  const promptType =
    noteType === NoteTypes.INITIAL && contentProgram?.appt_initial_ai_prompts
      ? contentProgram?.appt_initial_ai_prompts?.[0]
      : NoteTypes.SESSION && contentProgram?.appt_follow_up_ai_prompts
      ? contentProgram?.appt_follow_up_ai_prompts?.[0]
      : undefined

  const displayAINotesPanel =
    showAINotesFeatures &&
    hasFetchedSummaries &&
    noteAppointmentSummary &&
    ([AIDraftStatus.IN_PROGRESS, AIDraftStatus.NOT_STARTED].includes(noteAppointmentSummary.status) ||
      (noteAppointmentSummary.status === AIDraftStatus.COMPLETE && noteAppointmentSummary?.summary?.length)) &&
    !isNoteForFutureAppointment &&
    note.status !== NoteStatusStates.COMPLETED

  const onOpenPanel = () => {
    if (!isPanelOpen && firstAIEnabledField?.name) {
      document
        ?.getElementById(firstAIEnabledField?.name)
        ?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
    } else if (isPanelOpen) {
      feedbackFormRef.current?.restart()
    }
    track({
      event: mixpanelEvents.BUTTON_PRESS,
      action: isPanelOpen ? actions.AI_DRAFTS_WORKSPACE_CLOSE : actions.AI_DRAFTS_WORKSPACE_OPEN,
      details: {
        ...(noteAppointmentSummary?.status && { summaryStatus: noteSummaryStatusText[noteAppointmentSummary?.status] }),
        noteType: sessionNoteTypes[note.note_type],
        ...(note.appointment_id && { appointmentId: note.appointment_id?.toString() }),
      },
    })
    setIsPanelOpen(!isPanelOpen)
  }

  const scrollToFirstErrorIfNeeded = useCallback((setTouched: FormButtonParams['setTouched']) => {
    const errors = setTouched()
    if (errors.length < 1) {
      return
    }
    setTimeout(() => {
      document
        ?.querySelector('[role=alert]')
        ?.parentElement?.parentElement?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
    }, 100)
  }, [])

  const submitButton = useCallback(
    ({ handleSubmit, loading, setTouched }: FormButtonParams) => {
      return (
        <PrimaryButton
          text={loading ? 'Saving' : 'Submit'}
          onPress={() => {
            scrollToFirstErrorIfNeeded(setTouched)
            handleSubmit()
          }}
          testID={tID('ClientNoteDetails-submit')}
          disabled={isNoteForFutureAppointment || note.status === 'completed' || loading}
          loading={isNoteBeingManuallySubmitted}
        />
      )
    },
    [isNoteBeingManuallySubmitted, isNoteForFutureAppointment, note.status, scrollToFirstErrorIfNeeded],
  )

  return (
    <Container testID={tID('ClientNoteDetails-container')} ref={noteDetailsContainerRef}>
      <MainSectionContainer isPanelOpen={isPanelOpen}>
        <NoteInfoContainer>
          <GoBackContainer
            onPress={() =>
              navigate(CLIENT_NOTES.route, { state: { selectedEpisode: get(location.state, 'selectedEpisode') } })
            }
            testID={tID('ClientNoteDetails-go-back')}
          >
            <ChevronIcon direction={ChevronIconDirection.LEFT} fillColor={colors.ui_oatmeal5} />
            <BodyText text='Back' color={colors.ui_oatmeal5} />
          </GoBackContainer>
          {noteType !== NoteTypes.GENERAL && (
            <SidebarContainer>
              <NotesSidebar
                sessionDate={note.sessionDate || (note?.body?.sessionDate as string)}
                sessionNumber={note.sessionNumber || (note?.body?.sessionNumber as string)}
                noteMetadata={noteMetadata}
                completedNote={note.status === NoteStatusStates.COMPLETED}
                noteValues={note.body}
                summaryStatus={noteAppointmentSummary?.status}
                clientDetails={clientDetails}
              />
            </SidebarContainer>
          )}
          <NoteLayoutContainer>
            <NoteContainer
              note={note}
              formContext={{
                intl,
                icd10s: mappedIcd10s,
                states: STATES.map((state) => state.value),
                worksheets,
                lessons,
                infosheets,
                externalValues: { isGPSv1Enabled },
              }}
              saveForm={saveForm}
              asyncSaveForm={asyncSaveForm}
              submitButton={submitButton}
              showSubmit={!isFellow || isSupervisor}
              noteIsBeingAutoSaved={isNoteBeingAutoSaved}
              autoSaveFailed={autoSaveFailed}
              noteRef={noteRef}
              providerName={isFellowSupervisor ? `${supervisor.first_name} ${supervisor.last_name}` : userName}
            />
          </NoteLayoutContainer>
        </NoteInfoContainer>
      </MainSectionContainer>
      {displayAINotesPanel && firstAIEnabledField?.name && promptType && (
        <AINotesPanelContainer testID={tID('ClientNoteDetails-aiNotesPanelContainer')} ref={panelContainerRef}>
          <ExpandingSidePanel isPanelOpen={isPanelOpen} setIsPanelOpen={onOpenPanel}>
            {isPanelOpen && note.appointment_id ? (
              <ClientNotesPanel
                feedbackFormRef={feedbackFormRef}
                fieldName={firstAIEnabledField?.name}
                noteAppointmentSummary={noteAppointmentSummary}
                noteId={note.id}
                noteRef={noteRef}
                panelTitle={firstAIEnabledField?.title ?? ''}
                setIsPanelOpen={onOpenPanel}
                noteType={note.note_type}
                appointmentId={note.appointment_id.toString()}
                promptType={promptType}
              />
            ) : (
              <></>
            )}
          </ExpandingSidePanel>
        </AINotesPanelContainer>
      )}
    </Container>
  )
}

export type ClientNoteDetailsProps = ConnectedProps<typeof connector> & {}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators({ getAppointmentSummaries, submitNote, updateNote }, dispatch),
})

const connector = connect(null, mapDispatchToProps)

export default connector(ClientNoteDetails)
