import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { isBefore, parseISO } from 'date-fns'
import { Map } from 'immutable'
import { get, isEmpty, isNil } from 'lodash-es'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import { css } from 'styled-components'

import {
  Assignment,
  assignmentHasCompletedResponse,
  assignmentHasProviderDraft,
  assignmentOnlyHasProviderDraft,
  Episode,
  getCountryDisplayName,
  getDateFromAppointment,
  ProgramNames,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BootstrapContainer,
  DropdownButton,
  LoadingIndicator,
  LyraPopover,
  MessageIcon,
  NotificationIcon,
  useNewMessageSound,
  useWindowVisible,
} from '@lyrahealth-inc/ui-core'
import { CountryIcon, FullScreenOverlay, Modal, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'
import { ActivityBadge } from '@lyrahealth-inc/ui-core-crossplatform/src'

import styles from './client.module.scss'
import { setCurrentSessionCount } from './data/appointmentsSlice'
import { useClientAppointmentsData } from './data/hooks/useClientAppointmentsData'
import { clearSelectedClientStore, getClientId, getLtClient } from './data/ltClientDetailsAutoActions'
import { actions as mixpanelActions } from '../../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../../mixpanel/mixpanelTracking'
import { episodeStates, LCTT_ACTIVITY_BADGE_TEXT } from '../../../common/constants/appConstants'
import { programIconMap } from '../../../common/constants/programIconMap'
import {
  CLIENT_END_OF_SESSION,
  CLIENT_HOME,
  CLIENT_MEDICATIONS,
  CLIENT_NOTES,
  CLIENT_PROFILE,
  CLIENT_PROGRESS,
  CLIENTS_NEW_ASSIGNMENT,
  CLIENTS_SESSIONS,
  LT_CLIENTS,
} from '../../../common/constants/routingConstants'
import { getClientFullName, getEpisodeDropdownItems, getEpisodeDropdownText } from '../../../common/utils/utils'
import { getAuthConfig, getAuthUser } from '../../../data/auth/authSelectors'
import {
  getClientCurrentSessionCount,
  getClientDetailsData,
  getClientEpisodeProgramConfig,
  getClientNotesData,
  getClientSelectedEpisode,
  getClientUnclosedPastAppointments,
} from '../../../data/lyraTherapy/clientSelectors'
import { getLyraTherapyContentsData, getLyraTherapyContentsPrograms } from '../../../data/lyraTherapy/contentSelectors'
import { getRequestPaymentRates } from '../../../data/requestPayment/requestPaymentSelectors'
import { RootState, useAppDispatch } from '../../../data/store'
import { getProviderDetailsData } from '../../../providers/data/providerSelectors'
import { getProvider } from '../../../providers/individualProvider/data/providerDetailsActions'
import { getProviderRates } from '../../../requestPayment/data/requestPaymentActions'
import ActivityActionsModal from '../../assignments/ActivityActionsModal'
import { getAssignments, setAssignment } from '../../assignments/data/assignmentsAutoActions'
import ProviderExerciseDraft from '../../assignments/ProviderExerciseDraft'
import { getNotes } from '../../clientNotes/data/clientNotesAutoActions'
import { getContentsByProgramId, getPrograms } from '../../data/ltContentsAutoActions'
import { getLTVideoSessionInfo, getLTVideoSessionOpen } from '../../data/ltVideoSelectors'
import { getClientEpisodes, setSelectedEpisode, updateEpisode } from '../../episodes/data/episodesAutoActions'
import {
  selectMessageConversation,
  setLiveMsgClientIdToOpen,
  toggleLiveMsgSession,
} from '../../messages/data/messagesAutoActions'
import {
  getConversations,
  getConversationsClient,
  getMessagesActiveLiveMsgAppt,
  getMessagesConversationsFetched,
  getMessagesLiveMsgClientIdToOpen,
  getSelectedConversation,
  inLiveMsgSession,
} from '../../messages/data/messagesSelectors'
import MessagesPopover from '../../messages/MessagesPopover'

const Client = ({
  clientDetails,
  messageConversations,
  conversationsFetched,
  notes,
  currentSessionCount,
  videoSessionOpen,
  videoSessionInfo,
  currentEpisode,
  programConfig,
  programs,
  selectedConversation,
  inLiveMsgSession,
  liveMsgClientIdToOpen,
  activeLiveMsgAppt,
  rates,
  providerDetailsData,
  conversationsClient,
  pastAppointmentsToClose,
  contents,

  actions: {
    selectMessageConversation,
    getClientId,
    getLtClient,
    getAssignments,
    setSelectedEpisode,
    getClientEpisodes,
    getNotes,
    getContentsByProgramId,
    clearSelectedClientStore,
    getProviderRates,
    getPrograms,
    toggleLiveMsgSession,
    setLiveMsgClientIdToOpen,
    getProvider,
  },
}: any) => {
  const user = useSelector(getAuthUser)
  const config = useSelector(getAuthConfig)
  const [actionModalContent, setActionModalContent] = useState()
  const [fullScreenOverlayContent, setFullScreenOverlayContent] = useState<React.ReactElement | null>(null)
  const [msgPopoverOpen, setMsgPopoverOpen] = useState(false)
  const [clientId, setClientId] = useState(clientDetails?.id)
  const numUnreadMessages = selectedConversation?.conversation_attributes?.unread_provider_messages_count ?? 0
  const [playSound] = useNewMessageSound()
  const [windowVisible] = useWindowVisible()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { isPreferredNameEnabled } = useFlags()
  const {
    data: appointments,
    isLoading: loadingAppointments,
    isSuccess: fetchedAppointments,
  } = useClientAppointmentsData(undefined, { refetchOnMountOrArgChange: true })

  const pastAppointments = useMemo(
    () =>
      appointments?.filter(
        (appt) =>
          isBefore(getDateFromAppointment(appt), new Date()) &&
          !['canceled', 'missed'].includes(appt.appointmentStatus),
      ) ?? [],
    [appointments],
  )
  const appointmentToClose = pastAppointmentsToClose.find((appt: any) => {
    return appt.episodeId === currentEpisode?.id
  })

  useEffect(() => {
    setClientId(clientDetails?.id)
  }, [clientDetails?.id])

  useFetcher([getProvider, [user?.id], isEmpty(providerDetailsData), false])

  const editAssignment = (
    e: React.SyntheticEvent,
    _actionName: string,
    assignment: Assignment,
    sessionCount: number,
  ) => {
    e.preventDefault()
    navigate(CLIENTS_NEW_ASSIGNMENT.route, {
      state: {
        content: assignment,
        sessionCount: programConfig.sessionLabel === 'Week' ? assignment.session_period : sessionCount,
        editMode: true,
      },
    })
  }

  const openActionModal = (e: any, actionName: any, assignment: Assignment, sessionCount: any) => {
    e.stopPropagation()
    track({ event: 'BUTTON_PRESS', action: mixpanelActions.activities[actionName] })
    // @ts-expect-error TS(2345): Argument of type '{ actionName: any; assignment: a... Remove this comment to see the full error message
    setActionModalContent({ actionName, assignment, sessionCount })
  }

  const startExerciseDraft = (e: any, actionName: string, assignment: Assignment, sessionCount: number) => {
    e.preventDefault()
    track({ event: 'BUTTON_PRESS', action: mixpanelActions.activities[actionName] })

    let metaData = {}
    let instructions = ''
    let contentId = ''
    if (!assignment.content_meta_data) {
      // If we are starting a draft for an Exercise in a future Session, it will not have any meta_data for
      // the form, so we need to get it from the store.
      const content = contents.find((content: any) => content.name === assignment.name)
      metaData = content?.meta_data
      instructions = content?.instructions
      contentId = content?.id
    }

    setFullScreenOverlayContent(
      <ProviderExerciseDraft
        assignmentId={assignment.id}
        content={assignment.content_meta_data || metaData}
        contentId={assignment.content_id || contentId}
        instructions={assignment.instructions || instructions}
        name={assignment.content?.name || assignment.name}
        onExit={() => {
          setFullScreenOverlayContent(null)
        }}
        sessionCount={sessionCount}
        title={assignment.content?.title || assignment.title}
      />,
    )
  }

  const activityActions = [
    {
      name: 'edit',
      text: 'Edit',
      selectHandler: editAssignment,
      shouldShow: (assignment: Assignment, sessionCount: any) => {
        return (
          programConfig.activityActions?.edit &&
          sessionCount >= currentSessionCount &&
          (!['completed', 'in_progress'].includes(assignment.status) || assignmentOnlyHasProviderDraft(assignment))
        )
      },
    },
    {
      name: 'startDraft',
      text: 'Start a draft',
      selectHandler: startExerciseDraft,
      shouldShow: (assignment: Assignment) =>
        (assignment.content?.group === 'exercise' || assignment.group === 'exercise') &&
        !assignmentHasProviderDraft(assignment) &&
        !assignmentHasCompletedResponse(assignment),
    },
    {
      name: 'copy',
      text: 'Copy to...',
      selectHandler: openActionModal,
      shouldShow: () => programConfig.activityActions?.copy,
    },
    {
      name: 'move',
      text: 'Move to...',
      selectHandler: openActionModal,
      shouldShow: (assignment: any, sessionCount: any) =>
        programConfig.activityActions?.move && sessionCount >= currentSessionCount,
    },
    {
      name: 'unassign',
      text: 'Remove',
      selectHandler: openActionModal,
      shouldShow: (assignment: Assignment, sessionCount: number) => {
        return (
          programConfig.activityActions?.unassign &&
          (!['completed', 'in_progress'].includes(assignment.status) || sessionCount > currentSessionCount)
        )
      },
    },
  ]

  const getClientFromQueryParams = useCallback(async () => {
    if (!clientId) {
      const relationshipId = get(queryString.parse(location.search), 'relId')
      if (relationshipId) {
        const res = await getClientId({ relationshipId })
        const patientId = get(res, 'patient_id')
        if (patientId && get(res, 'provider_id') === user?.id) {
          setClientId(patientId)
        } else {
          navigate(LT_CLIENTS.route)
        }
      } else {
        navigate(LT_CLIENTS.route)
      }
    }
  }, [clientId, getClientId, navigate, location.search, user?.id])

  // loadingEpisodes and fetchedEpisodes refer to loading/fetching Client episodes AND Lt Client
  // @ts-expect-error TS(2548): Type 'unknown[] | undefined' is not an array type ... Remove this comment to see the full error message
  const [loadingEpisodes, [episodes = []], fetchedEpisodes] = useFetcher(
    [
      [getClientEpisodes, { provider_id: user?.id, patient_id: clientId }],
      [getLtClient, { clientId, providerId: user?.id }, clientId],
      [getPrograms, {}, isEmpty(programs)],
    ],
    [clientId, user?.id, (config as any).program],
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedEpisode =
    episodes?.find((episode: Episode) => episode.state === episodeStates.IN_PROGRESS) ||
    episodes?.toSorted((a: any, b: any) => isBefore(parseISO(get(a, 'end_date')), parseISO(get(b, 'end_date'))))[0] ||
    {}

  const ProgramIcon = programConfig?.icon && programIconMap[programConfig.icon]

  // loadingAppts and fetchedAppts refer to loading/fetching Appointments AND Assignments
  const [loadingAssignments, , fetchedAssignments] = useFetcher(
    [[getAssignments, { patient_id: clientId, provider_id: user?.id }]],
    [clientId, user?.id],
  )

  const [loadingRates] = useFetcher([getProviderRates, { id: user?.id }, isEmpty(rates)], [JSON.stringify(rates)])

  // loadingNotes and fetchedNotes refers to loading/fetching Notes AND Contents by Program ID
  const [loadingNotes, , fetchedNotes] = useFetcher(
    [
      [getNotes, { episode_id: selectedEpisode.id }],
      [getContentsByProgramId, { programId: selectedEpisode.program_id }],
    ],
    [selectedEpisode.id, selectedEpisode.program_id, user?.id],
  )

  const loading = loadingEpisodes || loadingAssignments || loadingAppointments || loadingNotes || loadingRates
  const fetched = fetchedEpisodes && fetchedAssignments && fetchedNotes && fetchedAppointments
  const display = !loading && fetched
  const currentEpisodeId = currentEpisode?.id

  useEffect(() => {
    if (appointments && currentEpisodeId) {
      dispatch(setCurrentSessionCount({ episodeId: currentEpisodeId, appointments }))
    }
  }, [appointments, currentEpisodeId, dispatch])

  useEffect(() => {
    getClientFromQueryParams()
  }, [getClientFromQueryParams])

  useEffect(() => () => clearSelectedClientStore(), [clearSelectedClientStore])

  useEffect(() => {
    if (selectedEpisode.id) {
      setSelectedEpisode({ episode: selectedEpisode })
    }
  }, [selectedEpisode, setSelectedEpisode])

  const conversationStringified = JSON.stringify(
    messageConversations.find((conversation: any) => conversation.patient_lyra_id === clientId),
  )

  useEffect(() => {
    if (conversationStringified) {
      const conversationsParsed = JSON.parse(conversationStringified)
      // if a conversation has not been selected before or a different client id has been set
      if (conversationsFetched) {
        if (conversationsParsed) {
          selectMessageConversation(conversationsParsed)
        }
      }
    }
  }, [conversationsFetched, conversationStringified, selectMessageConversation])

  // when a new message comes in
  // if the user is not in a video session and the window is not active, play a sound
  const onNewMessage = useCallback(
    (message) => {
      if (
        get(message, 'state.attributes.from') === user?.id ||
        message.conversation.sid !== selectedConversation?.conversation_id
      ) {
        return
      }
      if (!videoSessionOpen && (!msgPopoverOpen || !windowVisible)) {
        playSound()
      }
    },
    [msgPopoverOpen, playSound, windowVisible, videoSessionOpen, user?.id, selectedConversation],
  )

  useEffect(() => {
    if (conversationsClient) {
      conversationsClient.on('messageAdded', onNewMessage)
    }
    return () => {
      if (conversationsClient) {
        conversationsClient.off('messageAdded', onNewMessage)
      }
    }
  }, [conversationsClient, onNewMessage])

  const getNotifications = () => {
    const notifications = []
    if (!isNil(appointmentToClose)) {
      notifications.push({
        text: `Close Session ${appointmentToClose.sessionNumber}`,
        icon: NotificationIcon,
        clickAction: () => navigate(CLIENT_END_OF_SESSION.route),
        isDisabled: videoSessionOpen && videoSessionInfo?.appointment_id === String(appointmentToClose.appointmentId),
        disabledTooltip: `Please hang up the video call before closing Session ${appointmentToClose.sessionNumber}`,
      })
    }

    // We use filter here to map an appointment to a notification object
    const notesNotifications = pastAppointments
      .filter((appt: any) => {
        const sessionNote = notes.find(
          (note: any) =>
            note.appointment_id === appt.appointmentId &&
            note.status === 'completed' &&
            note.episode_id === appt.episodeId,
        )
        return !sessionNote && appt.episodeId === selectedEpisode.id
      })
      .map((appt: any) => ({
        text: `Add Session ${appt.sessionNumber} Notes`,
        icon: NotificationIcon,
        clickAction: () => navigate(CLIENT_NOTES.route),
      }))
    notifications.push(...notesNotifications)
    return notifications
  }

  const openPopover = useCallback(() => {
    if (!inLiveMsgSession && clientId === activeLiveMsgAppt?.userId) {
      toggleLiveMsgSession(true)
    }
    setMsgPopoverOpen(true)
    track({ event: 'OPEN_MESSENGER', details: { program: currentEpisode?.program_name } })
  }, [activeLiveMsgAppt, clientId, inLiveMsgSession, toggleLiveMsgSession, currentEpisode])

  const closePopover = useCallback(() => {
    setMsgPopoverOpen(false)
  }, [])

  const togglePopover = (e: any) => {
    e.stopPropagation()
    if (msgPopoverOpen) {
      closePopover()
    } else {
      openPopover()
    }
  }

  // This effect is listening on whether or not user joined the session through the live message banner.
  useEffect(() => {
    if (liveMsgClientIdToOpen && clientId === liveMsgClientIdToOpen) {
      openPopover()
      setLiveMsgClientIdToOpen(null)
    }
  }, [liveMsgClientIdToOpen, openPopover, setLiveMsgClientIdToOpen, clientId])

  const renderSubNav = () => (
    <div styleName='sub-nav'>
      <BootstrapContainer>
        <div styleName='sub-nav-content-container'>
          <div styleName='sub-nav-content' data-test-id='Client-subNav'>
            <div>
              <div styleName='name-container'>
                <h3>
                  {getClientFullName(clientDetails, isPreferredNameEnabled)}
                  {display && selectedEpisode.state !== episodeStates.IN_PROGRESS ? ' (inactive)' : ''}
                </h3>
                {selectedEpisode.program_name === ProgramNames.TeensTherapy && (
                  <ActivityBadge
                    text={LCTT_ACTIVITY_BADGE_TEXT}
                    backgroundColor={'#DCE0F7'}
                    color={'#1f1f6E'}
                    activityBadgeCustomStyle={css`
                      border-radius: 4px;
                      margin-left: 8px;
                      margin-right: 6px;
                      padding: 4px 8px;
                    `}
                  />
                )}
                {ProgramIcon && (
                  <div className={styles['icon-container']} data-test-id='Client-programIcon'>
                    <ProgramIcon width={44} />
                  </div>
                )}
                {!isEmpty(clientDetails?.country) && clientDetails?.country !== 'US' && (
                  <div className={styles['country-container']}>
                    {/* @ts-expect-error TS(2322): Type '{ children: Element; content: Element; iconI... Remove this comment to see the full error message */}
                    <LyraPopover
                      content={
                        <div>
                          This client is located in {getCountryDisplayName(clientDetails?.country)}. For more guidance
                          on country specifics,{' '}
                          <a
                            href='https://coaching-lyrahealth.zendesk.com/hc/en-us/articles/6282342838675'
                            target='_blank'
                            rel='noreferrer'
                          >
                            go to Zendesk article to learn more.
                          </a>
                        </div>
                      }
                      iconId='life-impairment-question-popover'
                      openOnHover={true}
                      keepOpenOnHover={true}
                      placement='bottom'
                      customClass={styles.popover}
                    >
                      <CountryIcon countryCode={clientDetails?.country} />
                    </LyraPopover>
                  </div>
                )}
              </div>

              {[CLIENT_HOME.route, CLIENTS_SESSIONS.route, CLIENT_PROFILE.route].includes(location.pathname) &&
                episodes?.length > 1 && (
                  <div styleName='dropdown-container'>
                    <DropdownButton
                      styling='inline'
                      data-test-id='Assignments-select-episode'
                      id='selectEpisode'
                      dropdownItems={getEpisodeDropdownItems(episodes, setSelectedEpisode)}
                    >
                      <span styleName='episode-select'>
                        {getEpisodeDropdownText(currentEpisode || selectedEpisode)}
                      </span>
                    </DropdownButton>
                  </div>
                )}
            </div>
            <div styleName='links'>
              <NavLink end to={CLIENT_HOME.route} styleName={display ? '' : 'hidden'}>
                Home
              </NavLink>
              <NavLink to={CLIENTS_SESSIONS.route} styleName={display ? '' : 'hidden'}>
                Sessions
              </NavLink>
              <NavLink to={CLIENT_PROGRESS.route} styleName={display ? '' : 'hidden'}>
                Progress
              </NavLink>
              <NavLink to={CLIENT_NOTES.route} styleName={display ? '' : 'hidden'}>
                Notes
              </NavLink>
              {config?.medicationEnabled && (
                <NavLink to={CLIENT_MEDICATIONS.route} styleName={display ? '' : 'hidden'}>
                  Medications
                </NavLink>
              )}
              <NavLink to={CLIENT_PROFILE.route} styleName={display ? '' : 'hidden'}>
                Profile
              </NavLink>
              <MessageIcon
                fillColor={msgPopoverOpen ? styles.x_primary_action : styles.x_medium_gray}
                isFilled
                withBadge
                width={24}
                styleName='message-icon'
                unreadMessages={msgPopoverOpen ? 0 : numUnreadMessages}
                // @ts-expect-error TS(2322): Type '(e: any) => void' is not assignable to type ... Remove this comment to see the full error message
                onMouseDown={togglePopover}
                data-test-id='MessagesPopover-MessageIcon'
                onKeyDown={(e: KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    togglePopover(e)
                  }
                }}
                aria-label='chat with patient'
                role='button'
                tabIndex={0}
              />
              {msgPopoverOpen && <MessagesPopover closePopover={closePopover} />}
            </div>
          </div>
        </div>
      </BootstrapContainer>
    </div>
  )

  return (
    <div>
      {renderSubNav()}
      <Modal
        visible={Boolean(actionModalContent)}
        modalContents={
          <ActivityActionsModal
            data={actionModalContent}
            currentSession={currentSessionCount}
            closeModal={() => setActionModalContent(undefined)}
          />
        }
        onRequestClose={() => setActionModalContent(undefined)}
      />
      <FullScreenOverlay
        isOpen={!!fullScreenOverlayContent}
        customStyles={videoSessionOpen ? { top: '90px', zIndex: '10' } : {}}
        isFocused={false}
      >
        {fullScreenOverlayContent}
      </FullScreenOverlay>
      {isEmpty(selectedEpisode) || loading ? (
        <div styleName='loading-container'>
          <LoadingIndicator size={45} />
        </div>
      ) : (
        <Outlet
          context={{
            activityActions,
            notifications: getNotifications(),
            appointment: appointmentToClose || {},
          }}
        />
      )}
    </div>
  )
}

Client.propTypes = {
  clientDetails: PropTypes.object,
  actions: PropTypes.object,
  currentSessionCount: PropTypes.number,
  pastAppointmentsToClose: PropTypes.array,
  notes: PropTypes.array,
  messageConversations: PropTypes.array,
  conversationsFetched: PropTypes.bool,
  videoSessionOpen: PropTypes.bool,
  videoSessionInfo: PropTypes.object,
  programs: PropTypes.array,
  programConfig: PropTypes.object,
  // @ts-expect-error TS(2345): Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
  providerDetailsData: PropTypes.instanceOf(Map),
  currentEpisode: PropTypes.object,
  selectedConversation: PropTypes.object,
  inLiveMsgSession: PropTypes.bool,
  liveMsgClientIdToOpen: PropTypes.string,
  activeLiveMsgAppt: PropTypes.object,
  rates: PropTypes.array,
  conversationsClient: PropTypes.object,
}

const mapStateToProps = (state: RootState) => {
  return {
    clientDetails: getClientDetailsData(state),
    currentSessionCount: getClientCurrentSessionCount(state),
    messageConversations: getConversations(state),
    conversationsFetched: getMessagesConversationsFetched(state),
    notes: getClientNotesData(state),
    videoSessionOpen: getLTVideoSessionOpen(state),
    videoSessionInfo: getLTVideoSessionInfo(state),
    // Appointments are ordered by appointment start date, hence, we do a find to get
    // the first appointment that hasn't been completed.
    // An appointment needs to be closed out if its start date is in the past, and its status
    // is either 'rescheduled', 'newAppt' or 'concluded' ('concluded' meaning that the appointment happened)
    programs: getLyraTherapyContentsPrograms(state),
    programConfig: getClientEpisodeProgramConfig(state),
    pastAppointmentsToClose: getClientUnclosedPastAppointments(state) ?? [],
    currentEpisode: getClientSelectedEpisode(state),
    selectedConversation: getSelectedConversation(state),
    inLiveMsgSession: inLiveMsgSession(state),
    activeLiveMsgAppt: getMessagesActiveLiveMsgAppt(state),
    liveMsgClientIdToOpen: getMessagesLiveMsgClientIdToOpen(state),
    rates: getRequestPaymentRates(state),
    providerDetailsData: getProviderDetailsData(state),
    conversationsClient: getConversationsClient(state),
    contents: getLyraTherapyContentsData(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      clearSelectedClientStore,
      getClientId,
      getLtClient,
      getProviderRates,
      setAssignment,
      getClientEpisodes,
      updateEpisode,
      getAssignments,
      setSelectedEpisode,
      getNotes,
      getContentsByProgramId,
      selectMessageConversation,
      getPrograms,
      toggleLiveMsgSession,
      setLiveMsgClientIdToOpen,
      getProvider,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(Client, styles))
