import { isString } from 'lodash-es'

import { addAlert } from '../../data/alertActions'
import store from '../../data/store'
import { getErrorText, logToSumoLogic } from '../utils/utils'

// auth
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SAVE_LAST_AUTHORIZED_ROUTE = 'SAVE_LAST_AUTHORIZED_ROUTE'
export const ADD_ALERT = 'ADD_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const HIDE_ALERTS = 'HIDE_ALERTS'
export const EXPIRE_ALERTS = 'EXPIRE_ALERTS'
export const REGISTER_SUCCESSFUL = 'REGISTER_SUCCESSFUL'
export const REFRESH_API_TOKEN = 'REFRESH_API_TOKEN'
export const REPLACE_USER = 'REPLACE_USER'

// providers
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_PROVIDERS_LITE = 'GET_PROVIDERS_LITE'
export const GET_PROVIDER = 'GET_PROVIDER'
export const GET_PROVIDER_RATES = 'GET_PROVIDER_RATES'
export const SET_PROVIDER_CAPACITY = 'SET_PROVIDER_CAPACITY'
export const GET_PROVIDER_CAPACITY = 'GET_PROVIDER_CAPACITY'
export const SET_SELECTED_PROVIDER_CAPACITY = 'SET_SELECTED_PROVIDER_CAPACITY'
export const PROVIDER_SELECTED = 'PROVIDER_SELECTED'
export const SUBMIT_CLIENT_EMAIL = 'SUBMIT_CLIENT_EMAIL'
export const SUBMIT_CLIENT_EMAIL_HEALTHPLAN = 'SUBMIT_CLIENT_EMAIL_HEALTHPLAN'
export const CLEAR_PROVIDER_DETAILS_STORE = 'CLEAR_PROVIDER_DETAILS_STORE'
export const CLEAR_PROVIDER_DETAILS_PAYMENT_HISTORY = 'CLEAR_PROVIDER_DETAILS_PAYMENT_HISTORY'
export const TOGGLE_REGISTER_PROVIDER_MODAL = 'TOGGLE_REGISTER_PROVIDER_MODAL'
export const TOGGLE_PROVIDER_DETAILS_MODAL = 'TOGGLE_PROVIDER_DETAILS_MODAL'
export const SELECT_ACCOUNT_TYPE = 'SELECT_ACCOUNT_TYPE'
export const CLEAR_ACCOUNT_TYPE = 'CLEAR_ACCOUNT_TYPE'
export const GET_PROVIDER_PAYMENT_HISTORY = 'GET_PROVIDER_PAYMENT_HISTORY'
export const GET_PROVIDER_PAYMENT_HISTORY_CSV = 'GET_PROVIDER_PAYMENT_HISTORY_CSV'
export const DOWNLOAD_PROVIDERS_CSV = 'DOWNLOAD_PROVIDERS_CSV'
export const UPDATE_RATES = 'UPDATE_RATES'
export const UPDATE_GRACE_PERIOD = 'UPDATE_GRACE_PERIOD'
export const UPDATE_EXPEDITED_BOOKING_STATUS = 'UPDATE_EXPEDITED_BOOKING_STATUS'
export const UPDATE_IN_PERSON_PREFERENCE_STATUS = 'UPDATE_IN_PERSON_PREFERENCE_STATUS'
export const GET_PROVIDER_TASK = 'GET_PROVIDER_TASK'
export const PATCH_PROVIDER_TASK = 'PATCH_PROVIDER_TASK'
export const SUBMIT_PROVIDER_OOO_MESSAGE = 'SUBMIT_PROVIDER_OOO_MESSAGE'

// payments
export const SUBMIT_PAYMENT_REQUEST = 'SUBMIT_PAYMENT_REQUEST'
export const CLEAR_REQUEST_PAYMENT_STORE = 'CLEAR_REQUEST_PAYMENT_STORE'
export const GET_CHARGE_TO_COPY = 'GET_CHARGE_TO_COPY'
export const GET_PAYMENTS_OF_TYPE = 'GET_PAYMENTS_OF_TYPE'
export const GET_PAYMENTS_BALANCES = 'GET_PAYMENTS_BALANCES'
export const GET_PAYMENT_HISTORY_OF_TYPE = 'GET_PAYMENT_HISTORY_OF_TYPE'
export const PAYMENT_HISTORY_SHOW_PAYMENT_MODAL = 'PAYMENT_HISTORY_SHOW_PAYMENT_MODAL'
export const PAYMENT_HISTORY_HIDE_PAYMENT_MODAL = 'PAYMENT_HISTORY_HIDE_PAYMENT_MODAL'
export const CLEAR_PAYMENT_HISTORY_STORE = 'CLEAR_PAYMENT_HISTORY_STORE'
export const UPDATE_CHARGE_STATUS = 'UPDATE_CHARGE_STATUS'
export const UPDATE_REVERSE_CHARGE_STATUS = 'UPDATE_REVERSE_CHARGE_STATUS'
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_BY_COUNTRY = 'GET_CUSTOMERS_BY_COUNTRY'
export const CLOSE_SESSIONS_MODAL = 'CLOSE_SESSIONS_MODAL'
export const CLOSE_SESSIONS_EXCEEDED_MODAL = 'CLOSE_SESSIONS_EXCEEDED_MODAL'
export const UPDATE_CHARGE_COMMENT = 'UPDATE_CHARGE_COMMENT'
export const SHOW_DUPLICATE_PAYMENT_MODAL = 'SHOW_DUPLICATE_PAYMENT_MODAL'
export const CLOSE_DUPLICATE_PAYMENT_MODAL = 'CLOSE_DUPLICATE_PAYMENT_MODAL'
export const SHOW_CANCELLED_SESSIONS_EXCEEDED_MODAL = 'SHOW_CANCELLED_SESSIONS_EXCEEDED_MODAL'
export const CLOSE_CANCELLED_SESSIONS_EXCEEDED_MODAL = 'CLOSE_CANCELLED_SESSIONS_EXCEEDED_MODAL'
export const PURGE_TOO_FREQUENT_REJECTIONS = 'PURGE_TOO_FREQUENT_REJECTIONS'
export const HANDLE_CHARGE_UPDATE_ERROR = 'HANDLE_CHARGE_UPDATE_ERROR'
export const CLEAR_UPDATED_CHARGES = 'CLEAR_UPDATED_CHARGES'
export const CLOSE_OUTCOME_REMINDER_MODAL = 'CLOSE_OUTCOME_REMINDER_MODAL'
export const GET_COMPANY_SESSION_TYPES = 'GET_COMPANY_SESSION_TYPES'
export const SET_LYRA_CODE_VALIDATION_INFO = 'SET_LYRA_CODE_VALIDATION_INFO'
export const CLEAR_LYRA_CODE_VALIDATION_INFO = 'CLEAR_LYRA_CODE_VALIDATION_INFO'
export const SET_ELIGIBILITY_STATUS = 'SET_ELIGIBILITY_STATUS'
export const SET_HP_ELIGIBILITY_STATUS = 'SET_HP_ELIGIBILITY_STATUS'
export const CLEAR_HP_ELIGIBILITY_STATUS = 'CLEAR_HP_ELIGIBILITY_STATUS'
export const DISPLAY_INELIGIBLE_CLIENT_MODAL = 'DISPLAY_INELIGIBLE_CLIENT_MODAL'
export const CLOSE_INELIGIBLE_CLIENT_MODAL = 'CLOSE_INELIGIBLE_CLIENT_MODAL'
export const CLEAR_PAYMENTS_LIST_OF_TYPE = 'CLEAR_PAYMENTS_LIST_OF_TYPE'

// Download CSV
export const TOGGLE_CSV_DOWNLOAD_MODAL = 'TOGGLE_CSV_DOWNLOAD_MODAL'
// practices
export const GET_PRACTICE_PROVIDERS = 'GET_PRACTICE_PROVIDERS'
export const GET_PRACTICES = 'GET_PRACTICES'
export const GET_PRACTICE = 'GET_PRACTICE'
export const PRACTICE_SELECTED = 'PRACTICE_SELECTED'
export const GET_PROVIDERS_FOR_PRACTICE = 'GET_PROVIDERS_FOR_PRACTICE'
export const CLEAR_PRACTICE_DETAILS_STORE = 'CLEAR_PRACTICE_DETAILS_STORE'
export const TOGGLE_REGISTER_PRACTICE_MODAL = 'TOGGLE_REGISTER_PRACTICE_MODAL'
export const TOGGLE_PRACTICE_DETAILS_MODAL = 'TOGGLE_PRACTICE_DETAILS_MODAL'
export const TOGGLE_ADD_PROVIDER_MODAL = 'TOGGLE_ADD_PROVIDER_MODAL'
export const GET_PRACTICE_PAYMENT_HISTORY = 'GET_PRACTICE_PAYMENT_HISTORY'
export const GET_AVAILABLE_PRACTICES = 'GET_AVAILABLE_PRACTICES'
export const SHOW_ADDPRACTICE_MODAL = 'SHOW_ADDPRACTICE_MODAL'
export const HIDE_ADDPRACTICE_MODAL = 'HIDE_ADDPRACTICE_MODAL'
export const ADD_PRACTICE = 'ADD_PRACTICE'
export const SELECT_PROVIDER = 'SELECT_PROVIDER'
export const ADD_PROVIDER_TO_PRACTICE = 'ADD_PROVIDER_TO_PRACTICE'

// clients
export const GET_CLIENTS = 'GET_CLIENTS'
export const SUBMIT_PROVIDER_OUTCOMES_CONSENT = 'SUBMIT_PROVIDER_OUTCOMES_CONSENT'
export const SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS'
export const GET_CLIENT = 'GET_CLIENT'
export const GET_CLIENT_OUTCOUMES = 'GET_CLIENT_OUTCOUMES'
export const GET_CLIENT_SESSION = 'GET_CLIENT_SESSION'
export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS'
export const RESENT_OUTCOME_EMAIL = 'RESEND_OUTCOME_EMAIL'
export const CHECK_CLIENT_ELIGIBILITY = 'CHECK_CLIENT_ELIGIBILITY'

// reconciler
export const UNRECONCILED_CLIENT_SELECTED = 'UNRECONCILED_CLIENT_SELECTED'
export const CLEAR_RECONCILER_DETAILS_STORE = 'CLEAR_RECONCILER_DETAILS_STORE'
export const GET_CLIENTS_TO_RECONCILE = 'GET_CLIENTS_TO_RECONCILE'
export const GET_POSSIBLE_MATCHES = 'GET_POSSIBLE_MATCHES'
export const SUBMIT_RECONCILER_MATCH = 'SUBMIT_RECONCILER_MATCH'
export const CLEAR_RECONCILER_LIST = 'CLEAR_RECONCILER_LIST'

// My Info
export const SET_MY_INFO_DATA = 'SET_MY_INFO_DATA'

// Health Plan
export const GET_ICD10S = 'GET_ICD10S'
export const SAVE_HEALTHPLAN_CHARGE = 'SAVE_HEALTHPLAN_CHARGE'
export const CLEAR_HEALTHPLAN_CHARGE = 'CLEAR_HEALTHPLAN_CHARGE'
export const GET_PAYMENTS_DATA = 'GET_PAYMENTS_DATA'

// Scheduling
export const GET_SCHEDULING = 'GET_SCHEDULING'
export const CONFIRM_ONLINE_BOOKING = 'CONFIRM_ONLINE_BOOKING'
export const UPDATE_CALENDAR_USE = 'UPDATE_CALENDAR_USE'
export const GET_SCHEDULING_UPDATED = 'GET_SCHEDULING_UPDATED'
export const SUBMIT_SCHEDULING_OAUTH = 'SUBMIT_SCHEDULING_OAUTH'

// Toast
export const SET_TOAST_CONTENT = 'SET_TOAST_CONTENT'
export const CLEAR_TOAST_CONTENT = 'CLEAR_TOAST_CONTENT'

// Lyra Therapy
export const GET_VIDEO_CALL_TOKEN = 'GET_VIDEO_CALL_TOKEN'
export const GET_CONTENTS = 'GET_CONTENTS'
export const GET_CONTENT_CATEGORIES_TAGS = 'GET_CONTENT_CATEGORIES_TAGS'
export const CLEAR_LT_CLIENTS = 'CLEAR_LT_CLIENTS'
export const GET_LT_ACTIVE_CLIENTS_V2 = 'GET_LT_ACTIVE_CLIENTS_V2'
export const GET_LT_INACTIVE_CLIENTS_V2 = 'GET_LT_INACTIVE_CLIENTS_V2'
export const GET_LT_ACTIVE_CLIENTS_V2_BY_PROGRAM = 'GET_LT_ACTIVE_CLIENTS_V2_BY_PROGRAM'
export const GET_LT_INACTIVE_CLIENTS_V2_BY_PROGRAM = 'GET_LT_INACTIVE_CLIENTS_V2_BY_PROGRAM'
export const GET_CLIENTS_COUNTS = 'GET_CLIENTS_COUNTS'
export const SET_INITIAL_ACTIVE_CLIENTS_FETCHED = 'SET_INITIAL_ACTIVE_CLIENTS_FETCHED'
export const SET_INITIAL_INACTIVE_CLIENTS_FETCHED = 'SET_INITIAL_INACTIVE_CLIENTS_FETCHED'
export const GET_CLIENT_EPISODES = 'GET_CLIENT_EPISODES'
export const GET_CLOSED_EPISODES = 'GET_CLOSED_EPISODES'
export const SELECT_EPISODE = 'SELECT_EPISODE'
export const UPDATE_EPISODE = 'UPDATE_EPISODE'
export const GET_PROGRAMS = 'GET_PROGRAMS'
export const GET_ASSIGNMENTS = 'GET_ASSIGNMENTS'
export const SELECT_ASSIGNMENT = 'SELECT_ASSIGNMENT'
export const SET_ASSIGNMENT = 'SET_ASSIGNMENT'
export const UNSET_ASSIGNMENT = 'UNSET_ASSIGNMENT'
export const CLEAR_ASSIGNMENTS = 'CLEAR_ASSIGNMENTS'
export const CLEAR_LT_CLIENT_DATA = 'CLEAR_LT_CLIENT_DATA'
export const DECREASE_ASSIGNMENT_UNREAD_COUNT = 'DECREASE_ASSIGNMENT_UNREAD_COUNT'
export const DECREASE_SELECTED_ASSIGNMENT_UNREAD_COUNT = 'DECREASE_SELECTED_ASSIGNMENT_UNREAD_COUNT'
export const SET_ASSIGNMENT_RESPONSE = 'SET_ASSIGNMENT_RESPONSE'
export const UPDATE_ASSIGNMENT_RESPONSE = 'UPDATE_ASSIGNMENT_RESPONSE'
export const CLEAR_ASSIGNMENT = 'CLEAR_ASSIGNMENT'
export const CLEAR_ASSIGNMENT_RESPONSE = 'CLEAR_ASSIGNMENT_RESPONSE'
export const DELETE_ASSIGNMENT_RESPONSE = 'DELETE_ASSIGNMENT_RESPONSE'
export const SELECT_ASSIGNMENT_RESPONSE = 'SELECT_ASSIGNMENT_RESPONSE'
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT'
export const LT_SELECTED_CLIENT = 'LT_SELECTED_CLIENT'
export const DECREASE_SELECTED_CLIENT_UNREAD_COUNT = 'DECREASE_SELECTED_CLIENT_UNREAD_COUNT'
export const CLEAR_CURRICULUM = 'CLEAR_CURRICULUM'
export const VIDEO_SESSION_STATUS_UPDATE = 'VIDEO_SESSION_STATUS_UPDATE'
export const VIDEO_SESSION_SETTINGS_UPDATE = 'VIDEO_SESSION_SETTINGS_UPDATE'
export const UPDATE_VIDEO_SESSION_METADATA = 'UPDATE_VIDEO_SESSION_METADATA'
export const CLEAR_VIDEO_SESSION_INFO = 'CLEAR_VIDEO_SESSION_INFO'
export const RESET_VIDEO_SESSION_INFO = 'RESET_VIDEO_SESSION_INFO'
export const SET_CONVERSATIONS_CLIENT = 'SET_CONVERSATIONS_CLIENT'
export const GET_MESSAGE_CONVERSATIONS = 'GET_MESSAGE_CONVERSATIONS'
export const GET_MESSAGES = 'GET_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const SAVE_MESSAGE_DRAFT = 'SAVE_MESSAGE_DRAFT'
export const UPDATE_UNREAD_MESSAGE_COUNT = 'UPDATE_UNREAD_MESSAGE_COUNT'
export const INCREMENT_UNREAD_MESSAGE_COUNT = 'INCREMENT_UNREAD_MESSAGE_COUNT'
export const SET_SELECTED_CONVERSATION = 'SET_SELECTED_CONVERSATION'
export const CLEAR_SELECTED_CONVERSATION = 'CLEAR_SELECTED_CONVERSATION'
export const UPDATE_SELECTED_CONVERSATION_ID = 'UPDATE_SELECTED_CONVERSATION_ID'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const GET_VIDEO_SESSIONS = 'GET_VIDEO_SESSIONS'
export const GET_VIDEO_SESSION_RECORDING = 'GET_VIDEO_SESSION_RECORDING'
export const CLEAR_EPISODES = 'CLEAR_EPISODES'
export const SUBMIT_VIDEO_QUALITY_FEEDBACK = 'SUBMIT_VIDEO_QUALITY_FEEDBACK'

export const GET_PROVIDER_AVAILABILITY_V2 = 'GET_PROVIDER_AVAILABILITY_V2'
export const GET_NOTES = 'GET_NOTES'
export const GET_NOTES_FEEDBACK = 'GET_NOTES_FEEDBACK'
export const GET_ALL_NOTES = 'GET_ALL_NOTES'
export const GET_APPOINTMENT_SUMMARIES = 'GET_APPOINTMENT_SUMMARIES'
export const SUBMIT_NOTE = 'SUBMIT_NOTE'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const UPDATE_NOTE_FEEDBACK = 'UPDATE_NOTE_FEEDBACK'
export const GET_CLIENT_PRESCRIPTIONS = 'GET_CLIENT_PRESCRIPTIONS'
export const CLEAR_CLIENT_PRESCRIPTIONS = 'CLEAR_CLIENT_PRESCRIPTIONS'
export const TOGGLE_LIVE_MSG_SESSION = 'TOGGLE_LIVE_MSG_SESSION'
export const SET_ACTIVE_LIVE_MSG_SESSION = 'SET_ACTIVE_LIVE_MSG_SESSION'
export const SET_LIVE_MSG_CLIENT_ID_TO_OPEN = 'SET_LIVE_MSG_CLIENT_ID_TO_OPEN'
export const GET_PLATFORM_ENGAGEMENT_METRICS_CURRENT = 'GET_PLATFORM_ENGAGEMENT_METRICS_CURRENT'
export const GET_PLATFORM_ENGAGEMENT_METRICS_PREVIOUS = 'GET_PLATFORM_ENGAGEMENT_METRICS_PREVIOUS'
export const GET_CALENDAR_TOKEN = 'GET_CALENDAR_TOKEN'
export const GET_CALENDAR_OAUTH_URL = 'GET_CALENDAR_OAUTH_URL'
export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS'
export const GET_CALENDAR_AVAILABILITY_SLOTS = 'GET_CALENDAR_AVAILABILITY_SLOTS'
export const CREATE_CALENDAR_EVENTS = 'CREATE_CALENDAR_EVENTS'
export const GET_CALENDARS = 'GET_CALENDARS'
export const PATCH_CALENDAR_EVENT = 'PATCH_CALENDAR_EVENT'
export const GET_GOOGLE_EVENT = 'GET_GOOGLE_EVENT'
export const GET_CALENDAR_PROVIDER = 'GET_CALENDAR_PROVIDER'
export const PATCH_CALENDAR_PROVIDER = 'PATCH_CALENDAR_PROVIDER'
export const SHOW_CALENDAR_LIVE_MODAL = 'SHOW_CALENDAR_LIVE_MODAL'
export const HIDE_CALENDAR_LIVE_MODAL = 'HIDE_CALENDAR_LIVE_MODAL'

const handleError = (error: any) => {
  logToSumoLogic('requestError', store.getState().auth?.user?.id ?? '', error)
  if (!error || !error.response || isString(error)) return error
  return error.response.data?.message || getErrorText(error.response.status)
}

// Supported styles of the Alert Notification
export const actionStyles = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'danger',
  SUCCESS: 'success',
}

/**
 *
 * Shows an alert banner with supported styles and with provided message
 *
 * @param {Object} actionStyle notification style in actionStyles map
 * @param {string | Error} message can be a string for success or error object
 * @param {function} dispatch dispatch function from redux.
 * @param {string | undefined} [action] Redux action related to the request.
 * @param {boolean | undefined} expires if banner expires
 * @param {number | undefined} autoDismissTimer timeout for banner
 */
export const actionAlertHandler = ({ actionStyle, message, dispatch, action, expires, autoDismissTimer }: any) => {
  let contents
  switch (actionStyle) {
    case actionStyles.ERROR:
      contents = handleError(message)
      break
    case actionStyles.SUCCESS:
      contents = message
      break
    default:
      break
  }
  if (!isString(contents)) return
  dispatch(
    addAlert({
      show: true,
      contents,
      style: actionStyle,
      expires,
      action,
      autoDismissTimer,
    }),
  )
  return message
}
