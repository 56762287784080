import { baseApi } from '../../../../data/baseApi'

type PEMetricsArgs = {
  providerId: string
  data: PEMetricsQuarters
}

type PEMetricsQuarter = {
  start_date: string
  end_date: string
}

export type PEMetricsQuarters = {
  start_date: string
  end_date: string
  quarters: PEMetricsQuarter[]
}

export type PEMetric = {
  engagement_rate: number | null
  assignment_rate: number | null
  feedback_rate: number | null
}
export type PEMetrics = { [key: string]: PEMetric }

const extendedAPI = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlatformEngagementMetrics: builder.query<PEMetrics, PEMetricsArgs>({
      query: ({ providerId, data }) => ({
        method: 'post',
        url: `/v1/providers/${providerId}/platform-engagement`,
        data,
      }),
    }),
  }),
})

export const { useGetPlatformEngagementMetricsQuery } = extendedAPI
