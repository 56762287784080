import React, { MutableRefObject, useMemo, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'

import { isString } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { css, useTheme } from 'styled-components/native'

import { FieldSchema, NoteTypes } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  FlyingEnvelopeLottie,
  FormBody,
  SecondaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

import aiDraftFeedbackMetadata from './aiDraftFeedbackMetadata'
import { submitFeedback } from './data/clientNotesAutoActions'
import { actions, mixpanelEvents, sessionNoteTypes } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { getAuthUserId } from '../../data/auth/authSelectors'
import { getClientNotesSummaryFeedback } from '../../data/lyraTherapy/clientSelectors'

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['16px'],
}))

const ImageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: theme.spacing['24px'],
}))

const ImageHeader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing['8px'],
}))

const ImageText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing['8px'],
  maxWidth: '280px',
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const FormButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['16px'],
  right: theme.spacing['16px'],
}))

const PanelFeedbackSection: React.FC<PanelFeedbackSectionProps> = ({
  appointmentId,
  feedbackFormRef,
  noteId,
  noteType,
  promptType,
  actions: { submitFeedback },
}) => {
  const { colors } = useTheme()
  const intl = useIntl()
  const userId = useSelector(getAuthUserId)
  const feedbackResponses = useSelector(getClientNotesSummaryFeedback)

  const [isSubmitted, setIsSubmitted] = useState(false)

  const feedbackResponse = useMemo(() => {
    if (feedbackResponses && feedbackResponses.length) {
      return feedbackResponses.find((response: { entity_id: string }) => {
        return response.entity_id === noteId
      })
    }
    return undefined
  }, [feedbackResponses, noteId])

  const FormButton = ({
    handleSubmit,
    pristine,
  }: {
    handleSubmit: FormRenderProps['handleSubmit']
    pristine: boolean
  }) => {
    return (
      <FormButtonContainer>
        <SecondaryButton
          text={
            <FormattedMessage
              defaultMessage='Submit feedback'
              description='Button to submit feedback on AI notes draft'
            />
          }
          onPress={() => {
            handleSubmit()
          }}
          disabled={pristine}
          testID={tID('PanelFeedbackSection-submitFeedbackButton')}
          accessibilityLabel={intl.formatMessage({
            defaultMessage: 'Submit feedback',
            description: 'Button to submit feedback on AI notes draft',
          })}
          periwinkle
        />
      </FormButtonContainer>
    )
  }
  const { schema, uiSchema } = aiDraftFeedbackMetadata ?? {}

  return feedbackResponse || isSubmitted ? (
    <ContentContainer testID={tID('ClientNotesPanel-feedbackSubmittedContainer')}>
      <ImageContainer>
        <FlyingEnvelopeLottie />
      </ImageContainer>
      <ImageHeader size={SubheadSize.SMALL} text='Feedback submitted' color={colors.textPrimary} />
      <ImageText
        testID={tID('ClientNotesPanel-feedbackSubmittedText')}
        text='Thank you for the feedback it will help us improve your experience.'
        size={BodyTextSize.SMALL}
        color={colors.textSecondary}
      />
    </ContentContainer>
  ) : (
    <FormBody
      name='eligibityForm'
      schema={schema as FieldSchema}
      uiSchema={uiSchema}
      formRef={feedbackFormRef}
      saveForm={({ values }) => {
        track({
          event: mixpanelEvents.BUTTON_PRESS,
          action: actions.AI_DRAFTS_SUBMIT_FEEDBACK,
          details: {
            noteType: sessionNoteTypes[noteType],
            appointmentId,
          },
        })
        submitFeedback({
          entity_type: noteType === NoteTypes.INITIAL ? 'note_summary_presenting_issues' : 'note_summary_session',
          entity_id: noteId ?? '',
          lyra_id: userId,
          user_type: 'provider',
          feedback_response: [
            ...Object.entries(values).map((value: [string, string | number | string[]]) => {
              return { question: value[0], answer: isString(value[1]) ? value[1] : JSON.stringify(value[1]) }
            }),
            {
              question: 'promptType',
              answer: promptType,
            },
          ],
        }).then(setIsSubmitted(true))
      }}
      intl={intl}
      useMultiSelectTypeAhead
      scrollContainerCustomStyles={{
        formBodyPageContainer: css`
          max-width: 100%;
        `,
        scrollContainerContentCustomStyles: {
          padding: 0,
        },
      }}
      formBodyCustomStyles={{
        submitButtonWrapper: css`
          align-self: end;
          max-width: 180px;
        `,
        submitButtonContainer: css`
          border: none;
          box-shadow: none;
          border-top-width: 0;
          padding: 0;
        `,
      }}
      formButton={FormButton}
    />
  )
}

type PanelFeedbackSectionProps = {
  appointmentId: string
  feedbackFormRef: MutableRefObject<any>
  noteId?: string
  noteType: string
  promptType: string
  actions: any
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ submitFeedback }, dispatch),
})

export default connect(null, mapDispatchToProps)(PanelFeedbackSection)
