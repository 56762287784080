import { useCallback, useMemo } from 'react'

import { addMonths, endOfQuarter, format, startOfYear, subYears } from 'date-fns'

import { PEMetricsQuarters } from '../data/platformEngagementApi'

const usePEQuarters = ({ previousYear }: { previousYear: boolean } = { previousYear: false }): PEMetricsQuarters => {
  const getStartAndEnd = useCallback(
    (startOfYearDate: Date, quarter: number) => ({
      start_date: format(addMonths(startOfYearDate, (quarter - 1) * 3), 'yyyy-MM-dd'),
      end_date: format(endOfQuarter(addMonths(startOfYearDate, (quarter - 1) * 3)), 'yyyy-MM-dd'),
    }),
    [],
  )

  return useMemo(() => {
    // Performance year is now Q1 to Q4 but used to be Q4 to Q1, so for years before 2024 we display it in the later format
    let startOfYearDate = startOfYear(new Date())

    if (previousYear) {
      startOfYearDate = subYears(startOfYearDate, 1)
    }
    const prevQ4 = getStartAndEnd(startOfYearDate, 0)
    const q1 = getStartAndEnd(startOfYearDate, 1)
    const q2 = getStartAndEnd(startOfYearDate, 2)
    const q3 = getStartAndEnd(startOfYearDate, 3)
    const q4 = getStartAndEnd(startOfYearDate, 4)

    const quarters = startOfYearDate.getUTCFullYear() < 2024 ? [prevQ4, q1, q2, q3] : [q1, q2, q3, q4]

    return {
      start_date: startOfYearDate.getUTCFullYear() < 2024 ? prevQ4.start_date : q1.start_date,
      end_date: startOfYearDate.getUTCFullYear() < 2024 ? q3.end_date : q4.end_date,
      quarters,
    }
  }, [getStartAndEnd, previousYear])
}

export default usePEQuarters
