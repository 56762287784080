import {
  appointmentUtilization,
  HistoricCharges,
  historicUtilization,
  targetUtilization,
  UtilizationCharges,
} from '@lyrahealth-inc/shared-app-logic'

import { baseApi } from '../../../../data/baseApi'
export type GetAppointmentUtilizationArgs = {
  providerId: string
  date: string
}

export type GetTargetUtilizationArgs = {
  providerId: string
  date: string
  includeFinalWeek?: boolean
}

export type GetHistoricUtilizationArgs = {
  providerId: string
  date: string
  bookableView?: boolean
  timeZone?: string
}

export type GetUtilizationChargesArgs = {
  providerId: string
  date: string
  sessionType: string
}
export type GetHistoricUtilizationChargesArgs = {
  providerId: string
  date: string
  sessionType: string
}

export type GetUtilizationQuartersArgs = {
  date: string
}

const extendedAPI = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentUtilization: builder.query<appointmentUtilization, GetAppointmentUtilizationArgs>({
      query: ({ providerId, date }) => ({
        method: 'get',
        url: `/v1/providers/${providerId}/utilization/appointments`,
        params: { date },
      }),
    }),
    getTargetUtilization: builder.query<targetUtilization, GetTargetUtilizationArgs>({
      query: ({ providerId, date, includeFinalWeek = false }) => ({
        method: 'get',
        url: `/v1/providers/${providerId}/utilization/targets`,
        params: { date, include_final_week: includeFinalWeek },
      }),
    }),
    getHistoricUtilization: builder.query<historicUtilization, GetHistoricUtilizationArgs>({
      query: ({ providerId, date, bookableView, timeZone }) => ({
        method: 'get',
        url: `/v1/providers/${providerId}/utilization/historic/week`,
        params: { date, bookable_view: bookableView, timezone: timeZone },
      }),
    }),
    getUtilizationCharges: builder.query<UtilizationCharges, GetUtilizationChargesArgs>({
      query: ({ providerId, date, sessionType }) => ({
        method: 'get',
        url: `/v1/providers/${providerId}/utilization/charges`,
        params: { date, session_type: sessionType },
      }),
    }),
    getHistoricUtilizationCharges: builder.query<HistoricCharges[], GetHistoricUtilizationChargesArgs>({
      query: ({ providerId, date, sessionType }) => ({
        method: 'get',
        url: `/v1/providers/${providerId}/utilization/charges/historic/week`,
        params: { date, session_type: sessionType },
      }),
    }),
    getUtilizationQuarters: builder.query<{ [key: string]: [string, string] }, GetUtilizationQuartersArgs>({
      query: ({ date }) => ({
        method: 'get',
        url: `/v1/utilization/quarters?date=${date}`,
      }),
    }),
  }),
})

export const {
  useGetAppointmentUtilizationQuery,
  useGetTargetUtilizationQuery,
  useGetHistoricUtilizationQuery,
  useGetUtilizationChargesQuery,
  useGetHistoricUtilizationChargesQuery,
  useGetUtilizationQuartersQuery,
} = extendedAPI
export default extendedAPI
