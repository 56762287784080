import React, { memo, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router'
import { Navigate, RouteProps } from 'react-router-dom'

import { bindActionCreators } from 'redux'

import { getAuthConfig, getAuthHydrated, getAuthIsLoggedIn, getAuthRoles } from '../../../../data/auth/authSelectors'
import * as authActions from '../../../../data/authActions'
import { LOGIN } from '../../../constants/routingConstants'
import { hasPermission } from '../../../utils/utils'
import ForbiddenRoute from '../forbiddenRoute/ForbiddenRoute'

type Props = {
  actions: {
    saveLastAuthorizedRoute: (location: any) => void
  }
} & RouteProps

// @ts-expect-error TS(2769): No overload matches this call.
const AuthorizedRoute = memo(({ actions: { saveLastAuthorizedRoute } }: Props) => {
  const isLoggedIn: boolean = useSelector(getAuthIsLoggedIn)
  const roles: string[] = useSelector(getAuthRoles)
  const config = useSelector(getAuthConfig)
  const hydrated: boolean = useSelector(getAuthHydrated)
  const location = useLocation()
  useEffect(() => {
    if (location?.pathname !== LOGIN.route) {
      saveLastAuthorizedRoute(location)
    }
  }, [location, saveLastAuthorizedRoute])

  if (!hydrated) {
    return false
  }
  if (isLoggedIn) {
    if (hasPermission(roles, config, location?.pathname)) {
      return <Outlet />
    } else {
      return <ForbiddenRoute />
    }
  }

  return <Navigate to={LOGIN.route} />
})

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators({ ...authActions }, dispatch),
})

export default connect(null, mapDispatchToProps)(AuthorizedRoute)
