// When naming new mixpanel events please follow the best practices: https://lyrahealth.atlassian.net/wiki/spaces/DS/pages/2598110057/Mixpanel+event+naming+best+practices
export const mixpanelEvents = {
  BUTTON_PRESS: 'Button Press',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  OUTCOMES_AGREED: 'Outcomes Consent Agreed',
  OUTCOMES_AGREED_FAILED: 'Failed Outcomes Consent Agreed',
  REGISTER: 'Register',
  STS: 'Show Session Tracking info',
  SUBMIT_PAYMENT: 'Submit Payment',
  SUBMIT_PAYMENT_FAILED: 'Failed Submit Payment',
  FILTER_CHANGE: 'Filter Change',
  SUBMIT_ICD10S: 'Submit ICD-10s',
  SUBMIT_ICD10S_FAILED: 'Submit ICD-10s Failure',
  OUTCOME_REMINDER: 'Show Outcome Reminder',
  SHOW_PROVIDER_AVAILABILITY: 'Availability Feature Shown',
  SHOW_PROVIDER_CAPACITY: 'Capacity Availability Feature Shown',
  UPDATE_CAPACITY: 'Update Capacity',
  BLOCK_PAYMENT: 'Payment Blocked',
  SHOW_RECOMMENDATION_EXPLANATION_MODAL: 'Show utilization recommendation explanation modal',
  CHANGE_QUARTERLY_PROGRESS_TARGET: 'Change selected target value for quarterly progress chart',
  SEND_MESSAGE: 'Send a message to a client',
  OPEN_MESSENGER: 'Open the messenger',
  REVIEW_CONTENT: 'Review some content',
  OPEN_GO_SEARCH_BAR: 'Open Go Search Bar',
  CLOSE_GO_SEARCH_BAR: 'Close Go Search Bar',
  NAVIGATE_TO_CLIENT_FROM_GO_SEARCH_BAR: 'Navigate to client from Go Search Bar',
  SHOW_OOO_TAB: 'Show OOO tab',
  SEND_SCHEDULED_OOO_SELECTED: 'Send scheduled OOO selected',
  GO_BACK_TO_EDIT_OOO_MESSAGE_SELECTED: 'Go back to edit OOO message selected',
  SEND_OOO_MESSAGE_SELECTED: 'Send OOO message selected',
  UPDATING_EXPEDITED_BOOKING: 'Expedited Booking updated',
  ENABLED_EXPEDITED_BOOKING: 'Enabled Expedited Booking',
  SELECTED_EXPEDITED_BOOKING_GRACE_PERIOD: 'Selected Expedited Booking grace period',
  TOGGLED_EXPEDITED_BOOKING: 'Toggled Expedited Booking',
  FILTER_PP_CONTENT_LIBRARY: 'filter provider portal content library',
  AVAILABILITY_ALERT_LEARN_MORE: 'Learn more selected in the availability alert',
  AVAILABILITY_ALERT_OPEN_CALENDAR: 'Open calendar selected from the availability alert',
  AVAILABILITY_ALERT_CLOSE_MODAL: 'Close the availability alert',
  CAPACITY_AVAILABILITY_ALERT_MODAL_SHOWN: 'Capacity setting availability modal surfaced',
  PERSISTENT_AVAILABILITY_ALERT_MODAL_SHOWN: 'Persistent availability alert pop over is surfaced',
  PERSISTENT_AVAILABILITY_ALERT_LEARN_MORE: 'Provider clicks on "Learn more" to link to ZenDesk',
  PERSISTENT_AVAILABILITY_ALERT_OPEN_CALENDAR: 'Provider clicks on "Open calendar" to open their google calendar',
  PERSISTENT_AVAILABILITY_ALERT_CLOSE_MODAL: 'Provider closes the availability modal',
  CALENDAR_SETUP_CANCEL_MODAL_SHOWN: 'Calendar setup cancel modal shown',
  CALENDAR_TEMPLATE_CANCEL_MODAL_SHOWN: 'Calendar template cancel modal shown',
  ALERT_SHOWN_UNDER_BOOKABLE: 'Alert shown under bookable',
  ALERT_SHOWN_OVER_BOOKABLE_TIME_OFF: 'Alert shown over bookable time off',
  ALERT_SHOWN_CAPACITY: 'Alert shown capacity',
}

export const mixpanelPages = {
  CLIENTS: 'clients/lt',
  CLIENTS_ASSIGN: 'clients/lt/home/assign',
  CLIENTS_ASSIGNMENT: 'clients/lt/home/assignment',
  CLIENTS_CLOSE_SESSION: 'clients/lt/home/close-session',
  CLIENTS_HOME: 'clients/lt/home',
  CLIENTS_LIBRARY: 'clients/lt/home/library',
  CLIENTS_TRACK: 'clients/lt/home/track',
  CLIENTS_TRACKS: 'clients/lt/home/tracks',
  CLIENTS_PROFILE: 'clients/lt/home/profile',
  DASHBOARD: 'dashboard',
  LOGIN: 'login',
  MEASUREMENT_BASED_CARE: 'performance/measurement-based-care',
  PERFORMANCE: 'performance',
  PLATFORM_ENGAGEMENT: 'performance/platform-engagement',
  PLATFORM_ENGAGEMENT_QUARTERLY_DETAILS: 'performance/platform-engagement/quarterly-details',
  ELIGIBILITY_CHECKER: 'eligibility-checker',
  CLIENT_SESSIONS: 'clients/lt/home/sessions',
  CLIENT_NOTES: 'clients/lt/home/notes',
  CLIENT_NOTES_NEW: 'clients/lt/home/notes/new',
}

/**
 * The session tracking information types.
 *
 * We track the following cases:
 *  - The initial session of a client
 *  - When the client has less than 5 sessions remaining
 *  - The last session
 *  - The number of sessions exceeds the number of available sessions.
 *
 * @type {Object} The list of different session traking info type.
 */
export const STSTypes = {
  STS_INITIAL: 'Initial',
  STS_WARNING: 'Warning',
  STS_LAST: 'Last',
  STS_EXCEEDED: 'Exceeded',
}

/**
 * Actions are the actions corresponding to a button click.
 */
export const actions = {
  OUTCOMES_CONSENT_NOT_NOW: 'Outcomes Consent Not Now',
  OUTCOMES_CONSENT_LEARN_MORE: 'Outcomes Learn More From Consent View',
  OUTCOMES_LEARN_MORE: 'Outcomes Learn More',
  OUTCOMES_CHANGE_DATE: 'Outcomes Change Date',
  OUTCOMES_LIFE_IMPAIRMENT: 'Outcomes Life Impairment',
  OUTCOMES_SELF_HARM: 'Outcomes Self Harm',
  OUTCOMES_ALLIANCE: 'Outcomes Alliance',
  OUTCOMES_GAD: 'View GAD7 Answers',
  OUTCOMES_PHQ: 'View PHQ9 Answers',
  OUTCOMES_GAD_TOOLTIP: 'View GAD7 Answers from graph tooltip',
  OUTCOMES_PHQ_TOOLTIP: 'View PHQ9 Answers from graph tooltip',
  RESET_PASSWORD: 'Reset Password',
  CHANGE_SESSION_PERIOD: 'View Different Session Period',
  ADD_ACTIVITY: 'Add Activity',
  VIEW_ASSESSMENT: 'View Assessment',
  VIEW_ACTIVITY_OVERVIEW: 'View Activity Overview',
  VIEW_MBC_PERCENTAGE_HELP_ARTICLE: 'View MBC Percentage Help Article',
  VIEW_MBC_PERCENTAGE_DETAILS: 'View MBC Percentage Details',
  VIEW_MBC_COUNT_DETAILS: 'View MBC Count Details',
  VIEW_MBC_COUNT_HELP_ARTICLE: 'View MBC Count Help Article',
  EDIT_ACTIVITY: 'Edit Activity',
  COPY_ACTIVITY: 'Copy Activity',
  MOVE_ACTIVITY: 'Move Activity',
  REMOVE_ACTIVITY: 'Remove Activity',
  PREVIEW_ACTIVITY: 'Preview Activity',
  SCHEDULE_NEW_SESSION: 'Schedule New Session',
  CANCEL_SESSION: 'Cancel Session',
  RESCHEDULE_SESSION: 'Reschedule Session',
  UPDATE_AVAILABILITY: 'Availability Update',
  UPDATE_CAPACITY: 'Capacity Availability Update',
  AVAILABLE: 'Availability Changed to Available',
  NOT_AVAILABLE: 'Availability Changed to Not Available',
  activities: {
    edit: 'EDIT_ACTIVITY',
    copy: 'COPY_ACTIVITY',
    move: 'MOVE_ACTIVITY',
    unassign: 'REMOVE_ACTIVITY',
  },
  SUBMIT_ELIGIBILITY_CHECKER_SEARCH: 'Submit Eligibility Checker Search',
  LEARN_MORE: 'Learn More',
  VIEW_QUARTERLY_DETAILS: 'View Quarterly Details',
  BACK: 'Back',
  CURRENT_YEAR: 'Current Year',
  PREVIOUS_YEAR: 'Previous Year',
  MARK_MESSAGE_UNREAD: 'Mark Message Unread',
  WATCH_RECORDING: 'Watch recording with client',
  CALENDAR_DROPDOWN: 'Calendar in Dropdown Menu',
  HELP_CENTER_DROPDOWN: 'Help Center in Dropdown Menu',
  CALENDAR_NAV_BAR: 'Calendar in Nav Bar',
  CALENDAR_BANNER: 'Calendar in Banner',
  CONNECT_CALENDAR_START: 'Start Connect Calendar Flow',
  SCHEDULING_DA_CONNECTION_INSTRUCTIONS: 'Scheduling DA Connection Instructions',
  SCHEDULING_FOOTER_CONNECT_CALENDAR: 'Scheduling Footer Connect Calendar',
  SCHEDULING_FOOTER_SIMPLE_PRACTICE: 'Scheduling Footer Simple Practice',
  SCHEDULING_FOOTER_APPOINTMENT_BOOKING: 'Scheduling Footer Appointment Booking',
  SCHEDULING_FOOTER_PRIVATE_CALENDAR: 'Scheduling Footer Private Calendar',
  SCHEDULING_FOOTER_SUBMIT_TICKET: 'Scheduling Footer Submit Ticket',
  SCHEDULING_FOOTER_UPDATE_CALENDAR: 'Scheduling Footer Update Calendar',
  SCHEDULING_FOOTER_CLIENT_INTAKE: 'Scheduling Footer Client Intake',
  SCHEDULING_FOOTER_BEST_PRACTICES: 'Scheduling Footer Best Practices',
  SCHEDULING_FOOTER_ACCEPTING_REFERRAL: 'Scheduling Footer Accepting Referral',
  SCHEDULING_WALKTHROUGH_BACK: 'Scheduling Walkthrough Back',
  SCHEDULING_WALKTHROUGH_EXIT: 'Scheduling Walkthrough Exit',
  SCHEDULING_WALKTHROUGH_HOW_TO_CONNECT: 'Scheduling Walkthrough How to Connect',
  SCHEDULING_WALKTHROUGH_GOOGLE_CONNECTION: 'Scheduling Walkthrough Google Connection',
  SCHEDULING_STATUS_CONTACT_US: 'Scheduling Status Contact Us',
  SCHEDULING_BEGIN_CALENDAR_SUBMISSION: 'Scheduling Begin Calendar Submission',
  SCHEDULING_CLOSE_SUBMISSION_MODAL: 'Scheduling Close Submission Modal',
  SCHEDULING_SUBMISSION_LEARN_MORE: 'Scheduling Submission Learn More',
  SCHEDULING_COMPLETE_CALENDAR_SUBMISSION: 'Scheduling Complete Calendar Submission',
  SCHEDULING_ADD_BLOCKING_CALENDAR: 'Scheduling Add Blocking Calendar',
  SCHEDULING_REMOVE_BLOCKING_CALENDAR: 'Scheduling Remove Blocking Calendar',
  SCHEDULING_SET_APPOINTMENT_CALENDAR: 'Scheduling Set Appointment Calendar',
  SCHEDULING_AVAILABILITY_CALENDAR_LINK: 'Scheduling Availability Calendar Link',
  SCHEDULING_CALENDAR_VIEW_NEXT: 'Scheduling Calendar View Next Page',
  SCHEDULING_CALENDAR_VIEW_PREV: 'Scheduling Calendar View Previous Page',
  SCHEDULING_INTERNATIONAL_APPROVAL_EMAIL: 'Scheduling International Approval Email',
  SCHEDULING_INTERNATIONAL_FOOTER_HOW_TO_CONNECT: 'Scheduling International Footer How To Connect',
  SCHEDULING_INTERNATIONAL_FOOTER_MANAGE_AVAILABILITY: 'Scheduling International Footer Manage Availability',
  SCHEDULING_INTERNATIONAL_FOOTER_CALENDAR_FAQ: 'Scheduling International Footer Calendar FAQ',
  SCHEDULING_INTERNATIONAL_FOOTER_PORTAL_HELP: 'Scheduling International Footer Portal Help',
  SCHEDULING_INTERNATIONAL_FOOTER_RISK_MANAGEMENT: 'Scheduling International Footer Risk Management',
  SCHEDULING_INTERNATIONAL_FOOTER_SUBMIT_TICKET: 'Scheduling International Footer Submit Ticket',
  SCHEDULING_INTERNATIONAL_WALKTHROUGH_HOW_TO_CONNECT: 'Scheduling International Walkthrough How to Connect',
  SCHEDULING_INTERNATIONAL_AVAILABILITY_CALENDAR_LINK: 'Scheduling International Availability Calendar Link',
  SCHEDULING_INTERNATIONAL_STATUS_CONTACT_US: 'Scheduling International Status Contact Us',
  AI_DRAFTS_WORKSPACE_OPEN: 'AI_drafts_workspace_open',
  AI_DRAFTS_WORKSPACE_CLOSE: 'AI_drafts_workspace_close',
  UNFINISHED_NOTES_DASHLET_OPEN_NOTE: 'unfinished_notes_dashlet_open_note',
  CLIENT_NOTES_TAB_OPEN_NOTE: 'client_notes_tab_open_note',
  AI_DRAFTS_PASTE_INTO_NOTE: 'AI_drafts_paste_into_note',
  AI_DRAFTS_SUBMIT_FEEDBACK: 'AI_drafts_submit_feedback',
  CALENDAR_SETUP_LEARN_MORE: 'Calendar Setup Learn More',
  CALENDAR_SETUP_SET_UP_CALENDAR: 'Calendar Setup Set Up Calendar',
  CALENDAR_SETUP_SAVE_SUCCESS: 'Calendar Setup Save Success',
  CALENDAR_SETUP_SAVE_BELOW: 'Calendar Setup Save Below',
  CALENDAR_SETUP_SAVE_ABOVE: 'Calendar Setup Save Above',
  CALENDAR_SETUP_CANCEL_MODAL_CONTINUE_EDITING: 'Calendar Setup Cancel Modal Continue Editing',
  CALENDAR_SETUP_CANCEL_MODAL_DISCARD_CHANGES: 'Calendar Setup Cancel Modal Discard Changes',
  CALENDAR_TEMPLATE_EDIT: 'Calendar Template Edit',
  CALENDAR_TEMPLATE_SAVE_SUCCESS: 'Calendar Template Save Success',
  CALENDAR_TEMPLATE_SAVE_BELOW: 'Calendar Template Save Below',
  CALENDAR_TEMPLATE_SAVE_ABOVE: 'Calendar Template Save Above',
  CALENDAR_TEMPLATE_CANCEL_MODAL_CONTINUE_EDITING: 'Calendar Template Cancel Modal Continue Editing',
  CALENDAR_TEMPLATE_CANCEL_MODAL_DISCARD_CHANGES: 'Calendar Template Cancel Modal Discard Changes',
  CALENDAR_MANAGEMENT_ADD_AVAILABLE: 'Calendar Management Add Available',
  CALENDAR_MANAGEMENT_DELETE_AVAILABLE: 'Calendar Management Delete Available',
  CALENDAR_MANAGEMENT_EDIT_AVAILABLE: 'Calendar Management Edit Available',
  CALENDAR_MANAGEMENT_JOIN_MEETING: 'Calendar Management Join Meeting',
  CALENDAR_MANAGEMENT_CLIENT_DETAILS: 'Calendar Management Client Details',
  CALENDAR_MANAGEMENT_OPEN_IN_GOOGLE_CALENDAR: 'Calendar Management Open in Google Calendar',
  NAVIGATION_MENU_SETTINGS: 'Navigation Menu Settings',
  NAVIGATION_CALENDAR_GEAR: 'Navigation Calendar Gear',
  NAVIGATION_CALENDAR_ZENDESK: 'Navigation Calendar Zendesk',
  CAPACITY_ALERT_OPEN_CAPACITY: 'Capacity Alert Open Capacity',
  CAPACITY_ALERT_ADD_FOR_ME: 'Capacity Alert Add for Me',
  CAPACITY_ALERT_X: 'Capacity Alert X',
  CALENDAR_MANAGEMENT_ALERT_DELETE_ALL: 'Calendar Management Alert Delete All',
}

export const trackElementsMap = {
  OutcomesConsent: [
    { target: '#learn-more', action: actions.OUTCOMES_CONSENT_LEARN_MORE },
    { target: '#outcomes-not-now', action: actions.OUTCOMES_CONSENT_NOT_NOW },
  ],
  ClientDetails: [
    { target: '#outcomes-learn-more', action: actions.OUTCOMES_LEARN_MORE },
    { target: '#select-outcome-dropdown a', action: actions.OUTCOMES_CHANGE_DATE },
  ],
}

export const sessionNoteTypes = {
  session: 'FOLLOW UP',
  general: 'GENERAL',
  initial: 'INTAKE',
  referral: 'CARE REFERRAL',
}
