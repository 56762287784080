import React, { FunctionComponent, MutableRefObject } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SafeAreaView } from 'react-native-safe-area-context'

import { StatusBar } from 'expo-status-bar'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  getVideoStatusesLabel,
  Review,
  VideoLesson,
  VideoStatuses,
  VideoStatusesLabel,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { ActivityIcon } from '../../atoms/icons/ActivityIcon'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { TruncatedText } from '../../atoms/truncate/TruncatedText'
import { IS_WEB } from '../../constants'
import { ActivityItem } from '../../molecules/activityItem/ActivityItem'
import { LessonHeader } from '../../molecules/lessonHeader/LessonHeader'
import { VideoList } from '../../organisms/videoList/VideoList'
import { SubheadSize } from '../../styles/typeStyles'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface LessonOverviewProps {
  id: string
  lessonName?: string
  thumbnail?: string
  instructions?: string | null
  onBackPress: () => void
  videos: VideoLesson[]
  ctaTitle: string | React.ReactNode
  onCTAPressed?: (id: string) => void
  onVideoPressed: (id: string, videoPropertyName?: string) => void
  review?: Review | null
  onReviewPressed: (propertyName: string, status: string) => void
  reviewAvailable: boolean
  topInset?: number
  bottomInset?: number
  accessibilityLabel?: string
  showCTAOnActivityItem?: number
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  isProvider?: boolean
}

const LessonOverviewContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  backgroundColor: colors.backgroundSecondary,
  height: '100%',
}))

const ScrollContainer = styled.ScrollView<{ bottomInset?: number; theme: ThemeType; hasMarginBottom: boolean }>(
  ({
    hasMarginBottom,
    bottomInset = 0,
    theme: {
      breakpoints: { isMinWidthTablet },
    },
  }) => ({
    ...(!isMinWidthTablet && hasMarginBottom
      ? { marginBottom: `${bottomInset + 50 + 30}px` }
      : { paddingBottom: `${bottomInset + 50 + 30}px` }), // 50 represents button height
  }),
)

const ButtonContainer = styled(SafeAreaView)<{ theme: ThemeType }>(({ theme: { spacing, colors } }) => ({
  position: 'absolute',
  bottom: '0px',
  width: '100%',
  padding: `${spacing['16px']} ${spacing['16px']}`,
  borderTopWidth: '1px',
  borderTopColor: colors.borderDefault,
  backgroundColor: colors.backgroundPrimary,
}))

const ScrollContainerContent = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  margin: `0px ${spacing['24px']}`,
  paddingBottom: !IS_WEB ? spacing['72px'] : spacing['12px'],
  alignItems: 'center',
}))

const LessonOverviewContent = styled.View({
  width: '100%',
  maxWidth: '466px',
})

const TruncatedTextContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing, breakpoints } }) => ({
  margin: `${breakpoints.isMinWidthTablet ? spacing['40px'] : spacing['24px']} 0px ${spacing['24px']}`,
}))

const PrimaryButtonComponent = styled(PrimaryButton)<{ theme: ThemeType }>(() => ({
  width: '100%',
  maxWidth: '466px',
  alignSelf: 'center',
}))

/**
 * After tapping on a lesson, this is the page the client will see for all the videos
 * a lesson contains and an overview of the lesson
 */
export const LessonOverview: FunctionComponent<LessonOverviewProps> = ({
  id,
  lessonName = '',
  thumbnail = '',
  instructions = '',
  onBackPress,
  review,
  onReviewPressed,
  ctaTitle,
  onCTAPressed,
  onVideoPressed,
  reviewAvailable,
  topInset = 0,
  bottomInset = 0,
  videos = [],
  showCTAOnActivityItem,
  accessibilityLabel,
  children,
  a11yFocusedElementOnLoad,
  isProvider = false,
}) => {
  const {
    breakpoints: { isMinWidthTablet },
    colors,
  } = useTheme()
  const activityItemTitle = !!review ? getVideoStatusesLabel(review?.reviewCompletionStatus) : ''
  const isReviewItemCompleted = activityItemTitle === VideoStatusesLabel.get(VideoStatuses.completed)
  const withCTAOnActivityItem = isMinWidthTablet
    ? showCTAOnActivityItem === videos.length && !isReviewItemCompleted && reviewAvailable && IS_WEB
    : false
  const hasBottomCTA = !isMinWidthTablet && !isReviewItemCompleted
  const getCTAOnPress = () => {
    // if all videos are completed, then return the lesson review function
    if (showCTAOnActivityItem === videos.length && !!review) {
      return onReviewPressed(review.reviewPropertyName, review.reviewCompletionStatus)
    } else {
      // for mobile CTA function
      if (!IS_WEB && onCTAPressed) {
        return onCTAPressed(id)
      } else {
        return onVideoPressed(videos[showCTAOnActivityItem!].videoId, videos[showCTAOnActivityItem!].videoPropertyName)
      }
    }
  }
  const { formatMessage } = useIntl()

  return (
    <LessonOverviewContainer testID={tID('LessonOverview')}>
      <ScrollContainer bottomInset={bottomInset} hasMarginBottom={hasBottomCTA}>
        <LessonHeader
          topInset={topInset}
          id={id}
          lessonName={lessonName}
          thumbnail={thumbnail}
          onBackPress={onBackPress}
          a11yFocusedElementOnLoad={a11yFocusedElementOnLoad}
        />
        <ScrollContainerContent>
          <LessonOverviewContent>
            <TruncatedTextContainer>
              {IS_WEB && isMinWidthTablet ? (
                <BodyText size={BodyTextSize.DEFAULT} text={instructions ?? ''} />
              ) : (
                <TruncatedText
                  collapseExpandColor={colors.textActive}
                  collapseExpandFontWeight={'normal'}
                  collapseExpandText={[
                    formatMessage({
                      defaultMessage: 'less',
                      description: 'Read less for truncated lesson instruction content',
                    }),
                    formatMessage({
                      defaultMessage: 'more',
                      description: 'Read more for truncated lesson instruction content',
                    }),
                  ]}
                  fontColor={colors.textPrimary}
                  maxNumberOfLines={3}
                  text={instructions ?? ''}
                  toggleCollapseExpand
                  collapseExpandTextSize={BodyTextSize.DEFAULT}
                />
              )}
            </TruncatedTextContainer>
            <VideoList
              videos={videos}
              onVideoPressed={onVideoPressed}
              withCTA={!hasBottomCTA}
              showCTAOnVideoItem={showCTAOnActivityItem}
              ctaTitle={ctaTitle}
              withProgressBar
              videoTitleSize={showCTAOnActivityItem ? SubheadSize.SMALL : SubheadSize.XSMALL}
              accessibilityLabel={accessibilityLabel}
              isPressable={isProvider}
            />
            {review && (
              <ActivityItem
                id={`id-${review.reviewPropertyName}`}
                title={
                  <FormattedMessage defaultMessage='Lesson review' description='Lesson review main button label' />
                }
                subtitle={activityItemTitle}
                onPress={
                  reviewAvailable
                    ? () => onReviewPressed(review.reviewPropertyName, review.reviewCompletionStatus)
                    : noop
                }
                withCTA={withCTAOnActivityItem}
                ctaTitle={ctaTitle}
                icon={<ActivityIcon size={80} />}
                disabled={!isProvider && (!reviewAvailable || (showCTAOnActivityItem ?? 0) !== videos.length)}
                headline={
                  <FormattedMessage defaultMessage='Review' description='Lesson review button headline label' />
                }
                withHeadline
                time={review.reviewTime}
              />
            )}
          </LessonOverviewContent>
        </ScrollContainerContent>
      </ScrollContainer>
      {hasBottomCTA && (
        <ButtonContainer edges={['bottom']} testID={tID('Footer-CTA')}>
          <PrimaryButtonComponent
            text={ctaTitle}
            onPress={getCTAOnPress}
            accessibilityLabel={accessibilityLabel}
            fullWidth
          />
        </ButtonContainer>
      )}
      {children}
      <StatusBar style='light' />
    </LessonOverviewContainer>
  )
}
