import React, { FunctionComponent, MutableRefObject, useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { Metadata, useWindowSize } from '@lyrahealth-inc/shared-app-logic'

import { PrimaryButton, ThemedStatusBar } from '../../atoms'
import { IS_WEB } from '../../constants'
import { InputAccessory } from '../../molecules/inputAccessory/InputAccessory'
import { ActivityHeader } from '../../organisms/activityHeader/ActivityHeader'
import { FormBody, FormButtonParams, FormChangeProperties } from '../../organisms/formBody/FormBody'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface LessonReviewEntryProps {
  content: Metadata
  title: string
  name: string
  saveForm: (values: Dict) => void
  onClosePress: () => void
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  ctaDisabled?: boolean
}

const LessonReviewEntryContainer = styled(Flex1View)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
}))

const CTAContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      spacing,
      breakpoints: { isMinWidthTablet },
    },
  }) => ({
    marginTop: spacing['16px'],
    marginBottom: isMinWidthTablet ? spacing['16px'] : spacing['32px'],
  }),
)

/**
 * Shows the form to input the lesson review
 */
export const LessonReviewEntry: FunctionComponent<LessonReviewEntryProps> = ({
  content,
  title,
  name,
  a11yFocusedElementOnLoad,
  onClosePress,
  saveForm,
  ctaDisabled = false,
}) => {
  const [isAtTopOfPage, setIsAtTopOfPage] = useState(true)
  const intl = useIntl()
  const { schema, uiSchema } = content ?? {}
  const [progress, setProgress] = useState(0)
  const inputAccessoryViewID = 'LessonReviewEntry'
  const [, windowHeight] = useWindowSize()
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const backgroundColor = colors.backgroundSecondary

  const FormButton = ({ handleSubmit, loading }: FormButtonParams) => {
    return (
      <CTAContainer>
        <PrimaryButton
          disabled={ctaDisabled}
          text={
            loading ? (
              <FormattedMessage defaultMessage='Loading...' description='Button for loading state' />
            ) : (
              <FormattedMessage defaultMessage='Submit' description='Button to submit lesson review entry' />
            )
          }
          onPress={() => handleSubmit()}
          testID={tID('form-reviewButton')}
          accessibilityLabel={
            loading
              ? formatMessage({
                  defaultMessage: 'Loading...',
                  description: 'button text loading',
                })
              : formatMessage({
                  defaultMessage: 'Submit',
                  description: 'button text submit',
                })
          }
          fullWidth
        />
      </CTAContainer>
    )
  }

  /**
   * Set the progress based on how many questions the user has answered
   */
  const handleFormChange = ({ values }: FormChangeProperties) => {
    if (schema?.properties) {
      setProgress(Object.keys(values).length / Object.keys(schema.properties).length)
    }
  }

  const calculateScrollFieldHeight = useCallback(() => {
    return windowHeight - (headerHeight + footerHeight)
  }, [footerHeight, headerHeight, windowHeight])

  return (
    <LessonReviewEntryContainer testID={tID('LessonReviewEntry')} backgroundColor={backgroundColor}>
      <ActivityHeader
        title={title}
        onClosePress={onClosePress}
        percentProgress={progress}
        setHeaderHeight={IS_WEB ? setHeaderHeight : noop}
        a11yFocusedElementOnLoad={a11yFocusedElementOnLoad}
        progressBarAccessibilityLiveRegion='assertive'
        isAtTopOfPage={isAtTopOfPage}
      />
      <Flex1View testID={tID('LessonReviewEntry-formContainer')}>
        {schema && (
          <FormBody
            intl={intl}
            schema={schema}
            uiSchema={uiSchema}
            name={name}
            saveForm={saveForm}
            formButton={FormButton}
            instructions='Take a few moments to reflect on the lesson by answering these questions.'
            onFormChange={handleFormChange}
            inputAccessoryViewID={inputAccessoryViewID}
            scrollListHeight={IS_WEB ? calculateScrollFieldHeight() : undefined}
            setFooterHeight={setFooterHeight}
            hasNewLookAndFeel
            backgroundColor={backgroundColor}
            setIsAtTopOfPage={setIsAtTopOfPage}
          />
        )}
      </Flex1View>

      <InputAccessory nativeID={inputAccessoryViewID} />
      <ThemedStatusBar defaultStyle='dark' />
    </LessonReviewEntryContainer>
  )
}
