/* eslint-disable @typescript-eslint/no-namespace */
import React, { DOMAttributes, useEffect, useRef } from 'react'

import { VideoPlayerContainer } from '@zoom/videosdk'

import { useZoom } from '../../hooks/useZoom'

type CustomElement<T> = Partial<
  T &
    DOMAttributes<T> & { children: any; ref?: React.RefObject<HTMLElement> } & {
      style: Partial<CSSStyleDeclaration>
    }
>
export type ZoomParticipantViewInnerProps = {
  show?: boolean
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['video-player-container']: CustomElement<Omit<VideoPlayerContainer, 'style'>>
    }
  }
}

export const ZoomParticipantViewInner: React.FC<ZoomParticipantViewInnerProps> = ({ show }) => {
  const { setParticipantElement, sessionStarted, remoteParticipant } = useZoom()
  const containerRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (show) {
      setParticipantElement(containerRef.current)
    }
    return () => setParticipantElement(null)
  }, [setParticipantElement, show])

  return (
    <video-player-container
      style={{
        width: '100%',
        height: '100%',
        display: !show || !sessionStarted || !remoteParticipant?.isVideoOn ? 'none' : 'block',
      }}
      ref={containerRef}
    />
  )
}
