import React, { useEffect, useState } from 'react'
import CSSModules from 'react-css-modules'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { cloneDeep, isEmpty, sumBy } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { ClientListClientObject, ConversationObject, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { Avatar, Dashlet, Label, RefreshButton, stringUtils } from '@lyrahealth-inc/ui-core'

import styles from './messagesDashlet.module.scss'
import { CLIENT_HOME } from '../../../common/constants/routingConstants'
import { getClientFullName } from '../../../common/utils/utils'
import { getAuthConfig } from '../../../data/auth/authSelectors'
import { getInactiveV2Clients } from '../../../data/lyraTherapy/clientSelectors'
import { RootState } from '../../../data/store'
import { clearSelectedAssignment } from '../../assignments/data/assignmentsAutoActions'
import { selectLtClient } from '../../clients/clientDetails/data/ltClientDetailsAutoActions'
import {
  clearSelectedConversation,
  getMessageConversations,
  selectMessageConversation,
} from '../../messages/data/messagesAutoActions'
import { getConversations, getMessagesConversationsFetched } from '../../messages/data/messagesSelectors'
import MessagesPopover from '../../messages/MessagesPopover'

export const MessagesDashlet: React.FC<MessagesDashletProps> = ({
  clients,
  conversationsFetched,
  activeClientsFetched,
  messageConversations,
  clientsInactive,
  providerId,
  actions: { getMessageConversations, clearSelectedConversation, selectLtClient, selectMessageConversation },
}) => {
  const showOverdueMessageFlag = useSelector(getAuthConfig)?.showOverdueMessageFlag ?? false
  const [isRefreshingMessages, setIsRefreshingMessages] = useState(false)
  const [msgPopoverOpen, setMsgPopoverOpen] = useState(false)
  const navigate = useNavigate()
  const { isPreferredNameEnabled } = useFlags()

  useEffect(() => {
    return () => clearSelectedConversation()
  }, [clearSelectedConversation])

  const unreadCount =
    sumBy(
      messageConversations,
      // @ts-expect-error TS(2345): Argument of type '(conversation: ConversationObjec... Remove this comment to see the full error message
      (conversation) => conversation?.conversation_attributes?.unread_provider_messages_count,
    ) || 0
  const onMessageItemClicked = (client: ClientListClientObject, conversation: ConversationObject) => {
    selectLtClient(client)
    selectMessageConversation(conversation)
    setMsgPopoverOpen(true)
  }

  const closePopover = () => {
    clearSelectedConversation()
    setMsgPopoverOpen(false)
  }

  const renderClientListItem = (conversation: ConversationObject) => {
    const client = Array.isArray(clients) && clients?.find(({ id }: any) => id === conversation.patient_lyra_id)
    if (!client) {
      // in the case where we have a conversation but no matching client
      return
    }
    const initials = stringUtils.getInitials(getClientFullName(client, isPreferredNameEnabled))
    return (
      <div
        className={styles['dashlet-item-container']}
        data-test-id={`MessagesDashlet-message-item-${client.first_name}`}
        key={conversation.conversation_id}
      >
        <button
          className={styles['dashlet-item-content']}
          key={conversation.conversation_id}
          onClick={() => onMessageItemClicked(client, conversation)}
        >
          <div className={styles.left}>
            <Avatar ltStyle size={35}>
              {initials}
            </Avatar>
            <button
              className={styles.clientName}
              onClick={() => {
                selectLtClient(client)
                navigate(CLIENT_HOME.route)
              }}
            >
              {getClientFullName(client, isPreferredNameEnabled)}
            </button>
            {clientsInactive?.some((c: any) => c.id === client.id) && (
              <div className={styles['inactive-tag']} data-test-id='MessagesDashlet-inactiveTag'>
                inactive
              </div>
            )}
          </div>
          <div className={styles.right}>
            {showOverdueMessageFlag && conversation?.overdue && (
              <div className={styles['overdue-tag']} data-test-id='MessagesDashlet-overdueTag'>
                overdue
              </div>
            )}
            <Label
              textColor={styles.x_white}
              backgroundColor={styles.x_alert}
              borderRadius='6px'
              padding='0 7px'
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              text={conversation?.conversation_attributes?.unread_provider_messages_count}
            />
          </div>
        </button>
      </div>
    )
  }

  const updateMessageCount = () => {
    setIsRefreshingMessages(true)
    getMessageConversations({
      provider_lyra_id: providerId,
      ...(showOverdueMessageFlag && { derived_properties: 'overdue' }),
    }).then(() => {
      setIsRefreshingMessages(false)
    })
  }
  return (
    <div className={styles['dashlet-container']} data-test-id='MessagesDashlet-container'>
      {msgPopoverOpen && (
        <div className={styles['messenger-container']}>
          {<MessagesPopover closePopover={closePopover} onUpdateMessageCount={updateMessageCount} />}
        </div>
      )}
      <div style={{ marginBottom: '20px', width: '100%', height: msgPopoverOpen ? '400px' : 'auto' }}>
        <Dashlet
          title={`Unread messages${activeClientsFetched ? ` (${unreadCount})` : ''}`}
          buttons={<RefreshButton size='20' isLoading={isRefreshingMessages} onClick={updateMessageCount} />}
          isLoading={!activeClientsFetched || (!conversationsFetched && isEmpty(messageConversations))}
        >
          {unreadCount === 0 ? (
            <div className={styles['empty-dashlet']}>No new messages</div>
          ) : (
            cloneDeep(messageConversations)
              ?.sort(
                (a, b) =>
                  new Date(b.conversation_update_datetime).getTime() -
                  new Date(a.conversation_update_datetime).getTime(),
              )
              ?.map(
                (conversation: any) =>
                  conversation?.conversation_attributes?.unread_provider_messages_count > 0 &&
                  renderClientListItem(conversation),
              )
          )}
        </Dashlet>
      </div>
    </div>
  )
}

type MessagesDashletProps = {
  actions: {
    clearSelectedConversation: () => void
    selectLtClient: (client: ClientListClientObject) => void
    clearSelectedAssignment: () => void
    getMessageConversations: ({ provider_lyra_id }: { provider_lyra_id: string }) => Promise<object>
    selectMessageConversation: (conversation: ConversationObject) => void
  }
  clients: ClientListClientObject[]
  conversationsFetched?: boolean
  activeClientsFetched: boolean
  messageConversations?: ConversationObject[]
  clientsInactive?: ClientListClientObject[]
  providerId: string
}

const mapStateToProps = (state: RootState) => ({
  messageConversations: getConversations(state),
  conversationsFetched: getMessagesConversationsFetched(state),
  clientsInactive: getInactiveV2Clients(state),
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): any => ({
  actions: bindActionCreators(
    {
      clearSelectedConversation,
      selectLtClient,
      clearSelectedAssignment,
      getMessageConversations,
      selectMessageConversation,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CSSModules(MessagesDashlet, styles, { allowMultiple: true }))
