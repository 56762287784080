import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { noop } from 'lodash-es'
import styled from 'styled-components/native'

import { AssignmentResponse, VideoStatuses } from '@lyrahealth-inc/shared-app-logic'
import { tID, VideoPlayerModal } from '@lyrahealth-inc/ui-core-crossplatform'

import { VideoPlayer } from '../videoPlayer/VideoPlayer.web'

type LessonVideoPlayerProps = {
  url: string
  assignmentResponse?: AssignmentResponse
  videoPropertyName: string
  onClickPlay?: () => void
  onVideoEnded?: () => void
  updateVideoStatus?: ({
    status,
    progress,
    totalDuration,
  }: {
    status: string
    progress: number
    totalDuration: number
  }) => void
  /** Modal close */
  onClose?: () => void
  nextVideoOverlay?: React.JSX.Element
  onVideoView?: () => void
  useModal?: boolean
}

const VideoContainer = styled.View({
  paddingTop: '56.25%', // 16:9 fixed aspect ratio box
})

/** Lessons video player for web only - uses react-player */
export const LessonVideoPlayer: FunctionComponent<LessonVideoPlayerProps> = ({
  url,
  assignmentResponse,
  videoPropertyName,
  onClickPlay = noop,
  onVideoEnded = noop,
  updateVideoStatus,
  onClose = noop,
  nextVideoOverlay,
  onVideoView = noop,
  useModal = true,
}) => {
  const videoResponse = assignmentResponse?.response[videoPropertyName]
  const curVideo = useRef('')
  const watched = useRef(videoResponse?.status === VideoStatuses.completed)
  const started = useRef(videoResponse?.status === VideoStatuses.started)
  const videoPlayerRef = useRef<ReactPlayer | null>(null)
  const [ended, setEnded] = useState(false)
  const duration = useRef<number>(0)
  const hasResumed = useRef(false)

  useEffect(() => {
    // Reset props when video changes
    if (curVideo.current !== videoPropertyName) {
      watched.current = videoResponse?.status === VideoStatuses.completed
      started.current = videoResponse?.status === VideoStatuses.started
      setEnded(false)
      duration.current = 0
      onVideoView()
      curVideo.current = videoPropertyName
    }
  }, [onVideoView, videoPropertyName, videoResponse?.status])

  const onStart = (): void => {
    if (watched.current || started.current) {
      return
    }
    updateVideoStatus &&
      updateVideoStatus({ status: VideoStatuses.started, progress: 0, totalDuration: duration.current })
    if (!ended) {
      setEnded(false)
    }
  }

  const onProgress = ({ played }: { played: number }): void => {
    if (!played || watched.current || !Number.isFinite(played) || !updateVideoStatus) {
      return
    }
    if (played <= 0.9) {
      return updateVideoStatus({ status: VideoStatuses.started, progress: played, totalDuration: duration.current })
    } else {
      watched.current = true
      return updateVideoStatus({ status: VideoStatuses.completed, progress: 0.0, totalDuration: duration.current })
    }
  }

  const onEnded = (): void => {
    if (!watched.current) {
      watched.current = true
      updateVideoStatus &&
        updateVideoStatus({ status: VideoStatuses.completed, progress: 0.0, totalDuration: duration.current })
    }
    setEnded(true)
    onVideoEnded()
  }

  const onPlay = (): void => {
    if (ended) {
      onClickPlay()
    }
  }

  const onSeek = (num: number): void => {
    const seekedValue = num / duration.current
    onProgress({ played: seekedValue })
  }

  const onDuration = (incomingDuration: number): void => {
    // Possible bug with onReady - getDuration() called from onReady returns null. Running it here instead to make sure duration exists because seekTo needs it to be set
    if (!hasResumed.current && videoResponse) {
      const resumeTo = videoResponse.progress
      if (videoPlayerRef.current?.getDuration()) {
        videoPlayerRef.current?.seekTo(typeof resumeTo === 'string' ? parseFloat(resumeTo) : resumeTo, 'fraction')
        hasResumed.current = true
      }
    }

    duration.current = incomingDuration
  }

  const playerProps = {
    onProgress,
    progressInterval: 10000,
    onStart,
    onEnded,
    onPlay,
    onSeek,
    onDuration,
    playing: true,
    controls: true,
  }

  return useModal ? (
    <VideoPlayerModal
      videoPlayerRef={videoPlayerRef}
      url={url}
      isVisible
      autoPlay
      onClose={onClose}
      playerProps={playerProps}
      videoOverlay={nextVideoOverlay}
    />
  ) : (
    <VideoContainer testID={tID('VideoPlayer')}>
      <VideoPlayer
        url={url}
        videoPlayerRef={videoPlayerRef}
        {...playerProps}
        width={'100%'}
        height={'100%'}
        style={{ position: 'absolute', top: 0 }}
      />
    </VideoContainer>
  )
}
