import { combineReducers } from '@reduxjs/toolkit'

import appointments from './appointmentsSlice'
import data from './ltClientDetailsDataReducer'
import assignments from '../../../assignments/data/assignmentsReducer'
import notes from '../../../clientNotes/data/clientNotesReducer'
import episodes from '../../../episodes/data/episodesReducer'
import medications from '../../../medications/data/medicationsReducer'

export default combineReducers({
  assignments,
  appointments,
  notes,
  medications,
  episodes,
  data,
})
