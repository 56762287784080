import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { isWithinInterval } from 'date-fns'

import { getYearMonthDayLocaleByDate } from '../../../common/utils/utils'
import { getRequestedCaseloadManagementQuarter } from '../../../data/lyraTherapy/clientSelectors'

export const useTargetDate = () => {
  const requestedQuarter = useSelector(getRequestedCaseloadManagementQuarter)
  return useMemo(() => {
    const todayDate = new Date()

    const requestedQuarterName = requestedQuarter ? Object.keys(requestedQuarter)[0] : ''
    const requestedDate =
      requestedQuarterName.length && new Date(`${requestedQuarter?.[requestedQuarterName][1]}T00:00`) // need time or else UTC time is assumed

    const shouldRequestCurrentQuarter =
      !requestedDate ||
      isWithinInterval(todayDate, {
        start: new Date(`${requestedQuarter?.[requestedQuarterName][0]}T00:00`),
        end: new Date(`${requestedQuarter?.[requestedQuarterName][1]}T00:00`),
      })
    return {
      targetDate: getYearMonthDayLocaleByDate(shouldRequestCurrentQuarter ? todayDate : requestedDate),
      shouldRequestCurrentQuarter,
    }
  }, [requestedQuarter])
}
